import React from 'react';
import {Spin} from 'antd';
import PropTypes from 'prop-types';
import {LoadingOutlined} from '@ant-design/icons';
import {Colors} from '_styles';

const Icon = <LoadingOutlined style={{fontSize: 35, color: Colors.orange}} spin />;

const Loading = props => {
	const {align, cover} = props;
	return (
		<div {...props} className={`loading text-${align} cover-${cover}`}>
			<Spin indicator={Icon} />
		</div>
	);
};

Loading.propTypes = {
	size: PropTypes.string,
	cover: PropTypes.string,
};

Loading.defaultProps = {
	align: 'center',
	cover: 'inline',
};

export default Loading;
