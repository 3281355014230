import fetch from './api-service';
import {AUTH_TOKEN} from '../redux/constants/Auth';

const faculdadeService = {};

faculdadeService.get = async id => {
	return fetch({
		url: `/v1/rest/faculdade/${id}`,
		method: 'get',
		headers: {is_app: true},
	});
};

faculdadeService.getLogo = async id => {
	return fetch({
		url: `/v1/rest/faculdade/logo/${id}`,
		method: 'get',
		headers: {is_app: true},
	});
};

faculdadeService.getCidades = async function () {
	return fetch({
		url: `/v1/rpc/cidade-filtro`,
		method: 'get',
		headers: {is_app: true},
	});
};

faculdadeService.getDisciplinasFaculdade = async (id, filter) => {
	return fetch({
		url: `/v1/rest/faculdade-disciplina/${id}?filters=${JSON.stringify(filter)}`,
		method: 'get',
	});
};

faculdadeService.getMatriculasFaculdade = async id => {
	return fetch({
		url: `/v1/rest/faculdade-matriculas/${id}`,
		method: 'get',
		headers: {is_app: true},
	});
};

faculdadeService.getDetalhesDisciplina = async id => {
	return fetch({
		url: `/v1/rest/faculdade-detalhes-disciplina/${id}`,
		method: 'get',
		headers: {is_app: true},
	});
};

faculdadeService.getAll = async function () {
	return fetch({
		url: '/v1/rest/faculdade',
		method: 'get',
		headers: {is_app: true},
	});
};

faculdadeService.getAllNomes = async function () {
	return fetch({
		url: '/v1/rest/faculdade',
		method: 'get',
		headers: {is_app: true, sem_paginacao: true},
	});
};

faculdadeService.post = async function (values) {
	return fetch({
		url: '/v1/rest/faculdade',
		method: 'post',
		data: values,
	});
};

faculdadeService.update = async function (values, id) {
	return fetch({
		url: `/v1/rest/faculdade/${id}`,
		method: 'put',
		data: values,
	});
};

faculdadeService.getFiltro = async function (id, filter) {
	return fetch({
		url: `/v1/rest/faculdade-matriculas/${id}?filters=${JSON.stringify(filter)}`,
		method: 'get',
	});
};

faculdadeService.uploadLogo = async function (values, id) {
	const formData = new FormData();
	formData.append('file', values);
	// formData.append('id_pessoa_juridica', id);
	return fetch({
		url: `/v1/rest/faculdade/upload-logo/${id}`,
		method: 'post',
		data: formData,
		body: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
			is_app: true,
			Authorization: localStorage.getItem(AUTH_TOKEN),
			authorization: localStorage.getItem(AUTH_TOKEN),
		},
	});
};

export default faculdadeService;
