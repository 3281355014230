export const primary = '#551B6B';
export const orange = '#EA5B13';
export const white = '#ffffff';
export const black = '#000000';
export const grey = '#9A9797';
export const inputBorder = '';
export const inputBorderHover = '#673178';
export const error = '#ff4d4f';
export const whats = '#25D366';
export const green = '#1FAB0B';
