import cidades from '_providers/cidades';

export const getAll = () => {
	return cidades.flatMap(e => e.cidades.map(v => ({cidade: v.cidade, id: v.id_cidade, estado: e.estado}))).sort();
};

export const find = nome => {
	return getAll().find(v => v.cidade === nome);
};

export const findByEstado = estado => {
	return getAll()
		.filter(v => v.estado == estado)
		.sort();
};

export const asOption = data => {
	return data.map(v => ({label: v.cidade, value: v.cidade})).sort();
};

export const findByDesc = (id_estado, descricao) => {
	return findByEstado(id_estado)
		.find(v => v.cidade === descricao)
		.sort();
};
