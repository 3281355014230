import cidades from '_providers/cidades';

export const getAll = () => {
	return cidades.map(v => ({estado: v.estado, sigla: v.sigla, id: v.id_estado}));
};

export const find = nome => {
	return getAll().find(v => v.estado == nome);
};

export const asOption = data => {
	return data.map(v => ({label: v.estado, value: v.estado})).sort();
};

export const getBySigla = sigla => {
	return getAll().find(v => v.sigla === sigla);
};
