import {Colors, Typography} from '_styles';
import {Text} from '_atoms';
import './styles.css';
import {useState} from 'react';
import {Spin} from 'antd';

export default props => {
	const [fade, setFade] = useState(false);

	return (
		<button
			style={
				props.type !== 'custom'
					? {
							display: 'flex',
							borderRadius: 30,
							height: 45,
							width: 159,
							backgroundColor: props.type === 'secondary' ? Colors.orange : Colors.primary,
							justifyContent: 'center',
							alignItems: 'center',
							boxShadow: '0px 4px 10px 0px #00000040',
							...props.style,
					  }
					: {backgroundColor: 'transparent', ...props.style}
			}
			onClick={() => {
				setFade(true);

				if (typeof props.onClick === 'function') props.onClick();
			}}
			onAnimationEnd={() => {
				setFade(false);
			}}
			className={`${fade ? 'fade' : ''} button ${props.className ?? ''}`}>
			{typeof props.children === 'string' ? (
				<Text
					style={{
						...Typography.medium22,
						color: props.style?.color ?? Colors.white,
						width: 'max-content',
					}}>
					{props.children}
				</Text>
			) : (
				props.children
			)}
		</button>
	);
};
