import AppRouter from './app-router';
import {useNavigate} from 'react-router-dom';

export default () => {
	return (
		<div>
			{/*Gerava um problema ao trocar de rota*/}
			{/*<AuthRouter />*/}
			<AppRouter />
		</div>
	);
};
