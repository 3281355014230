import fetch from './api-service';
import {DISCIPLINA, USER} from '../redux/constants/User';
import {AUTH_TOKEN} from '../redux/constants/Auth';

const DisciplinaService = {};

DisciplinaService.getAll = async function (page, pageSize) {
	return fetch({
		url: `/v1/rest/disciplina/?page=${page}&pageSize=${pageSize}`,
		method: 'get',
	});
};

DisciplinaService.getFiltro = async function (filter) {
	return fetch({
		url: `/v1/rest/disciplina/?filters=${JSON.stringify(filter)}`,
		method: 'get',
	});
};

DisciplinaService.getPaginate = async function () {
	return fetch({
		url: '/v1/rest/disciplina',
		method: 'get',
		headers: {is_app: true},
	});
};

DisciplinaService.get = async id => {
	return fetch({
		url: `/v1/rest/disciplina/${id}`,
		method: 'get',
	});
};

DisciplinaService.getLogo = async id => {
	return fetch({
		url: `/v1/rest/disciplina/logo/${id}`,
		method: 'get',
	});
};

DisciplinaService.post = async function (values) {
	return fetch({
		url: '/v1/rest/disciplina',
		method: 'post',
		data: values,
	});
};

DisciplinaService.put = async function (values, id) {
	return fetch({
		url: `/v1/rest/disciplina/${id}`,
		method: 'put',
		data: values,
	});
};

DisciplinaService.delete = async id => {
	return fetch({
		url: `/v1/rest/disciplina/${id}`,
		method: 'delete',
	});
};

DisciplinaService.planilha = async function (values, id) {
	const formData = new FormData();
	formData.append('file', values);
	formData.append('id_faculdade', id);
	return fetch({
		url: `v1/rpc/upload-arquivo`,
		method: 'post',
		data: formData,
		body: formData,
		headers: {
			'Content-Type': 'multipart/form-data',

			Authorization: localStorage.getItem(AUTH_TOKEN),
			authorization: localStorage.getItem(AUTH_TOKEN),
		},
	});
};

DisciplinaService.uploadLogo = async function (values, id) {
	const formData = new FormData();
	formData.append('file', values);
	// formData.append('id_disciplina', id);
	return fetch({
		url: `/v1/rest/disciplina/upload-logo/${id}`,
		method: 'post',
		data: formData,
		body: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
			is_app: true,
			Authorization: localStorage.getItem(AUTH_TOKEN),
			authorization: localStorage.getItem(AUTH_TOKEN),
		},
	});
};

export function getDisciplina() {
	return parseInt(localStorage.getItem(DISCIPLINA));
}

export default DisciplinaService;
