import fetch from './api-service';

const recuperarSenhaService = {};

recuperarSenhaService.requisitarRecuperacaoSenha = async function (email) {
	return fetch({
		url: "/v1/rpc/requisitar-recuperacao-senha",
		method: "post",
		data: {
			email,
		},
	});
};

recuperarSenhaService.verificarCodigo = async function (email, codigo) {
	return fetch({
		url: "/v1/rpc/verificar-codigo-recuperacao-senha",
		method: "post",
		data: {
			email,
			codigo_verificacao: codigo,
		},
	});
};

recuperarSenhaService.trocarSenha = async function (email, codigo, senha) {
	return fetch({
		url: "/v1/rpc/trocar-senha-recuperacao-senha",
		method: "post",
		data: {
			email,
			senha,
			codigo_verificacao: codigo,
		},
	});
};

export default recuperarSenhaService;
