import fetch from './api-service';

const JwtAuthService = {};

JwtAuthService.login = function (email, senha) {
	const body = {
		email,
		senha,
	};

	return fetch({
		url: '/v1/rpc/auth',
		method: 'post',
		headers: {
			'public-request': 'true',
		},
		data: body,
	});
};

export default JwtAuthService;
