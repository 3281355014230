import {Input} from 'antd';
import {Colors, Typography} from '_styles';
import {Text} from '_atoms';
import '_styles/styles.css';
import './styles.css';
import InputMask from 'react-input-mask';

const style = {...Typography.regular20};
export default props => {
	return (
		<div style={{width: '100%'}}>
			{props.label && (
				<Text style={{...Typography.regular22, color: Colors.orange, paddingLeft: 18}}>
					{props.label}
					{props.required ? '*' : ''}
				</Text>
			)}
			{!props.mask && (
				<Input
					{...props}
					className={'custom-input-wrapper'}
					style={{
						...style,
						...props.style,
					}}
				/>
			)}
			{!!props.mask && (
				<InputMask
					{...props}
					className={'ant-input custom-input-wrapper'}
					style={{
						...style,
						...props.style,
					}}
				/>
			)}
		</div>
	);
};
