import bancos from '_providers/bancos';

export const findAll = () => {
	return bancos;
};

export const find = id => {
	return findAll().find(v => v.id === id);
};

export const asOption = data => {
	return data.map(v => ({value: v.id, label: `${v.banco} (${v.codigo})`}));
};
