export default [
	{
		estado: 'Acre ',
		sigla: 'AC',
		id_estado: 1,
		cidades: [
			{
				cidade: 'Cruzeiro do Sul',
				id_cidade: 84,
			},
			{
				cidade: 'Feijó',
				id_cidade: 86,
			},
			{
				cidade: 'Jordão',
				id_cidade: 87,
			},
			{
				cidade: 'Mâncio Lima',
				id_cidade: 88,
			},
			{
				cidade: 'Manoel Urbano',
				id_cidade: 89,
			},
			{
				cidade: 'Marechal Thaumaturgo',
				id_cidade: 90,
			},
			{
				cidade: 'Plácido de Castro',
				id_cidade: 91,
			},
			{
				cidade: 'Porto Acre',
				id_cidade: 92,
			},
			{
				cidade: 'Porto Walter',
				id_cidade: 93,
			},
			{
				cidade: 'Rio Branco',
				id_cidade: 94,
			},
			{
				cidade: 'Rodrigues Alves',
				id_cidade: 95,
			},
			{
				cidade: 'Assis Brasil',
				id_cidade: 80,
			},
			{
				cidade: 'Santa Rosa do Purus',
				id_cidade: 96,
			},
			{
				cidade: 'Acrelândia',
				id_cidade: 79,
			},
			{
				cidade: 'Brasiléia',
				id_cidade: 81,
			},
			{
				cidade: 'Bujari',
				id_cidade: 82,
			},
			{
				cidade: 'Capixaba',
				id_cidade: 83,
			},
			{
				cidade: 'Epitaciolândia',
				id_cidade: 85,
			},
			{
				cidade: 'Senador Guiomard',
				id_cidade: 98,
			},
			{
				cidade: 'Sena Madureira',
				id_cidade: 97,
			},
			{
				cidade: 'Tarauacá',
				id_cidade: 99,
			},
			{
				cidade: 'Xapuri',
				id_cidade: 100,
			},
		],
	},
	{
		estado: 'Alagoas',
		sigla: 'AL',
		id_estado: 2,
		cidades: [
			{
				cidade: 'Atalaia',
				id_cidade: 104,
			},
			{
				cidade: 'Barra de Santo Antônio',
				id_cidade: 105,
			},
			{
				cidade: 'Barra de São Miguel',
				id_cidade: 106,
			},
			{
				cidade: 'Batalha',
				id_cidade: 107,
			},
			{
				cidade: 'Belém',
				id_cidade: 108,
			},
			{
				cidade: 'Belo Monte',
				id_cidade: 109,
			},
			{
				cidade: 'Boca da Mata',
				id_cidade: 110,
			},
			{
				cidade: 'Branquinha',
				id_cidade: 111,
			},
			{
				cidade: 'Cacimbinhas',
				id_cidade: 112,
			},
			{
				cidade: 'Cajueiro',
				id_cidade: 113,
			},
			{
				cidade: 'Campestre',
				id_cidade: 114,
			},
			{
				cidade: 'Campo Alegre',
				id_cidade: 115,
			},
			{
				cidade: 'Campo Grande',
				id_cidade: 116,
			},
			{
				cidade: 'Canapi',
				id_cidade: 117,
			},
			{
				cidade: 'Capela',
				id_cidade: 118,
			},
			{
				cidade: 'Carneiros',
				id_cidade: 119,
			},
			{
				cidade: 'Chã Preta',
				id_cidade: 120,
			},
			{
				cidade: 'Coité do Nóia',
				id_cidade: 121,
			},
			{
				cidade: 'Colônia Leopoldina',
				id_cidade: 122,
			},
			{
				cidade: 'Coqueiro Seco',
				id_cidade: 123,
			},
			{
				cidade: 'Coruripe',
				id_cidade: 124,
			},
			{
				cidade: 'Craíbas',
				id_cidade: 125,
			},
			{
				cidade: 'Delmiro Gouveia',
				id_cidade: 126,
			},
			{
				cidade: 'Dois Riachos',
				id_cidade: 127,
			},
			{
				cidade: 'Estrela de Alagoas',
				id_cidade: 128,
			},
			{
				cidade: 'Feira Grande',
				id_cidade: 129,
			},
			{
				cidade: 'Feliz Deserto',
				id_cidade: 130,
			},
			{
				cidade: 'Flexeiras',
				id_cidade: 131,
			},
			{
				cidade: 'Girau do Ponciano',
				id_cidade: 132,
			},
			{
				cidade: 'Ibateguara',
				id_cidade: 133,
			},
			{
				cidade: 'Igaci',
				id_cidade: 134,
			},
			{
				cidade: 'Igreja Nova',
				id_cidade: 135,
			},
			{
				cidade: 'Inhapi',
				id_cidade: 136,
			},
			{
				cidade: 'Água Branca',
				id_cidade: 101,
			},
			{
				cidade: 'Jacaré dos Homens',
				id_cidade: 137,
			},
			{
				cidade: 'Jacuípe',
				id_cidade: 138,
			},
			{
				cidade: 'Japaratinga',
				id_cidade: 139,
			},
			{
				cidade: 'Jaramataia',
				id_cidade: 140,
			},
			{
				cidade: 'Jequiá da Praia',
				id_cidade: 141,
			},
			{
				cidade: 'Joaquim Gomes',
				id_cidade: 142,
			},
			{
				cidade: 'Jundiá',
				id_cidade: 143,
			},
			{
				cidade: 'Junqueiro',
				id_cidade: 144,
			},
			{
				cidade: 'Lagoa da Canoa',
				id_cidade: 145,
			},
			{
				cidade: 'Limoeiro de Anadia',
				id_cidade: 146,
			},
			{
				cidade: 'Maceió',
				id_cidade: 147,
			},
			{
				cidade: 'Major Isidoro',
				id_cidade: 148,
			},
			{
				cidade: 'Maragogi',
				id_cidade: 150,
			},
			{
				cidade: 'Maravilha',
				id_cidade: 151,
			},
			{
				cidade: 'Marechal Deodoro',
				id_cidade: 152,
			},
			{
				cidade: 'Maribondo',
				id_cidade: 153,
			},
			{
				cidade: 'Mar Vermelho',
				id_cidade: 149,
			},
			{
				cidade: 'Mata Grande',
				id_cidade: 154,
			},
			{
				cidade: 'Matriz de Camaragibe',
				id_cidade: 155,
			},
			{
				cidade: 'Messias',
				id_cidade: 156,
			},
			{
				cidade: 'Minador do Negrão',
				id_cidade: 157,
			},
			{
				cidade: 'Monteirópolis',
				id_cidade: 158,
			},
			{
				cidade: 'Murici',
				id_cidade: 159,
			},
			{
				cidade: 'Novo Lino',
				id_cidade: 160,
			},
			{
				cidade: "Olho d'Água das Flores",
				id_cidade: 161,
			},
			{
				cidade: "Olho d'Água do Casado",
				id_cidade: 162,
			},
			{
				cidade: "Olho d'Água Grande",
				id_cidade: 163,
			},
			{
				cidade: 'Olivença',
				id_cidade: 164,
			},
			{
				cidade: 'Ouro Branco',
				id_cidade: 165,
			},
			{
				cidade: 'Palestina',
				id_cidade: 166,
			},
			{
				cidade: 'Palmeira dos Índios',
				id_cidade: 167,
			},
			{
				cidade: 'Pão de Açúcar',
				id_cidade: 168,
			},
			{
				cidade: 'Pariconha',
				id_cidade: 169,
			},
			{
				cidade: 'Paripueira',
				id_cidade: 170,
			},
			{
				cidade: 'Passo de Camaragibe',
				id_cidade: 171,
			},
			{
				cidade: 'Paulo Jacinto',
				id_cidade: 172,
			},
			{
				cidade: 'Penedo',
				id_cidade: 173,
			},
			{
				cidade: 'Piaçabuçu',
				id_cidade: 174,
			},
			{
				cidade: 'Pilar',
				id_cidade: 175,
			},
			{
				cidade: 'Pindoba',
				id_cidade: 176,
			},
			{
				cidade: 'Piranhas',
				id_cidade: 177,
			},
			{
				cidade: 'Poço das Trincheiras',
				id_cidade: 178,
			},
			{
				cidade: 'Porto Calvo',
				id_cidade: 179,
			},
			{
				cidade: 'Porto de Pedras',
				id_cidade: 180,
			},
			{
				cidade: 'Porto Real do Colégio',
				id_cidade: 181,
			},
			{
				cidade: 'Quebrangulo',
				id_cidade: 182,
			},
			{
				cidade: 'Rio Largo',
				id_cidade: 183,
			},
			{
				cidade: 'Roteiro',
				id_cidade: 184,
			},
			{
				cidade: 'Santa Luzia do Norte',
				id_cidade: 185,
			},
			{
				cidade: 'Santana do Ipanema',
				id_cidade: 186,
			},
			{
				cidade: 'Santana do Mundaú',
				id_cidade: 187,
			},
			{
				cidade: 'Anadia',
				id_cidade: 102,
			},
			{
				cidade: 'São Brás',
				id_cidade: 188,
			},
			{
				cidade: 'São José da Laje',
				id_cidade: 189,
			},
			{
				cidade: 'São José da Tapera',
				id_cidade: 190,
			},
			{
				cidade: 'São Luís do Quitunde',
				id_cidade: 191,
			},
			{
				cidade: 'São Miguel dos Campos',
				id_cidade: 192,
			},
			{
				cidade: 'São Miguel dos Milagres',
				id_cidade: 193,
			},
			{
				cidade: 'São Sebastião',
				id_cidade: 194,
			},
			{
				cidade: 'Satuba',
				id_cidade: 195,
			},
			{
				cidade: 'Senador Rui Palmeira',
				id_cidade: 196,
			},
			{
				cidade: "Tanque d'Arca",
				id_cidade: 197,
			},
			{
				cidade: 'Taquarana',
				id_cidade: 198,
			},
			{
				cidade: 'Arapiraca',
				id_cidade: 103,
			},
			{
				cidade: 'Teotônio Vilela',
				id_cidade: 199,
			},
			{
				cidade: 'Traipu',
				id_cidade: 200,
			},
			{
				cidade: 'União dos Palmares',
				id_cidade: 201,
			},
			{
				cidade: 'Viçosa',
				id_cidade: 202,
			},
		],
	},
	{
		estado: 'Amazonas',
		sigla: 'AM',
		id_estado: 3,
		cidades: [
			{
				cidade: 'Atalaia do Norte',
				id_cidade: 224,
			},
			{
				cidade: 'Autazes',
				id_cidade: 225,
			},
			{
				cidade: 'Barcelos',
				id_cidade: 226,
			},
			{
				cidade: 'Barreirinha',
				id_cidade: 227,
			},
			{
				cidade: 'Benjamin Constant',
				id_cidade: 228,
			},
			{
				cidade: 'Beruri',
				id_cidade: 229,
			},
			{
				cidade: 'Boa Vista do Ramos',
				id_cidade: 230,
			},
			{
				cidade: 'Boca do Acre',
				id_cidade: 231,
			},
			{
				cidade: 'Borba',
				id_cidade: 232,
			},
			{
				cidade: 'Caapiranga',
				id_cidade: 233,
			},
			{
				cidade: 'Canutama',
				id_cidade: 234,
			},
			{
				cidade: 'Carauari',
				id_cidade: 235,
			},
			{
				cidade: 'Careiro',
				id_cidade: 236,
			},
			{
				cidade: 'Careiro da Várzea',
				id_cidade: 237,
			},
			{
				cidade: 'Coari',
				id_cidade: 238,
			},
			{
				cidade: 'Codajás',
				id_cidade: 239,
			},
			{
				cidade: 'Eirunepé',
				id_cidade: 240,
			},
			{
				cidade: 'Envira',
				id_cidade: 241,
			},
			{
				cidade: 'Fonte Boa',
				id_cidade: 242,
			},
			{
				cidade: 'Guajará',
				id_cidade: 243,
			},
			{
				cidade: 'Humaitá',
				id_cidade: 244,
			},
			{
				cidade: 'Ipixuna',
				id_cidade: 245,
			},
			{
				cidade: 'Iranduba',
				id_cidade: 246,
			},
			{
				cidade: 'Itacoatiara',
				id_cidade: 247,
			},
			{
				cidade: 'Itamarati',
				id_cidade: 248,
			},
			{
				cidade: 'Itapiranga',
				id_cidade: 249,
			},
			{
				cidade: 'Japurá',
				id_cidade: 250,
			},
			{
				cidade: 'Juruá',
				id_cidade: 251,
			},
			{
				cidade: 'Jutaí',
				id_cidade: 252,
			},
			{
				cidade: 'Lábrea',
				id_cidade: 253,
			},
			{
				cidade: 'Manacapuru',
				id_cidade: 254,
			},
			{
				cidade: 'Manaquiri',
				id_cidade: 255,
			},
			{
				cidade: 'Manaus',
				id_cidade: 256,
			},
			{
				cidade: 'Manicoré',
				id_cidade: 257,
			},
			{
				cidade: 'Maraã',
				id_cidade: 258,
			},
			{
				cidade: 'Maués',
				id_cidade: 259,
			},
			{
				cidade: 'Nhamundá',
				id_cidade: 260,
			},
			{
				cidade: 'Nova Olinda do Norte',
				id_cidade: 261,
			},
			{
				cidade: 'Novo Airão',
				id_cidade: 262,
			},
			{
				cidade: 'Novo Aripuanã',
				id_cidade: 263,
			},
			{
				cidade: 'Parintins',
				id_cidade: 264,
			},
			{
				cidade: 'Pauini',
				id_cidade: 265,
			},
			{
				cidade: 'Presidente Figueiredo',
				id_cidade: 266,
			},
			{
				cidade: 'Rio Preto da Eva',
				id_cidade: 267,
			},
			{
				cidade: 'Santa Isabel do Rio Negro',
				id_cidade: 268,
			},
			{
				cidade: 'Alvarães',
				id_cidade: 219,
			},
			{
				cidade: 'Amaturá',
				id_cidade: 220,
			},
			{
				cidade: 'Anamã',
				id_cidade: 221,
			},
			{
				cidade: 'Santo Antônio do Içá',
				id_cidade: 269,
			},
			{
				cidade: 'São Gabriel da Cachoeira',
				id_cidade: 270,
			},
			{
				cidade: 'São Paulo de Olivença',
				id_cidade: 271,
			},
			{
				cidade: 'São Sebastião do Uatumã',
				id_cidade: 272,
			},
			{
				cidade: 'Anori',
				id_cidade: 222,
			},
			{
				cidade: 'Apuí',
				id_cidade: 223,
			},
			{
				cidade: 'Silves',
				id_cidade: 273,
			},
			{
				cidade: 'Tabatinga',
				id_cidade: 274,
			},
			{
				cidade: 'Tapauá',
				id_cidade: 275,
			},
			{
				cidade: 'Tefé',
				id_cidade: 276,
			},
			{
				cidade: 'Tonantins',
				id_cidade: 277,
			},
			{
				cidade: 'Uarini',
				id_cidade: 278,
			},
			{
				cidade: 'Urucará',
				id_cidade: 279,
			},
			{
				cidade: 'Urucurituba',
				id_cidade: 280,
			},
		],
	},
	{
		estado: 'Amapá',
		sigla: 'AP',
		id_estado: 4,
		cidades: [
			{
				cidade: 'Calçoene',
				id_cidade: 204,
			},
			{
				cidade: 'Cutias',
				id_cidade: 205,
			},
			{
				cidade: 'Ferreira Gomes',
				id_cidade: 206,
			},
			{
				cidade: 'Itaubal',
				id_cidade: 207,
			},
			{
				cidade: 'Laranjal do Jari',
				id_cidade: 208,
			},
			{
				cidade: 'Macapá',
				id_cidade: 209,
			},
			{
				cidade: 'Mazagão',
				id_cidade: 210,
			},
			{
				cidade: 'Oiapoque',
				id_cidade: 211,
			},
			{
				cidade: 'Pedra Branca do Amaparí',
				id_cidade: 212,
			},
			{
				cidade: 'Porto Grande',
				id_cidade: 213,
			},
			{
				cidade: 'Pracuúba',
				id_cidade: 214,
			},
			{
				cidade: 'Santana',
				id_cidade: 215,
			},
			{
				cidade: 'Amapá',
				id_cidade: 203,
			},
			{
				cidade: 'Serra do Navio',
				id_cidade: 216,
			},
			{
				cidade: 'Tartarugalzinho',
				id_cidade: 217,
			},
			{
				cidade: 'Vitória do Jari',
				id_cidade: 218,
			},
		],
	},
	{
		estado: 'Bahia',
		sigla: 'BA',
		id_estado: 5,
		cidades: [
			{
				cidade: 'Almadina',
				id_cidade: 289,
			},
			{
				cidade: 'Aratuípe',
				id_cidade: 308,
			},
			{
				cidade: 'Aurelino Leal',
				id_cidade: 309,
			},
			{
				cidade: 'Baianópolis',
				id_cidade: 310,
			},
			{
				cidade: 'Baixa Grande',
				id_cidade: 311,
			},
			{
				cidade: 'Água Fria',
				id_cidade: 285,
			},
			{
				cidade: 'Banzaê',
				id_cidade: 312,
			},
			{
				cidade: 'Barra',
				id_cidade: 313,
			},
			{
				cidade: 'Barra da Estiva',
				id_cidade: 314,
			},
			{
				cidade: 'Barra do Choça',
				id_cidade: 315,
			},
			{
				cidade: 'Barra do Mendes',
				id_cidade: 316,
			},
			{
				cidade: 'Barra do Rocha',
				id_cidade: 317,
			},
			{
				cidade: 'Barreiras',
				id_cidade: 318,
			},
			{
				cidade: 'Barro Alto',
				id_cidade: 319,
			},
			{
				cidade: 'Barrocas',
				id_cidade: 321,
			},
			{
				cidade: 'Belmonte',
				id_cidade: 322,
			},
			{
				cidade: 'Belo Campo',
				id_cidade: 323,
			},
			{
				cidade: 'Barro Preto (antigo Gov. Lomanto Jr.)',
				id_cidade: 320,
			},
			{
				cidade: 'Biritinga',
				id_cidade: 324,
			},
			{
				cidade: 'Boa Nova',
				id_cidade: 325,
			},
			{
				cidade: 'Boa Vista do Tupim',
				id_cidade: 326,
			},
			{
				cidade: 'Bom Jesus da Lapa',
				id_cidade: 327,
			},
			{
				cidade: 'Bom Jesus da Serra',
				id_cidade: 328,
			},
			{
				cidade: 'Boninal',
				id_cidade: 329,
			},
			{
				cidade: 'Bonito',
				id_cidade: 330,
			},
			{
				cidade: 'Boquira',
				id_cidade: 331,
			},
			{
				cidade: 'Botuporã',
				id_cidade: 332,
			},
			{
				cidade: 'Brejões',
				id_cidade: 333,
			},
			{
				cidade: 'Brejolândia',
				id_cidade: 334,
			},
			{
				cidade: 'Brotas de Macaúbas',
				id_cidade: 335,
			},
			{
				cidade: 'Brumado',
				id_cidade: 336,
			},
			{
				cidade: 'Buerarema',
				id_cidade: 337,
			},
			{
				cidade: 'Buritirama',
				id_cidade: 338,
			},
			{
				cidade: 'Caatiba',
				id_cidade: 339,
			},
			{
				cidade: 'Cabaceiras do Paraguaçu',
				id_cidade: 340,
			},
			{
				cidade: 'Cachoeira',
				id_cidade: 341,
			},
			{
				cidade: 'Caculé',
				id_cidade: 342,
			},
			{
				cidade: 'Caém',
				id_cidade: 343,
			},
			{
				cidade: 'Caetanos',
				id_cidade: 344,
			},
			{
				cidade: 'Caetité',
				id_cidade: 345,
			},
			{
				cidade: 'Cafarnaum',
				id_cidade: 346,
			},
			{
				cidade: 'Cairu',
				id_cidade: 347,
			},
			{
				cidade: 'Caldeirão Grande',
				id_cidade: 348,
			},
			{
				cidade: 'Camacan',
				id_cidade: 349,
			},
			{
				cidade: 'Camaçari',
				id_cidade: 350,
			},
			{
				cidade: 'Camamu',
				id_cidade: 351,
			},
			{
				cidade: 'Campo Alegre de Lourdes',
				id_cidade: 352,
			},
			{
				cidade: 'Campo Formoso',
				id_cidade: 353,
			},
			{
				cidade: 'Canápolis',
				id_cidade: 354,
			},
			{
				cidade: 'Canarana',
				id_cidade: 355,
			},
			{
				cidade: 'Canavieiras',
				id_cidade: 356,
			},
			{
				cidade: 'Candeal',
				id_cidade: 357,
			},
			{
				cidade: 'Candeias',
				id_cidade: 358,
			},
			{
				cidade: 'Candiba',
				id_cidade: 359,
			},
			{
				cidade: 'Cândido Sales',
				id_cidade: 360,
			},
			{
				cidade: 'Cansanção',
				id_cidade: 361,
			},
			{
				cidade: 'Canudos',
				id_cidade: 362,
			},
			{
				cidade: 'Capela do Alto Alegre',
				id_cidade: 363,
			},
			{
				cidade: 'Capim Grosso',
				id_cidade: 364,
			},
			{
				cidade: 'Caraíbas',
				id_cidade: 365,
			},
			{
				cidade: 'Caravelas',
				id_cidade: 366,
			},
			{
				cidade: 'Cardeal da Silva',
				id_cidade: 367,
			},
			{
				cidade: 'Carinhanha',
				id_cidade: 368,
			},
			{
				cidade: 'Casa Nova',
				id_cidade: 369,
			},
			{
				cidade: 'Castro Alves',
				id_cidade: 370,
			},
			{
				cidade: 'Catolândia',
				id_cidade: 371,
			},
			{
				cidade: 'Catu',
				id_cidade: 372,
			},
			{
				cidade: 'Caturama',
				id_cidade: 373,
			},
			{
				cidade: 'Central',
				id_cidade: 374,
			},
			{
				cidade: 'Chorrochó',
				id_cidade: 375,
			},
			{
				cidade: 'Cícero Dantas',
				id_cidade: 376,
			},
			{
				cidade: 'Cipó',
				id_cidade: 377,
			},
			{
				cidade: 'Coaraci',
				id_cidade: 378,
			},
			{
				cidade: 'Cocos',
				id_cidade: 379,
			},
			{
				cidade: 'Conceição da Feira',
				id_cidade: 380,
			},
			{
				cidade: 'Conceição do Almeida',
				id_cidade: 381,
			},
			{
				cidade: 'Conceição do Coité',
				id_cidade: 382,
			},
			{
				cidade: 'Conceição do Jacuípe',
				id_cidade: 383,
			},
			{
				cidade: 'Conde',
				id_cidade: 384,
			},
			{
				cidade: 'Condeúba',
				id_cidade: 385,
			},
			{
				cidade: 'Contendas do Sincorá',
				id_cidade: 386,
			},
			{
				cidade: 'Coração de Maria',
				id_cidade: 387,
			},
			{
				cidade: 'Cordeiros',
				id_cidade: 388,
			},
			{
				cidade: 'Coribe',
				id_cidade: 389,
			},
			{
				cidade: 'Coronel João Sá',
				id_cidade: 390,
			},
			{
				cidade: 'Correntina',
				id_cidade: 391,
			},
			{
				cidade: 'Cotegipe',
				id_cidade: 392,
			},
			{
				cidade: 'Cravolândia',
				id_cidade: 393,
			},
			{
				cidade: 'Crisópolis',
				id_cidade: 394,
			},
			{
				cidade: 'Cristópolis',
				id_cidade: 395,
			},
			{
				cidade: 'Cruz das Almas',
				id_cidade: 396,
			},
			{
				cidade: 'Curaçá',
				id_cidade: 397,
			},
			{
				cidade: 'Dário Meira',
				id_cidade: 398,
			},
			{
				cidade: "Dias d'Ávila",
				id_cidade: 399,
			},
			{
				cidade: 'Dom Basílio',
				id_cidade: 400,
			},
			{
				cidade: 'Dom Macedo Costa',
				id_cidade: 401,
			},
			{
				cidade: 'Elísio Medrado',
				id_cidade: 402,
			},
			{
				cidade: 'Encruzilhada',
				id_cidade: 403,
			},
			{
				cidade: 'Entre Rios',
				id_cidade: 404,
			},
			{
				cidade: 'Érico Cardoso',
				id_cidade: 405,
			},
			{
				cidade: 'Esplanada',
				id_cidade: 406,
			},
			{
				cidade: 'Euclides da Cunha',
				id_cidade: 407,
			},
			{
				cidade: 'Eunápolis',
				id_cidade: 408,
			},
			{
				cidade: 'Fátima',
				id_cidade: 409,
			},
			{
				cidade: 'Feira da Mata',
				id_cidade: 410,
			},
			{
				cidade: 'Feira de Santana',
				id_cidade: 411,
			},
			{
				cidade: 'Filadélfia',
				id_cidade: 412,
			},
			{
				cidade: 'Firmino Alves',
				id_cidade: 413,
			},
			{
				cidade: 'Floresta Azul',
				id_cidade: 414,
			},
			{
				cidade: 'Formosa do Rio Preto',
				id_cidade: 415,
			},
			{
				cidade: 'Gandu',
				id_cidade: 416,
			},
			{
				cidade: 'Gavião',
				id_cidade: 417,
			},
			{
				cidade: 'Gentio do Ouro',
				id_cidade: 418,
			},
			{
				cidade: 'Glória',
				id_cidade: 419,
			},
			{
				cidade: 'Gongogi',
				id_cidade: 420,
			},
			{
				cidade: 'Governador Mangabeira',
				id_cidade: 421,
			},
			{
				cidade: 'Guajeru',
				id_cidade: 422,
			},
			{
				cidade: 'Guanambi',
				id_cidade: 423,
			},
			{
				cidade: 'Guaratinga',
				id_cidade: 424,
			},
			{
				cidade: 'Heliópolis',
				id_cidade: 425,
			},
			{
				cidade: 'Iaçu',
				id_cidade: 426,
			},
			{
				cidade: 'Ibiassucê',
				id_cidade: 427,
			},
			{
				cidade: 'Ibicaraí',
				id_cidade: 428,
			},
			{
				cidade: 'Ibicoara',
				id_cidade: 429,
			},
			{
				cidade: 'Ibicuí',
				id_cidade: 430,
			},
			{
				cidade: 'Ibipeba',
				id_cidade: 431,
			},
			{
				cidade: 'Ibipitanga',
				id_cidade: 432,
			},
			{
				cidade: 'Ibiquera',
				id_cidade: 433,
			},
			{
				cidade: 'Ibirapitanga',
				id_cidade: 434,
			},
			{
				cidade: 'Ibirapuã',
				id_cidade: 435,
			},
			{
				cidade: 'Ibirataia',
				id_cidade: 436,
			},
			{
				cidade: 'Ibitiara',
				id_cidade: 437,
			},
			{
				cidade: 'Ibititá',
				id_cidade: 438,
			},
			{
				cidade: 'Ibotirama',
				id_cidade: 439,
			},
			{
				cidade: 'Ichu',
				id_cidade: 440,
			},
			{
				cidade: 'Igaporã',
				id_cidade: 441,
			},
			{
				cidade: 'Igrapiúna',
				id_cidade: 442,
			},
			{
				cidade: 'Iguaí',
				id_cidade: 443,
			},
			{
				cidade: 'Ilhéus',
				id_cidade: 444,
			},
			{
				cidade: 'Inhambupe',
				id_cidade: 445,
			},
			{
				cidade: 'Ipecaetá',
				id_cidade: 446,
			},
			{
				cidade: 'Ipiaú',
				id_cidade: 447,
			},
			{
				cidade: 'Ipirá',
				id_cidade: 448,
			},
			{
				cidade: 'Ipupiara',
				id_cidade: 449,
			},
			{
				cidade: 'Irajuba',
				id_cidade: 450,
			},
			{
				cidade: 'Iramaia',
				id_cidade: 451,
			},
			{
				cidade: 'Iraquara',
				id_cidade: 452,
			},
			{
				cidade: 'Irará',
				id_cidade: 453,
			},
			{
				cidade: 'Irecê',
				id_cidade: 454,
			},
			{
				cidade: 'Itabela',
				id_cidade: 455,
			},
			{
				cidade: 'Itaberaba',
				id_cidade: 456,
			},
			{
				cidade: 'Itabuna',
				id_cidade: 457,
			},
			{
				cidade: 'Itacaré',
				id_cidade: 458,
			},
			{
				cidade: 'Itaeté',
				id_cidade: 459,
			},
			{
				cidade: 'Itagi',
				id_cidade: 460,
			},
			{
				cidade: 'Itagibá',
				id_cidade: 461,
			},
			{
				cidade: 'Itagimirim',
				id_cidade: 462,
			},
			{
				cidade: 'Itaguaçu da Bahia',
				id_cidade: 463,
			},
			{
				cidade: 'Itaju do Colônia',
				id_cidade: 464,
			},
			{
				cidade: 'Itajuípe',
				id_cidade: 465,
			},
			{
				cidade: 'Itamaraju',
				id_cidade: 466,
			},
			{
				cidade: 'Itamari',
				id_cidade: 467,
			},
			{
				cidade: 'Itambé',
				id_cidade: 468,
			},
			{
				cidade: 'Itanagra',
				id_cidade: 469,
			},
			{
				cidade: 'Itanhém',
				id_cidade: 470,
			},
			{
				cidade: 'Itaparica',
				id_cidade: 471,
			},
			{
				cidade: 'Itapé',
				id_cidade: 472,
			},
			{
				cidade: 'Itapebi',
				id_cidade: 473,
			},
			{
				cidade: 'Itapetinga',
				id_cidade: 474,
			},
			{
				cidade: 'Itapicuru',
				id_cidade: 475,
			},
			{
				cidade: 'Itapitanga',
				id_cidade: 476,
			},
			{
				cidade: 'Itaquara',
				id_cidade: 477,
			},
			{
				cidade: 'Itarantim',
				id_cidade: 478,
			},
			{
				cidade: 'Itatim',
				id_cidade: 479,
			},
			{
				cidade: 'Itiruçu',
				id_cidade: 480,
			},
			{
				cidade: 'Itiúba',
				id_cidade: 481,
			},
			{
				cidade: 'Itororó',
				id_cidade: 482,
			},
			{
				cidade: 'Ituaçu',
				id_cidade: 483,
			},
			{
				cidade: 'Ituberá',
				id_cidade: 484,
			},
			{
				cidade: 'Iuiú',
				id_cidade: 485,
			},
			{
				cidade: 'Jaborandi',
				id_cidade: 486,
			},
			{
				cidade: 'Jacaraci',
				id_cidade: 487,
			},
			{
				cidade: 'Jacobina',
				id_cidade: 488,
			},
			{
				cidade: 'Jaguaquara',
				id_cidade: 489,
			},
			{
				cidade: 'Jaguarari',
				id_cidade: 490,
			},
			{
				cidade: 'Jaguaripe',
				id_cidade: 491,
			},
			{
				cidade: 'Jandaíra',
				id_cidade: 492,
			},
			{
				cidade: 'Jequié',
				id_cidade: 493,
			},
			{
				cidade: 'Jeremoabo',
				id_cidade: 494,
			},
			{
				cidade: 'Jiquiriçá',
				id_cidade: 495,
			},
			{
				cidade: 'Jitaúna',
				id_cidade: 496,
			},
			{
				cidade: 'João Dourado',
				id_cidade: 497,
			},
			{
				cidade: 'Juazeiro',
				id_cidade: 498,
			},
			{
				cidade: 'Jucuruçu',
				id_cidade: 499,
			},
			{
				cidade: 'Jussara',
				id_cidade: 500,
			},
			{
				cidade: 'Jussari',
				id_cidade: 501,
			},
			{
				cidade: 'Jussiape',
				id_cidade: 502,
			},
			{
				cidade: 'Lafaiete Coutinho',
				id_cidade: 503,
			},
			{
				cidade: 'Lagoa Real',
				id_cidade: 504,
			},
			{
				cidade: 'Laje',
				id_cidade: 505,
			},
			{
				cidade: 'Lajedão',
				id_cidade: 506,
			},
			{
				cidade: 'Lajedinho',
				id_cidade: 507,
			},
			{
				cidade: 'Lajedo do Tabocal',
				id_cidade: 508,
			},
			{
				cidade: 'Lamarão',
				id_cidade: 509,
			},
			{
				cidade: 'Lapão',
				id_cidade: 510,
			},
			{
				cidade: 'Lauro de Freitas',
				id_cidade: 511,
			},
			{
				cidade: 'Lençóis',
				id_cidade: 512,
			},
			{
				cidade: 'Licínio de Almeida',
				id_cidade: 513,
			},
			{
				cidade: 'Livramento de Nossa Senhora',
				id_cidade: 514,
			},
			{
				cidade: 'Luís Eduardo Magalhães',
				id_cidade: 515,
			},
			{
				cidade: 'Macajuba',
				id_cidade: 516,
			},
			{
				cidade: 'Macarani',
				id_cidade: 517,
			},
			{
				cidade: 'Macaúbas',
				id_cidade: 518,
			},
			{
				cidade: 'Macururé',
				id_cidade: 519,
			},
			{
				cidade: 'Madre de Deus',
				id_cidade: 520,
			},
			{
				cidade: 'Maetinga',
				id_cidade: 521,
			},
			{
				cidade: 'Maiquinique',
				id_cidade: 522,
			},
			{
				cidade: 'Mairi',
				id_cidade: 523,
			},
			{
				cidade: 'Malhada',
				id_cidade: 524,
			},
			{
				cidade: 'Malhada de Pedras',
				id_cidade: 525,
			},
			{
				cidade: 'Manoel Vitorino',
				id_cidade: 526,
			},
			{
				cidade: 'Mansidão',
				id_cidade: 527,
			},
			{
				cidade: 'Maracás',
				id_cidade: 528,
			},
			{
				cidade: 'Maragogipe',
				id_cidade: 529,
			},
			{
				cidade: 'Maraú',
				id_cidade: 530,
			},
			{
				cidade: 'Marcionílio Souza',
				id_cidade: 531,
			},
			{
				cidade: 'Mascote',
				id_cidade: 532,
			},
			{
				cidade: 'Mata de São João',
				id_cidade: 533,
			},
			{
				cidade: 'Matina',
				id_cidade: 534,
			},
			{
				cidade: 'Medeiros Neto',
				id_cidade: 535,
			},
			{
				cidade: 'Miguel Calmon',
				id_cidade: 536,
			},
			{
				cidade: 'Milagres',
				id_cidade: 537,
			},
			{
				cidade: 'Mirangaba',
				id_cidade: 538,
			},
			{
				cidade: 'Mirante',
				id_cidade: 539,
			},
			{
				cidade: 'Monte Santo',
				id_cidade: 540,
			},
			{
				cidade: 'Morpará',
				id_cidade: 541,
			},
			{
				cidade: 'Morro do Chapéu',
				id_cidade: 542,
			},
			{
				cidade: 'Mortugaba',
				id_cidade: 543,
			},
			{
				cidade: 'Mucugê',
				id_cidade: 544,
			},
			{
				cidade: 'Mucuri',
				id_cidade: 545,
			},
			{
				cidade: 'Mulungu do Morro',
				id_cidade: 546,
			},
			{
				cidade: 'Mundo Novo',
				id_cidade: 547,
			},
			{
				cidade: 'Muniz Ferreira',
				id_cidade: 548,
			},
			{
				cidade: 'Muquém de São Francisco',
				id_cidade: 549,
			},
			{
				cidade: 'Muritiba',
				id_cidade: 550,
			},
			{
				cidade: 'Mutuípe',
				id_cidade: 551,
			},
			{
				cidade: 'Nazaré',
				id_cidade: 552,
			},
			{
				cidade: 'Nilo Peçanha',
				id_cidade: 553,
			},
			{
				cidade: 'Nordestina',
				id_cidade: 554,
			},
			{
				cidade: 'Nova Canaã',
				id_cidade: 555,
			},
			{
				cidade: 'Nova Fátima',
				id_cidade: 556,
			},
			{
				cidade: 'Nova Ibiá',
				id_cidade: 557,
			},
			{
				cidade: 'Nova Itarana',
				id_cidade: 558,
			},
			{
				cidade: 'Nova Redenção',
				id_cidade: 559,
			},
			{
				cidade: 'Nova Soure',
				id_cidade: 560,
			},
			{
				cidade: 'Nova Viçosa',
				id_cidade: 561,
			},
			{
				cidade: 'Novo Horizonte',
				id_cidade: 562,
			},
			{
				cidade: 'Novo Triunfo',
				id_cidade: 563,
			},
			{
				cidade: 'Olindina',
				id_cidade: 564,
			},
			{
				cidade: 'Oliveira dos Brejinhos',
				id_cidade: 565,
			},
			{
				cidade: 'Ouriçangas',
				id_cidade: 566,
			},
			{
				cidade: 'Ourolândia',
				id_cidade: 567,
			},
			{
				cidade: 'Palmas de Monte Alto',
				id_cidade: 568,
			},
			{
				cidade: 'Palmeiras',
				id_cidade: 569,
			},
			{
				cidade: 'Paramirim',
				id_cidade: 570,
			},
			{
				cidade: 'Paratinga',
				id_cidade: 571,
			},
			{
				cidade: 'Paripiranga',
				id_cidade: 572,
			},
			{
				cidade: 'Pau Brasil',
				id_cidade: 573,
			},
			{
				cidade: 'Paulo Afonso',
				id_cidade: 574,
			},
			{
				cidade: 'Pé de Serra',
				id_cidade: 575,
			},
			{
				cidade: 'Pedrão',
				id_cidade: 576,
			},
			{
				cidade: 'Pedro Alexandre',
				id_cidade: 577,
			},
			{
				cidade: 'Piatã',
				id_cidade: 578,
			},
			{
				cidade: 'Pilão Arcado',
				id_cidade: 579,
			},
			{
				cidade: 'Pindaí',
				id_cidade: 580,
			},
			{
				cidade: 'Pindobaçu',
				id_cidade: 581,
			},
			{
				cidade: 'Pintadas',
				id_cidade: 582,
			},
			{
				cidade: 'Piraí do Norte',
				id_cidade: 583,
			},
			{
				cidade: 'Piripá',
				id_cidade: 584,
			},
			{
				cidade: 'Piritiba',
				id_cidade: 585,
			},
			{
				cidade: 'Planaltino',
				id_cidade: 586,
			},
			{
				cidade: 'Planalto',
				id_cidade: 587,
			},
			{
				cidade: 'Poções',
				id_cidade: 588,
			},
			{
				cidade: 'Pojuca',
				id_cidade: 589,
			},
			{
				cidade: 'Ponto Novo',
				id_cidade: 590,
			},
			{
				cidade: 'Porto Seguro',
				id_cidade: 591,
			},
			{
				cidade: 'Potiraguá',
				id_cidade: 592,
			},
			{
				cidade: 'Prado',
				id_cidade: 593,
			},
			{
				cidade: 'Presidente Dutra',
				id_cidade: 594,
			},
			{
				cidade: 'Presidente Jânio Quadros',
				id_cidade: 595,
			},
			{
				cidade: 'Presidente Tancredo Neves',
				id_cidade: 596,
			},
			{
				cidade: 'Queimadas',
				id_cidade: 597,
			},
			{
				cidade: 'Quijingue',
				id_cidade: 598,
			},
			{
				cidade: 'Quixabeira',
				id_cidade: 599,
			},
			{
				cidade: 'Rafael Jambeiro',
				id_cidade: 600,
			},
			{
				cidade: 'Remanso',
				id_cidade: 601,
			},
			{
				cidade: 'Retirolândia',
				id_cidade: 602,
			},
			{
				cidade: 'Riachão das Neves',
				id_cidade: 603,
			},
			{
				cidade: 'Riachão do Jacuípe',
				id_cidade: 604,
			},
			{
				cidade: 'Riacho de Santana',
				id_cidade: 605,
			},
			{
				cidade: 'Ribeira do Amparo',
				id_cidade: 606,
			},
			{
				cidade: 'Ribeira do Pombal',
				id_cidade: 607,
			},
			{
				cidade: 'Ribeirão do Largo',
				id_cidade: 608,
			},
			{
				cidade: 'Rio de Contas',
				id_cidade: 609,
			},
			{
				cidade: 'Rio do Antônio',
				id_cidade: 610,
			},
			{
				cidade: 'Rio do Pires',
				id_cidade: 611,
			},
			{
				cidade: 'Rio Real',
				id_cidade: 612,
			},
			{
				cidade: 'Rodelas',
				id_cidade: 613,
			},
			{
				cidade: 'Ruy Barbosa',
				id_cidade: 614,
			},
			{
				cidade: 'Salinas da Margarida',
				id_cidade: 615,
			},
			{
				cidade: 'Salvador',
				id_cidade: 616,
			},
			{
				cidade: 'Santa Bárbara',
				id_cidade: 617,
			},
			{
				cidade: 'Santa Brígida',
				id_cidade: 618,
			},
			{
				cidade: 'Santa Cruz Cabrália',
				id_cidade: 619,
			},
			{
				cidade: 'Santa Cruz da Vitória',
				id_cidade: 620,
			},
			{
				cidade: 'Santa Inês',
				id_cidade: 621,
			},
			{
				cidade: 'Santaluz',
				id_cidade: 626,
			},
			{
				cidade: 'Santa Luzia',
				id_cidade: 622,
			},
			{
				cidade: 'Santa Maria da Vitória',
				id_cidade: 623,
			},
			{
				cidade: 'Santana',
				id_cidade: 627,
			},
			{
				cidade: 'Amargosa',
				id_cidade: 290,
			},
			{
				cidade: 'Santanópolis',
				id_cidade: 628,
			},
			{
				cidade: 'Santa Rita de Cássia',
				id_cidade: 624,
			},
			{
				cidade: 'Amélia Rodrigues',
				id_cidade: 291,
			},
			{
				cidade: 'América Dourada',
				id_cidade: 292,
			},
			{
				cidade: 'Anagé',
				id_cidade: 293,
			},
			{
				cidade: 'Andaraí',
				id_cidade: 294,
			},
			{
				cidade: 'Andorinha',
				id_cidade: 295,
			},
			{
				cidade: 'Santa Teresinha',
				id_cidade: 625,
			},
			{
				cidade: 'Santo Amaro',
				id_cidade: 629,
			},
			{
				cidade: 'Santo Antônio de Jesus',
				id_cidade: 630,
			},
			{
				cidade: 'Abaíra',
				id_cidade: 281,
			},
			{
				cidade: 'Abaré',
				id_cidade: 282,
			},
			{
				cidade: 'Acajutiba',
				id_cidade: 283,
			},
			{
				cidade: 'Adustina',
				id_cidade: 284,
			},
			{
				cidade: 'Aiquara',
				id_cidade: 286,
			},
			{
				cidade: 'Alagoinhas',
				id_cidade: 287,
			},
			{
				cidade: 'Alcobaça',
				id_cidade: 288,
			},
			{
				cidade: 'Santo Estêvão',
				id_cidade: 631,
			},
			{
				cidade: 'São Desidério',
				id_cidade: 632,
			},
			{
				cidade: 'São Domingos',
				id_cidade: 633,
			},
			{
				cidade: 'São Felipe',
				id_cidade: 634,
			},
			{
				cidade: 'São Félix',
				id_cidade: 635,
			},
			{
				cidade: 'São Félix do Coribe',
				id_cidade: 636,
			},
			{
				cidade: 'São Francisco do Conde',
				id_cidade: 637,
			},
			{
				cidade: 'São Gabriel',
				id_cidade: 638,
			},
			{
				cidade: 'São Gonçalo dos Campos',
				id_cidade: 639,
			},
			{
				cidade: 'São José da Vitória',
				id_cidade: 640,
			},
			{
				cidade: 'São José do Jacuípe',
				id_cidade: 641,
			},
			{
				cidade: 'Angical',
				id_cidade: 296,
			},
			{
				cidade: 'São Miguel das Matas',
				id_cidade: 642,
			},
			{
				cidade: 'São Sebastião do Passé',
				id_cidade: 643,
			},
			{
				cidade: 'Sapeaçu',
				id_cidade: 644,
			},
			{
				cidade: 'Sátiro Dias',
				id_cidade: 645,
			},
			{
				cidade: 'Saubara',
				id_cidade: 646,
			},
			{
				cidade: 'Saúde',
				id_cidade: 647,
			},
			{
				cidade: 'Seabra',
				id_cidade: 648,
			},
			{
				cidade: 'Sebastião Laranjeiras',
				id_cidade: 649,
			},
			{
				cidade: 'Senhor do Bonfim',
				id_cidade: 650,
			},
			{
				cidade: 'Sento Sé',
				id_cidade: 651,
			},
			{
				cidade: 'Serra do Ramalho',
				id_cidade: 652,
			},
			{
				cidade: 'Serra Dourada',
				id_cidade: 653,
			},
			{
				cidade: 'Serra Preta',
				id_cidade: 654,
			},
			{
				cidade: 'Serrinha',
				id_cidade: 655,
			},
			{
				cidade: 'Anguera',
				id_cidade: 297,
			},
			{
				cidade: 'Antas',
				id_cidade: 298,
			},
			{
				cidade: 'Antônio Cardoso',
				id_cidade: 299,
			},
			{
				cidade: 'Antônio Gonçalves',
				id_cidade: 300,
			},
			{
				cidade: 'Aporá',
				id_cidade: 301,
			},
			{
				cidade: 'Apuarema',
				id_cidade: 302,
			},
			{
				cidade: 'Araças',
				id_cidade: 303,
			},
			{
				cidade: 'Aracatu',
				id_cidade: 304,
			},
			{
				cidade: 'Serrolândia',
				id_cidade: 656,
			},
			{
				cidade: 'Simões Filho',
				id_cidade: 657,
			},
			{
				cidade: 'Sítio do Mato',
				id_cidade: 658,
			},
			{
				cidade: 'Sítio do Quinto',
				id_cidade: 659,
			},
			{
				cidade: 'Sobradinho',
				id_cidade: 660,
			},
			{
				cidade: 'Souto Soares',
				id_cidade: 661,
			},
			{
				cidade: 'Tabocas do Brejo Velho',
				id_cidade: 662,
			},
			{
				cidade: 'Tanhaçu',
				id_cidade: 663,
			},
			{
				cidade: 'Tanque Novo',
				id_cidade: 664,
			},
			{
				cidade: 'Tanquinho',
				id_cidade: 665,
			},
			{
				cidade: 'Taperoá',
				id_cidade: 666,
			},
			{
				cidade: 'Tapiramutá',
				id_cidade: 667,
			},
			{
				cidade: 'Araci',
				id_cidade: 305,
			},
			{
				cidade: 'Aramari',
				id_cidade: 306,
			},
			{
				cidade: 'Arataca',
				id_cidade: 307,
			},
			{
				cidade: 'Teixeira de Freitas',
				id_cidade: 668,
			},
			{
				cidade: 'Teodoro Sampaio',
				id_cidade: 669,
			},
			{
				cidade: 'Teofilândia',
				id_cidade: 670,
			},
			{
				cidade: 'Teolândia',
				id_cidade: 671,
			},
			{
				cidade: 'Terra Nova',
				id_cidade: 672,
			},
			{
				cidade: 'Tremedal',
				id_cidade: 673,
			},
			{
				cidade: 'Tucano',
				id_cidade: 674,
			},
			{
				cidade: 'Uauá',
				id_cidade: 675,
			},
			{
				cidade: 'Ubaíra',
				id_cidade: 676,
			},
			{
				cidade: 'Ubaitaba',
				id_cidade: 677,
			},
			{
				cidade: 'Ubatã',
				id_cidade: 678,
			},
			{
				cidade: 'Uibaí',
				id_cidade: 679,
			},
			{
				cidade: 'Umburanas',
				id_cidade: 680,
			},
			{
				cidade: 'Una',
				id_cidade: 681,
			},
			{
				cidade: 'Urandi',
				id_cidade: 682,
			},
			{
				cidade: 'Uruçuca',
				id_cidade: 683,
			},
			{
				cidade: 'Utinga',
				id_cidade: 684,
			},
			{
				cidade: 'Valença',
				id_cidade: 685,
			},
			{
				cidade: 'Valente',
				id_cidade: 686,
			},
			{
				cidade: 'Várzea da Roça',
				id_cidade: 687,
			},
			{
				cidade: 'Várzea do Poço',
				id_cidade: 688,
			},
			{
				cidade: 'Várzea Nova',
				id_cidade: 689,
			},
			{
				cidade: 'Varzedo',
				id_cidade: 690,
			},
			{
				cidade: 'Vera Cruz',
				id_cidade: 691,
			},
			{
				cidade: 'Vereda',
				id_cidade: 692,
			},
			{
				cidade: 'Vitória da Conquista',
				id_cidade: 693,
			},
			{
				cidade: 'Wagner',
				id_cidade: 694,
			},
			{
				cidade: 'Wanderley',
				id_cidade: 695,
			},
			{
				cidade: 'Wenceslau Guimarães',
				id_cidade: 696,
			},
			{
				cidade: 'Xique-Xique',
				id_cidade: 697,
			},
		],
	},
	{
		estado: 'Ceará',
		sigla: 'CE',
		id_estado: 6,
		cidades: [
			{
				cidade: 'Aiuaba',
				id_cidade: 702,
			},
			{
				cidade: 'Alcântaras',
				id_cidade: 703,
			},
			{
				cidade: 'Aratuba',
				id_cidade: 714,
			},
			{
				cidade: 'Arneiroz',
				id_cidade: 715,
			},
			{
				cidade: 'Aurora',
				id_cidade: 717,
			},
			{
				cidade: 'Baixio',
				id_cidade: 718,
			},
			{
				cidade: 'Banabuiú',
				id_cidade: 719,
			},
			{
				cidade: 'Barbalha',
				id_cidade: 720,
			},
			{
				cidade: 'Barreira',
				id_cidade: 721,
			},
			{
				cidade: 'Barro',
				id_cidade: 722,
			},
			{
				cidade: 'Barroquinha',
				id_cidade: 723,
			},
			{
				cidade: 'Baturité',
				id_cidade: 724,
			},
			{
				cidade: 'Beberibe',
				id_cidade: 725,
			},
			{
				cidade: 'Bela Cruz',
				id_cidade: 726,
			},
			{
				cidade: 'Boa Viagem',
				id_cidade: 727,
			},
			{
				cidade: 'Brejo Santo',
				id_cidade: 728,
			},
			{
				cidade: 'Abaiara',
				id_cidade: 698,
			},
			{
				cidade: 'Acarape',
				id_cidade: 699,
			},
			{
				cidade: 'Acaraú',
				id_cidade: 700,
			},
			{
				cidade: 'Camocim',
				id_cidade: 729,
			},
			{
				cidade: 'Itapagé',
				id_cidade: 784,
			},
			{
				cidade: 'Campos Sales',
				id_cidade: 730,
			},
			{
				cidade: 'Canindé',
				id_cidade: 731,
			},
			{
				cidade: 'Capistrano',
				id_cidade: 732,
			},
			{
				cidade: 'Caridade',
				id_cidade: 733,
			},
			{
				cidade: 'Cariré',
				id_cidade: 734,
			},
			{
				cidade: 'Caririaçu',
				id_cidade: 735,
			},
			{
				cidade: 'Cariús',
				id_cidade: 736,
			},
			{
				cidade: 'Carnaubal',
				id_cidade: 737,
			},
			{
				cidade: 'Cascavel',
				id_cidade: 738,
			},
			{
				cidade: 'Catarina',
				id_cidade: 739,
			},
			{
				cidade: 'Catunda',
				id_cidade: 740,
			},
			{
				cidade: 'Caucaia',
				id_cidade: 741,
			},
			{
				cidade: 'Cedro',
				id_cidade: 742,
			},
			{
				cidade: 'Chaval',
				id_cidade: 743,
			},
			{
				cidade: 'Choró',
				id_cidade: 744,
			},
			{
				cidade: 'Chorozinho',
				id_cidade: 745,
			},
			{
				cidade: 'Coreaú',
				id_cidade: 746,
			},
			{
				cidade: 'Crateús',
				id_cidade: 747,
			},
			{
				cidade: 'Crato',
				id_cidade: 748,
			},
			{
				cidade: 'Croatá',
				id_cidade: 749,
			},
			{
				cidade: 'Cruz',
				id_cidade: 750,
			},
			{
				cidade: 'Deputado Irapuan Pinheiro',
				id_cidade: 751,
			},
			{
				cidade: 'Ererê',
				id_cidade: 752,
			},
			{
				cidade: 'Eusébio',
				id_cidade: 753,
			},
			{
				cidade: 'Farias Brito',
				id_cidade: 754,
			},
			{
				cidade: 'Forquilha',
				id_cidade: 755,
			},
			{
				cidade: 'Fortaleza',
				id_cidade: 756,
			},
			{
				cidade: 'Fortim',
				id_cidade: 757,
			},
			{
				cidade: 'Frecheirinha',
				id_cidade: 758,
			},
			{
				cidade: 'General Sampaio',
				id_cidade: 759,
			},
			{
				cidade: 'Graça',
				id_cidade: 760,
			},
			{
				cidade: 'Granja',
				id_cidade: 761,
			},
			{
				cidade: 'Granjeiro',
				id_cidade: 762,
			},
			{
				cidade: 'Groaíras',
				id_cidade: 763,
			},
			{
				cidade: 'Guaiúba',
				id_cidade: 764,
			},
			{
				cidade: 'Guaraciaba do Norte',
				id_cidade: 765,
			},
			{
				cidade: 'Guaramiranga',
				id_cidade: 766,
			},
			{
				cidade: 'Hidrolândia',
				id_cidade: 767,
			},
			{
				cidade: 'Horizonte',
				id_cidade: 768,
			},
			{
				cidade: 'Ibaretama',
				id_cidade: 769,
			},
			{
				cidade: 'Ibiapina',
				id_cidade: 770,
			},
			{
				cidade: 'Ibicuitinga',
				id_cidade: 771,
			},
			{
				cidade: 'Icapuí',
				id_cidade: 772,
			},
			{
				cidade: 'Icó',
				id_cidade: 773,
			},
			{
				cidade: 'Iguatu',
				id_cidade: 774,
			},
			{
				cidade: 'Independência',
				id_cidade: 775,
			},
			{
				cidade: 'Ipaporanga',
				id_cidade: 776,
			},
			{
				cidade: 'Ipaumirim',
				id_cidade: 777,
			},
			{
				cidade: 'Ipu',
				id_cidade: 778,
			},
			{
				cidade: 'Ipueiras',
				id_cidade: 779,
			},
			{
				cidade: 'Iracema',
				id_cidade: 780,
			},
			{
				cidade: 'Irauçuba',
				id_cidade: 781,
			},
			{
				cidade: 'Itaiçaba',
				id_cidade: 782,
			},
			{
				cidade: 'Itaitinga',
				id_cidade: 783,
			},
			{
				cidade: 'Itapipoca',
				id_cidade: 785,
			},
			{
				cidade: 'Itapiúna',
				id_cidade: 786,
			},
			{
				cidade: 'Itarema',
				id_cidade: 787,
			},
			{
				cidade: 'Itatira',
				id_cidade: 788,
			},
			{
				cidade: 'Jaguaretama',
				id_cidade: 789,
			},
			{
				cidade: 'Jaguaribara',
				id_cidade: 790,
			},
			{
				cidade: 'Jaguaribe',
				id_cidade: 791,
			},
			{
				cidade: 'Jaguaruana',
				id_cidade: 792,
			},
			{
				cidade: 'Jardim',
				id_cidade: 793,
			},
			{
				cidade: 'Jati',
				id_cidade: 794,
			},
			{
				cidade: 'Jijoca de Jericoacoara',
				id_cidade: 795,
			},
			{
				cidade: 'Juazeiro do Norte',
				id_cidade: 796,
			},
			{
				cidade: 'Jucás',
				id_cidade: 797,
			},
			{
				cidade: 'Lavras da Mangabeira',
				id_cidade: 798,
			},
			{
				cidade: 'Limoeiro do Norte',
				id_cidade: 799,
			},
			{
				cidade: 'Madalena',
				id_cidade: 800,
			},
			{
				cidade: 'Maracanaú',
				id_cidade: 801,
			},
			{
				cidade: 'Maranguape',
				id_cidade: 802,
			},
			{
				cidade: 'Marco',
				id_cidade: 803,
			},
			{
				cidade: 'Martinópole',
				id_cidade: 804,
			},
			{
				cidade: 'Massapê',
				id_cidade: 805,
			},
			{
				cidade: 'Mauriti',
				id_cidade: 806,
			},
			{
				cidade: 'Meruoca',
				id_cidade: 807,
			},
			{
				cidade: 'Milagres',
				id_cidade: 808,
			},
			{
				cidade: 'Milhã',
				id_cidade: 809,
			},
			{
				cidade: 'Miraíma',
				id_cidade: 810,
			},
			{
				cidade: 'Missão Velha',
				id_cidade: 811,
			},
			{
				cidade: 'Mombaça',
				id_cidade: 812,
			},
			{
				cidade: 'Monsenhor Tabosa',
				id_cidade: 813,
			},
			{
				cidade: 'Morada Nova',
				id_cidade: 814,
			},
			{
				cidade: 'Moraújo',
				id_cidade: 815,
			},
			{
				cidade: 'Morrinhos',
				id_cidade: 816,
			},
			{
				cidade: 'Mucambo',
				id_cidade: 817,
			},
			{
				cidade: 'Mulungu',
				id_cidade: 818,
			},
			{
				cidade: 'Nova Olinda',
				id_cidade: 819,
			},
			{
				cidade: 'Nova Russas',
				id_cidade: 820,
			},
			{
				cidade: 'Novo Oriente',
				id_cidade: 821,
			},
			{
				cidade: 'Ocara',
				id_cidade: 822,
			},
			{
				cidade: 'Orós',
				id_cidade: 823,
			},
			{
				cidade: 'Pacajus',
				id_cidade: 824,
			},
			{
				cidade: 'Pacatuba',
				id_cidade: 825,
			},
			{
				cidade: 'Pacoti',
				id_cidade: 826,
			},
			{
				cidade: 'Pacujá',
				id_cidade: 827,
			},
			{
				cidade: 'Palhano',
				id_cidade: 828,
			},
			{
				cidade: 'Palmácia',
				id_cidade: 829,
			},
			{
				cidade: 'Paracuru',
				id_cidade: 830,
			},
			{
				cidade: 'Paraipaba',
				id_cidade: 831,
			},
			{
				cidade: 'Parambu',
				id_cidade: 832,
			},
			{
				cidade: 'Paramoti',
				id_cidade: 833,
			},
			{
				cidade: 'Pedra Branca',
				id_cidade: 834,
			},
			{
				cidade: 'Penaforte',
				id_cidade: 835,
			},
			{
				cidade: 'Pentecoste',
				id_cidade: 836,
			},
			{
				cidade: 'Pereiro',
				id_cidade: 837,
			},
			{
				cidade: 'Pindoretama',
				id_cidade: 838,
			},
			{
				cidade: 'Piquet Carneiro',
				id_cidade: 839,
			},
			{
				cidade: 'Pires Ferreira',
				id_cidade: 840,
			},
			{
				cidade: 'Poranga',
				id_cidade: 841,
			},
			{
				cidade: 'Porteiras',
				id_cidade: 842,
			},
			{
				cidade: 'Potengi',
				id_cidade: 843,
			},
			{
				cidade: 'Potiretama',
				id_cidade: 844,
			},
			{
				cidade: 'Quiterianópolis',
				id_cidade: 845,
			},
			{
				cidade: 'Quixadá',
				id_cidade: 846,
			},
			{
				cidade: 'Quixelô',
				id_cidade: 847,
			},
			{
				cidade: 'Quixeramobim',
				id_cidade: 848,
			},
			{
				cidade: 'Quixeré',
				id_cidade: 849,
			},
			{
				cidade: 'Redenção',
				id_cidade: 850,
			},
			{
				cidade: 'Reriutaba',
				id_cidade: 851,
			},
			{
				cidade: 'Russas',
				id_cidade: 852,
			},
			{
				cidade: 'Saboeiro',
				id_cidade: 853,
			},
			{
				cidade: 'Salitre',
				id_cidade: 854,
			},
			{
				cidade: 'Assaré',
				id_cidade: 716,
			},
			{
				cidade: 'Santana do Acaraú',
				id_cidade: 856,
			},
			{
				cidade: 'Santana do Cariri',
				id_cidade: 857,
			},
			{
				cidade: 'Acopiara',
				id_cidade: 701,
			},
			{
				cidade: 'Altaneira',
				id_cidade: 704,
			},
			{
				cidade: 'Alto Santo',
				id_cidade: 705,
			},
			{
				cidade: 'Santa Quitéria',
				id_cidade: 855,
			},
			{
				cidade: 'Amontada',
				id_cidade: 706,
			},
			{
				cidade: 'São Benedito',
				id_cidade: 858,
			},
			{
				cidade: 'São Gonçalo do Amarante',
				id_cidade: 859,
			},
			{
				cidade: 'São João do Jaguaribe',
				id_cidade: 860,
			},
			{
				cidade: 'São Luís do Curu',
				id_cidade: 861,
			},
			{
				cidade: 'Senador Pompeu',
				id_cidade: 862,
			},
			{
				cidade: 'Senador Sá',
				id_cidade: 863,
			},
			{
				cidade: 'Antonina do Norte',
				id_cidade: 707,
			},
			{
				cidade: 'Apuiarés',
				id_cidade: 708,
			},
			{
				cidade: 'Aquiraz',
				id_cidade: 709,
			},
			{
				cidade: 'Aracati',
				id_cidade: 710,
			},
			{
				cidade: 'Sobral',
				id_cidade: 864,
			},
			{
				cidade: 'Solonópole',
				id_cidade: 865,
			},
			{
				cidade: 'Tabuleiro do Norte',
				id_cidade: 866,
			},
			{
				cidade: 'Tamboril',
				id_cidade: 867,
			},
			{
				cidade: 'Tarrafas',
				id_cidade: 868,
			},
			{
				cidade: 'Aracoiaba',
				id_cidade: 711,
			},
			{
				cidade: 'Ararendá',
				id_cidade: 712,
			},
			{
				cidade: 'Araripe',
				id_cidade: 713,
			},
			{
				cidade: 'Tauá',
				id_cidade: 869,
			},
			{
				cidade: 'Tejuçuoca',
				id_cidade: 870,
			},
			{
				cidade: 'Tianguá',
				id_cidade: 871,
			},
			{
				cidade: 'Trairi',
				id_cidade: 872,
			},
			{
				cidade: 'Tururu',
				id_cidade: 873,
			},
			{
				cidade: 'Ubajara',
				id_cidade: 874,
			},
			{
				cidade: 'Umari',
				id_cidade: 875,
			},
			{
				cidade: 'Umirim',
				id_cidade: 876,
			},
			{
				cidade: 'Uruburetama',
				id_cidade: 877,
			},
			{
				cidade: 'Uruoca',
				id_cidade: 878,
			},
			{
				cidade: 'Varjota',
				id_cidade: 879,
			},
			{
				cidade: 'Várzea Alegre',
				id_cidade: 880,
			},
			{
				cidade: 'Viçosa do Ceará',
				id_cidade: 881,
			},
		],
	},
	{
		estado: 'Distrito Federal',
		sigla: 'DF',
		id_estado: 7,
		cidades: [
			{
				cidade: 'Brasília',
				id_cidade: 882,
			},
		],
	},
	{
		estado: 'Espírito Santo',
		sigla: 'ES',
		id_estado: 8,
		cidades: [
			{
				cidade: 'Águia Branca',
				id_cidade: 3,
			},
			{
				cidade: 'Alegre',
				id_cidade: 4,
			},
			{
				cidade: 'Alfredo Chaves',
				id_cidade: 5,
			},
			{
				cidade: 'Atilio Vivacqua',
				id_cidade: 10,
			},
			{
				cidade: 'Baixo Guandu',
				id_cidade: 11,
			},
			{
				cidade: 'Barra de São Francisco',
				id_cidade: 12,
			},
			{
				cidade: 'Boa Esperança',
				id_cidade: 13,
			},
			{
				cidade: 'Bom Jesus do Norte',
				id_cidade: 14,
			},
			{
				cidade: 'Brejetuba',
				id_cidade: 15,
			},
			{
				cidade: 'Cachoeiro de Itapemirim',
				id_cidade: 16,
			},
			{
				cidade: 'Cariacica',
				id_cidade: 17,
			},
			{
				cidade: 'Castelo',
				id_cidade: 18,
			},
			{
				cidade: 'Colatina',
				id_cidade: 19,
			},
			{
				cidade: 'Conceição da Barra',
				id_cidade: 20,
			},
			{
				cidade: 'Conceição do Castelo',
				id_cidade: 21,
			},
			{
				cidade: 'Divino de São Lourenço',
				id_cidade: 22,
			},
			{
				cidade: 'Domingos Martins',
				id_cidade: 23,
			},
			{
				cidade: 'Dores do Rio Preto',
				id_cidade: 24,
			},
			{
				cidade: 'Ecoporanga',
				id_cidade: 25,
			},
			{
				cidade: 'Fundão',
				id_cidade: 26,
			},
			{
				cidade: 'Governador Lindenberg',
				id_cidade: 27,
			},
			{
				cidade: 'Guaçuí',
				id_cidade: 28,
			},
			{
				cidade: 'Guarapari',
				id_cidade: 29,
			},
			{
				cidade: 'Ibatiba',
				id_cidade: 30,
			},
			{
				cidade: 'Ibiraçu',
				id_cidade: 31,
			},
			{
				cidade: 'Ibitirama',
				id_cidade: 32,
			},
			{
				cidade: 'Iconha',
				id_cidade: 33,
			},
			{
				cidade: 'Irupi',
				id_cidade: 34,
			},
			{
				cidade: 'Itaguaçu',
				id_cidade: 35,
			},
			{
				cidade: 'Itapemirim',
				id_cidade: 36,
			},
			{
				cidade: 'Itarana',
				id_cidade: 37,
			},
			{
				cidade: 'Iúna',
				id_cidade: 38,
			},
			{
				cidade: 'Jaguaré',
				id_cidade: 39,
			},
			{
				cidade: 'Jerônimo Monteiro',
				id_cidade: 40,
			},
			{
				cidade: 'João Neiva',
				id_cidade: 41,
			},
			{
				cidade: 'Laranja da Terra',
				id_cidade: 42,
			},
			{
				cidade: 'Linhares',
				id_cidade: 43,
			},
			{
				cidade: 'Mantenópolis',
				id_cidade: 44,
			},
			{
				cidade: 'Marataízes',
				id_cidade: 45,
			},
			{
				cidade: 'Marechal Floriano',
				id_cidade: 46,
			},
			{
				cidade: 'Marilândia',
				id_cidade: 47,
			},
			{
				cidade: 'Mimoso do Sul',
				id_cidade: 48,
			},
			{
				cidade: 'Montanha',
				id_cidade: 49,
			},
			{
				cidade: 'Mucurici',
				id_cidade: 50,
			},
			{
				cidade: 'Muniz Freire',
				id_cidade: 51,
			},
			{
				cidade: 'Muqui',
				id_cidade: 52,
			},
			{
				cidade: 'Nova Venécia',
				id_cidade: 53,
			},
			{
				cidade: 'Pancas',
				id_cidade: 54,
			},
			{
				cidade: 'Pedro Canário',
				id_cidade: 55,
			},
			{
				cidade: 'Pinheiros',
				id_cidade: 56,
			},
			{
				cidade: 'Piúma',
				id_cidade: 57,
			},
			{
				cidade: 'Ponto Belo',
				id_cidade: 58,
			},
			{
				cidade: 'Presidente Kennedy',
				id_cidade: 59,
			},
			{
				cidade: 'Rio Bananal',
				id_cidade: 60,
			},
			{
				cidade: 'Rio Novo do Sul',
				id_cidade: 61,
			},
			{
				cidade: 'Santa Leopoldina',
				id_cidade: 62,
			},
			{
				cidade: 'Santa Maria de Jetibá',
				id_cidade: 63,
			},
			{
				cidade: 'Afonso Cláudio',
				id_cidade: 1,
			},
			{
				cidade: 'Água Doce do Norte',
				id_cidade: 2,
			},
			{
				cidade: 'Alto Rio Novo',
				id_cidade: 6,
			},
			{
				cidade: 'Anchieta',
				id_cidade: 7,
			},
			{
				cidade: 'Santa Teresa',
				id_cidade: 64,
			},
			{
				cidade: 'São Domingos do Norte',
				id_cidade: 65,
			},
			{
				cidade: 'São Gabriel da Palha',
				id_cidade: 66,
			},
			{
				cidade: 'São José do Calçado',
				id_cidade: 67,
			},
			{
				cidade: 'São Mateus',
				id_cidade: 68,
			},
			{
				cidade: 'São Roque do Canaã',
				id_cidade: 69,
			},
			{
				cidade: 'Serra',
				id_cidade: 70,
			},
			{
				cidade: 'Apiacá',
				id_cidade: 8,
			},
			{
				cidade: 'Sooretama',
				id_cidade: 71,
			},
			{
				cidade: 'Aracruz',
				id_cidade: 9,
			},
			{
				cidade: 'Vargem Alta',
				id_cidade: 72,
			},
			{
				cidade: 'Venda Nova do Imigrante',
				id_cidade: 73,
			},
			{
				cidade: 'Viana',
				id_cidade: 74,
			},
			{
				cidade: 'Vila Pavão',
				id_cidade: 75,
			},
			{
				cidade: 'Vila Valério',
				id_cidade: 76,
			},
			{
				cidade: 'Vila Velha',
				id_cidade: 77,
			},
			{
				cidade: 'Vitória',
				id_cidade: 78,
			},
		],
	},
	{
		estado: 'Goiás',
		sigla: 'GO',
		id_estado: 9,
		cidades: [
			{
				cidade: 'Águas Lindas de Goiás',
				id_cidade: 889,
			},
			{
				cidade: 'Alexânia',
				id_cidade: 890,
			},
			{
				cidade: 'Aloândia',
				id_cidade: 891,
			},
			{
				cidade: 'Arenópolis',
				id_cidade: 908,
			},
			{
				cidade: 'Aurilândia',
				id_cidade: 910,
			},
			{
				cidade: 'Avelinópolis',
				id_cidade: 911,
			},
			{
				cidade: 'Baliza',
				id_cidade: 912,
			},
			{
				cidade: 'Barro Alto',
				id_cidade: 913,
			},
			{
				cidade: 'Bela Vista de Goiás',
				id_cidade: 914,
			},
			{
				cidade: 'Bom Jardim de Goiás',
				id_cidade: 915,
			},
			{
				cidade: 'Bom Jesus de Goiás',
				id_cidade: 916,
			},
			{
				cidade: 'Bonfinópolis',
				id_cidade: 917,
			},
			{
				cidade: 'Bonópolis',
				id_cidade: 918,
			},
			{
				cidade: 'Brazabrantes',
				id_cidade: 919,
			},
			{
				cidade: 'Britânia',
				id_cidade: 920,
			},
			{
				cidade: 'Buriti Alegre',
				id_cidade: 921,
			},
			{
				cidade: 'Buriti de Goiás',
				id_cidade: 922,
			},
			{
				cidade: 'Buritinópolis',
				id_cidade: 923,
			},
			{
				cidade: 'Cabeceiras',
				id_cidade: 924,
			},
			{
				cidade: 'Cachoeira Alta',
				id_cidade: 925,
			},
			{
				cidade: 'Cachoeira de Goiás',
				id_cidade: 926,
			},
			{
				cidade: 'Cachoeira Dourada',
				id_cidade: 927,
			},
			{
				cidade: 'Caçu',
				id_cidade: 928,
			},
			{
				cidade: 'Caiapônia',
				id_cidade: 929,
			},
			{
				cidade: 'Caldas Novas',
				id_cidade: 930,
			},
			{
				cidade: 'Caldazinha',
				id_cidade: 931,
			},
			{
				cidade: 'Campestre de Goiás',
				id_cidade: 932,
			},
			{
				cidade: 'Campinaçu',
				id_cidade: 933,
			},
			{
				cidade: 'Campinorte',
				id_cidade: 934,
			},
			{
				cidade: 'Campo Alegre de Goiás',
				id_cidade: 935,
			},
			{
				cidade: 'Campo Limpo de Goiás',
				id_cidade: 936,
			},
			{
				cidade: 'Campos Belos',
				id_cidade: 937,
			},
			{
				cidade: 'Campos Verdes',
				id_cidade: 938,
			},
			{
				cidade: 'Carmo do Rio Verde',
				id_cidade: 939,
			},
			{
				cidade: 'Castelândia',
				id_cidade: 940,
			},
			{
				cidade: 'Catalão',
				id_cidade: 941,
			},
			{
				cidade: 'Caturaí',
				id_cidade: 942,
			},
			{
				cidade: 'Cavalcante',
				id_cidade: 943,
			},
			{
				cidade: 'Ceres',
				id_cidade: 944,
			},
			{
				cidade: 'Cezarina',
				id_cidade: 945,
			},
			{
				cidade: 'Chapadão do Céu',
				id_cidade: 946,
			},
			{
				cidade: 'Cidade Ocidental',
				id_cidade: 947,
			},
			{
				cidade: 'Cocalzinho de Goiás',
				id_cidade: 948,
			},
			{
				cidade: 'Colinas do Sul',
				id_cidade: 949,
			},
			{
				cidade: 'Córrego do Ouro',
				id_cidade: 950,
			},
			{
				cidade: 'Corumbá de Goiás',
				id_cidade: 951,
			},
			{
				cidade: 'Corumbaíba',
				id_cidade: 952,
			},
			{
				cidade: 'Cristalina',
				id_cidade: 953,
			},
			{
				cidade: 'Cristianópolis',
				id_cidade: 954,
			},
			{
				cidade: 'Crixás',
				id_cidade: 955,
			},
			{
				cidade: 'Cromínia',
				id_cidade: 956,
			},
			{
				cidade: 'Cumari',
				id_cidade: 957,
			},
			{
				cidade: 'Damianópolis',
				id_cidade: 958,
			},
			{
				cidade: 'Damolândia',
				id_cidade: 959,
			},
			{
				cidade: 'Davinópolis',
				id_cidade: 960,
			},
			{
				cidade: 'Diorama',
				id_cidade: 961,
			},
			{
				cidade: 'Divinópolis de Goiás',
				id_cidade: 962,
			},
			{
				cidade: 'Doverlândia',
				id_cidade: 963,
			},
			{
				cidade: 'Edealina',
				id_cidade: 964,
			},
			{
				cidade: 'Edéia',
				id_cidade: 965,
			},
			{
				cidade: 'Estrela do Norte',
				id_cidade: 966,
			},
			{
				cidade: 'Faina',
				id_cidade: 967,
			},
			{
				cidade: 'Fazenda Nova',
				id_cidade: 968,
			},
			{
				cidade: 'Firminópolis',
				id_cidade: 969,
			},
			{
				cidade: 'Flores de Goiás',
				id_cidade: 970,
			},
			{
				cidade: 'Formosa',
				id_cidade: 971,
			},
			{
				cidade: 'Formoso',
				id_cidade: 972,
			},
			{
				cidade: 'Gameleira de Goiás',
				id_cidade: 973,
			},
			{
				cidade: 'Goianápolis',
				id_cidade: 974,
			},
			{
				cidade: 'Goiandira',
				id_cidade: 975,
			},
			{
				cidade: 'Goianésia',
				id_cidade: 976,
			},
			{
				cidade: 'Goiânia',
				id_cidade: 977,
			},
			{
				cidade: 'Goianira',
				id_cidade: 978,
			},
			{
				cidade: 'Goiatuba',
				id_cidade: 980,
			},
			{
				cidade: 'Gouvelândia',
				id_cidade: 981,
			},
			{
				cidade: 'Guapó',
				id_cidade: 982,
			},
			{
				cidade: 'Guaraíta',
				id_cidade: 983,
			},
			{
				cidade: 'Guarani de Goiás',
				id_cidade: 984,
			},
			{
				cidade: 'Guarinos',
				id_cidade: 985,
			},
			{
				cidade: 'Heitoraí',
				id_cidade: 986,
			},
			{
				cidade: 'Hidrolândia',
				id_cidade: 987,
			},
			{
				cidade: 'Hidrolina',
				id_cidade: 988,
			},
			{
				cidade: 'Iaciara',
				id_cidade: 989,
			},
			{
				cidade: 'Inaciolândia',
				id_cidade: 990,
			},
			{
				cidade: 'Indiara',
				id_cidade: 991,
			},
			{
				cidade: 'Inhumas',
				id_cidade: 992,
			},
			{
				cidade: 'Ipameri',
				id_cidade: 993,
			},
			{
				cidade: 'Ipiranga de Goiás',
				id_cidade: 994,
			},
			{
				cidade: 'Iporá',
				id_cidade: 995,
			},
			{
				cidade: 'Israelândia',
				id_cidade: 996,
			},
			{
				cidade: 'Itaberaí',
				id_cidade: 997,
			},
			{
				cidade: 'Itaguari',
				id_cidade: 998,
			},
			{
				cidade: 'Itaguaru',
				id_cidade: 999,
			},
			{
				cidade: 'Itajá',
				id_cidade: 1000,
			},
			{
				cidade: 'Itapaci',
				id_cidade: 1001,
			},
			{
				cidade: 'Itapirapuã',
				id_cidade: 1002,
			},
			{
				cidade: 'Itapuranga',
				id_cidade: 1003,
			},
			{
				cidade: 'Itarumã',
				id_cidade: 1004,
			},
			{
				cidade: 'Itauçu',
				id_cidade: 1005,
			},
			{
				cidade: 'Itumbiara',
				id_cidade: 1006,
			},
			{
				cidade: 'Ivolândia',
				id_cidade: 1007,
			},
			{
				cidade: 'Jandaia',
				id_cidade: 1008,
			},
			{
				cidade: 'Jaraguá',
				id_cidade: 1009,
			},
			{
				cidade: 'Jataí',
				id_cidade: 1010,
			},
			{
				cidade: 'Jaupaci',
				id_cidade: 1011,
			},
			{
				cidade: 'Jesúpolis',
				id_cidade: 1012,
			},
			{
				cidade: 'Joviânia',
				id_cidade: 1013,
			},
			{
				cidade: 'Jussara',
				id_cidade: 1014,
			},
			{
				cidade: 'Lagoa Santa',
				id_cidade: 1015,
			},
			{
				cidade: 'Leopoldo de Bulhões',
				id_cidade: 1016,
			},
			{
				cidade: 'Luziânia',
				id_cidade: 1017,
			},
			{
				cidade: 'Mairipotaba',
				id_cidade: 1018,
			},
			{
				cidade: 'Mambaí',
				id_cidade: 1019,
			},
			{
				cidade: 'Mara Rosa',
				id_cidade: 1020,
			},
			{
				cidade: 'Marzagão',
				id_cidade: 1021,
			},
			{
				cidade: 'Matrinchã',
				id_cidade: 1022,
			},
			{
				cidade: 'Maurilândia',
				id_cidade: 1023,
			},
			{
				cidade: 'Mimoso de Goiás',
				id_cidade: 1024,
			},
			{
				cidade: 'Minaçu',
				id_cidade: 1025,
			},
			{
				cidade: 'Mineiros',
				id_cidade: 1026,
			},
			{
				cidade: 'Moiporá',
				id_cidade: 1027,
			},
			{
				cidade: 'Monte Alegre de Goiás',
				id_cidade: 1028,
			},
			{
				cidade: 'Montes Claros de Goiás',
				id_cidade: 1029,
			},
			{
				cidade: 'Montividiu',
				id_cidade: 1030,
			},
			{
				cidade: 'Montividiu do Norte',
				id_cidade: 1031,
			},
			{
				cidade: 'Morrinhos',
				id_cidade: 1032,
			},
			{
				cidade: 'Morro Agudo de Goiás',
				id_cidade: 1033,
			},
			{
				cidade: 'Mossâmedes',
				id_cidade: 1034,
			},
			{
				cidade: 'Mozarlândia',
				id_cidade: 1035,
			},
			{
				cidade: 'Mundo Novo',
				id_cidade: 1036,
			},
			{
				cidade: 'Mutunópolis',
				id_cidade: 1037,
			},
			{
				cidade: 'Nazário',
				id_cidade: 1038,
			},
			{
				cidade: 'Nerópolis',
				id_cidade: 1039,
			},
			{
				cidade: 'Niquelândia',
				id_cidade: 1040,
			},
			{
				cidade: 'Nova América',
				id_cidade: 1041,
			},
			{
				cidade: 'Nova Aurora',
				id_cidade: 1042,
			},
			{
				cidade: 'Nova Crixás',
				id_cidade: 1043,
			},
			{
				cidade: 'Nova Glória',
				id_cidade: 1044,
			},
			{
				cidade: 'Nova Iguaçu de Goiás',
				id_cidade: 1045,
			},
			{
				cidade: 'Nova Roma',
				id_cidade: 1046,
			},
			{
				cidade: 'Nova Veneza',
				id_cidade: 1047,
			},
			{
				cidade: 'Novo Brasil',
				id_cidade: 1048,
			},
			{
				cidade: 'Novo Gama',
				id_cidade: 1049,
			},
			{
				cidade: 'Novo Planalto',
				id_cidade: 1050,
			},
			{
				cidade: 'Orizona',
				id_cidade: 1051,
			},
			{
				cidade: 'Ouro Verde de Goiás',
				id_cidade: 1052,
			},
			{
				cidade: 'Ouvidor',
				id_cidade: 1053,
			},
			{
				cidade: 'Padre Bernardo',
				id_cidade: 1054,
			},
			{
				cidade: 'Palestina de Goiás',
				id_cidade: 1055,
			},
			{
				cidade: 'Palmeiras de Goiás',
				id_cidade: 1056,
			},
			{
				cidade: 'Palmelo',
				id_cidade: 1057,
			},
			{
				cidade: 'Palminópolis',
				id_cidade: 1058,
			},
			{
				cidade: 'Panamá',
				id_cidade: 1059,
			},
			{
				cidade: 'Paranaiguara',
				id_cidade: 1060,
			},
			{
				cidade: 'Paraúna',
				id_cidade: 1061,
			},
			{
				cidade: 'Perolândia',
				id_cidade: 1062,
			},
			{
				cidade: 'Petrolina de Goiás',
				id_cidade: 1063,
			},
			{
				cidade: 'Pilar de Goiás',
				id_cidade: 1064,
			},
			{
				cidade: 'Piracanjuba',
				id_cidade: 1065,
			},
			{
				cidade: 'Piranhas',
				id_cidade: 1066,
			},
			{
				cidade: 'Pirenópolis',
				id_cidade: 1067,
			},
			{
				cidade: 'Pires do Rio',
				id_cidade: 1068,
			},
			{
				cidade: 'Planaltina',
				id_cidade: 1069,
			},
			{
				cidade: 'Pontalina',
				id_cidade: 1070,
			},
			{
				cidade: 'Porangatu',
				id_cidade: 1071,
			},
			{
				cidade: 'Porteirão',
				id_cidade: 1072,
			},
			{
				cidade: 'Portelândia',
				id_cidade: 1073,
			},
			{
				cidade: 'Posse',
				id_cidade: 1074,
			},
			{
				cidade: 'Professor Jamil',
				id_cidade: 1075,
			},
			{
				cidade: 'Quirinópolis',
				id_cidade: 1076,
			},
			{
				cidade: 'Rialma',
				id_cidade: 1077,
			},
			{
				cidade: 'Rianápolis',
				id_cidade: 1078,
			},
			{
				cidade: 'Rio Quente',
				id_cidade: 1079,
			},
			{
				cidade: 'Rio Verde',
				id_cidade: 1080,
			},
			{
				cidade: 'Rubiataba',
				id_cidade: 1081,
			},
			{
				cidade: 'Sanclerlândia',
				id_cidade: 1082,
			},
			{
				cidade: 'Santa Bárbara de Goiás',
				id_cidade: 1083,
			},
			{
				cidade: 'Santa Cruz de Goiás',
				id_cidade: 1084,
			},
			{
				cidade: 'Santa Fé de Goiás',
				id_cidade: 1085,
			},
			{
				cidade: 'Aruanã',
				id_cidade: 909,
			},
			{
				cidade: 'Santa Helena de Goiás',
				id_cidade: 1086,
			},
			{
				cidade: 'Santa Isabel',
				id_cidade: 1087,
			},
			{
				cidade: 'Abadia de Goiás',
				id_cidade: 883,
			},
			{
				cidade: 'Abadiânia',
				id_cidade: 884,
			},
			{
				cidade: 'Acreúna',
				id_cidade: 885,
			},
			{
				cidade: 'Adelândia',
				id_cidade: 886,
			},
			{
				cidade: 'Água Fria de Goiás',
				id_cidade: 887,
			},
			{
				cidade: 'Água Limpa',
				id_cidade: 888,
			},
			{
				cidade: 'Alto Horizonte',
				id_cidade: 892,
			},
			{
				cidade: 'Alto Paraíso de Goiás',
				id_cidade: 893,
			},
			{
				cidade: 'Alvorada do Norte',
				id_cidade: 894,
			},
			{
				cidade: 'Amaralina',
				id_cidade: 895,
			},
			{
				cidade: 'Santa Rita do Araguaia',
				id_cidade: 1088,
			},
			{
				cidade: 'Santa Rita do Novo Destino',
				id_cidade: 1089,
			},
			{
				cidade: 'Americano do Brasil',
				id_cidade: 896,
			},
			{
				cidade: 'Amorinópolis',
				id_cidade: 897,
			},
			{
				cidade: 'Anápolis',
				id_cidade: 898,
			},
			{
				cidade: 'Santa Rosa de Goiás',
				id_cidade: 1090,
			},
			{
				cidade: 'Santa Tereza de Goiás',
				id_cidade: 1091,
			},
			{
				cidade: 'Santa Terezinha de Goiás',
				id_cidade: 1092,
			},
			{
				cidade: 'Santo Antônio da Barra',
				id_cidade: 1093,
			},
			{
				cidade: 'Santo Antônio de Goiás',
				id_cidade: 1094,
			},
			{
				cidade: 'Goiás',
				id_cidade: 979,
			},
			{
				cidade: 'Santo Antônio do Descoberto',
				id_cidade: 1095,
			},
			{
				cidade: 'São Domingos',
				id_cidade: 1096,
			},
			{
				cidade: 'São Francisco de Goiás',
				id_cidade: 1097,
			},
			{
				cidade: "São João d'Aliança",
				id_cidade: 1098,
			},
			{
				cidade: 'São João da Paraúna',
				id_cidade: 1099,
			},
			{
				cidade: 'São Luís de Montes Belos',
				id_cidade: 1100,
			},
			{
				cidade: 'São Luíz do Norte',
				id_cidade: 1101,
			},
			{
				cidade: 'São Miguel do Araguaia',
				id_cidade: 1102,
			},
			{
				cidade: 'São Miguel do Passa Quatro',
				id_cidade: 1103,
			},
			{
				cidade: 'São Patrício',
				id_cidade: 1104,
			},
			{
				cidade: 'São Simão',
				id_cidade: 1105,
			},
			{
				cidade: 'Senador Canedo',
				id_cidade: 1106,
			},
			{
				cidade: 'Serranópolis',
				id_cidade: 1107,
			},
			{
				cidade: 'Anhanguera',
				id_cidade: 899,
			},
			{
				cidade: 'Anicuns',
				id_cidade: 900,
			},
			{
				cidade: 'Aparecida de Goiânia',
				id_cidade: 901,
			},
			{
				cidade: 'Aparecida do Rio Doce',
				id_cidade: 902,
			},
			{
				cidade: 'Aporé',
				id_cidade: 903,
			},
			{
				cidade: 'Silvânia',
				id_cidade: 1108,
			},
			{
				cidade: 'Simolândia',
				id_cidade: 1109,
			},
			{
				cidade: "Sítio d'Abadia",
				id_cidade: 1110,
			},
			{
				cidade: 'Taquaral de Goiás',
				id_cidade: 1111,
			},
			{
				cidade: 'Araçu',
				id_cidade: 904,
			},
			{
				cidade: 'Aragarças',
				id_cidade: 905,
			},
			{
				cidade: 'Aragoiânia',
				id_cidade: 906,
			},
			{
				cidade: 'Araguapaz',
				id_cidade: 907,
			},
			{
				cidade: 'Teresina de Goiás',
				id_cidade: 1112,
			},
			{
				cidade: 'Terezópolis de Goiás',
				id_cidade: 1113,
			},
			{
				cidade: 'Três Ranchos',
				id_cidade: 1114,
			},
			{
				cidade: 'Trindade',
				id_cidade: 1115,
			},
			{
				cidade: 'Trombas',
				id_cidade: 1116,
			},
			{
				cidade: 'Turvânia',
				id_cidade: 1117,
			},
			{
				cidade: 'Turvelândia',
				id_cidade: 1118,
			},
			{
				cidade: 'Uirapuru',
				id_cidade: 1119,
			},
			{
				cidade: 'Uruaçu',
				id_cidade: 1120,
			},
			{
				cidade: 'Uruana',
				id_cidade: 1121,
			},
			{
				cidade: 'Urutaí',
				id_cidade: 1122,
			},
			{
				cidade: 'Valparaíso de Goiás',
				id_cidade: 1123,
			},
			{
				cidade: 'Varjão',
				id_cidade: 1124,
			},
			{
				cidade: 'Vianópolis',
				id_cidade: 1125,
			},
			{
				cidade: 'Vicentinópolis',
				id_cidade: 1126,
			},
			{
				cidade: 'Vila Boa',
				id_cidade: 1127,
			},
			{
				cidade: 'Vila Propício',
				id_cidade: 1128,
			},
		],
	},
	{
		estado: 'Maranhão',
		sigla: 'MA',
		id_estado: 10,
		cidades: [
			{
				cidade: 'Axixá',
				id_cidade: 1147,
			},
			{
				cidade: 'Bacabal',
				id_cidade: 1148,
			},
			{
				cidade: 'Bacabeira',
				id_cidade: 1149,
			},
			{
				cidade: 'Bacuri',
				id_cidade: 1150,
			},
			{
				cidade: 'Bacurituba',
				id_cidade: 1151,
			},
			{
				cidade: 'Balsas',
				id_cidade: 1152,
			},
			{
				cidade: 'Barão de Grajaú',
				id_cidade: 1153,
			},
			{
				cidade: 'Barra do Corda',
				id_cidade: 1154,
			},
			{
				cidade: 'Barreirinhas',
				id_cidade: 1155,
			},
			{
				cidade: 'Belágua',
				id_cidade: 1157,
			},
			{
				cidade: 'Bela Vista do Maranhão',
				id_cidade: 1156,
			},
			{
				cidade: 'Benedito Leite',
				id_cidade: 1158,
			},
			{
				cidade: 'Bequimão',
				id_cidade: 1159,
			},
			{
				cidade: 'Bernardo do Mearim',
				id_cidade: 1160,
			},
			{
				cidade: 'Boa Vista do Gurupi',
				id_cidade: 1161,
			},
			{
				cidade: 'Bom Jardim',
				id_cidade: 1162,
			},
			{
				cidade: 'Bom Jesus das Selvas',
				id_cidade: 1163,
			},
			{
				cidade: 'Bom Lugar',
				id_cidade: 1164,
			},
			{
				cidade: 'Brejo',
				id_cidade: 1165,
			},
			{
				cidade: 'Brejo de Areia',
				id_cidade: 1166,
			},
			{
				cidade: 'Buriti',
				id_cidade: 1167,
			},
			{
				cidade: 'Buriti Bravo',
				id_cidade: 1168,
			},
			{
				cidade: 'Buriticupu',
				id_cidade: 1169,
			},
			{
				cidade: 'Buritirana',
				id_cidade: 1170,
			},
			{
				cidade: 'Cachoeira Grande',
				id_cidade: 1171,
			},
			{
				cidade: 'Cajapió',
				id_cidade: 1172,
			},
			{
				cidade: 'Cajari',
				id_cidade: 1173,
			},
			{
				cidade: 'Campestre do Maranhão',
				id_cidade: 1174,
			},
			{
				cidade: 'Cândido Mendes',
				id_cidade: 1175,
			},
			{
				cidade: 'Cantanhede',
				id_cidade: 1176,
			},
			{
				cidade: 'Capinzal do Norte',
				id_cidade: 1177,
			},
			{
				cidade: 'Carolina',
				id_cidade: 1178,
			},
			{
				cidade: 'Carutapera',
				id_cidade: 1179,
			},
			{
				cidade: 'Caxias',
				id_cidade: 1180,
			},
			{
				cidade: 'Cedral',
				id_cidade: 1181,
			},
			{
				cidade: 'Central do Maranhão',
				id_cidade: 1182,
			},
			{
				cidade: 'Centro do Guilherme',
				id_cidade: 1183,
			},
			{
				cidade: 'Centro Novo do Maranhão',
				id_cidade: 1184,
			},
			{
				cidade: 'Chapadinha',
				id_cidade: 1185,
			},
			{
				cidade: 'Açailândia',
				id_cidade: 1129,
			},
			{
				cidade: 'Afonso Cunha',
				id_cidade: 1130,
			},
			{
				cidade: 'Água Doce do Maranhão',
				id_cidade: 1131,
			},
			{
				cidade: 'Aldeias Altas',
				id_cidade: 1133,
			},
			{
				cidade: 'Altamira do Maranhão',
				id_cidade: 1134,
			},
			{
				cidade: 'Alto Alegre do Maranhão',
				id_cidade: 1135,
			},
			{
				cidade: 'Alto Alegre do Pindaré',
				id_cidade: 1136,
			},
			{
				cidade: 'Alto Parnaíba',
				id_cidade: 1137,
			},
			{
				cidade: 'Amapá do Maranhão',
				id_cidade: 1138,
			},
			{
				cidade: 'Cidelândia',
				id_cidade: 1186,
			},
			{
				cidade: 'Codó',
				id_cidade: 1187,
			},
			{
				cidade: 'Coelho Neto',
				id_cidade: 1188,
			},
			{
				cidade: 'Colinas',
				id_cidade: 1189,
			},
			{
				cidade: 'Conceição do Lago-Açu',
				id_cidade: 1190,
			},
			{
				cidade: 'Coroatá',
				id_cidade: 1191,
			},
			{
				cidade: 'Cururupu',
				id_cidade: 1192,
			},
			{
				cidade: 'Davinópolis',
				id_cidade: 1193,
			},
			{
				cidade: 'Dom Pedro',
				id_cidade: 1194,
			},
			{
				cidade: 'Duque Bacelar',
				id_cidade: 1195,
			},
			{
				cidade: 'Esperantinópolis',
				id_cidade: 1196,
			},
			{
				cidade: 'Estreito',
				id_cidade: 1197,
			},
			{
				cidade: 'Feira Nova do Maranhão',
				id_cidade: 1198,
			},
			{
				cidade: 'Fernando Falcão',
				id_cidade: 1199,
			},
			{
				cidade: 'Formosa da Serra Negra',
				id_cidade: 1200,
			},
			{
				cidade: 'Fortaleza dos Nogueiras',
				id_cidade: 1201,
			},
			{
				cidade: 'Fortuna',
				id_cidade: 1202,
			},
			{
				cidade: 'Godofredo Viana',
				id_cidade: 1203,
			},
			{
				cidade: 'Gonçalves Dias',
				id_cidade: 1204,
			},
			{
				cidade: 'Governador Archer',
				id_cidade: 1205,
			},
			{
				cidade: 'Governador Edison Lobão',
				id_cidade: 1206,
			},
			{
				cidade: 'Governador Eugênio Barros',
				id_cidade: 1207,
			},
			{
				cidade: 'Governador Luiz Rocha',
				id_cidade: 1208,
			},
			{
				cidade: 'Governador Newton Bello',
				id_cidade: 1209,
			},
			{
				cidade: 'Governador Nunes Freire',
				id_cidade: 1210,
			},
			{
				cidade: 'Graça Aranha',
				id_cidade: 1211,
			},
			{
				cidade: 'Grajaú',
				id_cidade: 1212,
			},
			{
				cidade: 'Guimarães',
				id_cidade: 1213,
			},
			{
				cidade: 'Humberto de Campos',
				id_cidade: 1214,
			},
			{
				cidade: 'Icatu',
				id_cidade: 1215,
			},
			{
				cidade: 'Igarapé do Meio',
				id_cidade: 1216,
			},
			{
				cidade: 'Igarapé Grande',
				id_cidade: 1217,
			},
			{
				cidade: 'Imperatriz',
				id_cidade: 1218,
			},
			{
				cidade: 'Itaipava do Grajaú',
				id_cidade: 1219,
			},
			{
				cidade: 'Itapecuru Mirim',
				id_cidade: 1220,
			},
			{
				cidade: 'Itinga do Maranhão',
				id_cidade: 1221,
			},
			{
				cidade: 'Jatobá',
				id_cidade: 1222,
			},
			{
				cidade: 'Jenipapo dos Vieiras',
				id_cidade: 1223,
			},
			{
				cidade: 'João Lisboa',
				id_cidade: 1224,
			},
			{
				cidade: 'Joselândia',
				id_cidade: 1225,
			},
			{
				cidade: 'Junco do Maranhão',
				id_cidade: 1226,
			},
			{
				cidade: 'Lagoa do Mato',
				id_cidade: 1231,
			},
			{
				cidade: 'Lagoa Grande do Maranhão',
				id_cidade: 1232,
			},
			{
				cidade: 'Lago da Pedra',
				id_cidade: 1227,
			},
			{
				cidade: 'Lago do Junco',
				id_cidade: 1228,
			},
			{
				cidade: 'Lago dos Rodrigues',
				id_cidade: 1229,
			},
			{
				cidade: 'Lago Verde',
				id_cidade: 1230,
			},
			{
				cidade: 'Lajeado Novo',
				id_cidade: 1233,
			},
			{
				cidade: 'Lima Campos',
				id_cidade: 1234,
			},
			{
				cidade: 'Loreto',
				id_cidade: 1235,
			},
			{
				cidade: 'Luís Domingues',
				id_cidade: 1236,
			},
			{
				cidade: 'Magalhães de Almeida',
				id_cidade: 1237,
			},
			{
				cidade: 'Maracaçumé',
				id_cidade: 1238,
			},
			{
				cidade: 'Marajá do Sena',
				id_cidade: 1239,
			},
			{
				cidade: 'Maranhãozinho',
				id_cidade: 1240,
			},
			{
				cidade: 'Mata Roma',
				id_cidade: 1241,
			},
			{
				cidade: 'Matinha',
				id_cidade: 1242,
			},
			{
				cidade: 'Matões',
				id_cidade: 1243,
			},
			{
				cidade: 'Matões do Norte',
				id_cidade: 1244,
			},
			{
				cidade: 'Milagres do Maranhão',
				id_cidade: 1245,
			},
			{
				cidade: 'Mirador',
				id_cidade: 1246,
			},
			{
				cidade: 'Miranda do Norte',
				id_cidade: 1247,
			},
			{
				cidade: 'Mirinzal',
				id_cidade: 1248,
			},
			{
				cidade: 'Monção',
				id_cidade: 1249,
			},
			{
				cidade: 'Montes Altos',
				id_cidade: 1250,
			},
			{
				cidade: 'Morros',
				id_cidade: 1251,
			},
			{
				cidade: 'Nina Rodrigues',
				id_cidade: 1252,
			},
			{
				cidade: 'Nova Colinas',
				id_cidade: 1253,
			},
			{
				cidade: 'Nova Iorque',
				id_cidade: 1254,
			},
			{
				cidade: 'Nova Olinda do Maranhão',
				id_cidade: 1255,
			},
			{
				cidade: "Olho d'Água das Cunhãs",
				id_cidade: 1256,
			},
			{
				cidade: 'Olinda Nova do Maranhão',
				id_cidade: 1257,
			},
			{
				cidade: 'Paço do Lumiar',
				id_cidade: 1258,
			},
			{
				cidade: 'Palmeirândia',
				id_cidade: 1259,
			},
			{
				cidade: 'Paraibano',
				id_cidade: 1260,
			},
			{
				cidade: 'Parnarama',
				id_cidade: 1261,
			},
			{
				cidade: 'Passagem Franca',
				id_cidade: 1262,
			},
			{
				cidade: 'Pastos Bons',
				id_cidade: 1263,
			},
			{
				cidade: 'Paulino Neves',
				id_cidade: 1264,
			},
			{
				cidade: 'Paulo Ramos',
				id_cidade: 1265,
			},
			{
				cidade: 'Pedreiras',
				id_cidade: 1266,
			},
			{
				cidade: 'Pedro do Rosário',
				id_cidade: 1267,
			},
			{
				cidade: 'Penalva',
				id_cidade: 1268,
			},
			{
				cidade: 'Peri Mirim',
				id_cidade: 1269,
			},
			{
				cidade: 'Peritoró',
				id_cidade: 1270,
			},
			{
				cidade: 'Pindaré-Mirim',
				id_cidade: 1271,
			},
			{
				cidade: 'Pinheiro',
				id_cidade: 1272,
			},
			{
				cidade: 'Pio XII',
				id_cidade: 1273,
			},
			{
				cidade: 'Pirapemas',
				id_cidade: 1274,
			},
			{
				cidade: 'Poção de Pedras',
				id_cidade: 1275,
			},
			{
				cidade: 'Porto Franco',
				id_cidade: 1276,
			},
			{
				cidade: 'Porto Rico do Maranhão',
				id_cidade: 1277,
			},
			{
				cidade: 'Presidente Dutra',
				id_cidade: 1278,
			},
			{
				cidade: 'Presidente Juscelino',
				id_cidade: 1279,
			},
			{
				cidade: 'Presidente Médici',
				id_cidade: 1280,
			},
			{
				cidade: 'Presidente Sarney',
				id_cidade: 1281,
			},
			{
				cidade: 'Presidente Vargas',
				id_cidade: 1282,
			},
			{
				cidade: 'Primeira Cruz',
				id_cidade: 1283,
			},
			{
				cidade: 'Raposa',
				id_cidade: 1284,
			},
			{
				cidade: 'Riachão',
				id_cidade: 1285,
			},
			{
				cidade: 'Ribamar Fiquene',
				id_cidade: 1286,
			},
			{
				cidade: 'Rosário',
				id_cidade: 1287,
			},
			{
				cidade: 'Sambaíba',
				id_cidade: 1288,
			},
			{
				cidade: 'Alcântara',
				id_cidade: 1132,
			},
			{
				cidade: 'Anajatuba',
				id_cidade: 1140,
			},
			{
				cidade: 'Anapurus',
				id_cidade: 1141,
			},
			{
				cidade: 'Araguanã',
				id_cidade: 1143,
			},
			{
				cidade: 'Araioses',
				id_cidade: 1144,
			},
			{
				cidade: 'Arame',
				id_cidade: 1145,
			},
			{
				cidade: 'Arari',
				id_cidade: 1146,
			},
			{
				cidade: 'Santa Filomena do Maranhão',
				id_cidade: 1289,
			},
			{
				cidade: 'Santa Helena',
				id_cidade: 1290,
			},
			{
				cidade: 'Santa Inês',
				id_cidade: 1291,
			},
			{
				cidade: 'Santa Luzia',
				id_cidade: 1292,
			},
			{
				cidade: 'Santa Luzia do Paruá',
				id_cidade: 1293,
			},
			{
				cidade: 'Santana do Maranhão',
				id_cidade: 1296,
			},
			{
				cidade: 'Amarante do Maranhão',
				id_cidade: 1139,
			},
			{
				cidade: 'Santa Quitéria do Maranhão',
				id_cidade: 1294,
			},
			{
				cidade: 'Santa Rita',
				id_cidade: 1295,
			},
			{
				cidade: 'Santo Amaro do Maranhão',
				id_cidade: 1297,
			},
			{
				cidade: 'Santo Antônio dos Lopes',
				id_cidade: 1298,
			},
			{
				cidade: 'São Benedito do Rio Preto',
				id_cidade: 1299,
			},
			{
				cidade: 'São Bento',
				id_cidade: 1300,
			},
			{
				cidade: 'São Bernardo',
				id_cidade: 1301,
			},
			{
				cidade: 'São Domingos do Azeitão',
				id_cidade: 1302,
			},
			{
				cidade: 'São Domingos do Maranhão',
				id_cidade: 1303,
			},
			{
				cidade: 'São Félix de Balsas',
				id_cidade: 1304,
			},
			{
				cidade: 'São Francisco do Brejão',
				id_cidade: 1305,
			},
			{
				cidade: 'São Francisco do Maranhão',
				id_cidade: 1306,
			},
			{
				cidade: 'São João Batista',
				id_cidade: 1307,
			},
			{
				cidade: 'São João do Carú',
				id_cidade: 1308,
			},
			{
				cidade: 'São João do Paraíso',
				id_cidade: 1309,
			},
			{
				cidade: 'São João do Soter',
				id_cidade: 1310,
			},
			{
				cidade: 'São João dos Patos',
				id_cidade: 1311,
			},
			{
				cidade: 'São José de Ribamar',
				id_cidade: 1312,
			},
			{
				cidade: 'São José dos Basílios',
				id_cidade: 1313,
			},
			{
				cidade: 'São Luís',
				id_cidade: 1314,
			},
			{
				cidade: 'São Luís Gonzaga do Maranhão',
				id_cidade: 1315,
			},
			{
				cidade: 'São Mateus do Maranhão',
				id_cidade: 1316,
			},
			{
				cidade: 'São Pedro da Água Branca',
				id_cidade: 1317,
			},
			{
				cidade: 'São Pedro dos Crentes',
				id_cidade: 1318,
			},
			{
				cidade: 'São Raimundo das Mangabeiras',
				id_cidade: 1319,
			},
			{
				cidade: 'São Raimundo do Doca Bezerra',
				id_cidade: 1320,
			},
			{
				cidade: 'São Roberto',
				id_cidade: 1321,
			},
			{
				cidade: 'São Vicente Ferrer',
				id_cidade: 1322,
			},
			{
				cidade: 'Satubinha',
				id_cidade: 1323,
			},
			{
				cidade: 'Senador Alexandre Costa',
				id_cidade: 1324,
			},
			{
				cidade: 'Senador La Rocque',
				id_cidade: 1325,
			},
			{
				cidade: 'Serrano do Maranhão',
				id_cidade: 1326,
			},
			{
				cidade: 'Apicum-Açu',
				id_cidade: 1142,
			},
			{
				cidade: 'Sítio Novo',
				id_cidade: 1327,
			},
			{
				cidade: 'Sucupira do Norte',
				id_cidade: 1328,
			},
			{
				cidade: 'Sucupira do Riachão',
				id_cidade: 1329,
			},
			{
				cidade: 'Tasso Fragoso',
				id_cidade: 1330,
			},
			{
				cidade: 'Timbiras',
				id_cidade: 1331,
			},
			{
				cidade: 'Timon',
				id_cidade: 1332,
			},
			{
				cidade: 'Trizidela do Vale',
				id_cidade: 1333,
			},
			{
				cidade: 'Tufilândia',
				id_cidade: 1334,
			},
			{
				cidade: 'Tuntum',
				id_cidade: 1335,
			},
			{
				cidade: 'Turiaçu',
				id_cidade: 1336,
			},
			{
				cidade: 'Turilândia',
				id_cidade: 1337,
			},
			{
				cidade: 'Tutóia',
				id_cidade: 1338,
			},
			{
				cidade: 'Urbano Santos',
				id_cidade: 1339,
			},
			{
				cidade: 'Vargem Grande',
				id_cidade: 1340,
			},
			{
				cidade: 'Viana',
				id_cidade: 1341,
			},
			{
				cidade: 'Vila Nova dos Martírios',
				id_cidade: 1342,
			},
			{
				cidade: 'Vitória do Mearim',
				id_cidade: 1343,
			},
			{
				cidade: 'Vitorino Freire',
				id_cidade: 1344,
			},
			{
				cidade: 'Zé Doca',
				id_cidade: 1345,
			},
		],
	},
	{
		estado: 'Minas Gerais',
		sigla: 'MG',
		id_estado: 11,
		cidades: [
			{
				cidade: 'Águas Vermelhas',
				id_cidade: 1574,
			},
			{
				cidade: 'Aimorés',
				id_cidade: 1575,
			},
			{
				cidade: 'Aiuruoca',
				id_cidade: 1576,
			},
			{
				cidade: 'Alagoa',
				id_cidade: 1577,
			},
			{
				cidade: 'Albertina',
				id_cidade: 1578,
			},
			{
				cidade: 'Além Paraíba',
				id_cidade: 1579,
			},
			{
				cidade: 'Alfenas',
				id_cidade: 1580,
			},
			{
				cidade: 'Alfredo Vasconcelos',
				id_cidade: 1581,
			},
			{
				cidade: 'Almenara',
				id_cidade: 1582,
			},
			{
				cidade: 'Alpercata',
				id_cidade: 1583,
			},
			{
				cidade: 'Araújos',
				id_cidade: 1607,
			},
			{
				cidade: 'Araxá',
				id_cidade: 1608,
			},
			{
				cidade: 'Arceburgo',
				id_cidade: 1609,
			},
			{
				cidade: 'Arcos',
				id_cidade: 1610,
			},
			{
				cidade: 'Areado',
				id_cidade: 1611,
			},
			{
				cidade: 'Argirita',
				id_cidade: 1612,
			},
			{
				cidade: 'Aricanduva',
				id_cidade: 1613,
			},
			{
				cidade: 'Arinos',
				id_cidade: 1614,
			},
			{
				cidade: 'Astolfo Dutra',
				id_cidade: 1615,
			},
			{
				cidade: 'Ataléia',
				id_cidade: 1616,
			},
			{
				cidade: 'Augusto de Lima',
				id_cidade: 1617,
			},
			{
				cidade: 'Baependi',
				id_cidade: 1618,
			},
			{
				cidade: 'Baldim',
				id_cidade: 1619,
			},
			{
				cidade: 'Bambuí',
				id_cidade: 1620,
			},
			{
				cidade: 'Bandeira',
				id_cidade: 1621,
			},
			{
				cidade: 'Bandeira do Sul',
				id_cidade: 1622,
			},
			{
				cidade: 'Barão de Cocais',
				id_cidade: 1623,
			},
			{
				cidade: 'Barão de Monte Alto',
				id_cidade: 1624,
			},
			{
				cidade: 'Barbacena',
				id_cidade: 1625,
			},
			{
				cidade: 'Barra Longa',
				id_cidade: 1626,
			},
			{
				cidade: 'Barroso',
				id_cidade: 1627,
			},
			{
				cidade: 'Bela Vista de Minas',
				id_cidade: 1628,
			},
			{
				cidade: 'Belmiro Braga',
				id_cidade: 1629,
			},
			{
				cidade: 'Belo Horizonte',
				id_cidade: 1630,
			},
			{
				cidade: 'Belo Oriente',
				id_cidade: 1631,
			},
			{
				cidade: 'Belo Vale',
				id_cidade: 1632,
			},
			{
				cidade: 'Berilo',
				id_cidade: 1633,
			},
			{
				cidade: 'Berizal',
				id_cidade: 1634,
			},
			{
				cidade: 'Bertópolis',
				id_cidade: 1635,
			},
			{
				cidade: 'Betim',
				id_cidade: 1636,
			},
			{
				cidade: 'Bias Fortes',
				id_cidade: 1637,
			},
			{
				cidade: 'Bicas',
				id_cidade: 1638,
			},
			{
				cidade: 'Biquinhas',
				id_cidade: 1639,
			},
			{
				cidade: 'Boa Esperança',
				id_cidade: 1640,
			},
			{
				cidade: 'Bocaina de Minas',
				id_cidade: 1641,
			},
			{
				cidade: 'Bocaiúva',
				id_cidade: 1642,
			},
			{
				cidade: 'Bom Despacho',
				id_cidade: 1643,
			},
			{
				cidade: 'Bom Jardim de Minas',
				id_cidade: 1644,
			},
			{
				cidade: 'Bom Jesus da Penha',
				id_cidade: 1645,
			},
			{
				cidade: 'Bom Jesus do Amparo',
				id_cidade: 1646,
			},
			{
				cidade: 'Bom Jesus do Galho',
				id_cidade: 1647,
			},
			{
				cidade: 'Bom Repouso',
				id_cidade: 1648,
			},
			{
				cidade: 'Bom Sucesso',
				id_cidade: 1649,
			},
			{
				cidade: 'Bonfim',
				id_cidade: 1650,
			},
			{
				cidade: 'Bonfinópolis de Minas',
				id_cidade: 1651,
			},
			{
				cidade: 'Bonito de Minas',
				id_cidade: 1652,
			},
			{
				cidade: 'Borda da Mata',
				id_cidade: 1653,
			},
			{
				cidade: 'Botelhos',
				id_cidade: 1654,
			},
			{
				cidade: 'Botumirim',
				id_cidade: 1655,
			},
			{
				cidade: 'Brasilândia de Minas',
				id_cidade: 1657,
			},
			{
				cidade: 'Brasília de Minas',
				id_cidade: 1658,
			},
			{
				cidade: 'Brás Pires',
				id_cidade: 1656,
			},
			{
				cidade: 'Braúnas',
				id_cidade: 1660,
			},
			{
				cidade: 'Brumadinho',
				id_cidade: 1661,
			},
			{
				cidade: 'Bueno Brandão',
				id_cidade: 1662,
			},
			{
				cidade: 'Buenópolis',
				id_cidade: 1663,
			},
			{
				cidade: 'Bugre',
				id_cidade: 1664,
			},
			{
				cidade: 'Buritis',
				id_cidade: 1665,
			},
			{
				cidade: 'Buritizeiro',
				id_cidade: 1666,
			},
			{
				cidade: 'Cabeceira Grande',
				id_cidade: 1667,
			},
			{
				cidade: 'Cabo Verde',
				id_cidade: 1668,
			},
			{
				cidade: 'Cachoeira da Prata',
				id_cidade: 1669,
			},
			{
				cidade: 'Cachoeira de Minas',
				id_cidade: 1670,
			},
			{
				cidade: 'Cachoeira de Pajeú',
				id_cidade: 1671,
			},
			{
				cidade: 'Cachoeira Dourada',
				id_cidade: 1672,
			},
			{
				cidade: 'Caetanópolis',
				id_cidade: 1673,
			},
			{
				cidade: 'Caeté',
				id_cidade: 1674,
			},
			{
				cidade: 'Caiana',
				id_cidade: 1675,
			},
			{
				cidade: 'Cajuri',
				id_cidade: 1676,
			},
			{
				cidade: 'Caldas',
				id_cidade: 1677,
			},
			{
				cidade: 'Camacho',
				id_cidade: 1678,
			},
			{
				cidade: 'Camanducaia',
				id_cidade: 1679,
			},
			{
				cidade: 'Cambuí',
				id_cidade: 1680,
			},
			{
				cidade: 'Cambuquira',
				id_cidade: 1681,
			},
			{
				cidade: 'Campanário',
				id_cidade: 1682,
			},
			{
				cidade: 'Campanha',
				id_cidade: 1683,
			},
			{
				cidade: 'Campestre',
				id_cidade: 1684,
			},
			{
				cidade: 'Campina Verde',
				id_cidade: 1685,
			},
			{
				cidade: 'Campo Azul',
				id_cidade: 1686,
			},
			{
				cidade: 'Campo Belo',
				id_cidade: 1687,
			},
			{
				cidade: 'Campo do Meio',
				id_cidade: 1688,
			},
			{
				cidade: 'Campo Florido',
				id_cidade: 1689,
			},
			{
				cidade: 'Campos Altos',
				id_cidade: 1690,
			},
			{
				cidade: 'Campos Gerais',
				id_cidade: 1691,
			},
			{
				cidade: 'Canaã',
				id_cidade: 1693,
			},
			{
				cidade: 'Canápolis',
				id_cidade: 1694,
			},
			{
				cidade: 'Cana Verde',
				id_cidade: 1692,
			},
			{
				cidade: 'Candeias',
				id_cidade: 1695,
			},
			{
				cidade: 'Cantagalo',
				id_cidade: 1696,
			},
			{
				cidade: 'Caparaó',
				id_cidade: 1697,
			},
			{
				cidade: 'Capela Nova',
				id_cidade: 1698,
			},
			{
				cidade: 'Capelinha',
				id_cidade: 1699,
			},
			{
				cidade: 'Capetinga',
				id_cidade: 1700,
			},
			{
				cidade: 'Capim Branco',
				id_cidade: 1701,
			},
			{
				cidade: 'Capinópolis',
				id_cidade: 1702,
			},
			{
				cidade: 'Capitão Andrade',
				id_cidade: 1703,
			},
			{
				cidade: 'Capitão Enéas',
				id_cidade: 1704,
			},
			{
				cidade: 'Capitólio',
				id_cidade: 1705,
			},
			{
				cidade: 'Caputira',
				id_cidade: 1706,
			},
			{
				cidade: 'Caraí',
				id_cidade: 1707,
			},
			{
				cidade: 'Caranaíba',
				id_cidade: 1708,
			},
			{
				cidade: 'Carandaí',
				id_cidade: 1709,
			},
			{
				cidade: 'Carangola',
				id_cidade: 1710,
			},
			{
				cidade: 'Caratinga',
				id_cidade: 1711,
			},
			{
				cidade: 'Carbonita',
				id_cidade: 1712,
			},
			{
				cidade: 'Careaçu',
				id_cidade: 1713,
			},
			{
				cidade: 'Carlos Chagas',
				id_cidade: 1714,
			},
			{
				cidade: 'Carmésia',
				id_cidade: 1715,
			},
			{
				cidade: 'Carmo da Cachoeira',
				id_cidade: 1716,
			},
			{
				cidade: 'Carmo da Mata',
				id_cidade: 1717,
			},
			{
				cidade: 'Carmo de Minas',
				id_cidade: 1718,
			},
			{
				cidade: 'Carmo do Cajuru',
				id_cidade: 1719,
			},
			{
				cidade: 'Carmo do Paranaíba',
				id_cidade: 1720,
			},
			{
				cidade: 'Carmo do Rio Claro',
				id_cidade: 1721,
			},
			{
				cidade: 'Carmópolis de Minas',
				id_cidade: 1722,
			},
			{
				cidade: 'Carneirinho',
				id_cidade: 1723,
			},
			{
				cidade: 'Carrancas',
				id_cidade: 1724,
			},
			{
				cidade: 'Carvalhópolis',
				id_cidade: 1725,
			},
			{
				cidade: 'Carvalhos',
				id_cidade: 1726,
			},
			{
				cidade: 'Casa Grande',
				id_cidade: 1727,
			},
			{
				cidade: 'Cascalho Rico',
				id_cidade: 1728,
			},
			{
				cidade: 'Cássia',
				id_cidade: 1729,
			},
			{
				cidade: 'Cataguases',
				id_cidade: 1730,
			},
			{
				cidade: 'Catas Altas',
				id_cidade: 1731,
			},
			{
				cidade: 'Catas Altas da Noruega',
				id_cidade: 1732,
			},
			{
				cidade: 'Catuji',
				id_cidade: 1733,
			},
			{
				cidade: 'Catuti',
				id_cidade: 1734,
			},
			{
				cidade: 'Caxambu',
				id_cidade: 1735,
			},
			{
				cidade: 'Cedro do Abaeté',
				id_cidade: 1736,
			},
			{
				cidade: 'Central de Minas',
				id_cidade: 1737,
			},
			{
				cidade: 'Centralina',
				id_cidade: 1738,
			},
			{
				cidade: 'Chácara',
				id_cidade: 1739,
			},
			{
				cidade: 'Chalé',
				id_cidade: 1740,
			},
			{
				cidade: 'Chapada do Norte',
				id_cidade: 1741,
			},
			{
				cidade: 'Chapada Gaúcha',
				id_cidade: 1742,
			},
			{
				cidade: 'Chiador',
				id_cidade: 1743,
			},
			{
				cidade: 'Cipotânea',
				id_cidade: 1744,
			},
			{
				cidade: 'Claraval',
				id_cidade: 1745,
			},
			{
				cidade: 'Claro dos Poções',
				id_cidade: 1746,
			},
			{
				cidade: 'Cláudio',
				id_cidade: 1747,
			},
			{
				cidade: 'Coimbra',
				id_cidade: 1748,
			},
			{
				cidade: 'Coluna',
				id_cidade: 1749,
			},
			{
				cidade: 'Comendador Gomes',
				id_cidade: 1750,
			},
			{
				cidade: 'Comercinho',
				id_cidade: 1751,
			},
			{
				cidade: 'Conceição da Aparecida',
				id_cidade: 1752,
			},
			{
				cidade: 'Conceição da Barra de Minas',
				id_cidade: 1753,
			},
			{
				cidade: 'Conceição das Alagoas',
				id_cidade: 1754,
			},
			{
				cidade: 'Conceição das Pedras',
				id_cidade: 1755,
			},
			{
				cidade: 'Conceição de Ipanema',
				id_cidade: 1756,
			},
			{
				cidade: 'Conceição do Mato Dentro',
				id_cidade: 1757,
			},
			{
				cidade: 'Conceição do Pará',
				id_cidade: 1758,
			},
			{
				cidade: 'Conceição do Rio Verde',
				id_cidade: 1759,
			},
			{
				cidade: 'Conceição dos Ouros',
				id_cidade: 1760,
			},
			{
				cidade: 'Cônego Marinho',
				id_cidade: 1761,
			},
			{
				cidade: 'Confins',
				id_cidade: 1762,
			},
			{
				cidade: 'Congonhal',
				id_cidade: 1763,
			},
			{
				cidade: 'Congonhas',
				id_cidade: 1764,
			},
			{
				cidade: 'Congonhas do Norte',
				id_cidade: 1765,
			},
			{
				cidade: 'Conquista',
				id_cidade: 1766,
			},
			{
				cidade: 'Conselheiro Lafaiete',
				id_cidade: 1767,
			},
			{
				cidade: 'Conselheiro Pena',
				id_cidade: 1768,
			},
			{
				cidade: 'Consolação',
				id_cidade: 1769,
			},
			{
				cidade: 'Contagem',
				id_cidade: 1770,
			},
			{
				cidade: 'Coqueiral',
				id_cidade: 1771,
			},
			{
				cidade: 'Coração de Jesus',
				id_cidade: 1772,
			},
			{
				cidade: 'Cordisburgo',
				id_cidade: 1773,
			},
			{
				cidade: 'Cordislândia',
				id_cidade: 1774,
			},
			{
				cidade: 'Corinto',
				id_cidade: 1775,
			},
			{
				cidade: 'Coroaci',
				id_cidade: 1776,
			},
			{
				cidade: 'Coromandel',
				id_cidade: 1777,
			},
			{
				cidade: 'Coronel Fabriciano',
				id_cidade: 1778,
			},
			{
				cidade: 'Coronel Murta',
				id_cidade: 1779,
			},
			{
				cidade: 'Coronel Pacheco',
				id_cidade: 1780,
			},
			{
				cidade: 'Coronel Xavier Chaves',
				id_cidade: 1781,
			},
			{
				cidade: 'Córrego Danta',
				id_cidade: 1782,
			},
			{
				cidade: 'Córrego do Bom Jesus',
				id_cidade: 1783,
			},
			{
				cidade: 'Córrego Fundo',
				id_cidade: 1784,
			},
			{
				cidade: 'Córrego Novo',
				id_cidade: 1785,
			},
			{
				cidade: 'Couto de Magalhães de Minas',
				id_cidade: 1786,
			},
			{
				cidade: 'Crisólita',
				id_cidade: 1787,
			},
			{
				cidade: 'Cristais',
				id_cidade: 1788,
			},
			{
				cidade: 'Cristália',
				id_cidade: 1789,
			},
			{
				cidade: 'Cristiano Otoni',
				id_cidade: 1790,
			},
			{
				cidade: 'Cristina',
				id_cidade: 1791,
			},
			{
				cidade: 'Crucilândia',
				id_cidade: 1792,
			},
			{
				cidade: 'Cruzeiro da Fortaleza',
				id_cidade: 1793,
			},
			{
				cidade: 'Cruzília',
				id_cidade: 1794,
			},
			{
				cidade: 'Cuparaque',
				id_cidade: 1795,
			},
			{
				cidade: 'Curral de Dentro',
				id_cidade: 1796,
			},
			{
				cidade: 'Curvelo',
				id_cidade: 1797,
			},
			{
				cidade: 'Datas',
				id_cidade: 1798,
			},
			{
				cidade: 'Delfim Moreira',
				id_cidade: 1799,
			},
			{
				cidade: 'Delfinópolis',
				id_cidade: 1800,
			},
			{
				cidade: 'Delta',
				id_cidade: 1801,
			},
			{
				cidade: 'Descoberto',
				id_cidade: 1802,
			},
			{
				cidade: 'Desterro de Entre Rios',
				id_cidade: 1803,
			},
			{
				cidade: 'Desterro do Melo',
				id_cidade: 1804,
			},
			{
				cidade: 'Diamantina',
				id_cidade: 1805,
			},
			{
				cidade: 'Diogo de Vasconcelos',
				id_cidade: 1806,
			},
			{
				cidade: 'Dionísio',
				id_cidade: 1807,
			},
			{
				cidade: 'Divinésia',
				id_cidade: 1808,
			},
			{
				cidade: 'Divino',
				id_cidade: 1809,
			},
			{
				cidade: 'Divino das Laranjeiras',
				id_cidade: 1810,
			},
			{
				cidade: 'Divinolândia de Minas',
				id_cidade: 1811,
			},
			{
				cidade: 'Divinópolis',
				id_cidade: 1812,
			},
			{
				cidade: 'Divisa Alegre',
				id_cidade: 1813,
			},
			{
				cidade: 'Divisa Nova',
				id_cidade: 1814,
			},
			{
				cidade: 'Divisópolis',
				id_cidade: 1815,
			},
			{
				cidade: 'Dom Bosco',
				id_cidade: 1816,
			},
			{
				cidade: 'Dom Cavati',
				id_cidade: 1817,
			},
			{
				cidade: 'Dom Joaquim',
				id_cidade: 1818,
			},
			{
				cidade: 'Dom Silvério',
				id_cidade: 1819,
			},
			{
				cidade: 'Dom Viçoso',
				id_cidade: 1820,
			},
			{
				cidade: 'Dona Eusébia',
				id_cidade: 1821,
			},
			{
				cidade: 'Dores de Campos',
				id_cidade: 1822,
			},
			{
				cidade: 'Dores de Guanhães',
				id_cidade: 1823,
			},
			{
				cidade: 'Dores do Indaiá',
				id_cidade: 1824,
			},
			{
				cidade: 'Dores do Turvo',
				id_cidade: 1825,
			},
			{
				cidade: 'Doresópolis',
				id_cidade: 1826,
			},
			{
				cidade: 'Douradoquara',
				id_cidade: 1827,
			},
			{
				cidade: 'Abadia dos Dourados',
				id_cidade: 1565,
			},
			{
				cidade: 'Abre Campo',
				id_cidade: 1567,
			},
			{
				cidade: 'Água Boa',
				id_cidade: 1570,
			},
			{
				cidade: 'Água Comprida',
				id_cidade: 1571,
			},
			{
				cidade: 'Durandé',
				id_cidade: 1828,
			},
			{
				cidade: 'Elói Mendes',
				id_cidade: 1829,
			},
			{
				cidade: 'Engenheiro Caldas',
				id_cidade: 1830,
			},
			{
				cidade: 'Engenheiro Navarro',
				id_cidade: 1831,
			},
			{
				cidade: 'Entre Folhas',
				id_cidade: 1832,
			},
			{
				cidade: 'Entre Rios de Minas',
				id_cidade: 1833,
			},
			{
				cidade: 'Ervália',
				id_cidade: 1834,
			},
			{
				cidade: 'Esmeraldas',
				id_cidade: 1835,
			},
			{
				cidade: 'Espera Feliz',
				id_cidade: 1836,
			},
			{
				cidade: 'Espinosa',
				id_cidade: 1837,
			},
			{
				cidade: 'Espírito Santo do Dourado',
				id_cidade: 1838,
			},
			{
				cidade: 'Estiva',
				id_cidade: 1839,
			},
			{
				cidade: 'Estrela Dalva',
				id_cidade: 1840,
			},
			{
				cidade: 'Estrela do Indaiá',
				id_cidade: 1841,
			},
			{
				cidade: 'Estrela do Sul',
				id_cidade: 1842,
			},
			{
				cidade: 'Eugenópolis',
				id_cidade: 1843,
			},
			{
				cidade: 'Ewbank da Câmara',
				id_cidade: 1844,
			},
			{
				cidade: 'Extrema',
				id_cidade: 1845,
			},
			{
				cidade: 'Brasópolis',
				id_cidade: 1659,
			},
			{
				cidade: 'Fama',
				id_cidade: 1846,
			},
			{
				cidade: 'Faria Lemos',
				id_cidade: 1847,
			},
			{
				cidade: 'Felício dos Santos',
				id_cidade: 1848,
			},
			{
				cidade: 'Felisburgo',
				id_cidade: 1849,
			},
			{
				cidade: 'Felixlândia',
				id_cidade: 1850,
			},
			{
				cidade: 'Fernandes Tourinho',
				id_cidade: 1851,
			},
			{
				cidade: 'Ferros',
				id_cidade: 1852,
			},
			{
				cidade: 'Fervedouro',
				id_cidade: 1853,
			},
			{
				cidade: 'Florestal',
				id_cidade: 1854,
			},
			{
				cidade: 'Formiga',
				id_cidade: 1855,
			},
			{
				cidade: 'Formoso',
				id_cidade: 1856,
			},
			{
				cidade: 'Fortaleza de Minas',
				id_cidade: 1857,
			},
			{
				cidade: 'Fortuna de Minas',
				id_cidade: 1858,
			},
			{
				cidade: 'Francisco Badaró',
				id_cidade: 1859,
			},
			{
				cidade: 'Francisco Dumont',
				id_cidade: 1860,
			},
			{
				cidade: 'Franciscópolis',
				id_cidade: 1862,
			},
			{
				cidade: 'Francisco Sá',
				id_cidade: 1861,
			},
			{
				cidade: 'Frei Gaspar',
				id_cidade: 1863,
			},
			{
				cidade: 'Frei Inocêncio',
				id_cidade: 1864,
			},
			{
				cidade: 'Frei Lagonegro',
				id_cidade: 1865,
			},
			{
				cidade: 'Fronteira',
				id_cidade: 1866,
			},
			{
				cidade: 'Fronteira dos Vales',
				id_cidade: 1867,
			},
			{
				cidade: 'Fruta de Leite',
				id_cidade: 1868,
			},
			{
				cidade: 'Frutal',
				id_cidade: 1869,
			},
			{
				cidade: 'Funilândia',
				id_cidade: 1870,
			},
			{
				cidade: 'Galiléia',
				id_cidade: 1871,
			},
			{
				cidade: 'Gameleiras',
				id_cidade: 1872,
			},
			{
				cidade: 'Glaucilândia',
				id_cidade: 1873,
			},
			{
				cidade: 'Goiabeira',
				id_cidade: 1874,
			},
			{
				cidade: 'Goianá',
				id_cidade: 1875,
			},
			{
				cidade: 'Gonçalves',
				id_cidade: 1876,
			},
			{
				cidade: 'Gonzaga',
				id_cidade: 1877,
			},
			{
				cidade: 'Gouveia',
				id_cidade: 1878,
			},
			{
				cidade: 'Governador Valadares',
				id_cidade: 1879,
			},
			{
				cidade: 'Itabirinha de Mantena',
				id_cidade: 1926,
			},
			{
				cidade: 'Grão Mogol',
				id_cidade: 1880,
			},
			{
				cidade: 'Grupiara',
				id_cidade: 1881,
			},
			{
				cidade: 'Guanhães',
				id_cidade: 1882,
			},
			{
				cidade: 'Guapé',
				id_cidade: 1883,
			},
			{
				cidade: 'Guaraciaba',
				id_cidade: 1884,
			},
			{
				cidade: 'Guaraciama',
				id_cidade: 1885,
			},
			{
				cidade: 'Guaranésia',
				id_cidade: 1886,
			},
			{
				cidade: 'Guarani',
				id_cidade: 1887,
			},
			{
				cidade: 'Guarará',
				id_cidade: 1888,
			},
			{
				cidade: 'Guarda-Mor',
				id_cidade: 1889,
			},
			{
				cidade: 'Guaxupé',
				id_cidade: 1890,
			},
			{
				cidade: 'Guidoval',
				id_cidade: 1891,
			},
			{
				cidade: 'Guimarânia',
				id_cidade: 1892,
			},
			{
				cidade: 'Guiricema',
				id_cidade: 1893,
			},
			{
				cidade: 'Gurinhatã',
				id_cidade: 1894,
			},
			{
				cidade: 'Heliodora',
				id_cidade: 1895,
			},
			{
				cidade: 'Iapu',
				id_cidade: 1896,
			},
			{
				cidade: 'Ibertioga',
				id_cidade: 1897,
			},
			{
				cidade: 'Ibiá',
				id_cidade: 1898,
			},
			{
				cidade: 'Ibiaí',
				id_cidade: 1899,
			},
			{
				cidade: "Olhos-d'Água",
				id_cidade: 2095,
			},
			{
				cidade: 'Ibiracatu',
				id_cidade: 1900,
			},
			{
				cidade: 'Ibiraci',
				id_cidade: 1901,
			},
			{
				cidade: 'Ibirité',
				id_cidade: 1902,
			},
			{
				cidade: 'Ibitiúra de Minas',
				id_cidade: 1903,
			},
			{
				cidade: 'Ibituruna',
				id_cidade: 1904,
			},
			{
				cidade: 'Icaraí de Minas',
				id_cidade: 1905,
			},
			{
				cidade: 'Igarapé',
				id_cidade: 1906,
			},
			{
				cidade: 'Igaratinga',
				id_cidade: 1907,
			},
			{
				cidade: 'Iguatama',
				id_cidade: 1908,
			},
			{
				cidade: 'Ijaci',
				id_cidade: 1909,
			},
			{
				cidade: 'Ilicínea',
				id_cidade: 1910,
			},
			{
				cidade: 'Imbé de Minas',
				id_cidade: 1911,
			},
			{
				cidade: 'Inconfidentes',
				id_cidade: 1912,
			},
			{
				cidade: 'Indaiabira',
				id_cidade: 1913,
			},
			{
				cidade: 'Indianópolis',
				id_cidade: 1914,
			},
			{
				cidade: 'Ingaí',
				id_cidade: 1915,
			},
			{
				cidade: 'Inhapim',
				id_cidade: 1916,
			},
			{
				cidade: 'Inhaúma',
				id_cidade: 1917,
			},
			{
				cidade: 'Inimutaba',
				id_cidade: 1918,
			},
			{
				cidade: 'Ipaba',
				id_cidade: 1919,
			},
			{
				cidade: 'Ipanema',
				id_cidade: 1920,
			},
			{
				cidade: 'Ipatinga',
				id_cidade: 1921,
			},
			{
				cidade: 'Ipiaçu',
				id_cidade: 1922,
			},
			{
				cidade: 'Ipuiúna',
				id_cidade: 1923,
			},
			{
				cidade: 'Iraí de Minas',
				id_cidade: 1924,
			},
			{
				cidade: 'Itabira',
				id_cidade: 1925,
			},
			{
				cidade: 'Itabirito',
				id_cidade: 1927,
			},
			{
				cidade: 'Itacambira',
				id_cidade: 1928,
			},
			{
				cidade: 'Itacarambi',
				id_cidade: 1929,
			},
			{
				cidade: 'Itaguara',
				id_cidade: 1930,
			},
			{
				cidade: 'Itaipé',
				id_cidade: 1931,
			},
			{
				cidade: 'Itajubá',
				id_cidade: 1932,
			},
			{
				cidade: 'Itamarandiba',
				id_cidade: 1933,
			},
			{
				cidade: 'Itamarati de Minas',
				id_cidade: 1934,
			},
			{
				cidade: 'Itambacuri',
				id_cidade: 1935,
			},
			{
				cidade: 'Itambé do Mato Dentro',
				id_cidade: 1936,
			},
			{
				cidade: 'Itamogi',
				id_cidade: 1937,
			},
			{
				cidade: 'Itamonte',
				id_cidade: 1938,
			},
			{
				cidade: 'Itanhandu',
				id_cidade: 1939,
			},
			{
				cidade: 'Itanhomi',
				id_cidade: 1940,
			},
			{
				cidade: 'Itaobim',
				id_cidade: 1941,
			},
			{
				cidade: 'Itapagipe',
				id_cidade: 1942,
			},
			{
				cidade: 'Itapecerica',
				id_cidade: 1943,
			},
			{
				cidade: 'Itapeva',
				id_cidade: 1944,
			},
			{
				cidade: 'Itatiaiuçu',
				id_cidade: 1945,
			},
			{
				cidade: 'Itaú de Minas',
				id_cidade: 1946,
			},
			{
				cidade: 'Itaúna',
				id_cidade: 1947,
			},
			{
				cidade: 'Itaverava',
				id_cidade: 1948,
			},
			{
				cidade: 'Itinga',
				id_cidade: 1949,
			},
			{
				cidade: 'Itueta',
				id_cidade: 1950,
			},
			{
				cidade: 'Ituiutaba',
				id_cidade: 1951,
			},
			{
				cidade: 'Itumirim',
				id_cidade: 1952,
			},
			{
				cidade: 'Iturama',
				id_cidade: 1953,
			},
			{
				cidade: 'Itutinga',
				id_cidade: 1954,
			},
			{
				cidade: 'Jaboticatubas',
				id_cidade: 1955,
			},
			{
				cidade: 'Jacinto',
				id_cidade: 1956,
			},
			{
				cidade: 'Jacuí',
				id_cidade: 1957,
			},
			{
				cidade: 'Jacutinga',
				id_cidade: 1958,
			},
			{
				cidade: 'Jaguaraçu',
				id_cidade: 1959,
			},
			{
				cidade: 'Jaíba',
				id_cidade: 1960,
			},
			{
				cidade: 'Jampruca',
				id_cidade: 1961,
			},
			{
				cidade: 'Janaúba',
				id_cidade: 1962,
			},
			{
				cidade: 'Januária',
				id_cidade: 1963,
			},
			{
				cidade: 'Japaraíba',
				id_cidade: 1964,
			},
			{
				cidade: 'Japonvar',
				id_cidade: 1965,
			},
			{
				cidade: 'Jeceaba',
				id_cidade: 1966,
			},
			{
				cidade: 'Jenipapo de Minas',
				id_cidade: 1967,
			},
			{
				cidade: 'Jequeri',
				id_cidade: 1968,
			},
			{
				cidade: 'Jequitaí',
				id_cidade: 1969,
			},
			{
				cidade: 'Jequitibá',
				id_cidade: 1970,
			},
			{
				cidade: 'Jequitinhonha',
				id_cidade: 1971,
			},
			{
				cidade: 'Jesuânia',
				id_cidade: 1972,
			},
			{
				cidade: 'Joaíma',
				id_cidade: 1973,
			},
			{
				cidade: 'Joanésia',
				id_cidade: 1974,
			},
			{
				cidade: 'João Monlevade',
				id_cidade: 1975,
			},
			{
				cidade: 'João Pinheiro',
				id_cidade: 1976,
			},
			{
				cidade: 'Joaquim Felício',
				id_cidade: 1977,
			},
			{
				cidade: 'Jordânia',
				id_cidade: 1978,
			},
			{
				cidade: 'José Gonçalves de Minas',
				id_cidade: 1979,
			},
			{
				cidade: 'Josenópolis',
				id_cidade: 1981,
			},
			{
				cidade: 'José Raydan',
				id_cidade: 1980,
			},
			{
				cidade: 'Juatuba',
				id_cidade: 1982,
			},
			{
				cidade: 'Juiz de Fora',
				id_cidade: 1983,
			},
			{
				cidade: 'Juramento',
				id_cidade: 1984,
			},
			{
				cidade: 'Juruaia',
				id_cidade: 1985,
			},
			{
				cidade: 'Juvenília',
				id_cidade: 1986,
			},
			{
				cidade: 'Ladainha',
				id_cidade: 1987,
			},
			{
				cidade: 'Lagamar',
				id_cidade: 1988,
			},
			{
				cidade: 'Lagoa da Prata',
				id_cidade: 1989,
			},
			{
				cidade: 'Lagoa dos Patos',
				id_cidade: 1990,
			},
			{
				cidade: 'Lagoa Dourada',
				id_cidade: 1991,
			},
			{
				cidade: 'Lagoa Formosa',
				id_cidade: 1992,
			},
			{
				cidade: 'Lagoa Grande',
				id_cidade: 1993,
			},
			{
				cidade: 'Lagoa Santa',
				id_cidade: 1994,
			},
			{
				cidade: 'Lajinha',
				id_cidade: 1995,
			},
			{
				cidade: 'Lambari',
				id_cidade: 1996,
			},
			{
				cidade: 'Lamim',
				id_cidade: 1997,
			},
			{
				cidade: 'Laranjal',
				id_cidade: 1998,
			},
			{
				cidade: 'Lassance',
				id_cidade: 1999,
			},
			{
				cidade: 'Lavras',
				id_cidade: 2000,
			},
			{
				cidade: 'Leandro Ferreira',
				id_cidade: 2001,
			},
			{
				cidade: 'Leme do Prado',
				id_cidade: 2002,
			},
			{
				cidade: 'Leopoldina',
				id_cidade: 2003,
			},
			{
				cidade: 'Liberdade',
				id_cidade: 2004,
			},
			{
				cidade: 'Lima Duarte',
				id_cidade: 2005,
			},
			{
				cidade: 'Limeira do Oeste',
				id_cidade: 2006,
			},
			{
				cidade: 'Lontra',
				id_cidade: 2007,
			},
			{
				cidade: 'Luisburgo',
				id_cidade: 2008,
			},
			{
				cidade: 'Luislândia',
				id_cidade: 2009,
			},
			{
				cidade: 'Luminárias',
				id_cidade: 2010,
			},
			{
				cidade: 'Luz',
				id_cidade: 2011,
			},
			{
				cidade: 'Machacalis',
				id_cidade: 2012,
			},
			{
				cidade: 'Machado',
				id_cidade: 2013,
			},
			{
				cidade: 'Madre de Deus de Minas',
				id_cidade: 2014,
			},
			{
				cidade: 'Malacacheta',
				id_cidade: 2015,
			},
			{
				cidade: 'Mamonas',
				id_cidade: 2016,
			},
			{
				cidade: 'Manga',
				id_cidade: 2017,
			},
			{
				cidade: 'Manhuaçu',
				id_cidade: 2018,
			},
			{
				cidade: 'Manhumirim',
				id_cidade: 2019,
			},
			{
				cidade: 'Mantena',
				id_cidade: 2020,
			},
			{
				cidade: 'Maravilhas',
				id_cidade: 2022,
			},
			{
				cidade: 'Mar de Espanha',
				id_cidade: 2021,
			},
			{
				cidade: 'Maria da Fé',
				id_cidade: 2023,
			},
			{
				cidade: 'Mariana',
				id_cidade: 2024,
			},
			{
				cidade: 'Marilac',
				id_cidade: 2025,
			},
			{
				cidade: 'Mário Campos',
				id_cidade: 2026,
			},
			{
				cidade: 'Maripá de Minas',
				id_cidade: 2027,
			},
			{
				cidade: 'Marliéria',
				id_cidade: 2028,
			},
			{
				cidade: 'Marmelópolis',
				id_cidade: 2029,
			},
			{
				cidade: 'Martinho Campos',
				id_cidade: 2030,
			},
			{
				cidade: 'Martins Soares',
				id_cidade: 2031,
			},
			{
				cidade: 'Mata Verde',
				id_cidade: 2032,
			},
			{
				cidade: 'Materlândia',
				id_cidade: 2033,
			},
			{
				cidade: 'Mateus Leme',
				id_cidade: 2034,
			},
			{
				cidade: 'Mathias Lobato',
				id_cidade: 2035,
			},
			{
				cidade: 'Matias Barbosa',
				id_cidade: 2036,
			},
			{
				cidade: 'Matias Cardoso',
				id_cidade: 2037,
			},
			{
				cidade: 'Matipó',
				id_cidade: 2038,
			},
			{
				cidade: 'Mato Verde',
				id_cidade: 2039,
			},
			{
				cidade: 'Matozinhos',
				id_cidade: 2040,
			},
			{
				cidade: 'Matutina',
				id_cidade: 2041,
			},
			{
				cidade: 'Medeiros',
				id_cidade: 2042,
			},
			{
				cidade: 'Medina',
				id_cidade: 2043,
			},
			{
				cidade: 'Mendes Pimentel',
				id_cidade: 2044,
			},
			{
				cidade: 'Mercês',
				id_cidade: 2045,
			},
			{
				cidade: 'Mesquita',
				id_cidade: 2046,
			},
			{
				cidade: 'Minas Novas',
				id_cidade: 2047,
			},
			{
				cidade: 'Minduri',
				id_cidade: 2048,
			},
			{
				cidade: 'Mirabela',
				id_cidade: 2049,
			},
			{
				cidade: 'Miradouro',
				id_cidade: 2050,
			},
			{
				cidade: 'Miraí',
				id_cidade: 2051,
			},
			{
				cidade: 'Miravânia',
				id_cidade: 2052,
			},
			{
				cidade: 'Moeda',
				id_cidade: 2053,
			},
			{
				cidade: 'Moema',
				id_cidade: 2054,
			},
			{
				cidade: 'Monjolos',
				id_cidade: 2055,
			},
			{
				cidade: 'Monsenhor Paulo',
				id_cidade: 2056,
			},
			{
				cidade: 'Montalvânia',
				id_cidade: 2057,
			},
			{
				cidade: 'Monte Alegre de Minas',
				id_cidade: 2058,
			},
			{
				cidade: 'Monte Azul',
				id_cidade: 2059,
			},
			{
				cidade: 'Monte Belo',
				id_cidade: 2060,
			},
			{
				cidade: 'Monte Carmelo',
				id_cidade: 2061,
			},
			{
				cidade: 'Monte Formoso',
				id_cidade: 2062,
			},
			{
				cidade: 'Monte Santo de Minas',
				id_cidade: 2063,
			},
			{
				cidade: 'Montes Claros',
				id_cidade: 2065,
			},
			{
				cidade: 'Monte Sião',
				id_cidade: 2064,
			},
			{
				cidade: 'Montezuma',
				id_cidade: 2066,
			},
			{
				cidade: 'Morada Nova de Minas',
				id_cidade: 2067,
			},
			{
				cidade: 'Morro da Garça',
				id_cidade: 2068,
			},
			{
				cidade: 'Morro do Pilar',
				id_cidade: 2069,
			},
			{
				cidade: 'Munhoz',
				id_cidade: 2070,
			},
			{
				cidade: 'Muriaé',
				id_cidade: 2071,
			},
			{
				cidade: 'Mutum',
				id_cidade: 2072,
			},
			{
				cidade: 'Muzambinho',
				id_cidade: 2073,
			},
			{
				cidade: 'Nacip Raydan',
				id_cidade: 2074,
			},
			{
				cidade: 'Nanuque',
				id_cidade: 2075,
			},
			{
				cidade: 'Naque',
				id_cidade: 2076,
			},
			{
				cidade: 'Natalândia',
				id_cidade: 2077,
			},
			{
				cidade: 'Natércia',
				id_cidade: 2078,
			},
			{
				cidade: 'Nazareno',
				id_cidade: 2079,
			},
			{
				cidade: 'Nepomuceno',
				id_cidade: 2080,
			},
			{
				cidade: 'Ninheira',
				id_cidade: 2081,
			},
			{
				cidade: 'Nova Belém',
				id_cidade: 2082,
			},
			{
				cidade: 'Nova Era',
				id_cidade: 2083,
			},
			{
				cidade: 'Nova Lima',
				id_cidade: 2084,
			},
			{
				cidade: 'Nova Módica',
				id_cidade: 2085,
			},
			{
				cidade: 'Nova Ponte',
				id_cidade: 2086,
			},
			{
				cidade: 'Nova Porteirinha',
				id_cidade: 2087,
			},
			{
				cidade: 'Nova Resende',
				id_cidade: 2088,
			},
			{
				cidade: 'Nova Serrana',
				id_cidade: 2089,
			},
			{
				cidade: 'Nova União',
				id_cidade: 2090,
			},
			{
				cidade: 'Novo Cruzeiro',
				id_cidade: 2091,
			},
			{
				cidade: 'Novo Oriente de Minas',
				id_cidade: 2092,
			},
			{
				cidade: 'Novorizonte',
				id_cidade: 2093,
			},
			{
				cidade: 'Olaria',
				id_cidade: 2094,
			},
			{
				cidade: 'Olímpio Noronha',
				id_cidade: 2096,
			},
			{
				cidade: 'Oliveira',
				id_cidade: 2097,
			},
			{
				cidade: 'Oliveira Fortes',
				id_cidade: 2098,
			},
			{
				cidade: 'Onça de Pitangui',
				id_cidade: 2099,
			},
			{
				cidade: 'Oratórios',
				id_cidade: 2100,
			},
			{
				cidade: 'Orizânia',
				id_cidade: 2101,
			},
			{
				cidade: 'Ouro Branco',
				id_cidade: 2102,
			},
			{
				cidade: 'Ouro Fino',
				id_cidade: 2103,
			},
			{
				cidade: 'Ouro Preto',
				id_cidade: 2104,
			},
			{
				cidade: 'Ouro Verde de Minas',
				id_cidade: 2105,
			},
			{
				cidade: 'Padre Carvalho',
				id_cidade: 2106,
			},
			{
				cidade: 'Padre Paraíso',
				id_cidade: 2107,
			},
			{
				cidade: 'Paineiras',
				id_cidade: 2109,
			},
			{
				cidade: 'Pains',
				id_cidade: 2110,
			},
			{
				cidade: 'Pai Pedro',
				id_cidade: 2108,
			},
			{
				cidade: 'Paiva',
				id_cidade: 2111,
			},
			{
				cidade: 'Palma',
				id_cidade: 2112,
			},
			{
				cidade: 'Palmópolis',
				id_cidade: 2113,
			},
			{
				cidade: 'Papagaios',
				id_cidade: 2114,
			},
			{
				cidade: 'Paracatu',
				id_cidade: 2116,
			},
			{
				cidade: 'Pará de Minas',
				id_cidade: 2115,
			},
			{
				cidade: 'Paraguaçu',
				id_cidade: 2117,
			},
			{
				cidade: 'Paraisópolis',
				id_cidade: 2118,
			},
			{
				cidade: 'Paraopeba',
				id_cidade: 2119,
			},
			{
				cidade: 'Passabém',
				id_cidade: 2122,
			},
			{
				cidade: 'Passa Quatro',
				id_cidade: 2120,
			},
			{
				cidade: 'Passa Tempo',
				id_cidade: 2121,
			},
			{
				cidade: 'Passa-Vinte',
				id_cidade: 2123,
			},
			{
				cidade: 'Passos',
				id_cidade: 2124,
			},
			{
				cidade: 'Patis',
				id_cidade: 2125,
			},
			{
				cidade: 'Patos de Minas',
				id_cidade: 2126,
			},
			{
				cidade: 'Patrocínio',
				id_cidade: 2127,
			},
			{
				cidade: 'Patrocínio do Muriaé',
				id_cidade: 2128,
			},
			{
				cidade: 'Paula Cândido',
				id_cidade: 2129,
			},
			{
				cidade: 'Paulistas',
				id_cidade: 2130,
			},
			{
				cidade: 'Pavão',
				id_cidade: 2131,
			},
			{
				cidade: 'Peçanha',
				id_cidade: 2132,
			},
			{
				cidade: 'Pedra Azul',
				id_cidade: 2133,
			},
			{
				cidade: 'Pedra Bonita',
				id_cidade: 2134,
			},
			{
				cidade: 'Pedra do Anta',
				id_cidade: 2135,
			},
			{
				cidade: 'Pedra do Indaiá',
				id_cidade: 2136,
			},
			{
				cidade: 'Pedra Dourada',
				id_cidade: 2137,
			},
			{
				cidade: 'Pedralva',
				id_cidade: 2138,
			},
			{
				cidade: 'Pedras de Maria da Cruz',
				id_cidade: 2139,
			},
			{
				cidade: 'Pedrinópolis',
				id_cidade: 2140,
			},
			{
				cidade: 'Pedro Leopoldo',
				id_cidade: 2141,
			},
			{
				cidade: 'Pedro Teixeira',
				id_cidade: 2142,
			},
			{
				cidade: 'Pequeri',
				id_cidade: 2143,
			},
			{
				cidade: 'Pequi',
				id_cidade: 2144,
			},
			{
				cidade: 'Perdigão',
				id_cidade: 2145,
			},
			{
				cidade: 'Perdizes',
				id_cidade: 2146,
			},
			{
				cidade: 'Perdões',
				id_cidade: 2147,
			},
			{
				cidade: 'Periquito',
				id_cidade: 2148,
			},
			{
				cidade: 'Pescador',
				id_cidade: 2149,
			},
			{
				cidade: 'Piau',
				id_cidade: 2150,
			},
			{
				cidade: 'Piedade de Caratinga',
				id_cidade: 2151,
			},
			{
				cidade: 'Piedade de Ponte Nova',
				id_cidade: 2152,
			},
			{
				cidade: 'Piedade do Rio Grande',
				id_cidade: 2153,
			},
			{
				cidade: 'Piedade dos Gerais',
				id_cidade: 2154,
			},
			{
				cidade: 'Pimenta',
				id_cidade: 2155,
			},
			{
				cidade: "Pingo-d'Água",
				id_cidade: 2156,
			},
			{
				cidade: 'Pintópolis',
				id_cidade: 2157,
			},
			{
				cidade: 'Piracema',
				id_cidade: 2158,
			},
			{
				cidade: 'Pirajuba',
				id_cidade: 2159,
			},
			{
				cidade: 'Piranga',
				id_cidade: 2160,
			},
			{
				cidade: 'Piranguçu',
				id_cidade: 2161,
			},
			{
				cidade: 'Piranguinho',
				id_cidade: 2162,
			},
			{
				cidade: 'Pirapetinga',
				id_cidade: 2163,
			},
			{
				cidade: 'Pirapora',
				id_cidade: 2164,
			},
			{
				cidade: 'Piraúba',
				id_cidade: 2165,
			},
			{
				cidade: 'Pitangui',
				id_cidade: 2166,
			},
			{
				cidade: 'Piumhi',
				id_cidade: 2167,
			},
			{
				cidade: 'Planura',
				id_cidade: 2168,
			},
			{
				cidade: 'Poço Fundo',
				id_cidade: 2169,
			},
			{
				cidade: 'Poços de Caldas',
				id_cidade: 2170,
			},
			{
				cidade: 'Pocrane',
				id_cidade: 2171,
			},
			{
				cidade: 'Pompéu',
				id_cidade: 2172,
			},
			{
				cidade: 'Ponte Nova',
				id_cidade: 2173,
			},
			{
				cidade: 'Ponto Chique',
				id_cidade: 2174,
			},
			{
				cidade: 'Ponto dos Volantes',
				id_cidade: 2175,
			},
			{
				cidade: 'Porteirinha',
				id_cidade: 2176,
			},
			{
				cidade: 'Porto Firme',
				id_cidade: 2177,
			},
			{
				cidade: 'Poté',
				id_cidade: 2178,
			},
			{
				cidade: 'Pouso Alegre',
				id_cidade: 2179,
			},
			{
				cidade: 'Pouso Alto',
				id_cidade: 2180,
			},
			{
				cidade: 'Prados',
				id_cidade: 2181,
			},
			{
				cidade: 'Prata',
				id_cidade: 2182,
			},
			{
				cidade: 'Pratápolis',
				id_cidade: 2183,
			},
			{
				cidade: 'Pratinha',
				id_cidade: 2184,
			},
			{
				cidade: 'Presidente Bernardes',
				id_cidade: 2185,
			},
			{
				cidade: 'Presidente Juscelino',
				id_cidade: 2186,
			},
			{
				cidade: 'Presidente Kubitschek',
				id_cidade: 2187,
			},
			{
				cidade: 'Presidente Olegário',
				id_cidade: 2188,
			},
			{
				cidade: 'Prudente de Morais',
				id_cidade: 2189,
			},
			{
				cidade: 'Quartel Geral',
				id_cidade: 2190,
			},
			{
				cidade: 'Queluzito',
				id_cidade: 2191,
			},
			{
				cidade: 'Raposos',
				id_cidade: 2192,
			},
			{
				cidade: 'Raul Soares',
				id_cidade: 2193,
			},
			{
				cidade: 'Recreio',
				id_cidade: 2194,
			},
			{
				cidade: 'Reduto',
				id_cidade: 2195,
			},
			{
				cidade: 'Resende Costa',
				id_cidade: 2196,
			},
			{
				cidade: 'Resplendor',
				id_cidade: 2197,
			},
			{
				cidade: 'Ressaquinha',
				id_cidade: 2198,
			},
			{
				cidade: 'Riachinho',
				id_cidade: 2199,
			},
			{
				cidade: 'Riacho dos Machados',
				id_cidade: 2200,
			},
			{
				cidade: 'Ribeirão das Neves',
				id_cidade: 2201,
			},
			{
				cidade: 'Ribeirão Vermelho',
				id_cidade: 2202,
			},
			{
				cidade: 'Rio Acima',
				id_cidade: 2203,
			},
			{
				cidade: 'Rio Casca',
				id_cidade: 2204,
			},
			{
				cidade: 'Rio Doce',
				id_cidade: 2206,
			},
			{
				cidade: 'Rio do Prado',
				id_cidade: 2205,
			},
			{
				cidade: 'Rio Espera',
				id_cidade: 2207,
			},
			{
				cidade: 'Rio Manso',
				id_cidade: 2208,
			},
			{
				cidade: 'Rio Novo',
				id_cidade: 2209,
			},
			{
				cidade: 'Rio Paranaíba',
				id_cidade: 2210,
			},
			{
				cidade: 'Rio Pardo de Minas',
				id_cidade: 2211,
			},
			{
				cidade: 'Rio Piracicaba',
				id_cidade: 2212,
			},
			{
				cidade: 'Rio Pomba',
				id_cidade: 2213,
			},
			{
				cidade: 'Rio Preto',
				id_cidade: 2214,
			},
			{
				cidade: 'Rio Vermelho',
				id_cidade: 2215,
			},
			{
				cidade: 'Ritápolis',
				id_cidade: 2216,
			},
			{
				cidade: 'Rochedo de Minas',
				id_cidade: 2217,
			},
			{
				cidade: 'Rodeiro',
				id_cidade: 2218,
			},
			{
				cidade: 'Romaria',
				id_cidade: 2219,
			},
			{
				cidade: 'Rosário da Limeira',
				id_cidade: 2220,
			},
			{
				cidade: 'Rubelita',
				id_cidade: 2221,
			},
			{
				cidade: 'Rubim',
				id_cidade: 2222,
			},
			{
				cidade: 'Sabará',
				id_cidade: 2223,
			},
			{
				cidade: 'Sabinópolis',
				id_cidade: 2224,
			},
			{
				cidade: 'Sacramento',
				id_cidade: 2225,
			},
			{
				cidade: 'Salto da Divisa',
				id_cidade: 2227,
			},
			{
				cidade: 'Santa Bárbara',
				id_cidade: 2228,
			},
			{
				cidade: 'Santa Bárbara do Leste',
				id_cidade: 2229,
			},
			{
				cidade: 'Santa Bárbara do Monte Verde',
				id_cidade: 2230,
			},
			{
				cidade: 'Santa Bárbara do Tugúrio',
				id_cidade: 2231,
			},
			{
				cidade: 'Santa Cruz de Minas',
				id_cidade: 2232,
			},
			{
				cidade: 'Santa Cruz de Salinas',
				id_cidade: 2233,
			},
			{
				cidade: 'Santa Cruz do Escalvado',
				id_cidade: 2234,
			},
			{
				cidade: 'Santa Efigênia de Minas',
				id_cidade: 2235,
			},
			{
				cidade: 'Santa Fé de Minas',
				id_cidade: 2236,
			},
			{
				cidade: 'Santa Helena de Minas',
				id_cidade: 2237,
			},
			{
				cidade: 'Santa Juliana',
				id_cidade: 2238,
			},
			{
				cidade: 'Santa Luzia',
				id_cidade: 2239,
			},
			{
				cidade: 'Santa Margarida',
				id_cidade: 2240,
			},
			{
				cidade: 'Santa Maria de Itabira',
				id_cidade: 2241,
			},
			{
				cidade: 'Santa Maria do Salto',
				id_cidade: 2242,
			},
			{
				cidade: 'Santa Maria do Suaçuí',
				id_cidade: 2243,
			},
			{
				cidade: 'Santana da Vargem',
				id_cidade: 2252,
			},
			{
				cidade: 'Santana de Cataguases',
				id_cidade: 2253,
			},
			{
				cidade: 'Santana de Pirapama',
				id_cidade: 2254,
			},
			{
				cidade: 'Santana do Deserto',
				id_cidade: 2255,
			},
			{
				cidade: 'Santana do Garambéu',
				id_cidade: 2256,
			},
			{
				cidade: 'Santana do Jacaré',
				id_cidade: 2257,
			},
			{
				cidade: 'Santana do Manhuaçu',
				id_cidade: 2258,
			},
			{
				cidade: 'Santana do Paraíso',
				id_cidade: 2259,
			},
			{
				cidade: 'Santana do Riacho',
				id_cidade: 2260,
			},
			{
				cidade: 'Açucena',
				id_cidade: 1569,
			},
			{
				cidade: 'Aguanil',
				id_cidade: 1572,
			},
			{
				cidade: 'Águas Formosas',
				id_cidade: 1573,
			},
			{
				cidade: 'Alpinópolis',
				id_cidade: 1584,
			},
			{
				cidade: 'Alterosa',
				id_cidade: 1585,
			},
			{
				cidade: 'Alto Caparaó',
				id_cidade: 1586,
			},
			{
				cidade: 'Alto Jequitibá',
				id_cidade: 1587,
			},
			{
				cidade: 'Alto Rio Doce',
				id_cidade: 1588,
			},
			{
				cidade: 'Alvarenga',
				id_cidade: 1589,
			},
			{
				cidade: 'Alvinópolis',
				id_cidade: 1590,
			},
			{
				cidade: 'Alvorada de Minas',
				id_cidade: 1591,
			},
			{
				cidade: 'Santana dos Montes',
				id_cidade: 2261,
			},
			{
				cidade: 'Santa Rita de Caldas',
				id_cidade: 2244,
			},
			{
				cidade: 'Santa Rita de Ibitipoca',
				id_cidade: 2245,
			},
			{
				cidade: 'Santa Rita de Jacutinga',
				id_cidade: 2246,
			},
			{
				cidade: 'Santa Rita de Minas',
				id_cidade: 2247,
			},
			{
				cidade: 'Santa Rita do Itueto',
				id_cidade: 2248,
			},
			{
				cidade: 'Amparo do Serra',
				id_cidade: 1592,
			},
			{
				cidade: 'Andradas',
				id_cidade: 1593,
			},
			{
				cidade: 'Santa Rita do Sapucaí',
				id_cidade: 2249,
			},
			{
				cidade: 'Santa Rosa da Serra',
				id_cidade: 2250,
			},
			{
				cidade: 'Santa Vitória',
				id_cidade: 2251,
			},
			{
				cidade: 'Santo Antônio do Amparo',
				id_cidade: 2262,
			},
			{
				cidade: 'Santo Antônio do Aventureiro',
				id_cidade: 2263,
			},
			{
				cidade: 'Santo Antônio do Grama',
				id_cidade: 2264,
			},
			{
				cidade: 'Santo Antônio do Itambé',
				id_cidade: 2265,
			},
			{
				cidade: 'Santo Antônio do Jacinto',
				id_cidade: 2266,
			},
			{
				cidade: 'Santo Antônio do Monte',
				id_cidade: 2267,
			},
			{
				cidade: 'Santo Antônio do Retiro',
				id_cidade: 2268,
			},
			{
				cidade: 'Santo Antônio do Rio Abaixo',
				id_cidade: 2269,
			},
			{
				cidade: 'Santo Hipólito',
				id_cidade: 2270,
			},
			{
				cidade: 'Santos Dumont',
				id_cidade: 2271,
			},
			{
				cidade: 'São Bento Abade',
				id_cidade: 2272,
			},
			{
				cidade: 'São Brás do Suaçuí',
				id_cidade: 2273,
			},
			{
				cidade: 'São Domingos das Dores',
				id_cidade: 2274,
			},
			{
				cidade: 'São Domingos do Prata',
				id_cidade: 2275,
			},
			{
				cidade: 'São Félix de Minas',
				id_cidade: 2276,
			},
			{
				cidade: 'São Francisco',
				id_cidade: 2277,
			},
			{
				cidade: 'São Francisco de Paula',
				id_cidade: 2278,
			},
			{
				cidade: 'São Francisco de Sales',
				id_cidade: 2279,
			},
			{
				cidade: 'São Francisco do Glória',
				id_cidade: 2280,
			},
			{
				cidade: 'São Geraldo',
				id_cidade: 2281,
			},
			{
				cidade: 'São Geraldo da Piedade',
				id_cidade: 2282,
			},
			{
				cidade: 'São Geraldo do Baixio',
				id_cidade: 2283,
			},
			{
				cidade: 'São Gonçalo do Abaeté',
				id_cidade: 2284,
			},
			{
				cidade: 'São Gonçalo do Pará',
				id_cidade: 2285,
			},
			{
				cidade: 'São Gonçalo do Rio Abaixo',
				id_cidade: 2286,
			},
			{
				cidade: 'São Gonçalo do Rio Preto',
				id_cidade: 2287,
			},
			{
				cidade: 'São Gonçalo do Sapucaí',
				id_cidade: 2288,
			},
			{
				cidade: 'Andrelândia',
				id_cidade: 1594,
			},
			{
				cidade: 'Angelândia',
				id_cidade: 1595,
			},
			{
				cidade: 'São Gotardo',
				id_cidade: 2289,
			},
			{
				cidade: 'São João Batista do Glória',
				id_cidade: 2290,
			},
			{
				cidade: 'São João da Lagoa',
				id_cidade: 2291,
			},
			{
				cidade: 'São João da Mata',
				id_cidade: 2292,
			},
			{
				cidade: 'São João da Ponte',
				id_cidade: 2293,
			},
			{
				cidade: 'São João das Missões',
				id_cidade: 2294,
			},
			{
				cidade: 'São João del Rei',
				id_cidade: 2295,
			},
			{
				cidade: 'São João do Manhuaçu',
				id_cidade: 2296,
			},
			{
				cidade: 'São João do Manteninha',
				id_cidade: 2297,
			},
			{
				cidade: 'São João do Oriente',
				id_cidade: 2298,
			},
			{
				cidade: 'São João do Pacuí',
				id_cidade: 2299,
			},
			{
				cidade: 'São João do Paraíso',
				id_cidade: 2300,
			},
			{
				cidade: 'São João Evangelista',
				id_cidade: 2301,
			},
			{
				cidade: 'São João Nepomuceno',
				id_cidade: 2302,
			},
			{
				cidade: 'São Joaquim de Bicas',
				id_cidade: 2303,
			},
			{
				cidade: 'São José da Barra',
				id_cidade: 2304,
			},
			{
				cidade: 'São José da Lapa',
				id_cidade: 2305,
			},
			{
				cidade: 'São José da Safira',
				id_cidade: 2306,
			},
			{
				cidade: 'São José da Varginha',
				id_cidade: 2307,
			},
			{
				cidade: 'São José do Alegre',
				id_cidade: 2308,
			},
			{
				cidade: 'São José do Divino',
				id_cidade: 2309,
			},
			{
				cidade: 'São José do Goiabal',
				id_cidade: 2310,
			},
			{
				cidade: 'São José do Jacuri',
				id_cidade: 2311,
			},
			{
				cidade: 'São José do Mantimento',
				id_cidade: 2312,
			},
			{
				cidade: 'São Lourenço',
				id_cidade: 2313,
			},
			{
				cidade: 'São Miguel do Anta',
				id_cidade: 2314,
			},
			{
				cidade: 'São Pedro da União',
				id_cidade: 2315,
			},
			{
				cidade: 'São Pedro dos Ferros',
				id_cidade: 2317,
			},
			{
				cidade: 'São Pedro do Suaçuí',
				id_cidade: 2316,
			},
			{
				cidade: 'São Romão',
				id_cidade: 2318,
			},
			{
				cidade: 'São Roque de Minas',
				id_cidade: 2319,
			},
			{
				cidade: 'São Sebastião da Bela Vista',
				id_cidade: 2320,
			},
			{
				cidade: 'São Sebastião da Vargem Alegre',
				id_cidade: 2321,
			},
			{
				cidade: 'São Sebastião do Anta',
				id_cidade: 2322,
			},
			{
				cidade: 'São Sebastião do Maranhão',
				id_cidade: 2323,
			},
			{
				cidade: 'São Sebastião do Oeste',
				id_cidade: 2324,
			},
			{
				cidade: 'São Sebastião do Paraíso',
				id_cidade: 2325,
			},
			{
				cidade: 'São Sebastião do Rio Preto',
				id_cidade: 2326,
			},
			{
				cidade: 'São Sebastião do Rio Verde',
				id_cidade: 2327,
			},
			{
				cidade: 'São Thomé das Letras',
				id_cidade: 2328,
			},
			{
				cidade: 'São Tiago',
				id_cidade: 2329,
			},
			{
				cidade: 'São Tomás de Aquino',
				id_cidade: 2330,
			},
			{
				cidade: 'São Vicente de Minas',
				id_cidade: 2331,
			},
			{
				cidade: 'Sapucaí-Mirim',
				id_cidade: 2332,
			},
			{
				cidade: 'Sardoá',
				id_cidade: 2333,
			},
			{
				cidade: 'Sarzedo',
				id_cidade: 2334,
			},
			{
				cidade: 'Sem-Peixe',
				id_cidade: 2335,
			},
			{
				cidade: 'Senador Amaral',
				id_cidade: 2336,
			},
			{
				cidade: 'Senador Cortes',
				id_cidade: 2337,
			},
			{
				cidade: 'Senador Firmino',
				id_cidade: 2338,
			},
			{
				cidade: 'Senador José Bento',
				id_cidade: 2339,
			},
			{
				cidade: 'Senador Modestino Gonçalves',
				id_cidade: 2340,
			},
			{
				cidade: 'Senhora de Oliveira',
				id_cidade: 2341,
			},
			{
				cidade: 'Senhora do Porto',
				id_cidade: 2342,
			},
			{
				cidade: 'Senhora dos Remédios',
				id_cidade: 2343,
			},
			{
				cidade: 'Sericita',
				id_cidade: 2344,
			},
			{
				cidade: 'Seritinga',
				id_cidade: 2345,
			},
			{
				cidade: 'Serra Azul de Minas',
				id_cidade: 2346,
			},
			{
				cidade: 'Serra da Saudade',
				id_cidade: 2347,
			},
			{
				cidade: 'Serra dos Aimorés',
				id_cidade: 2349,
			},
			{
				cidade: 'Serra do Salitre',
				id_cidade: 2348,
			},
			{
				cidade: 'Serrania',
				id_cidade: 2350,
			},
			{
				cidade: 'Serranópolis de Minas',
				id_cidade: 2351,
			},
			{
				cidade: 'Serranos',
				id_cidade: 2352,
			},
			{
				cidade: 'Antônio Carlos',
				id_cidade: 1596,
			},
			{
				cidade: 'Antônio Dias',
				id_cidade: 1597,
			},
			{
				cidade: 'Antônio Prado de Minas',
				id_cidade: 1598,
			},
			{
				cidade: 'Araçaí',
				id_cidade: 1599,
			},
			{
				cidade: 'Serro',
				id_cidade: 2353,
			},
			{
				cidade: 'Sete Lagoas',
				id_cidade: 2354,
			},
			{
				cidade: 'Setubinha',
				id_cidade: 2355,
			},
			{
				cidade: 'Abaeté',
				id_cidade: 1566,
			},
			{
				cidade: 'Acaiaca',
				id_cidade: 1568,
			},
			{
				cidade: 'Silveirânia',
				id_cidade: 2356,
			},
			{
				cidade: 'Silvianópolis',
				id_cidade: 2357,
			},
			{
				cidade: 'Simão Pereira',
				id_cidade: 2358,
			},
			{
				cidade: 'Simonésia',
				id_cidade: 2359,
			},
			{
				cidade: 'Sobrália',
				id_cidade: 2360,
			},
			{
				cidade: 'Soledade de Minas',
				id_cidade: 2361,
			},
			{
				cidade: 'Tabuleiro',
				id_cidade: 2362,
			},
			{
				cidade: 'Taiobeiras',
				id_cidade: 2363,
			},
			{
				cidade: 'Taparuba',
				id_cidade: 2364,
			},
			{
				cidade: 'Tapira',
				id_cidade: 2365,
			},
			{
				cidade: 'Tapiraí',
				id_cidade: 2366,
			},
			{
				cidade: 'Taquaraçu de Minas',
				id_cidade: 2367,
			},
			{
				cidade: 'Aracitaba',
				id_cidade: 1600,
			},
			{
				cidade: 'Araçuaí',
				id_cidade: 1601,
			},
			{
				cidade: 'Araguari',
				id_cidade: 1602,
			},
			{
				cidade: 'Arantina',
				id_cidade: 1603,
			},
			{
				cidade: 'Araponga',
				id_cidade: 1604,
			},
			{
				cidade: 'Araporã',
				id_cidade: 1605,
			},
			{
				cidade: 'Arapuá',
				id_cidade: 1606,
			},
			{
				cidade: 'Salinas',
				id_cidade: 2226,
			},
			{
				cidade: 'Tarumirim',
				id_cidade: 2368,
			},
			{
				cidade: 'Teixeiras',
				id_cidade: 2369,
			},
			{
				cidade: 'Teófilo Otoni',
				id_cidade: 2370,
			},
			{
				cidade: 'Timóteo',
				id_cidade: 2371,
			},
			{
				cidade: 'Tiradentes',
				id_cidade: 2372,
			},
			{
				cidade: 'Tiros',
				id_cidade: 2373,
			},
			{
				cidade: 'Tocantins',
				id_cidade: 2374,
			},
			{
				cidade: 'Tocos do Moji',
				id_cidade: 2375,
			},
			{
				cidade: 'Toledo',
				id_cidade: 2376,
			},
			{
				cidade: 'Tombos',
				id_cidade: 2377,
			},
			{
				cidade: 'Três Corações',
				id_cidade: 2378,
			},
			{
				cidade: 'Três Marias',
				id_cidade: 2379,
			},
			{
				cidade: 'Três Pontas',
				id_cidade: 2380,
			},
			{
				cidade: 'Tumiritinga',
				id_cidade: 2381,
			},
			{
				cidade: 'Tupaciguara',
				id_cidade: 2382,
			},
			{
				cidade: 'Turmalina',
				id_cidade: 2383,
			},
			{
				cidade: 'Turvolândia',
				id_cidade: 2384,
			},
			{
				cidade: 'Ubá',
				id_cidade: 2385,
			},
			{
				cidade: 'Ubaí',
				id_cidade: 2386,
			},
			{
				cidade: 'Ubaporanga',
				id_cidade: 2387,
			},
			{
				cidade: 'Uberaba',
				id_cidade: 2388,
			},
			{
				cidade: 'Uberlândia',
				id_cidade: 2389,
			},
			{
				cidade: 'Umburatiba',
				id_cidade: 2390,
			},
			{
				cidade: 'Unaí',
				id_cidade: 2391,
			},
			{
				cidade: 'União de Minas',
				id_cidade: 2392,
			},
			{
				cidade: 'Uruana de Minas',
				id_cidade: 2393,
			},
			{
				cidade: 'Urucânia',
				id_cidade: 2394,
			},
			{
				cidade: 'Urucuia',
				id_cidade: 2395,
			},
			{
				cidade: 'Vargem Alegre',
				id_cidade: 2396,
			},
			{
				cidade: 'Vargem Bonita',
				id_cidade: 2397,
			},
			{
				cidade: 'Vargem Grande do Rio Pardo',
				id_cidade: 2398,
			},
			{
				cidade: 'Varginha',
				id_cidade: 2399,
			},
			{
				cidade: 'Varjão de Minas',
				id_cidade: 2400,
			},
			{
				cidade: 'Várzea da Palma',
				id_cidade: 2401,
			},
			{
				cidade: 'Varzelândia',
				id_cidade: 2402,
			},
			{
				cidade: 'Vazante',
				id_cidade: 2403,
			},
			{
				cidade: 'Verdelândia',
				id_cidade: 2404,
			},
			{
				cidade: 'Veredinha',
				id_cidade: 2405,
			},
			{
				cidade: 'Veríssimo',
				id_cidade: 2406,
			},
			{
				cidade: 'Vermelho Novo',
				id_cidade: 2407,
			},
			{
				cidade: 'Vespasiano',
				id_cidade: 2408,
			},
			{
				cidade: 'Viçosa',
				id_cidade: 2409,
			},
			{
				cidade: 'Vieiras',
				id_cidade: 2410,
			},
			{
				cidade: 'Virgem da Lapa',
				id_cidade: 2411,
			},
			{
				cidade: 'Virgínia',
				id_cidade: 2412,
			},
			{
				cidade: 'Virginópolis',
				id_cidade: 2413,
			},
			{
				cidade: 'Virgolândia',
				id_cidade: 2414,
			},
			{
				cidade: 'Visconde do Rio Branco',
				id_cidade: 2415,
			},
			{
				cidade: 'Volta Grande',
				id_cidade: 2416,
			},
			{
				cidade: 'Wenceslau Braz',
				id_cidade: 2417,
			},
		],
	},
	{
		estado: 'Mato Grosso do Sul',
		sigla: 'MS',
		id_estado: 12,
		cidades: [
			{
				cidade: 'Alcinópolis',
				id_cidade: 1488,
			},
			{
				cidade: 'Bandeirantes',
				id_cidade: 1497,
			},
			{
				cidade: 'Bataguassu',
				id_cidade: 1498,
			},
			{
				cidade: 'Bela Vista',
				id_cidade: 1500,
			},
			{
				cidade: 'Bodoquena',
				id_cidade: 1501,
			},
			{
				cidade: 'Bonito',
				id_cidade: 1502,
			},
			{
				cidade: 'Brasilândia',
				id_cidade: 1503,
			},
			{
				cidade: 'Caarapó',
				id_cidade: 1504,
			},
			{
				cidade: 'Camapuã',
				id_cidade: 1505,
			},
			{
				cidade: 'Campo Grande',
				id_cidade: 1506,
			},
			{
				cidade: 'Caracol',
				id_cidade: 1507,
			},
			{
				cidade: 'Cassilândia',
				id_cidade: 1508,
			},
			{
				cidade: 'Chapadão do Sul',
				id_cidade: 1509,
			},
			{
				cidade: 'Corguinho',
				id_cidade: 1510,
			},
			{
				cidade: 'Coronel Sapucaia',
				id_cidade: 1511,
			},
			{
				cidade: 'Corumbá',
				id_cidade: 1512,
			},
			{
				cidade: 'Costa Rica',
				id_cidade: 1513,
			},
			{
				cidade: 'Coxim',
				id_cidade: 1514,
			},
			{
				cidade: 'Amambaí',
				id_cidade: 1489,
			},
			{
				cidade: 'Deodápolis',
				id_cidade: 1515,
			},
			{
				cidade: 'Dois Irmãos do Buriti',
				id_cidade: 1516,
			},
			{
				cidade: 'Bataiporã',
				id_cidade: 1499,
			},
			{
				cidade: 'Douradina',
				id_cidade: 1517,
			},
			{
				cidade: 'Dourados',
				id_cidade: 1518,
			},
			{
				cidade: 'Eldorado',
				id_cidade: 1519,
			},
			{
				cidade: 'Fátima do Sul',
				id_cidade: 1520,
			},
			{
				cidade: 'Figueirão',
				id_cidade: 1521,
			},
			{
				cidade: 'Glória de Dourados',
				id_cidade: 1522,
			},
			{
				cidade: 'Guia Lopes da Laguna',
				id_cidade: 1523,
			},
			{
				cidade: 'Iguatemi',
				id_cidade: 1524,
			},
			{
				cidade: 'Inocência',
				id_cidade: 1525,
			},
			{
				cidade: 'Itaporã',
				id_cidade: 1526,
			},
			{
				cidade: 'Itaquiraí',
				id_cidade: 1527,
			},
			{
				cidade: 'Ivinhema',
				id_cidade: 1528,
			},
			{
				cidade: 'Japorã',
				id_cidade: 1529,
			},
			{
				cidade: 'Jaraguari',
				id_cidade: 1530,
			},
			{
				cidade: 'Jardim',
				id_cidade: 1531,
			},
			{
				cidade: 'Jateí',
				id_cidade: 1532,
			},
			{
				cidade: 'Juti',
				id_cidade: 1533,
			},
			{
				cidade: 'Ladário',
				id_cidade: 1534,
			},
			{
				cidade: 'Laguna Carapã',
				id_cidade: 1535,
			},
			{
				cidade: 'Maracaju',
				id_cidade: 1536,
			},
			{
				cidade: 'Miranda',
				id_cidade: 1537,
			},
			{
				cidade: 'Mundo Novo',
				id_cidade: 1538,
			},
			{
				cidade: 'Naviraí',
				id_cidade: 1539,
			},
			{
				cidade: 'Nioaque',
				id_cidade: 1540,
			},
			{
				cidade: 'Nova Alvorada do Sul',
				id_cidade: 1541,
			},
			{
				cidade: 'Nova Andradina',
				id_cidade: 1542,
			},
			{
				cidade: 'Novo Horizonte do Sul',
				id_cidade: 1543,
			},
			{
				cidade: 'Paranaíba',
				id_cidade: 1544,
			},
			{
				cidade: 'Paranhos',
				id_cidade: 1545,
			},
			{
				cidade: 'Pedro Gomes',
				id_cidade: 1546,
			},
			{
				cidade: 'Ponta Porã',
				id_cidade: 1547,
			},
			{
				cidade: 'Porto Murtinho',
				id_cidade: 1548,
			},
			{
				cidade: 'Ribas do Rio Pardo',
				id_cidade: 1549,
			},
			{
				cidade: 'Rio Brilhante',
				id_cidade: 1550,
			},
			{
				cidade: 'Rio Negro',
				id_cidade: 1551,
			},
			{
				cidade: 'Rio Verde de Mato Grosso',
				id_cidade: 1552,
			},
			{
				cidade: 'Rochedo',
				id_cidade: 1553,
			},
			{
				cidade: 'Água Clara',
				id_cidade: 1487,
			},
			{
				cidade: 'Santa Rita do Pardo',
				id_cidade: 1554,
			},
			{
				cidade: 'Anastácio',
				id_cidade: 1490,
			},
			{
				cidade: 'Anaurilândia',
				id_cidade: 1491,
			},
			{
				cidade: 'São Gabriel do Oeste',
				id_cidade: 1555,
			},
			{
				cidade: 'Angélica',
				id_cidade: 1492,
			},
			{
				cidade: 'Selvíria',
				id_cidade: 1556,
			},
			{
				cidade: 'Antônio João',
				id_cidade: 1493,
			},
			{
				cidade: 'Aparecida do Taboado',
				id_cidade: 1494,
			},
			{
				cidade: 'Aquidauana',
				id_cidade: 1495,
			},
			{
				cidade: 'Sete Quedas',
				id_cidade: 1557,
			},
			{
				cidade: 'Sidrolândia',
				id_cidade: 1558,
			},
			{
				cidade: 'Sonora',
				id_cidade: 1559,
			},
			{
				cidade: 'Tacuru',
				id_cidade: 1560,
			},
			{
				cidade: 'Taquarussu',
				id_cidade: 1561,
			},
			{
				cidade: 'Aral Moreira',
				id_cidade: 1496,
			},
			{
				cidade: 'Terenos',
				id_cidade: 1562,
			},
			{
				cidade: 'Três Lagoas',
				id_cidade: 1563,
			},
			{
				cidade: 'Vicentina',
				id_cidade: 1564,
			},
		],
	},
	{
		estado: 'Mato Grosso',
		sigla: 'MT',
		id_estado: 13,
		cidades: [
			{
				cidade: 'Arenápolis',
				id_cidade: 1358,
			},
			{
				cidade: 'Aripuanã',
				id_cidade: 1359,
			},
			{
				cidade: 'Barão de Melgaço',
				id_cidade: 1360,
			},
			{
				cidade: 'Barra do Bugres',
				id_cidade: 1361,
			},
			{
				cidade: 'Barra do Garças',
				id_cidade: 1362,
			},
			{
				cidade: 'Bom Jesus do Araguaia',
				id_cidade: 1363,
			},
			{
				cidade: 'Brasnorte',
				id_cidade: 1364,
			},
			{
				cidade: 'Cáceres',
				id_cidade: 1365,
			},
			{
				cidade: 'Campinápolis',
				id_cidade: 1366,
			},
			{
				cidade: 'Campo Novo do Parecis',
				id_cidade: 1367,
			},
			{
				cidade: 'Campos de Júlio',
				id_cidade: 1369,
			},
			{
				cidade: 'Campo Verde',
				id_cidade: 1368,
			},
			{
				cidade: 'Canabrava do Norte',
				id_cidade: 1370,
			},
			{
				cidade: 'Canarana',
				id_cidade: 1371,
			},
			{
				cidade: 'Carlinda',
				id_cidade: 1372,
			},
			{
				cidade: 'Castanheira',
				id_cidade: 1373,
			},
			{
				cidade: 'Chapada dos Guimarães',
				id_cidade: 1374,
			},
			{
				cidade: 'Cláudia',
				id_cidade: 1375,
			},
			{
				cidade: 'Cocalinho',
				id_cidade: 1376,
			},
			{
				cidade: 'Colíder',
				id_cidade: 1377,
			},
			{
				cidade: 'Colniza',
				id_cidade: 1378,
			},
			{
				cidade: 'Comodoro',
				id_cidade: 1379,
			},
			{
				cidade: 'Confresa',
				id_cidade: 1380,
			},
			{
				cidade: 'Cotriguaçu',
				id_cidade: 1382,
			},
			{
				cidade: 'Cuiabá',
				id_cidade: 1383,
			},
			{
				cidade: "Conquista d'Oeste",
				id_cidade: 1381,
			},
			{
				cidade: "Figueirópolis d'Oeste",
				id_cidade: 1390,
			},
			{
				cidade: "Glória d'Oeste",
				id_cidade: 1393,
			},
			{
				cidade: "Lambari d'Oeste",
				id_cidade: 1408,
			},
			{
				cidade: 'Curvelândia',
				id_cidade: 1385,
			},
			{
				cidade: 'Curvelândia',
				id_cidade: 1384,
			},
			{
				cidade: 'Nova Monte verde',
				id_cidade: 1424,
			},
			{
				cidade: 'Denise',
				id_cidade: 1386,
			},
			{
				cidade: 'Poxoréo',
				id_cidade: 1447,
			},
			{
				cidade: 'Diamantino',
				id_cidade: 1387,
			},
			{
				cidade: 'Dom Aquino',
				id_cidade: 1388,
			},
			{
				cidade: 'Água Boa',
				id_cidade: 1347,
			},
			{
				cidade: 'Feliz Natal',
				id_cidade: 1389,
			},
			{
				cidade: 'Gaúcha do Norte',
				id_cidade: 1391,
			},
			{
				cidade: 'General Carneiro',
				id_cidade: 1392,
			},
			{
				cidade: 'Guarantã do Norte',
				id_cidade: 1394,
			},
			{
				cidade: 'Guiratinga',
				id_cidade: 1395,
			},
			{
				cidade: 'Indiavaí',
				id_cidade: 1396,
			},
			{
				cidade: 'Ipiranga do Norte',
				id_cidade: 1397,
			},
			{
				cidade: 'Itanhangá',
				id_cidade: 1398,
			},
			{
				cidade: 'Itaúba',
				id_cidade: 1399,
			},
			{
				cidade: 'Itiquira',
				id_cidade: 1400,
			},
			{
				cidade: 'Jaciara',
				id_cidade: 1401,
			},
			{
				cidade: 'Jangada',
				id_cidade: 1402,
			},
			{
				cidade: 'Jauru',
				id_cidade: 1403,
			},
			{
				cidade: 'Juara',
				id_cidade: 1404,
			},
			{
				cidade: 'Juína',
				id_cidade: 1405,
			},
			{
				cidade: 'Juruena',
				id_cidade: 1406,
			},
			{
				cidade: 'Juscimeira',
				id_cidade: 1407,
			},
			{
				cidade: 'Lucas do Rio Verde',
				id_cidade: 1409,
			},
			{
				cidade: 'Luciára',
				id_cidade: 1410,
			},
			{
				cidade: 'Marcelândia',
				id_cidade: 1411,
			},
			{
				cidade: 'Matupá',
				id_cidade: 1412,
			},
			{
				cidade: "Mirassol d'Oeste",
				id_cidade: 1413,
			},
			{
				cidade: 'Nobres',
				id_cidade: 1414,
			},
			{
				cidade: 'Nortelândia',
				id_cidade: 1415,
			},
			{
				cidade: 'Nossa Senhora do Livramento',
				id_cidade: 1416,
			},
			{
				cidade: 'Nova Bandeirantes',
				id_cidade: 1417,
			},
			{
				cidade: 'Nova Brasilândia',
				id_cidade: 1418,
			},
			{
				cidade: 'Nova Canaã do Norte',
				id_cidade: 1419,
			},
			{
				cidade: 'Nova Guarita',
				id_cidade: 1420,
			},
			{
				cidade: 'Nova Lacerda',
				id_cidade: 1421,
			},
			{
				cidade: 'Nova Marilândia',
				id_cidade: 1422,
			},
			{
				cidade: 'Nova Maringá',
				id_cidade: 1423,
			},
			{
				cidade: 'Nova Mutum',
				id_cidade: 1425,
			},
			{
				cidade: 'Nova Olímpia',
				id_cidade: 1426,
			},
			{
				cidade: 'Nova Santa Helena',
				id_cidade: 1427,
			},
			{
				cidade: 'Nova Ubiratã',
				id_cidade: 1428,
			},
			{
				cidade: 'Nova Xavantina',
				id_cidade: 1429,
			},
			{
				cidade: 'Novo Horizonte do Norte',
				id_cidade: 1430,
			},
			{
				cidade: 'Novo Mundo',
				id_cidade: 1431,
			},
			{
				cidade: 'Novo Santo Antônio',
				id_cidade: 1432,
			},
			{
				cidade: 'Novo São Joaquim',
				id_cidade: 1433,
			},
			{
				cidade: 'Paranaíta',
				id_cidade: 1434,
			},
			{
				cidade: 'Paranatinga',
				id_cidade: 1435,
			},
			{
				cidade: 'Pedra Preta',
				id_cidade: 1436,
			},
			{
				cidade: 'Peixoto de Azevedo',
				id_cidade: 1437,
			},
			{
				cidade: 'Planalto da Serra',
				id_cidade: 1438,
			},
			{
				cidade: 'Poconé',
				id_cidade: 1439,
			},
			{
				cidade: 'Pontal do Araguaia',
				id_cidade: 1440,
			},
			{
				cidade: 'Ponte Branca',
				id_cidade: 1441,
			},
			{
				cidade: 'Pontes e Lacerda',
				id_cidade: 1442,
			},
			{
				cidade: 'Porto Alegre do Norte',
				id_cidade: 1443,
			},
			{
				cidade: 'Porto dos Gaúchos',
				id_cidade: 1444,
			},
			{
				cidade: 'Porto Esperidião',
				id_cidade: 1445,
			},
			{
				cidade: 'Porto Estrela',
				id_cidade: 1446,
			},
			{
				cidade: 'Primavera do Leste',
				id_cidade: 1448,
			},
			{
				cidade: 'Querência',
				id_cidade: 1449,
			},
			{
				cidade: 'Reserva do Cabaçal',
				id_cidade: 1450,
			},
			{
				cidade: 'Ribeirão Cascalheira',
				id_cidade: 1451,
			},
			{
				cidade: 'Ribeirãozinho',
				id_cidade: 1452,
			},
			{
				cidade: 'Rio Branco',
				id_cidade: 1453,
			},
			{
				cidade: 'Rondolândia',
				id_cidade: 1454,
			},
			{
				cidade: 'Rondonópolis',
				id_cidade: 1455,
			},
			{
				cidade: 'Rosário Oeste',
				id_cidade: 1456,
			},
			{
				cidade: 'Salto do Céu',
				id_cidade: 1457,
			},
			{
				cidade: 'Santa Carmem',
				id_cidade: 1458,
			},
			{
				cidade: 'Santa Cruz do Xingu',
				id_cidade: 1459,
			},
			{
				cidade: 'Acorizal',
				id_cidade: 1346,
			},
			{
				cidade: 'Alta Floresta',
				id_cidade: 1348,
			},
			{
				cidade: 'Alto Araguaia',
				id_cidade: 1349,
			},
			{
				cidade: 'Alto Boa Vista',
				id_cidade: 1350,
			},
			{
				cidade: 'Alto Garças',
				id_cidade: 1351,
			},
			{
				cidade: 'Alto Paraguai',
				id_cidade: 1352,
			},
			{
				cidade: 'Alto Taquari',
				id_cidade: 1353,
			},
			{
				cidade: 'Santa Rita do Trivelato',
				id_cidade: 1460,
			},
			{
				cidade: 'Santa Terezinha',
				id_cidade: 1461,
			},
			{
				cidade: 'Santo Afonso',
				id_cidade: 1462,
			},
			{
				cidade: 'Santo Antônio do Leste',
				id_cidade: 1463,
			},
			{
				cidade: 'Santo Antônio do Leverger',
				id_cidade: 1464,
			},
			{
				cidade: 'São Félix do Araguaia',
				id_cidade: 1465,
			},
			{
				cidade: 'São José do Povo',
				id_cidade: 1466,
			},
			{
				cidade: 'São José do Rio Claro',
				id_cidade: 1467,
			},
			{
				cidade: 'São José dos Quatro Marcos',
				id_cidade: 1469,
			},
			{
				cidade: 'São José do Xingu',
				id_cidade: 1468,
			},
			{
				cidade: 'São Pedro da Cipa',
				id_cidade: 1470,
			},
			{
				cidade: 'Sapezal',
				id_cidade: 1471,
			},
			{
				cidade: 'Serra Nova Dourada',
				id_cidade: 1472,
			},
			{
				cidade: 'Apiacás',
				id_cidade: 1354,
			},
			{
				cidade: 'Sinop',
				id_cidade: 1473,
			},
			{
				cidade: 'Sorriso',
				id_cidade: 1474,
			},
			{
				cidade: 'Tabaporã',
				id_cidade: 1475,
			},
			{
				cidade: 'Tangará da Serra',
				id_cidade: 1476,
			},
			{
				cidade: 'Tapurah',
				id_cidade: 1477,
			},
			{
				cidade: 'Araguaiana',
				id_cidade: 1355,
			},
			{
				cidade: 'Araguainha',
				id_cidade: 1356,
			},
			{
				cidade: 'Araputanga',
				id_cidade: 1357,
			},
			{
				cidade: 'Terra Nova do Norte',
				id_cidade: 1478,
			},
			{
				cidade: 'Tesouro',
				id_cidade: 1479,
			},
			{
				cidade: 'Torixoréu',
				id_cidade: 1480,
			},
			{
				cidade: 'União do Sul',
				id_cidade: 1481,
			},
			{
				cidade: 'Vale de São Domingos',
				id_cidade: 1482,
			},
			{
				cidade: 'Várzea Grande',
				id_cidade: 1483,
			},
			{
				cidade: 'Vera',
				id_cidade: 1484,
			},
			{
				cidade: 'Vila Bela da Santíssima Trindade',
				id_cidade: 1485,
			},
			{
				cidade: 'Vila Rica',
				id_cidade: 1486,
			},
		],
	},
	{
		estado: 'Pará',
		sigla: 'PA',
		id_estado: 14,
		cidades: [
			{
				cidade: 'Aveiro',
				id_cidade: 2431,
			},
			{
				cidade: 'Alenquer',
				id_cidade: 2423,
			},
			{
				cidade: 'Almeirim',
				id_cidade: 2424,
			},
			{
				cidade: 'Augusto Corrêa',
				id_cidade: 2429,
			},
			{
				cidade: 'Aurora do Pará',
				id_cidade: 2430,
			},
			{
				cidade: 'Bagre',
				id_cidade: 2432,
			},
			{
				cidade: 'Baião',
				id_cidade: 2433,
			},
			{
				cidade: 'Bannach',
				id_cidade: 2434,
			},
			{
				cidade: 'Barcarena',
				id_cidade: 2435,
			},
			{
				cidade: 'Belém',
				id_cidade: 2436,
			},
			{
				cidade: 'Belterra',
				id_cidade: 2437,
			},
			{
				cidade: 'Benevides',
				id_cidade: 2438,
			},
			{
				cidade: 'Bom Jesus do Tocantins',
				id_cidade: 2439,
			},
			{
				cidade: 'Bonito',
				id_cidade: 2440,
			},
			{
				cidade: 'Bragança',
				id_cidade: 2441,
			},
			{
				cidade: 'Brasil Novo',
				id_cidade: 2442,
			},
			{
				cidade: 'Brejo Grande do Araguaia',
				id_cidade: 2443,
			},
			{
				cidade: 'Breu Branco',
				id_cidade: 2444,
			},
			{
				cidade: 'Breves',
				id_cidade: 2445,
			},
			{
				cidade: 'Bujaru',
				id_cidade: 2446,
			},
			{
				cidade: 'Cachoeira do Arari',
				id_cidade: 2447,
			},
			{
				cidade: 'Cachoeira do Piriá',
				id_cidade: 2448,
			},
			{
				cidade: 'Cametá',
				id_cidade: 2449,
			},
			{
				cidade: 'Canaã dos Carajás',
				id_cidade: 2450,
			},
			{
				cidade: 'Capanema',
				id_cidade: 2451,
			},
			{
				cidade: 'Capitão Poço',
				id_cidade: 2452,
			},
			{
				cidade: 'Castanhal',
				id_cidade: 2453,
			},
			{
				cidade: 'Chaves',
				id_cidade: 2454,
			},
			{
				cidade: 'Colares',
				id_cidade: 2455,
			},
			{
				cidade: 'Conceição do Araguaia',
				id_cidade: 2456,
			},
			{
				cidade: 'Concórdia do Pará',
				id_cidade: 2457,
			},
			{
				cidade: 'Cumaru do Norte',
				id_cidade: 2458,
			},
			{
				cidade: 'Curionópolis',
				id_cidade: 2459,
			},
			{
				cidade: 'Curralinho',
				id_cidade: 2460,
			},
			{
				cidade: 'Curuá',
				id_cidade: 2461,
			},
			{
				cidade: 'Curuçá',
				id_cidade: 2462,
			},
			{
				cidade: 'Dom Eliseu',
				id_cidade: 2463,
			},
			{
				cidade: 'Faro',
				id_cidade: 2465,
			},
			{
				cidade: 'Floresta do Araguaia',
				id_cidade: 2466,
			},
			{
				cidade: 'Garrafão do Norte',
				id_cidade: 2467,
			},
			{
				cidade: 'Goianésia do Pará',
				id_cidade: 2468,
			},
			{
				cidade: 'Gurupá',
				id_cidade: 2469,
			},
			{
				cidade: 'Igarapé-Açu',
				id_cidade: 2470,
			},
			{
				cidade: 'Igarapé-Miri',
				id_cidade: 2471,
			},
			{
				cidade: 'Inhangapi',
				id_cidade: 2472,
			},
			{
				cidade: 'Ipixuna do Pará',
				id_cidade: 2473,
			},
			{
				cidade: 'Irituia',
				id_cidade: 2474,
			},
			{
				cidade: 'Itaituba',
				id_cidade: 2475,
			},
			{
				cidade: 'Itupiranga',
				id_cidade: 2476,
			},
			{
				cidade: 'Eldorado dos Carajás',
				id_cidade: 2464,
			},
			{
				cidade: 'Jacareacanga',
				id_cidade: 2477,
			},
			{
				cidade: 'Jacundá',
				id_cidade: 2478,
			},
			{
				cidade: 'Santa Isabel do Pará',
				id_cidade: 2525,
			},
			{
				cidade: 'Juruti',
				id_cidade: 2479,
			},
			{
				cidade: 'Limoeiro do Ajuru',
				id_cidade: 2480,
			},
			{
				cidade: 'Mãe do Rio',
				id_cidade: 2481,
			},
			{
				cidade: 'Magalhães Barata',
				id_cidade: 2482,
			},
			{
				cidade: 'Marabá',
				id_cidade: 2483,
			},
			{
				cidade: 'Maracanã',
				id_cidade: 2484,
			},
			{
				cidade: 'Marapanim',
				id_cidade: 2485,
			},
			{
				cidade: 'Marituba',
				id_cidade: 2486,
			},
			{
				cidade: 'Medicilândia',
				id_cidade: 2487,
			},
			{
				cidade: 'Melgaço',
				id_cidade: 2488,
			},
			{
				cidade: 'Mocajuba',
				id_cidade: 2489,
			},
			{
				cidade: 'Moju',
				id_cidade: 2490,
			},
			{
				cidade: 'Monte Alegre',
				id_cidade: 2491,
			},
			{
				cidade: 'Muaná',
				id_cidade: 2492,
			},
			{
				cidade: 'Nova Esperança do Piriá',
				id_cidade: 2493,
			},
			{
				cidade: 'Nova Ipixuna',
				id_cidade: 2494,
			},
			{
				cidade: 'Nova Timboteua',
				id_cidade: 2495,
			},
			{
				cidade: 'Novo Progresso',
				id_cidade: 2496,
			},
			{
				cidade: 'Novo Repartimento',
				id_cidade: 2497,
			},
			{
				cidade: 'Óbidos',
				id_cidade: 2498,
			},
			{
				cidade: 'Oeiras do Pará',
				id_cidade: 2499,
			},
			{
				cidade: 'Oriximiná',
				id_cidade: 2500,
			},
			{
				cidade: 'Ourém',
				id_cidade: 2501,
			},
			{
				cidade: 'Ourilândia do Norte',
				id_cidade: 2502,
			},
			{
				cidade: 'Pacajá',
				id_cidade: 2503,
			},
			{
				cidade: 'Palestina do Pará',
				id_cidade: 2504,
			},
			{
				cidade: 'Paragominas',
				id_cidade: 2505,
			},
			{
				cidade: 'Parauapebas',
				id_cidade: 2506,
			},
			{
				cidade: "Pau d'Arco",
				id_cidade: 2507,
			},
			{
				cidade: 'Peixe-Boi',
				id_cidade: 2508,
			},
			{
				cidade: 'Piçarra',
				id_cidade: 2509,
			},
			{
				cidade: 'Placas',
				id_cidade: 2510,
			},
			{
				cidade: 'Ponta de Pedras',
				id_cidade: 2511,
			},
			{
				cidade: 'Portel',
				id_cidade: 2512,
			},
			{
				cidade: 'Porto de Moz',
				id_cidade: 2513,
			},
			{
				cidade: 'Prainha',
				id_cidade: 2514,
			},
			{
				cidade: 'Primavera',
				id_cidade: 2515,
			},
			{
				cidade: 'Quatipuru',
				id_cidade: 2516,
			},
			{
				cidade: 'Redenção',
				id_cidade: 2517,
			},
			{
				cidade: 'Rio Maria',
				id_cidade: 2518,
			},
			{
				cidade: 'Rondon do Pará',
				id_cidade: 2519,
			},
			{
				cidade: 'Rurópolis',
				id_cidade: 2520,
			},
			{
				cidade: 'Salinópolis',
				id_cidade: 2521,
			},
			{
				cidade: 'Salvaterra',
				id_cidade: 2522,
			},
			{
				cidade: 'Santa Bárbara do Pará',
				id_cidade: 2523,
			},
			{
				cidade: 'Santa Cruz do Arari',
				id_cidade: 2524,
			},
			{
				cidade: 'Santa Luzia do Pará',
				id_cidade: 2526,
			},
			{
				cidade: 'Santa Maria das Barreiras',
				id_cidade: 2527,
			},
			{
				cidade: 'Santa Maria do Pará',
				id_cidade: 2528,
			},
			{
				cidade: 'Santana do Araguaia',
				id_cidade: 2529,
			},
			{
				cidade: 'Abaetetuba',
				id_cidade: 2418,
			},
			{
				cidade: 'Abel Figueiredo',
				id_cidade: 2419,
			},
			{
				cidade: 'Acará',
				id_cidade: 2420,
			},
			{
				cidade: 'Afuá',
				id_cidade: 2421,
			},
			{
				cidade: 'Água Azul do Norte',
				id_cidade: 2422,
			},
			{
				cidade: 'Altamira',
				id_cidade: 2425,
			},
			{
				cidade: 'Santarém',
				id_cidade: 2530,
			},
			{
				cidade: 'Santarém Novo',
				id_cidade: 2531,
			},
			{
				cidade: 'Anajás',
				id_cidade: 2426,
			},
			{
				cidade: 'Ananindeua',
				id_cidade: 2427,
			},
			{
				cidade: 'Anapu',
				id_cidade: 2428,
			},
			{
				cidade: 'Santo Antônio do Tauá',
				id_cidade: 2532,
			},
			{
				cidade: 'São Caetano de Odivelas',
				id_cidade: 2533,
			},
			{
				cidade: 'São Domingos do Araguaia',
				id_cidade: 2534,
			},
			{
				cidade: 'São Domingos do Capim',
				id_cidade: 2535,
			},
			{
				cidade: 'São Félix do Xingu',
				id_cidade: 2536,
			},
			{
				cidade: 'São Francisco do Pará',
				id_cidade: 2537,
			},
			{
				cidade: 'São Geraldo do Araguaia',
				id_cidade: 2538,
			},
			{
				cidade: 'São João da Ponta',
				id_cidade: 2539,
			},
			{
				cidade: 'São João de Pirabas',
				id_cidade: 2540,
			},
			{
				cidade: 'São João do Araguaia',
				id_cidade: 2541,
			},
			{
				cidade: 'São Miguel do Guamá',
				id_cidade: 2542,
			},
			{
				cidade: 'São Sebastião da Boa Vista',
				id_cidade: 2543,
			},
			{
				cidade: 'Sapucaia',
				id_cidade: 2544,
			},
			{
				cidade: 'Senador José Porfírio',
				id_cidade: 2545,
			},
			{
				cidade: 'Soure',
				id_cidade: 2546,
			},
			{
				cidade: 'Tailândia',
				id_cidade: 2547,
			},
			{
				cidade: 'Terra Alta',
				id_cidade: 2548,
			},
			{
				cidade: 'Terra Santa',
				id_cidade: 2549,
			},
			{
				cidade: 'Tomé-Açu',
				id_cidade: 2550,
			},
			{
				cidade: 'Tracuateua',
				id_cidade: 2551,
			},
			{
				cidade: 'Trairão',
				id_cidade: 2552,
			},
			{
				cidade: 'Tucumã',
				id_cidade: 2553,
			},
			{
				cidade: 'Tucuruí',
				id_cidade: 2554,
			},
			{
				cidade: 'Ulianópolis',
				id_cidade: 2555,
			},
			{
				cidade: 'Uruará',
				id_cidade: 2556,
			},
			{
				cidade: 'Vigia',
				id_cidade: 2557,
			},
			{
				cidade: 'Viseu',
				id_cidade: 2558,
			},
			{
				cidade: 'Vitória do Xingu',
				id_cidade: 2559,
			},
			{
				cidade: 'Xinguara',
				id_cidade: 2560,
			},
		],
	},
	{
		estado: 'Paraíba',
		sigla: 'PB',
		id_estado: 15,
		cidades: [
			{
				cidade: 'Aguiar',
				id_cidade: 2562,
			},
			{
				cidade: 'Alagoa Nova',
				id_cidade: 2564,
			},
			{
				cidade: 'Alagoinha',
				id_cidade: 2565,
			},
			{
				cidade: 'Alcantil',
				id_cidade: 2566,
			},
			{
				cidade: 'Algodão de Jandaíra',
				id_cidade: 2567,
			},
			{
				cidade: 'Alhandra',
				id_cidade: 2568,
			},
			{
				cidade: 'Areia',
				id_cidade: 2574,
			},
			{
				cidade: 'Areia de Baraúnas',
				id_cidade: 2575,
			},
			{
				cidade: 'Areial',
				id_cidade: 2576,
			},
			{
				cidade: 'Aroeiras',
				id_cidade: 2577,
			},
			{
				cidade: 'Baía da Traição',
				id_cidade: 2579,
			},
			{
				cidade: 'Bananeiras',
				id_cidade: 2580,
			},
			{
				cidade: 'Baraúna',
				id_cidade: 2581,
			},
			{
				cidade: 'Barra de Santana',
				id_cidade: 2583,
			},
			{
				cidade: 'Barra de Santa Rosa',
				id_cidade: 2582,
			},
			{
				cidade: 'Barra de São Miguel',
				id_cidade: 2584,
			},
			{
				cidade: 'Bayeux',
				id_cidade: 2585,
			},
			{
				cidade: 'Belém',
				id_cidade: 2586,
			},
			{
				cidade: 'Belém do Brejo do Cruz',
				id_cidade: 2587,
			},
			{
				cidade: 'Bernardino Batista',
				id_cidade: 2588,
			},
			{
				cidade: 'Boa Ventura',
				id_cidade: 2589,
			},
			{
				cidade: 'Boa Vista',
				id_cidade: 2590,
			},
			{
				cidade: 'Bom Jesus',
				id_cidade: 2591,
			},
			{
				cidade: 'Bom Sucesso',
				id_cidade: 2592,
			},
			{
				cidade: 'Bonito de Santa Fé',
				id_cidade: 2593,
			},
			{
				cidade: 'Boqueirão',
				id_cidade: 2594,
			},
			{
				cidade: 'Borborema',
				id_cidade: 2595,
			},
			{
				cidade: 'Brejo do Cruz',
				id_cidade: 2596,
			},
			{
				cidade: 'Brejo dos Santos',
				id_cidade: 2597,
			},
			{
				cidade: 'Caaporã',
				id_cidade: 2598,
			},
			{
				cidade: 'Cabaceiras',
				id_cidade: 2599,
			},
			{
				cidade: 'Cabedelo',
				id_cidade: 2600,
			},
			{
				cidade: 'Cachoeira dos Índios',
				id_cidade: 2601,
			},
			{
				cidade: 'Cacimba de Areia',
				id_cidade: 2602,
			},
			{
				cidade: 'Cacimba de Dentro',
				id_cidade: 2603,
			},
			{
				cidade: 'Cacimbas',
				id_cidade: 2604,
			},
			{
				cidade: 'Caiçara',
				id_cidade: 2605,
			},
			{
				cidade: 'Cajazeiras',
				id_cidade: 2606,
			},
			{
				cidade: 'Cajazeirinhas',
				id_cidade: 2607,
			},
			{
				cidade: 'Caldas Brandão',
				id_cidade: 2608,
			},
			{
				cidade: 'Camalaú',
				id_cidade: 2609,
			},
			{
				cidade: 'Campina Grande',
				id_cidade: 2610,
			},
			{
				cidade: 'Capim',
				id_cidade: 2612,
			},
			{
				cidade: 'Caraúbas',
				id_cidade: 2613,
			},
			{
				cidade: 'Carrapateira',
				id_cidade: 2614,
			},
			{
				cidade: 'Casserengue',
				id_cidade: 2615,
			},
			{
				cidade: 'Catingueira',
				id_cidade: 2616,
			},
			{
				cidade: 'Catolé do Rocha',
				id_cidade: 2617,
			},
			{
				cidade: 'Caturité',
				id_cidade: 2618,
			},
			{
				cidade: 'Conceição',
				id_cidade: 2619,
			},
			{
				cidade: 'Condado',
				id_cidade: 2620,
			},
			{
				cidade: 'Conde',
				id_cidade: 2621,
			},
			{
				cidade: 'Congo',
				id_cidade: 2622,
			},
			{
				cidade: 'Coremas',
				id_cidade: 2623,
			},
			{
				cidade: 'Coxixola',
				id_cidade: 2624,
			},
			{
				cidade: 'Cruz do Espírito Santo',
				id_cidade: 2625,
			},
			{
				cidade: 'Cubati',
				id_cidade: 2626,
			},
			{
				cidade: 'Cuité',
				id_cidade: 2627,
			},
			{
				cidade: 'Cuité de Mamanguape',
				id_cidade: 2628,
			},
			{
				cidade: 'Cuitegi',
				id_cidade: 2629,
			},
			{
				cidade: 'Curral de Cima',
				id_cidade: 2630,
			},
			{
				cidade: 'Curral Velho',
				id_cidade: 2631,
			},
			{
				cidade: 'Damião',
				id_cidade: 2632,
			},
			{
				cidade: 'Desterro',
				id_cidade: 2633,
			},
			{
				cidade: 'Diamante',
				id_cidade: 2634,
			},
			{
				cidade: 'Dona Inês',
				id_cidade: 2635,
			},
			{
				cidade: 'Duas Estradas',
				id_cidade: 2636,
			},
			{
				cidade: 'Emas',
				id_cidade: 2637,
			},
			{
				cidade: 'Esperança',
				id_cidade: 2638,
			},
			{
				cidade: 'Fagundes',
				id_cidade: 2639,
			},
			{
				cidade: 'Frei Martinho',
				id_cidade: 2640,
			},
			{
				cidade: 'Gado Bravo',
				id_cidade: 2641,
			},
			{
				cidade: 'Guarabira',
				id_cidade: 2642,
			},
			{
				cidade: 'Gurinhém',
				id_cidade: 2643,
			},
			{
				cidade: 'Gurjão',
				id_cidade: 2644,
			},
			{
				cidade: 'Ibiara',
				id_cidade: 2645,
			},
			{
				cidade: 'Igaracy',
				id_cidade: 2646,
			},
			{
				cidade: 'Imaculada',
				id_cidade: 2647,
			},
			{
				cidade: 'Ingá',
				id_cidade: 2648,
			},
			{
				cidade: 'Itabaiana',
				id_cidade: 2649,
			},
			{
				cidade: 'Itaporanga',
				id_cidade: 2650,
			},
			{
				cidade: 'Itapororoca',
				id_cidade: 2651,
			},
			{
				cidade: 'Itatuba',
				id_cidade: 2652,
			},
			{
				cidade: 'Água Branca',
				id_cidade: 2561,
			},
			{
				cidade: 'Alagoa Grande',
				id_cidade: 2563,
			},
			{
				cidade: 'Jacaraú',
				id_cidade: 2653,
			},
			{
				cidade: 'Jericó',
				id_cidade: 2654,
			},
			{
				cidade: 'Campo de Santana',
				id_cidade: 2611,
			},
			{
				cidade: 'João Pessoa',
				id_cidade: 2655,
			},
			{
				cidade: 'Juarez Távora',
				id_cidade: 2656,
			},
			{
				cidade: 'Juazeirinho',
				id_cidade: 2657,
			},
			{
				cidade: 'Junco do Seridó',
				id_cidade: 2658,
			},
			{
				cidade: 'Juripiranga',
				id_cidade: 2659,
			},
			{
				cidade: 'Juru',
				id_cidade: 2660,
			},
			{
				cidade: 'Lagoa',
				id_cidade: 2661,
			},
			{
				cidade: 'Lagoa de Dentro',
				id_cidade: 2662,
			},
			{
				cidade: 'São Domingos de Pombal',
				id_cidade: 2739,
			},
			{
				cidade: 'Lagoa Seca',
				id_cidade: 2663,
			},
			{
				cidade: 'Seridó',
				id_cidade: 2760,
			},
			{
				cidade: 'Lastro',
				id_cidade: 2664,
			},
			{
				cidade: 'Livramento',
				id_cidade: 2665,
			},
			{
				cidade: 'Logradouro',
				id_cidade: 2666,
			},
			{
				cidade: 'Lucena',
				id_cidade: 2667,
			},
			{
				cidade: "Mãe d'Água",
				id_cidade: 2668,
			},
			{
				cidade: 'Malta',
				id_cidade: 2669,
			},
			{
				cidade: 'Mamanguape',
				id_cidade: 2670,
			},
			{
				cidade: 'Manaíra',
				id_cidade: 2671,
			},
			{
				cidade: 'Marcação',
				id_cidade: 2672,
			},
			{
				cidade: 'Mari',
				id_cidade: 2673,
			},
			{
				cidade: 'Marizópolis',
				id_cidade: 2674,
			},
			{
				cidade: 'Massaranduba',
				id_cidade: 2675,
			},
			{
				cidade: 'Mataraca',
				id_cidade: 2676,
			},
			{
				cidade: 'Matinhas',
				id_cidade: 2677,
			},
			{
				cidade: 'Mato Grosso',
				id_cidade: 2678,
			},
			{
				cidade: 'Maturéia',
				id_cidade: 2679,
			},
			{
				cidade: 'Mogeiro',
				id_cidade: 2680,
			},
			{
				cidade: 'Montadas',
				id_cidade: 2681,
			},
			{
				cidade: 'Monte Horebe',
				id_cidade: 2682,
			},
			{
				cidade: 'Monteiro',
				id_cidade: 2683,
			},
			{
				cidade: 'Mulungu',
				id_cidade: 2684,
			},
			{
				cidade: 'Natuba',
				id_cidade: 2685,
			},
			{
				cidade: 'Nazarezinho',
				id_cidade: 2686,
			},
			{
				cidade: 'Nova Floresta',
				id_cidade: 2687,
			},
			{
				cidade: 'Nova Olinda',
				id_cidade: 2688,
			},
			{
				cidade: 'Nova Palmeira',
				id_cidade: 2689,
			},
			{
				cidade: "Olho d'Água",
				id_cidade: 2690,
			},
			{
				cidade: 'Olivedos',
				id_cidade: 2691,
			},
			{
				cidade: 'Ouro Velho',
				id_cidade: 2692,
			},
			{
				cidade: 'Parari',
				id_cidade: 2693,
			},
			{
				cidade: 'Passagem',
				id_cidade: 2694,
			},
			{
				cidade: 'Patos',
				id_cidade: 2695,
			},
			{
				cidade: 'Paulista',
				id_cidade: 2696,
			},
			{
				cidade: 'Pedra Branca',
				id_cidade: 2697,
			},
			{
				cidade: 'Pedra Lavrada',
				id_cidade: 2698,
			},
			{
				cidade: 'Pedras de Fogo',
				id_cidade: 2699,
			},
			{
				cidade: 'Pedro Régis',
				id_cidade: 2700,
			},
			{
				cidade: 'Piancó',
				id_cidade: 2701,
			},
			{
				cidade: 'Picuí',
				id_cidade: 2702,
			},
			{
				cidade: 'Pilar',
				id_cidade: 2703,
			},
			{
				cidade: 'Pilões',
				id_cidade: 2704,
			},
			{
				cidade: 'Pilõezinhos',
				id_cidade: 2705,
			},
			{
				cidade: 'Pirpirituba',
				id_cidade: 2706,
			},
			{
				cidade: 'Pitimbu',
				id_cidade: 2707,
			},
			{
				cidade: 'Pocinhos',
				id_cidade: 2708,
			},
			{
				cidade: 'Poço Dantas',
				id_cidade: 2709,
			},
			{
				cidade: 'Poço de José de Moura',
				id_cidade: 2710,
			},
			{
				cidade: 'Pombal',
				id_cidade: 2711,
			},
			{
				cidade: 'Prata',
				id_cidade: 2712,
			},
			{
				cidade: 'Princesa Isabel',
				id_cidade: 2713,
			},
			{
				cidade: 'Puxinanã',
				id_cidade: 2714,
			},
			{
				cidade: 'Queimadas',
				id_cidade: 2715,
			},
			{
				cidade: 'Quixabá',
				id_cidade: 2716,
			},
			{
				cidade: 'Remígio',
				id_cidade: 2717,
			},
			{
				cidade: 'Riachão',
				id_cidade: 2718,
			},
			{
				cidade: 'Riachão do Bacamarte',
				id_cidade: 2719,
			},
			{
				cidade: 'Riachão do Poço',
				id_cidade: 2720,
			},
			{
				cidade: 'Riacho de Santo Antônio',
				id_cidade: 2721,
			},
			{
				cidade: 'Riacho dos Cavalos',
				id_cidade: 2722,
			},
			{
				cidade: 'Rio Tinto',
				id_cidade: 2723,
			},
			{
				cidade: 'Salgadinho',
				id_cidade: 2724,
			},
			{
				cidade: 'Salgado de São Félix',
				id_cidade: 2725,
			},
			{
				cidade: 'Santa Cecília',
				id_cidade: 2726,
			},
			{
				cidade: 'Santa Cruz',
				id_cidade: 2727,
			},
			{
				cidade: 'Assunção',
				id_cidade: 2578,
			},
			{
				cidade: 'Santa Helena',
				id_cidade: 2728,
			},
			{
				cidade: 'Santa Inês',
				id_cidade: 2729,
			},
			{
				cidade: 'Santa Luzia',
				id_cidade: 2730,
			},
			{
				cidade: 'Santana de Mangueira',
				id_cidade: 2733,
			},
			{
				cidade: 'Santana dos Garrotes',
				id_cidade: 2734,
			},
			{
				cidade: 'Santarém',
				id_cidade: 2735,
			},
			{
				cidade: 'Santa Rita',
				id_cidade: 2731,
			},
			{
				cidade: 'Amparo',
				id_cidade: 2569,
			},
			{
				cidade: 'Santa Teresinha',
				id_cidade: 2732,
			},
			{
				cidade: 'Santo André',
				id_cidade: 2736,
			},
			{
				cidade: 'São Bentinho',
				id_cidade: 2737,
			},
			{
				cidade: 'São Bento',
				id_cidade: 2738,
			},
			{
				cidade: 'São Domingos do Cariri',
				id_cidade: 2740,
			},
			{
				cidade: 'São Francisco',
				id_cidade: 2741,
			},
			{
				cidade: 'São João do Cariri',
				id_cidade: 2742,
			},
			{
				cidade: 'São João do Rio do Peixe',
				id_cidade: 2743,
			},
			{
				cidade: 'São João do Tigre',
				id_cidade: 2744,
			},
			{
				cidade: 'São José da Lagoa Tapada',
				id_cidade: 2745,
			},
			{
				cidade: 'São José de Caiana',
				id_cidade: 2746,
			},
			{
				cidade: 'São José de Espinharas',
				id_cidade: 2747,
			},
			{
				cidade: 'São José de Piranhas',
				id_cidade: 2748,
			},
			{
				cidade: 'São José de Princesa',
				id_cidade: 2749,
			},
			{
				cidade: 'São José do Bonfim',
				id_cidade: 2750,
			},
			{
				cidade: 'São José do Brejo do Cruz',
				id_cidade: 2751,
			},
			{
				cidade: 'São José do Sabugi',
				id_cidade: 2752,
			},
			{
				cidade: 'São José dos Cordeiros',
				id_cidade: 2753,
			},
			{
				cidade: 'São José dos Ramos',
				id_cidade: 2754,
			},
			{
				cidade: 'São Mamede',
				id_cidade: 2755,
			},
			{
				cidade: 'São Miguel de Taipu',
				id_cidade: 2756,
			},
			{
				cidade: 'São Sebastião de Lagoa de Roça',
				id_cidade: 2757,
			},
			{
				cidade: 'São Sebastião do Umbuzeiro',
				id_cidade: 2758,
			},
			{
				cidade: 'Sapé',
				id_cidade: 2759,
			},
			{
				cidade: 'Serra Branca',
				id_cidade: 2761,
			},
			{
				cidade: 'Serra da Raiz',
				id_cidade: 2762,
			},
			{
				cidade: 'Serra Grande',
				id_cidade: 2763,
			},
			{
				cidade: 'Serra Redonda',
				id_cidade: 2764,
			},
			{
				cidade: 'Serraria',
				id_cidade: 2765,
			},
			{
				cidade: 'Aparecida',
				id_cidade: 2570,
			},
			{
				cidade: 'Araçagi',
				id_cidade: 2571,
			},
			{
				cidade: 'Sertãozinho',
				id_cidade: 2766,
			},
			{
				cidade: 'Sobrado',
				id_cidade: 2767,
			},
			{
				cidade: 'Solânea',
				id_cidade: 2768,
			},
			{
				cidade: 'Soledade',
				id_cidade: 2769,
			},
			{
				cidade: 'Sossêgo',
				id_cidade: 2770,
			},
			{
				cidade: 'Sousa',
				id_cidade: 2771,
			},
			{
				cidade: 'Sumé',
				id_cidade: 2772,
			},
			{
				cidade: 'Taperoá',
				id_cidade: 2773,
			},
			{
				cidade: 'Arara',
				id_cidade: 2572,
			},
			{
				cidade: 'Araruna',
				id_cidade: 2573,
			},
			{
				cidade: 'Tavares',
				id_cidade: 2774,
			},
			{
				cidade: 'Teixeira',
				id_cidade: 2775,
			},
			{
				cidade: 'Tenório',
				id_cidade: 2776,
			},
			{
				cidade: 'Triunfo',
				id_cidade: 2777,
			},
			{
				cidade: 'Uiraúna',
				id_cidade: 2778,
			},
			{
				cidade: 'Umbuzeiro',
				id_cidade: 2779,
			},
			{
				cidade: 'Várzea',
				id_cidade: 2780,
			},
			{
				cidade: 'Vieirópolis',
				id_cidade: 2781,
			},
			{
				cidade: 'Vista Serrana',
				id_cidade: 2782,
			},
			{
				cidade: 'Zabelê',
				id_cidade: 2783,
			},
		],
	},
	{
		estado: 'Pernambuco',
		sigla: 'PE',
		id_estado: 16,
		cidades: [
			{
				cidade: 'Alagoinha',
				id_cidade: 3189,
			},
			{
				cidade: 'Aliança',
				id_cidade: 3190,
			},
			{
				cidade: 'Arcoverde',
				id_cidade: 3196,
			},
			{
				cidade: 'Barra de Guabiraba',
				id_cidade: 3197,
			},
			{
				cidade: 'Barreiros',
				id_cidade: 3198,
			},
			{
				cidade: 'Belém de Maria',
				id_cidade: 3199,
			},
			{
				cidade: 'Belo Jardim',
				id_cidade: 3201,
			},
			{
				cidade: 'Betânia',
				id_cidade: 3202,
			},
			{
				cidade: 'Bezerros',
				id_cidade: 3203,
			},
			{
				cidade: 'Bodocó',
				id_cidade: 3204,
			},
			{
				cidade: 'Bom Conselho',
				id_cidade: 3205,
			},
			{
				cidade: 'Bom Jardim',
				id_cidade: 3206,
			},
			{
				cidade: 'Bonito',
				id_cidade: 3207,
			},
			{
				cidade: 'Brejão',
				id_cidade: 3208,
			},
			{
				cidade: 'Brejinho',
				id_cidade: 3209,
			},
			{
				cidade: 'Brejo da Madre de Deus',
				id_cidade: 3210,
			},
			{
				cidade: 'Buenos Aires',
				id_cidade: 3211,
			},
			{
				cidade: 'Buíque',
				id_cidade: 3212,
			},
			{
				cidade: 'Cabo de Santo Agostinho',
				id_cidade: 3213,
			},
			{
				cidade: 'Cabrobó',
				id_cidade: 3214,
			},
			{
				cidade: 'Cachoeirinha',
				id_cidade: 3215,
			},
			{
				cidade: 'Caetés',
				id_cidade: 3216,
			},
			{
				cidade: 'Calçado',
				id_cidade: 3217,
			},
			{
				cidade: 'Calumbi',
				id_cidade: 3218,
			},
			{
				cidade: 'Camaragibe',
				id_cidade: 3219,
			},
			{
				cidade: 'Camocim de São Félix',
				id_cidade: 3220,
			},
			{
				cidade: 'Camutanga',
				id_cidade: 3221,
			},
			{
				cidade: 'Canhotinho',
				id_cidade: 3222,
			},
			{
				cidade: 'Capoeiras',
				id_cidade: 3223,
			},
			{
				cidade: 'Carnaíba',
				id_cidade: 3224,
			},
			{
				cidade: 'Carnaubeira da Penha',
				id_cidade: 3225,
			},
			{
				cidade: 'Carpina',
				id_cidade: 3226,
			},
			{
				cidade: 'Caruaru',
				id_cidade: 3227,
			},
			{
				cidade: 'Casinhas',
				id_cidade: 3228,
			},
			{
				cidade: 'Catende',
				id_cidade: 3229,
			},
			{
				cidade: 'Cedro',
				id_cidade: 3230,
			},
			{
				cidade: 'Chã de Alegria',
				id_cidade: 3231,
			},
			{
				cidade: 'Chã Grande',
				id_cidade: 3232,
			},
			{
				cidade: 'Condado',
				id_cidade: 3233,
			},
			{
				cidade: 'Correntes',
				id_cidade: 3234,
			},
			{
				cidade: 'Cortês',
				id_cidade: 3235,
			},
			{
				cidade: 'Cumaru',
				id_cidade: 3236,
			},
			{
				cidade: 'Cupira',
				id_cidade: 3237,
			},
			{
				cidade: 'Custódia',
				id_cidade: 3238,
			},
			{
				cidade: 'Dormentes',
				id_cidade: 3239,
			},
			{
				cidade: 'Escada',
				id_cidade: 3240,
			},
			{
				cidade: 'Exu',
				id_cidade: 3241,
			},
			{
				cidade: 'Feira Nova',
				id_cidade: 3242,
			},
			{
				cidade: 'Fernando de Noronha',
				id_cidade: 3243,
			},
			{
				cidade: 'Ferreiros',
				id_cidade: 3244,
			},
			{
				cidade: 'Flores',
				id_cidade: 3245,
			},
			{
				cidade: 'Floresta',
				id_cidade: 3246,
			},
			{
				cidade: 'Frei Miguelinho',
				id_cidade: 3247,
			},
			{
				cidade: 'Gameleira',
				id_cidade: 3248,
			},
			{
				cidade: 'Garanhuns',
				id_cidade: 3249,
			},
			{
				cidade: 'Glória do Goitá',
				id_cidade: 3250,
			},
			{
				cidade: 'Goiana',
				id_cidade: 3251,
			},
			{
				cidade: 'Granito',
				id_cidade: 3252,
			},
			{
				cidade: 'Gravatá',
				id_cidade: 3253,
			},
			{
				cidade: 'Iati',
				id_cidade: 3254,
			},
			{
				cidade: 'Ibimirim',
				id_cidade: 3255,
			},
			{
				cidade: 'Ibirajuba',
				id_cidade: 3256,
			},
			{
				cidade: 'Igarassu',
				id_cidade: 3257,
			},
			{
				cidade: 'Ilha de Itamaracá',
				id_cidade: 3259,
			},
			{
				cidade: 'Inajá',
				id_cidade: 3260,
			},
			{
				cidade: 'Ingazeira',
				id_cidade: 3261,
			},
			{
				cidade: 'Ipojuca',
				id_cidade: 3262,
			},
			{
				cidade: 'Ipubi',
				id_cidade: 3263,
			},
			{
				cidade: 'Itacuruba',
				id_cidade: 3264,
			},
			{
				cidade: 'Itaíba',
				id_cidade: 3265,
			},
			{
				cidade: 'Itambé',
				id_cidade: 3266,
			},
			{
				cidade: 'Itapetim',
				id_cidade: 3267,
			},
			{
				cidade: 'Itapissuma',
				id_cidade: 3268,
			},
			{
				cidade: 'Itaquitinga',
				id_cidade: 3269,
			},
			{
				cidade: 'Jaboatão dos Guararapes',
				id_cidade: 3270,
			},
			{
				cidade: 'Jaqueira',
				id_cidade: 3271,
			},
			{
				cidade: 'Jataúba',
				id_cidade: 3272,
			},
			{
				cidade: 'Jatobá',
				id_cidade: 3273,
			},
			{
				cidade: 'João Alfredo',
				id_cidade: 3274,
			},
			{
				cidade: 'Joaquim Nabuco',
				id_cidade: 3275,
			},
			{
				cidade: 'Jucati',
				id_cidade: 3276,
			},
			{
				cidade: 'Jupi',
				id_cidade: 3277,
			},
			{
				cidade: 'Jurema',
				id_cidade: 3278,
			},
			{
				cidade: 'Lagoa do Carro',
				id_cidade: 3279,
			},
			{
				cidade: 'Lagoa do Ouro',
				id_cidade: 3281,
			},
			{
				cidade: 'Lagoa dos Gatos',
				id_cidade: 3282,
			},
			{
				cidade: 'Lagoa Grande',
				id_cidade: 3283,
			},
			{
				cidade: 'Lajedo',
				id_cidade: 3284,
			},
			{
				cidade: 'Limoeiro',
				id_cidade: 3285,
			},
			{
				cidade: 'Macaparana',
				id_cidade: 3286,
			},
			{
				cidade: 'Machados',
				id_cidade: 3287,
			},
			{
				cidade: 'Manari',
				id_cidade: 3288,
			},
			{
				cidade: 'Maraial',
				id_cidade: 3289,
			},
			{
				cidade: 'Belém de São Francisco',
				id_cidade: 3200,
			},
			{
				cidade: 'Mirandiba',
				id_cidade: 3290,
			},
			{
				cidade: 'Iguaraci',
				id_cidade: 3258,
			},
			{
				cidade: 'Lagoa do Itaenga',
				id_cidade: 3280,
			},
			{
				cidade: 'Moreilândia',
				id_cidade: 3291,
			},
			{
				cidade: 'Moreno',
				id_cidade: 3292,
			},
			{
				cidade: 'São Caitano',
				id_cidade: 3333,
			},
			{
				cidade: 'Nazaré da Mata',
				id_cidade: 3293,
			},
			{
				cidade: 'Olinda',
				id_cidade: 3294,
			},
			{
				cidade: 'Orobó',
				id_cidade: 3295,
			},
			{
				cidade: 'Orocó',
				id_cidade: 3296,
			},
			{
				cidade: 'Ouricuri',
				id_cidade: 3297,
			},
			{
				cidade: 'Palmares',
				id_cidade: 3298,
			},
			{
				cidade: 'Palmeirina',
				id_cidade: 3299,
			},
			{
				cidade: 'Panelas',
				id_cidade: 3300,
			},
			{
				cidade: 'Paranatama',
				id_cidade: 3301,
			},
			{
				cidade: 'Parnamirim',
				id_cidade: 3302,
			},
			{
				cidade: 'Passira',
				id_cidade: 3303,
			},
			{
				cidade: 'Paudalho',
				id_cidade: 3304,
			},
			{
				cidade: 'Paulista',
				id_cidade: 3305,
			},
			{
				cidade: 'Pedra',
				id_cidade: 3306,
			},
			{
				cidade: 'Pesqueira',
				id_cidade: 3307,
			},
			{
				cidade: 'Petrolândia',
				id_cidade: 3308,
			},
			{
				cidade: 'Petrolina',
				id_cidade: 3309,
			},
			{
				cidade: 'Poção',
				id_cidade: 3310,
			},
			{
				cidade: 'Pombos',
				id_cidade: 3311,
			},
			{
				cidade: 'Primavera',
				id_cidade: 3312,
			},
			{
				cidade: 'Quipapá',
				id_cidade: 3313,
			},
			{
				cidade: 'Quixaba',
				id_cidade: 3314,
			},
			{
				cidade: 'Recife',
				id_cidade: 3315,
			},
			{
				cidade: 'Riacho das Almas',
				id_cidade: 3316,
			},
			{
				cidade: 'Ribeirão',
				id_cidade: 3317,
			},
			{
				cidade: 'Rio Formoso',
				id_cidade: 3318,
			},
			{
				cidade: 'Sairé',
				id_cidade: 3319,
			},
			{
				cidade: 'Salgadinho',
				id_cidade: 3320,
			},
			{
				cidade: 'Salgueiro',
				id_cidade: 3321,
			},
			{
				cidade: 'Saloá',
				id_cidade: 3322,
			},
			{
				cidade: 'Sanharó',
				id_cidade: 3323,
			},
			{
				cidade: 'Santa Cruz',
				id_cidade: 3324,
			},
			{
				cidade: 'Santa Cruz da Baixa Verde',
				id_cidade: 3325,
			},
			{
				cidade: 'Santa Cruz do Capibaribe',
				id_cidade: 3326,
			},
			{
				cidade: 'Santa Filomena',
				id_cidade: 3327,
			},
			{
				cidade: 'Santa Maria da Boa Vista',
				id_cidade: 3328,
			},
			{
				cidade: 'Santa Maria do Cambucá',
				id_cidade: 3329,
			},
			{
				cidade: 'Abreu e Lima',
				id_cidade: 3183,
			},
			{
				cidade: 'Afogados da Ingazeira',
				id_cidade: 3184,
			},
			{
				cidade: 'Afrânio',
				id_cidade: 3185,
			},
			{
				cidade: 'Agrestina',
				id_cidade: 3186,
			},
			{
				cidade: 'Água Preta',
				id_cidade: 3187,
			},
			{
				cidade: 'Águas Belas',
				id_cidade: 3188,
			},
			{
				cidade: 'Altinho',
				id_cidade: 3191,
			},
			{
				cidade: 'Amaraji',
				id_cidade: 3192,
			},
			{
				cidade: 'Santa Terezinha',
				id_cidade: 3330,
			},
			{
				cidade: 'São Benedito do Sul',
				id_cidade: 3331,
			},
			{
				cidade: 'São Bento do Una',
				id_cidade: 3332,
			},
			{
				cidade: 'São João',
				id_cidade: 3334,
			},
			{
				cidade: 'São Joaquim do Monte',
				id_cidade: 3335,
			},
			{
				cidade: 'São José da Coroa Grande',
				id_cidade: 3336,
			},
			{
				cidade: 'São José do Belmonte',
				id_cidade: 3337,
			},
			{
				cidade: 'São José do Egito',
				id_cidade: 3338,
			},
			{
				cidade: 'Angelim',
				id_cidade: 3193,
			},
			{
				cidade: 'São Lourenço da Mata',
				id_cidade: 3339,
			},
			{
				cidade: 'São Vicente Ferrer',
				id_cidade: 3340,
			},
			{
				cidade: 'Serra Talhada',
				id_cidade: 3341,
			},
			{
				cidade: 'Serrita',
				id_cidade: 3342,
			},
			{
				cidade: 'Sertânia',
				id_cidade: 3343,
			},
			{
				cidade: 'Sirinhaém',
				id_cidade: 3344,
			},
			{
				cidade: 'Solidão',
				id_cidade: 3345,
			},
			{
				cidade: 'Surubim',
				id_cidade: 3346,
			},
			{
				cidade: 'Tabira',
				id_cidade: 3347,
			},
			{
				cidade: 'Tacaimbó',
				id_cidade: 3348,
			},
			{
				cidade: 'Tacaratu',
				id_cidade: 3349,
			},
			{
				cidade: 'Tamandaré',
				id_cidade: 3350,
			},
			{
				cidade: 'Taquaritinga do Norte',
				id_cidade: 3351,
			},
			{
				cidade: 'Araçoiaba',
				id_cidade: 3194,
			},
			{
				cidade: 'Araripina',
				id_cidade: 3195,
			},
			{
				cidade: 'Terezinha',
				id_cidade: 3352,
			},
			{
				cidade: 'Terra Nova',
				id_cidade: 3353,
			},
			{
				cidade: 'Timbaúba',
				id_cidade: 3354,
			},
			{
				cidade: 'Toritama',
				id_cidade: 3355,
			},
			{
				cidade: 'Tracunhaém',
				id_cidade: 3356,
			},
			{
				cidade: 'Trindade',
				id_cidade: 3357,
			},
			{
				cidade: 'Triunfo',
				id_cidade: 3358,
			},
			{
				cidade: 'Tupanatinga',
				id_cidade: 3359,
			},
			{
				cidade: 'Tuparetama',
				id_cidade: 3360,
			},
			{
				cidade: 'Venturosa',
				id_cidade: 3361,
			},
			{
				cidade: 'Verdejante',
				id_cidade: 3362,
			},
			{
				cidade: 'Vertente do Lério',
				id_cidade: 3363,
			},
			{
				cidade: 'Vertentes',
				id_cidade: 3364,
			},
			{
				cidade: 'Vicência',
				id_cidade: 3365,
			},
			{
				cidade: 'Vitória de Santo Antão',
				id_cidade: 3366,
			},
			{
				cidade: 'Xexéu',
				id_cidade: 3367,
			},
		],
	},
	{
		estado: 'Piauí',
		sigla: 'PI',
		id_estado: 17,
		cidades: [
			{
				cidade: 'Alagoinha do Piauí',
				id_cidade: 3371,
			},
			{
				cidade: 'Alegrete do Piauí',
				id_cidade: 3372,
			},
			{
				cidade: 'Aroazes',
				id_cidade: 3380,
			},
			{
				cidade: 'Aroeiras do Itaim',
				id_cidade: 3381,
			},
			{
				cidade: 'Arraial',
				id_cidade: 3382,
			},
			{
				cidade: 'Assunção do Piauí',
				id_cidade: 3383,
			},
			{
				cidade: 'Avelino Lopes',
				id_cidade: 3384,
			},
			{
				cidade: 'Baixa Grande do Ribeiro',
				id_cidade: 3385,
			},
			{
				cidade: 'Barras',
				id_cidade: 3387,
			},
			{
				cidade: 'Barreiras do Piauí',
				id_cidade: 3388,
			},
			{
				cidade: 'Barro Duro',
				id_cidade: 3389,
			},
			{
				cidade: 'Batalha',
				id_cidade: 3390,
			},
			{
				cidade: 'Bela Vista do Piauí',
				id_cidade: 3391,
			},
			{
				cidade: 'Belém do Piauí',
				id_cidade: 3392,
			},
			{
				cidade: 'Beneditinos',
				id_cidade: 3393,
			},
			{
				cidade: 'Bertolínia',
				id_cidade: 3394,
			},
			{
				cidade: 'Betânia do Piauí',
				id_cidade: 3395,
			},
			{
				cidade: 'Boa Hora',
				id_cidade: 3396,
			},
			{
				cidade: 'Bocaina',
				id_cidade: 3397,
			},
			{
				cidade: 'Bom Jesus',
				id_cidade: 3398,
			},
			{
				cidade: 'Bom Princípio do Piauí',
				id_cidade: 3399,
			},
			{
				cidade: 'Bonfim do Piauí',
				id_cidade: 3400,
			},
			{
				cidade: 'Boqueirão do Piauí',
				id_cidade: 3401,
			},
			{
				cidade: 'Brasileira',
				id_cidade: 3402,
			},
			{
				cidade: 'Brejo do Piauí',
				id_cidade: 3403,
			},
			{
				cidade: 'Buriti dos Lopes',
				id_cidade: 3404,
			},
			{
				cidade: 'Buriti dos Montes',
				id_cidade: 3405,
			},
			{
				cidade: 'Cabeceiras do Piauí',
				id_cidade: 3406,
			},
			{
				cidade: 'Cajazeiras do Piauí',
				id_cidade: 3407,
			},
			{
				cidade: 'Cajueiro da Praia',
				id_cidade: 3408,
			},
			{
				cidade: 'Caldeirão Grande do Piauí',
				id_cidade: 3409,
			},
			{
				cidade: 'Campinas do Piauí',
				id_cidade: 3410,
			},
			{
				cidade: 'Campo Alegre do Fidalgo',
				id_cidade: 3411,
			},
			{
				cidade: 'Campo Grande do Piauí',
				id_cidade: 3412,
			},
			{
				cidade: 'Campo Largo do Piauí',
				id_cidade: 3413,
			},
			{
				cidade: 'Campo Maior',
				id_cidade: 3414,
			},
			{
				cidade: 'Canavieira',
				id_cidade: 3415,
			},
			{
				cidade: 'Canto do Buriti',
				id_cidade: 3416,
			},
			{
				cidade: 'Capitão de Campos',
				id_cidade: 3417,
			},
			{
				cidade: 'Capitão Gervásio Oliveira',
				id_cidade: 3418,
			},
			{
				cidade: 'Caracol',
				id_cidade: 3419,
			},
			{
				cidade: 'Caraúbas do Piauí',
				id_cidade: 3420,
			},
			{
				cidade: 'Caridade do Piauí',
				id_cidade: 3421,
			},
			{
				cidade: 'Castelo do Piauí',
				id_cidade: 3422,
			},
			{
				cidade: 'Caxingó',
				id_cidade: 3423,
			},
			{
				cidade: 'Cocal',
				id_cidade: 3424,
			},
			{
				cidade: 'Cocal de Telha',
				id_cidade: 3425,
			},
			{
				cidade: 'Cocal dos Alves',
				id_cidade: 3426,
			},
			{
				cidade: 'Coivaras',
				id_cidade: 3427,
			},
			{
				cidade: 'Colônia do Gurguéia',
				id_cidade: 3428,
			},
			{
				cidade: 'Colônia do Piauí',
				id_cidade: 3429,
			},
			{
				cidade: 'Conceição do Canindé',
				id_cidade: 3430,
			},
			{
				cidade: 'Coronel José Dias',
				id_cidade: 3431,
			},
			{
				cidade: 'Corrente',
				id_cidade: 3432,
			},
			{
				cidade: 'Cristalândia do Piauí',
				id_cidade: 3433,
			},
			{
				cidade: 'Cristino Castro',
				id_cidade: 3434,
			},
			{
				cidade: 'Curimatá',
				id_cidade: 3435,
			},
			{
				cidade: 'Currais',
				id_cidade: 3436,
			},
			{
				cidade: 'Curralinhos',
				id_cidade: 3438,
			},
			{
				cidade: 'Curral Novo do Piauí',
				id_cidade: 3437,
			},
			{
				cidade: 'Demerval Lobão',
				id_cidade: 3439,
			},
			{
				cidade: 'Dirceu Arcoverde',
				id_cidade: 3440,
			},
			{
				cidade: 'Dom Expedito Lopes',
				id_cidade: 3441,
			},
			{
				cidade: 'Domingos Mourão',
				id_cidade: 3443,
			},
			{
				cidade: 'Dom Inocêncio',
				id_cidade: 3442,
			},
			{
				cidade: 'Elesbão Veloso',
				id_cidade: 3444,
			},
			{
				cidade: 'Eliseu Martins',
				id_cidade: 3445,
			},
			{
				cidade: 'Esperantina',
				id_cidade: 3446,
			},
			{
				cidade: 'Fartura do Piauí',
				id_cidade: 3447,
			},
			{
				cidade: 'Flores do Piauí',
				id_cidade: 3448,
			},
			{
				cidade: 'Floresta do Piauí',
				id_cidade: 3449,
			},
			{
				cidade: 'Floriano',
				id_cidade: 3450,
			},
			{
				cidade: 'Francinópolis',
				id_cidade: 3451,
			},
			{
				cidade: 'Francisco Ayres',
				id_cidade: 3452,
			},
			{
				cidade: 'Francisco Macedo',
				id_cidade: 3453,
			},
			{
				cidade: 'Francisco Santos',
				id_cidade: 3454,
			},
			{
				cidade: 'Fronteiras',
				id_cidade: 3455,
			},
			{
				cidade: 'Geminiano',
				id_cidade: 3456,
			},
			{
				cidade: 'Gilbués',
				id_cidade: 3457,
			},
			{
				cidade: 'Guadalupe',
				id_cidade: 3458,
			},
			{
				cidade: 'Guaribas',
				id_cidade: 3459,
			},
			{
				cidade: 'Hugo Napoleão',
				id_cidade: 3460,
			},
			{
				cidade: 'Ilha Grande',
				id_cidade: 3461,
			},
			{
				cidade: 'Inhuma',
				id_cidade: 3462,
			},
			{
				cidade: 'Ipiranga do Piauí',
				id_cidade: 3463,
			},
			{
				cidade: 'Isaías Coelho',
				id_cidade: 3464,
			},
			{
				cidade: 'Itainópolis',
				id_cidade: 3465,
			},
			{
				cidade: 'Itaueira',
				id_cidade: 3466,
			},
			{
				cidade: 'Água Branca',
				id_cidade: 3370,
			},
			{
				cidade: 'Jacobina do Piauí',
				id_cidade: 3467,
			},
			{
				cidade: 'Jaicós',
				id_cidade: 3468,
			},
			{
				cidade: 'Jardim do Mulato',
				id_cidade: 3469,
			},
			{
				cidade: 'Jatobá do Piauí',
				id_cidade: 3470,
			},
			{
				cidade: 'Jerumenha',
				id_cidade: 3471,
			},
			{
				cidade: 'João Costa',
				id_cidade: 3472,
			},
			{
				cidade: 'Joaquim Pires',
				id_cidade: 3473,
			},
			{
				cidade: 'Joca Marques',
				id_cidade: 3474,
			},
			{
				cidade: 'José de Freitas',
				id_cidade: 3475,
			},
			{
				cidade: 'Juazeiro do Piauí',
				id_cidade: 3476,
			},
			{
				cidade: 'Júlio Borges',
				id_cidade: 3477,
			},
			{
				cidade: 'Jurema',
				id_cidade: 3478,
			},
			{
				cidade: 'Lagoa Alegre',
				id_cidade: 3479,
			},
			{
				cidade: 'Lagoa de São Francisco',
				id_cidade: 3480,
			},
			{
				cidade: 'Lagoa do Barro do Piauí',
				id_cidade: 3481,
			},
			{
				cidade: 'Lagoa do Piauí',
				id_cidade: 3482,
			},
			{
				cidade: 'Lagoa do Sítio',
				id_cidade: 3483,
			},
			{
				cidade: 'Lagoinha do Piauí',
				id_cidade: 3484,
			},
			{
				cidade: 'Landri Sales',
				id_cidade: 3485,
			},
			{
				cidade: 'Luís Correia',
				id_cidade: 3486,
			},
			{
				cidade: 'Luzilândia',
				id_cidade: 3487,
			},
			{
				cidade: 'Madeiro',
				id_cidade: 3488,
			},
			{
				cidade: 'Manoel Emídio',
				id_cidade: 3489,
			},
			{
				cidade: 'Marcolândia',
				id_cidade: 3490,
			},
			{
				cidade: 'Marcos Parente',
				id_cidade: 3491,
			},
			{
				cidade: 'Massapê do Piauí',
				id_cidade: 3492,
			},
			{
				cidade: 'Matias Olímpio',
				id_cidade: 3493,
			},
			{
				cidade: 'Miguel Alves',
				id_cidade: 3494,
			},
			{
				cidade: 'Miguel Leão',
				id_cidade: 3495,
			},
			{
				cidade: 'Milton Brandão',
				id_cidade: 3496,
			},
			{
				cidade: 'Monsenhor Gil',
				id_cidade: 3497,
			},
			{
				cidade: 'Monsenhor Hipólito',
				id_cidade: 3498,
			},
			{
				cidade: 'Monte Alegre do Piauí',
				id_cidade: 3499,
			},
			{
				cidade: 'Morro Cabeça no Tempo',
				id_cidade: 3500,
			},
			{
				cidade: 'Morro do Chapéu do Piauí',
				id_cidade: 3501,
			},
			{
				cidade: 'Murici dos Portelas',
				id_cidade: 3502,
			},
			{
				cidade: "Barra d'Alcântara",
				id_cidade: 3386,
			},
			{
				cidade: 'Nazaré do Piauí',
				id_cidade: 3503,
			},
			{
				cidade: 'Nossa Senhora de Nazaré',
				id_cidade: 3504,
			},
			{
				cidade: 'Nossa Senhora dos Remédios',
				id_cidade: 3505,
			},
			{
				cidade: "Olho d'Água do Piauí",
				id_cidade: 3510,
			},
			{
				cidade: "Pau d'Arco do Piauí",
				id_cidade: 3521,
			},
			{
				cidade: 'Nova Santa Rita',
				id_cidade: 3506,
			},
			{
				cidade: 'Novo Oriente do Piauí',
				id_cidade: 3507,
			},
			{
				cidade: 'Novo Santo Antônio',
				id_cidade: 3508,
			},
			{
				cidade: 'Oeiras',
				id_cidade: 3509,
			},
			{
				cidade: 'Padre Marcos',
				id_cidade: 3511,
			},
			{
				cidade: 'Paes Landim',
				id_cidade: 3512,
			},
			{
				cidade: 'Pajeú do Piauí',
				id_cidade: 3513,
			},
			{
				cidade: 'Palmeira do Piauí',
				id_cidade: 3514,
			},
			{
				cidade: 'Palmeirais',
				id_cidade: 3515,
			},
			{
				cidade: 'Paquetá',
				id_cidade: 3516,
			},
			{
				cidade: 'Parnaguá',
				id_cidade: 3517,
			},
			{
				cidade: 'Parnaíba',
				id_cidade: 3518,
			},
			{
				cidade: 'Passagem Franca do Piauí',
				id_cidade: 3519,
			},
			{
				cidade: 'Patos do Piauí',
				id_cidade: 3520,
			},
			{
				cidade: 'Paulistana',
				id_cidade: 3522,
			},
			{
				cidade: 'Pavussu',
				id_cidade: 3523,
			},
			{
				cidade: 'Pedro II',
				id_cidade: 3524,
			},
			{
				cidade: 'Pedro Laurentino',
				id_cidade: 3525,
			},
			{
				cidade: 'Picos',
				id_cidade: 3526,
			},
			{
				cidade: 'Pimenteiras',
				id_cidade: 3527,
			},
			{
				cidade: 'Pio IX',
				id_cidade: 3528,
			},
			{
				cidade: 'Piracuruca',
				id_cidade: 3529,
			},
			{
				cidade: 'Piripiri',
				id_cidade: 3530,
			},
			{
				cidade: 'Porto',
				id_cidade: 3531,
			},
			{
				cidade: 'Porto Alegre do Piauí',
				id_cidade: 3532,
			},
			{
				cidade: 'Prata do Piauí',
				id_cidade: 3533,
			},
			{
				cidade: 'Queimada Nova',
				id_cidade: 3534,
			},
			{
				cidade: 'Redenção do Gurguéia',
				id_cidade: 3535,
			},
			{
				cidade: 'Regeneração',
				id_cidade: 3536,
			},
			{
				cidade: 'Riacho Frio',
				id_cidade: 3537,
			},
			{
				cidade: 'Ribeira do Piauí',
				id_cidade: 3538,
			},
			{
				cidade: 'Ribeiro Gonçalves',
				id_cidade: 3539,
			},
			{
				cidade: 'Rio Grande do Piauí',
				id_cidade: 3540,
			},
			{
				cidade: 'Santa Cruz do Piauí',
				id_cidade: 3541,
			},
			{
				cidade: 'Santa Cruz dos Milagres',
				id_cidade: 3542,
			},
			{
				cidade: 'Santa Filomena',
				id_cidade: 3543,
			},
			{
				cidade: 'Santa Luz',
				id_cidade: 3544,
			},
			{
				cidade: 'Santana do Piauí',
				id_cidade: 3546,
			},
			{
				cidade: 'Acauã',
				id_cidade: 3368,
			},
			{
				cidade: 'Agricolândia',
				id_cidade: 3369,
			},
			{
				cidade: 'Alto Longá',
				id_cidade: 3373,
			},
			{
				cidade: 'Altos',
				id_cidade: 3374,
			},
			{
				cidade: 'Alvorada do Gurguéia',
				id_cidade: 3375,
			},
			{
				cidade: 'Amarante',
				id_cidade: 3376,
			},
			{
				cidade: 'Santa Rosa do Piauí',
				id_cidade: 3545,
			},
			{
				cidade: 'Santo Antônio de Lisboa',
				id_cidade: 3547,
			},
			{
				cidade: 'Santo Antônio dos Milagres',
				id_cidade: 3548,
			},
			{
				cidade: 'Santo Inácio do Piauí',
				id_cidade: 3549,
			},
			{
				cidade: 'São Braz do Piauí',
				id_cidade: 3550,
			},
			{
				cidade: 'São Félix do Piauí',
				id_cidade: 3551,
			},
			{
				cidade: 'São Francisco de Assis do Piauí',
				id_cidade: 3552,
			},
			{
				cidade: 'São Francisco do Piauí',
				id_cidade: 3553,
			},
			{
				cidade: 'São Gonçalo do Gurguéia',
				id_cidade: 3554,
			},
			{
				cidade: 'São Gonçalo do Piauí',
				id_cidade: 3555,
			},
			{
				cidade: 'São João da Canabrava',
				id_cidade: 3556,
			},
			{
				cidade: 'São João da Fronteira',
				id_cidade: 3557,
			},
			{
				cidade: 'São João da Serra',
				id_cidade: 3558,
			},
			{
				cidade: 'São João da Varjota',
				id_cidade: 3559,
			},
			{
				cidade: 'São João do Arraial',
				id_cidade: 3560,
			},
			{
				cidade: 'São João do Piauí',
				id_cidade: 3561,
			},
			{
				cidade: 'São José do Divino',
				id_cidade: 3562,
			},
			{
				cidade: 'São José do Peixe',
				id_cidade: 3563,
			},
			{
				cidade: 'São José do Piauí',
				id_cidade: 3564,
			},
			{
				cidade: 'Angical do Piauí',
				id_cidade: 3377,
			},
			{
				cidade: 'São Julião',
				id_cidade: 3565,
			},
			{
				cidade: 'São Lourenço do Piauí',
				id_cidade: 3566,
			},
			{
				cidade: 'São Luis do Piauí',
				id_cidade: 3567,
			},
			{
				cidade: 'São Miguel da Baixa Grande',
				id_cidade: 3568,
			},
			{
				cidade: 'São Miguel do Fidalgo',
				id_cidade: 3569,
			},
			{
				cidade: 'São Miguel do Tapuio',
				id_cidade: 3570,
			},
			{
				cidade: 'São Pedro do Piauí',
				id_cidade: 3571,
			},
			{
				cidade: 'São Raimundo Nonato',
				id_cidade: 3572,
			},
			{
				cidade: 'Sebastião Barros',
				id_cidade: 3573,
			},
			{
				cidade: 'Sebastião Leal',
				id_cidade: 3574,
			},
			{
				cidade: 'Anísio de Abreu',
				id_cidade: 3378,
			},
			{
				cidade: 'Antônio Almeida',
				id_cidade: 3379,
			},
			{
				cidade: 'Sigefredo Pacheco',
				id_cidade: 3575,
			},
			{
				cidade: 'Simões',
				id_cidade: 3576,
			},
			{
				cidade: 'Simplício Mendes',
				id_cidade: 3577,
			},
			{
				cidade: 'Socorro do Piauí',
				id_cidade: 3578,
			},
			{
				cidade: 'Sussuapara',
				id_cidade: 3579,
			},
			{
				cidade: 'Tamboril do Piauí',
				id_cidade: 3580,
			},
			{
				cidade: 'Tanque do Piauí',
				id_cidade: 3581,
			},
			{
				cidade: 'Teresina',
				id_cidade: 3582,
			},
			{
				cidade: 'União',
				id_cidade: 3583,
			},
			{
				cidade: 'Uruçuí',
				id_cidade: 3584,
			},
			{
				cidade: 'Valença do Piauí',
				id_cidade: 3585,
			},
			{
				cidade: 'Várzea Branca',
				id_cidade: 3586,
			},
			{
				cidade: 'Várzea Grande',
				id_cidade: 3587,
			},
			{
				cidade: 'Vera Mendes',
				id_cidade: 3588,
			},
			{
				cidade: 'Vila Nova do Piauí',
				id_cidade: 3589,
			},
			{
				cidade: 'Wall Ferraz',
				id_cidade: 3590,
			},
		],
	},
	{
		estado: 'Paraná',
		sigla: 'PR',
		id_estado: 18,
		cidades: [
			{
				cidade: 'Agudos do Sul',
				id_cidade: 2786,
			},
			{
				cidade: 'Almirante Tamandaré',
				id_cidade: 2787,
			},
			{
				cidade: 'Araucária',
				id_cidade: 2806,
			},
			{
				cidade: 'Ariranha do Ivaí',
				id_cidade: 2807,
			},
			{
				cidade: 'Astorga',
				id_cidade: 2810,
			},
			{
				cidade: 'Atalaia',
				id_cidade: 2811,
			},
			{
				cidade: 'Balsa Nova',
				id_cidade: 2812,
			},
			{
				cidade: 'Bandeirantes',
				id_cidade: 2813,
			},
			{
				cidade: 'Barbosa Ferraz',
				id_cidade: 2814,
			},
			{
				cidade: 'Barracão',
				id_cidade: 2816,
			},
			{
				cidade: 'Barra do Jacaré',
				id_cidade: 2815,
			},
			{
				cidade: 'Bela Vista da Caroba',
				id_cidade: 2817,
			},
			{
				cidade: 'Bela Vista do Paraíso',
				id_cidade: 2818,
			},
			{
				cidade: 'Bituruna',
				id_cidade: 2819,
			},
			{
				cidade: 'Boa Esperança',
				id_cidade: 2820,
			},
			{
				cidade: 'Boa Esperança do Iguaçu',
				id_cidade: 2821,
			},
			{
				cidade: 'Boa Ventura de São Roque',
				id_cidade: 2822,
			},
			{
				cidade: 'Boa Vista da Aparecida',
				id_cidade: 2823,
			},
			{
				cidade: 'Bocaiúva do Sul',
				id_cidade: 2824,
			},
			{
				cidade: 'Bom Jesus do Sul',
				id_cidade: 2825,
			},
			{
				cidade: 'Bom Sucesso',
				id_cidade: 2826,
			},
			{
				cidade: 'Bom Sucesso do Sul',
				id_cidade: 2827,
			},
			{
				cidade: 'Borrazópolis',
				id_cidade: 2828,
			},
			{
				cidade: 'Braganey',
				id_cidade: 2829,
			},
			{
				cidade: 'Brasilândia do Sul',
				id_cidade: 2830,
			},
			{
				cidade: 'Cafeara',
				id_cidade: 2831,
			},
			{
				cidade: 'Cafelândia',
				id_cidade: 2832,
			},
			{
				cidade: 'Cafezal do Sul',
				id_cidade: 2833,
			},
			{
				cidade: 'Califórnia',
				id_cidade: 2834,
			},
			{
				cidade: 'Cambará',
				id_cidade: 2835,
			},
			{
				cidade: 'Cambé',
				id_cidade: 2836,
			},
			{
				cidade: 'Cambira',
				id_cidade: 2837,
			},
			{
				cidade: 'Campina da Lagoa',
				id_cidade: 2838,
			},
			{
				cidade: 'Campina do Simão',
				id_cidade: 2839,
			},
			{
				cidade: 'Campina Grande do Sul',
				id_cidade: 2840,
			},
			{
				cidade: 'Campo Bonito',
				id_cidade: 2841,
			},
			{
				cidade: 'Campo do Tenente',
				id_cidade: 2842,
			},
			{
				cidade: 'Campo Largo',
				id_cidade: 2843,
			},
			{
				cidade: 'Campo Magro',
				id_cidade: 2844,
			},
			{
				cidade: 'Campo Mourão',
				id_cidade: 2845,
			},
			{
				cidade: 'Cândido de Abreu',
				id_cidade: 2846,
			},
			{
				cidade: 'Candói',
				id_cidade: 2847,
			},
			{
				cidade: 'Cantagalo',
				id_cidade: 2848,
			},
			{
				cidade: 'Capanema',
				id_cidade: 2849,
			},
			{
				cidade: 'Capitão Leônidas Marques',
				id_cidade: 2850,
			},
			{
				cidade: 'Carambeí',
				id_cidade: 2851,
			},
			{
				cidade: 'Carlópolis',
				id_cidade: 2852,
			},
			{
				cidade: 'Cascavel',
				id_cidade: 2853,
			},
			{
				cidade: 'Castro',
				id_cidade: 2854,
			},
			{
				cidade: 'Catanduvas',
				id_cidade: 2855,
			},
			{
				cidade: 'Centenário do Sul',
				id_cidade: 2856,
			},
			{
				cidade: 'Cerro Azul',
				id_cidade: 2857,
			},
			{
				cidade: 'Céu Azul',
				id_cidade: 2858,
			},
			{
				cidade: 'Chopinzinho',
				id_cidade: 2859,
			},
			{
				cidade: 'Amaporã',
				id_cidade: 2794,
			},
			{
				cidade: 'Cianorte',
				id_cidade: 2860,
			},
			{
				cidade: 'Cidade Gaúcha',
				id_cidade: 2861,
			},
			{
				cidade: 'Clevelândia',
				id_cidade: 2862,
			},
			{
				cidade: 'Colombo',
				id_cidade: 2863,
			},
			{
				cidade: 'Colorado',
				id_cidade: 2864,
			},
			{
				cidade: 'Congonhinhas',
				id_cidade: 2865,
			},
			{
				cidade: 'Conselheiro Mairinck',
				id_cidade: 2866,
			},
			{
				cidade: 'Contenda',
				id_cidade: 2867,
			},
			{
				cidade: 'Corbélia',
				id_cidade: 2868,
			},
			{
				cidade: 'Cornélio Procópio',
				id_cidade: 2869,
			},
			{
				cidade: 'Coronel Domingos Soares',
				id_cidade: 2870,
			},
			{
				cidade: 'Coronel Vivida',
				id_cidade: 2871,
			},
			{
				cidade: 'Corumbataí do Sul',
				id_cidade: 2872,
			},
			{
				cidade: 'Cruzeiro do Iguaçu',
				id_cidade: 2874,
			},
			{
				cidade: 'Cruzeiro do Oeste',
				id_cidade: 2875,
			},
			{
				cidade: 'Cruzeiro do Sul',
				id_cidade: 2876,
			},
			{
				cidade: 'Cruz Machado',
				id_cidade: 2873,
			},
			{
				cidade: 'Cruzmaltina',
				id_cidade: 2877,
			},
			{
				cidade: 'Curitiba',
				id_cidade: 2878,
			},
			{
				cidade: 'Curiúva',
				id_cidade: 2879,
			},
			{
				cidade: 'Diamante do Norte',
				id_cidade: 2881,
			},
			{
				cidade: 'Diamante do Sul',
				id_cidade: 2882,
			},
			{
				cidade: 'Dois Vizinhos',
				id_cidade: 2883,
			},
			{
				cidade: 'Douradina',
				id_cidade: 2884,
			},
			{
				cidade: 'Doutor Camargo',
				id_cidade: 2885,
			},
			{
				cidade: 'Doutor Ulysses',
				id_cidade: 2886,
			},
			{
				cidade: 'Enéas Marques',
				id_cidade: 2887,
			},
			{
				cidade: 'Engenheiro Beltrão',
				id_cidade: 2888,
			},
			{
				cidade: 'Entre Rios do Oeste',
				id_cidade: 2889,
			},
			{
				cidade: 'Esperança Nova',
				id_cidade: 2890,
			},
			{
				cidade: 'Espigão Alto do Iguaçu',
				id_cidade: 2891,
			},
			{
				cidade: 'Farol',
				id_cidade: 2892,
			},
			{
				cidade: 'Faxinal',
				id_cidade: 2893,
			},
			{
				cidade: 'Fazenda Rio Grande',
				id_cidade: 2894,
			},
			{
				cidade: 'Fênix',
				id_cidade: 2895,
			},
			{
				cidade: 'Fernandes Pinheiro',
				id_cidade: 2896,
			},
			{
				cidade: 'Figueira',
				id_cidade: 2897,
			},
			{
				cidade: 'Floraí',
				id_cidade: 2899,
			},
			{
				cidade: 'Flor da Serra do Sul',
				id_cidade: 2898,
			},
			{
				cidade: 'Floresta',
				id_cidade: 2900,
			},
			{
				cidade: 'Florestópolis',
				id_cidade: 2901,
			},
			{
				cidade: 'Flórida',
				id_cidade: 2902,
			},
			{
				cidade: 'Formosa do Oeste',
				id_cidade: 2903,
			},
			{
				cidade: 'Foz do Iguaçu',
				id_cidade: 2904,
			},
			{
				cidade: 'Foz do Jordão',
				id_cidade: 2905,
			},
			{
				cidade: 'Francisco Alves',
				id_cidade: 2906,
			},
			{
				cidade: 'Francisco Beltrão',
				id_cidade: 2907,
			},
			{
				cidade: 'General Carneiro',
				id_cidade: 2908,
			},
			{
				cidade: 'Godoy Moreira',
				id_cidade: 2909,
			},
			{
				cidade: 'Goioerê',
				id_cidade: 2910,
			},
			{
				cidade: 'Goioxim',
				id_cidade: 2911,
			},
			{
				cidade: 'Grandes Rios',
				id_cidade: 2912,
			},
			{
				cidade: 'Guaíra',
				id_cidade: 2913,
			},
			{
				cidade: 'Guairaçá',
				id_cidade: 2914,
			},
			{
				cidade: 'Guamiranga',
				id_cidade: 2915,
			},
			{
				cidade: 'Guapirama',
				id_cidade: 2916,
			},
			{
				cidade: 'Guaporema',
				id_cidade: 2917,
			},
			{
				cidade: 'Guaraci',
				id_cidade: 2918,
			},
			{
				cidade: 'Guaraniaçu',
				id_cidade: 2919,
			},
			{
				cidade: 'Guarapuava',
				id_cidade: 2920,
			},
			{
				cidade: 'Guaraqueçaba',
				id_cidade: 2921,
			},
			{
				cidade: 'Guaratuba',
				id_cidade: 2922,
			},
			{
				cidade: 'Honório Serpa',
				id_cidade: 2923,
			},
			{
				cidade: 'Ibaiti',
				id_cidade: 2924,
			},
			{
				cidade: 'Ibema',
				id_cidade: 2925,
			},
			{
				cidade: 'Ibiporã',
				id_cidade: 2926,
			},
			{
				cidade: 'Icaraíma',
				id_cidade: 2927,
			},
			{
				cidade: 'Iguaraçu',
				id_cidade: 2928,
			},
			{
				cidade: 'Iguatu',
				id_cidade: 2929,
			},
			{
				cidade: 'Imbaú',
				id_cidade: 2930,
			},
			{
				cidade: 'Imbituva',
				id_cidade: 2931,
			},
			{
				cidade: 'Inácio Martins',
				id_cidade: 2932,
			},
			{
				cidade: 'Inajá',
				id_cidade: 2933,
			},
			{
				cidade: 'Indianópolis',
				id_cidade: 2934,
			},
			{
				cidade: 'Ipiranga',
				id_cidade: 2935,
			},
			{
				cidade: 'Iporã',
				id_cidade: 2936,
			},
			{
				cidade: 'Iracema do Oeste',
				id_cidade: 2937,
			},
			{
				cidade: 'Irati',
				id_cidade: 2938,
			},
			{
				cidade: 'Iretama',
				id_cidade: 2939,
			},
			{
				cidade: 'Itaguajé',
				id_cidade: 2940,
			},
			{
				cidade: 'Itaipulândia',
				id_cidade: 2941,
			},
			{
				cidade: 'Itambaracá',
				id_cidade: 2942,
			},
			{
				cidade: 'Itambé',
				id_cidade: 2943,
			},
			{
				cidade: "Itapejara d'Oeste",
				id_cidade: 2944,
			},
			{
				cidade: 'Itaperuçu',
				id_cidade: 2945,
			},
			{
				cidade: 'Itaúna do Sul',
				id_cidade: 2946,
			},
			{
				cidade: 'Ivaí',
				id_cidade: 2947,
			},
			{
				cidade: 'Ivaiporã',
				id_cidade: 2948,
			},
			{
				cidade: 'Ivaté',
				id_cidade: 2949,
			},
			{
				cidade: 'Ivatuba',
				id_cidade: 2950,
			},
			{
				cidade: 'Jaboti',
				id_cidade: 2951,
			},
			{
				cidade: 'Jacarezinho',
				id_cidade: 2952,
			},
			{
				cidade: 'Jaguapitã',
				id_cidade: 2953,
			},
			{
				cidade: 'Jaguariaíva',
				id_cidade: 2954,
			},
			{
				cidade: 'Jandaia do Sul',
				id_cidade: 2955,
			},
			{
				cidade: 'Janiópolis',
				id_cidade: 2956,
			},
			{
				cidade: 'Japira',
				id_cidade: 2957,
			},
			{
				cidade: 'Japurá',
				id_cidade: 2958,
			},
			{
				cidade: 'Jardim Alegre',
				id_cidade: 2959,
			},
			{
				cidade: 'Jardim Olinda',
				id_cidade: 2960,
			},
			{
				cidade: 'Jataizinho',
				id_cidade: 2961,
			},
			{
				cidade: 'Jesuítas',
				id_cidade: 2962,
			},
			{
				cidade: 'Joaquim Távora',
				id_cidade: 2963,
			},
			{
				cidade: 'Jundiaí do Sul',
				id_cidade: 2964,
			},
			{
				cidade: 'Juranda',
				id_cidade: 2965,
			},
			{
				cidade: 'Jussara',
				id_cidade: 2966,
			},
			{
				cidade: 'Kaloré',
				id_cidade: 2967,
			},
			{
				cidade: 'Alto Paraná',
				id_cidade: 2790,
			},
			{
				cidade: 'Lapa',
				id_cidade: 2968,
			},
			{
				cidade: 'Laranjal',
				id_cidade: 2969,
			},
			{
				cidade: 'Laranjeiras do Sul',
				id_cidade: 2970,
			},
			{
				cidade: 'Leópolis',
				id_cidade: 2971,
			},
			{
				cidade: 'Lidianópolis',
				id_cidade: 2972,
			},
			{
				cidade: 'Lindoeste',
				id_cidade: 2973,
			},
			{
				cidade: 'Lobato',
				id_cidade: 2975,
			},
			{
				cidade: 'Londrina',
				id_cidade: 2976,
			},
			{
				cidade: "Diamante d'Oeste",
				id_cidade: 2880,
			},
			{
				cidade: 'Luiziana',
				id_cidade: 2977,
			},
			{
				cidade: 'Lunardelli',
				id_cidade: 2978,
			},
			{
				cidade: 'Lupionópolis',
				id_cidade: 2979,
			},
			{
				cidade: 'Mallet',
				id_cidade: 2980,
			},
			{
				cidade: 'Mamborê',
				id_cidade: 2981,
			},
			{
				cidade: 'Mandaguaçu',
				id_cidade: 2982,
			},
			{
				cidade: 'Mandaguari',
				id_cidade: 2983,
			},
			{
				cidade: 'Mandirituba',
				id_cidade: 2984,
			},
			{
				cidade: 'Manfrinópolis',
				id_cidade: 2985,
			},
			{
				cidade: 'Mangueirinha',
				id_cidade: 2986,
			},
			{
				cidade: 'Manoel Ribas',
				id_cidade: 2987,
			},
			{
				cidade: 'Marechal Cândido Rondon',
				id_cidade: 2988,
			},
			{
				cidade: 'Maria Helena',
				id_cidade: 2989,
			},
			{
				cidade: 'Marialva',
				id_cidade: 2990,
			},
			{
				cidade: 'Marilândia do Sul',
				id_cidade: 2991,
			},
			{
				cidade: 'Marilena',
				id_cidade: 2992,
			},
			{
				cidade: 'Mariluz',
				id_cidade: 2993,
			},
			{
				cidade: 'Maringá',
				id_cidade: 2994,
			},
			{
				cidade: 'Mariópolis',
				id_cidade: 2995,
			},
			{
				cidade: 'Maripá',
				id_cidade: 2996,
			},
			{
				cidade: 'Marmeleiro',
				id_cidade: 2997,
			},
			{
				cidade: 'Marquinho',
				id_cidade: 2998,
			},
			{
				cidade: "Rancho Alegre d'Oeste",
				id_cidade: 3082,
			},
			{
				cidade: 'Marumbi',
				id_cidade: 2999,
			},
			{
				cidade: 'Matelândia',
				id_cidade: 3000,
			},
			{
				cidade: 'Matinhos',
				id_cidade: 3001,
			},
			{
				cidade: 'Mato Rico',
				id_cidade: 3002,
			},
			{
				cidade: 'Mauá da Serra',
				id_cidade: 3003,
			},
			{
				cidade: 'Medianeira',
				id_cidade: 3004,
			},
			{
				cidade: 'Mercedes',
				id_cidade: 3005,
			},
			{
				cidade: 'Mirador',
				id_cidade: 3006,
			},
			{
				cidade: 'Miraselva',
				id_cidade: 3007,
			},
			{
				cidade: 'Missal',
				id_cidade: 3008,
			},
			{
				cidade: 'Moreira Sales',
				id_cidade: 3009,
			},
			{
				cidade: 'Morretes',
				id_cidade: 3010,
			},
			{
				cidade: 'Munhoz de Melo',
				id_cidade: 3011,
			},
			{
				cidade: 'Nossa Senhora das Graças',
				id_cidade: 3012,
			},
			{
				cidade: 'Nova Aliança do Ivaí',
				id_cidade: 3013,
			},
			{
				cidade: 'Nova América da Colina',
				id_cidade: 3014,
			},
			{
				cidade: 'Nova Aurora',
				id_cidade: 3015,
			},
			{
				cidade: 'Nova Cantu',
				id_cidade: 3016,
			},
			{
				cidade: 'Nova Esperança',
				id_cidade: 3017,
			},
			{
				cidade: 'Nova Esperança do Sudoeste',
				id_cidade: 3018,
			},
			{
				cidade: 'Nova Fátima',
				id_cidade: 3019,
			},
			{
				cidade: 'Nova Laranjeiras',
				id_cidade: 3020,
			},
			{
				cidade: 'Nova Londrina',
				id_cidade: 3021,
			},
			{
				cidade: 'Nova Olímpia',
				id_cidade: 3022,
			},
			{
				cidade: 'Nova Prata do Iguaçu',
				id_cidade: 3023,
			},
			{
				cidade: 'Nova Santa Bárbara',
				id_cidade: 3024,
			},
			{
				cidade: 'Nova Santa Rosa',
				id_cidade: 3025,
			},
			{
				cidade: 'Nova Tebas',
				id_cidade: 3026,
			},
			{
				cidade: 'Novo Itacolomi',
				id_cidade: 3027,
			},
			{
				cidade: 'Ortigueira',
				id_cidade: 3028,
			},
			{
				cidade: 'Ourizona',
				id_cidade: 3029,
			},
			{
				cidade: 'Ouro Verde do Oeste',
				id_cidade: 3030,
			},
			{
				cidade: 'Paiçandu',
				id_cidade: 3031,
			},
			{
				cidade: 'Palmas',
				id_cidade: 3032,
			},
			{
				cidade: 'Palmeira',
				id_cidade: 3033,
			},
			{
				cidade: 'Palmital',
				id_cidade: 3034,
			},
			{
				cidade: 'Palotina',
				id_cidade: 3035,
			},
			{
				cidade: 'Paraíso do Norte',
				id_cidade: 3036,
			},
			{
				cidade: 'Paranacity',
				id_cidade: 3037,
			},
			{
				cidade: 'Paranaguá',
				id_cidade: 3038,
			},
			{
				cidade: 'Paranapoema',
				id_cidade: 3039,
			},
			{
				cidade: 'Paranavaí',
				id_cidade: 3040,
			},
			{
				cidade: 'Pato Bragado',
				id_cidade: 3041,
			},
			{
				cidade: 'Pato Branco',
				id_cidade: 3042,
			},
			{
				cidade: 'Paula Freitas',
				id_cidade: 3043,
			},
			{
				cidade: 'Paulo Frontin',
				id_cidade: 3044,
			},
			{
				cidade: 'Peabiru',
				id_cidade: 3045,
			},
			{
				cidade: 'Perobal',
				id_cidade: 3046,
			},
			{
				cidade: 'Pérola',
				id_cidade: 3047,
			},
			{
				cidade: "Pérola d'Oeste",
				id_cidade: 3048,
			},
			{
				cidade: 'Piên',
				id_cidade: 3049,
			},
			{
				cidade: 'Pinhais',
				id_cidade: 3050,
			},
			{
				cidade: 'Pinhalão',
				id_cidade: 3052,
			},
			{
				cidade: 'Pinhal de São Bento',
				id_cidade: 3051,
			},
			{
				cidade: 'Pinhão',
				id_cidade: 3053,
			},
			{
				cidade: 'Piraí do Sul',
				id_cidade: 3054,
			},
			{
				cidade: 'Piraquara',
				id_cidade: 3055,
			},
			{
				cidade: 'Pitanga',
				id_cidade: 3056,
			},
			{
				cidade: 'Pitangueiras',
				id_cidade: 3057,
			},
			{
				cidade: 'Planaltina do Paraná',
				id_cidade: 3058,
			},
			{
				cidade: 'Planalto',
				id_cidade: 3059,
			},
			{
				cidade: 'Ponta Grossa',
				id_cidade: 3060,
			},
			{
				cidade: 'Pontal do Paraná',
				id_cidade: 3061,
			},
			{
				cidade: 'Porecatu',
				id_cidade: 3062,
			},
			{
				cidade: 'Porto Amazonas',
				id_cidade: 3063,
			},
			{
				cidade: 'Porto Barreiro',
				id_cidade: 3064,
			},
			{
				cidade: 'Porto Rico',
				id_cidade: 3065,
			},
			{
				cidade: 'Porto Vitória',
				id_cidade: 3066,
			},
			{
				cidade: 'Prado Ferreira',
				id_cidade: 3067,
			},
			{
				cidade: 'Pranchita',
				id_cidade: 3068,
			},
			{
				cidade: 'Presidente Castelo Branco',
				id_cidade: 3069,
			},
			{
				cidade: 'Primeiro de Maio',
				id_cidade: 3070,
			},
			{
				cidade: 'Prudentópolis',
				id_cidade: 3071,
			},
			{
				cidade: 'Quarto Centenário',
				id_cidade: 3072,
			},
			{
				cidade: 'Quatiguá',
				id_cidade: 3073,
			},
			{
				cidade: 'Quatro Barras',
				id_cidade: 3074,
			},
			{
				cidade: 'Quatro Pontes',
				id_cidade: 3075,
			},
			{
				cidade: 'Quedas do Iguaçu',
				id_cidade: 3076,
			},
			{
				cidade: 'Querência do Norte',
				id_cidade: 3077,
			},
			{
				cidade: 'Quinta do Sol',
				id_cidade: 3078,
			},
			{
				cidade: 'Quitandinha',
				id_cidade: 3079,
			},
			{
				cidade: 'Ramilândia',
				id_cidade: 3080,
			},
			{
				cidade: 'Rancho Alegre',
				id_cidade: 3081,
			},
			{
				cidade: 'Realeza',
				id_cidade: 3083,
			},
			{
				cidade: 'Rebouças',
				id_cidade: 3084,
			},
			{
				cidade: 'Renascença',
				id_cidade: 3085,
			},
			{
				cidade: 'Reserva',
				id_cidade: 3086,
			},
			{
				cidade: 'Reserva do Iguaçu',
				id_cidade: 3087,
			},
			{
				cidade: 'Ribeirão Claro',
				id_cidade: 3088,
			},
			{
				cidade: 'Ribeirão do Pinhal',
				id_cidade: 3089,
			},
			{
				cidade: 'Rio Azul',
				id_cidade: 3090,
			},
			{
				cidade: 'Rio Bom',
				id_cidade: 3091,
			},
			{
				cidade: 'Rio Bonito do Iguaçu',
				id_cidade: 3092,
			},
			{
				cidade: 'Rio Branco do Ivaí',
				id_cidade: 3093,
			},
			{
				cidade: 'Rio Branco do Sul',
				id_cidade: 3094,
			},
			{
				cidade: 'Rio Negro',
				id_cidade: 3095,
			},
			{
				cidade: 'Rolândia',
				id_cidade: 3096,
			},
			{
				cidade: 'Roncador',
				id_cidade: 3097,
			},
			{
				cidade: 'Rondon',
				id_cidade: 3098,
			},
			{
				cidade: 'Rosário do Ivaí',
				id_cidade: 3099,
			},
			{
				cidade: 'Sabáudia',
				id_cidade: 3100,
			},
			{
				cidade: 'Salgado Filho',
				id_cidade: 3101,
			},
			{
				cidade: 'Salto do Itararé',
				id_cidade: 3102,
			},
			{
				cidade: 'Salto do Lontra',
				id_cidade: 3103,
			},
			{
				cidade: 'Santa Amélia',
				id_cidade: 3104,
			},
			{
				cidade: 'Santa Cecília do Pavão',
				id_cidade: 3105,
			},
			{
				cidade: 'Santa Cruz de Monte Castelo',
				id_cidade: 3106,
			},
			{
				cidade: 'Santa Fé',
				id_cidade: 3107,
			},
			{
				cidade: 'Assaí',
				id_cidade: 2808,
			},
			{
				cidade: 'Assis Chateaubriand',
				id_cidade: 2809,
			},
			{
				cidade: 'Santa Helena',
				id_cidade: 3108,
			},
			{
				cidade: 'Santa Inês',
				id_cidade: 3109,
			},
			{
				cidade: 'Santa Isabel do Ivaí',
				id_cidade: 3110,
			},
			{
				cidade: 'Santa Izabel do Oeste',
				id_cidade: 3111,
			},
			{
				cidade: 'Santa Lúcia',
				id_cidade: 3112,
			},
			{
				cidade: 'Santa Maria do Oeste',
				id_cidade: 3113,
			},
			{
				cidade: 'Santa Mariana',
				id_cidade: 3114,
			},
			{
				cidade: 'Santa Mônica',
				id_cidade: 3115,
			},
			{
				cidade: 'Santana do Itararé',
				id_cidade: 3118,
			},
			{
				cidade: 'Abatiá',
				id_cidade: 2784,
			},
			{
				cidade: 'Adrianópolis',
				id_cidade: 2785,
			},
			{
				cidade: 'Altamira do Paraná',
				id_cidade: 2788,
			},
			{
				cidade: 'Altônia',
				id_cidade: 2792,
			},
			{
				cidade: 'Alto Paraíso',
				id_cidade: 2789,
			},
			{
				cidade: 'Alto Piquiri',
				id_cidade: 2791,
			},
			{
				cidade: 'Alvorada do Sul',
				id_cidade: 2793,
			},
			{
				cidade: 'Ampére',
				id_cidade: 2795,
			},
			{
				cidade: 'Anahy',
				id_cidade: 2796,
			},
			{
				cidade: 'Andirá',
				id_cidade: 2797,
			},
			{
				cidade: 'Santa Tereza do Oeste',
				id_cidade: 3116,
			},
			{
				cidade: 'Santa Terezinha de Itaipu',
				id_cidade: 3117,
			},
			{
				cidade: 'Santo Antônio da Platina',
				id_cidade: 3119,
			},
			{
				cidade: 'Santo Antônio do Caiuá',
				id_cidade: 3120,
			},
			{
				cidade: 'Santo Antônio do Paraíso',
				id_cidade: 3121,
			},
			{
				cidade: 'Santo Antônio do Sudoeste',
				id_cidade: 3122,
			},
			{
				cidade: 'Santo Inácio',
				id_cidade: 3123,
			},
			{
				cidade: 'São Carlos do Ivaí',
				id_cidade: 3124,
			},
			{
				cidade: 'São Jerônimo da Serra',
				id_cidade: 3125,
			},
			{
				cidade: 'São João',
				id_cidade: 3126,
			},
			{
				cidade: 'São João do Caiuá',
				id_cidade: 3127,
			},
			{
				cidade: 'São João do Ivaí',
				id_cidade: 3128,
			},
			{
				cidade: 'São João do Triunfo',
				id_cidade: 3129,
			},
			{
				cidade: "São Jorge d'Oeste",
				id_cidade: 3130,
			},
			{
				cidade: 'São Jorge do Ivaí',
				id_cidade: 3131,
			},
			{
				cidade: 'São Jorge do Patrocínio',
				id_cidade: 3132,
			},
			{
				cidade: 'São José da Boa Vista',
				id_cidade: 3133,
			},
			{
				cidade: 'São José das Palmeiras',
				id_cidade: 3134,
			},
			{
				cidade: 'São José dos Pinhais',
				id_cidade: 3135,
			},
			{
				cidade: 'São Manoel do Paraná',
				id_cidade: 3136,
			},
			{
				cidade: 'São Mateus do Sul',
				id_cidade: 3137,
			},
			{
				cidade: 'São Miguel do Iguaçu',
				id_cidade: 3138,
			},
			{
				cidade: 'São Pedro do Iguaçu',
				id_cidade: 3139,
			},
			{
				cidade: 'São Pedro do Ivaí',
				id_cidade: 3140,
			},
			{
				cidade: 'São Pedro do Paraná',
				id_cidade: 3141,
			},
			{
				cidade: 'São Sebastião da Amoreira',
				id_cidade: 3142,
			},
			{
				cidade: 'São Tomé',
				id_cidade: 3143,
			},
			{
				cidade: 'Sapopema',
				id_cidade: 3144,
			},
			{
				cidade: 'Sarandi',
				id_cidade: 3145,
			},
			{
				cidade: 'Saudade do Iguaçu',
				id_cidade: 3146,
			},
			{
				cidade: 'Sengés',
				id_cidade: 3147,
			},
			{
				cidade: 'Serranópolis do Iguaçu',
				id_cidade: 3148,
			},
			{
				cidade: 'Ângulo',
				id_cidade: 2798,
			},
			{
				cidade: 'Antonina',
				id_cidade: 2799,
			},
			{
				cidade: 'Antônio Olinto',
				id_cidade: 2800,
			},
			{
				cidade: 'Apucarana',
				id_cidade: 2801,
			},
			{
				cidade: 'Sertaneja',
				id_cidade: 3149,
			},
			{
				cidade: 'Sertanópolis',
				id_cidade: 3150,
			},
			{
				cidade: 'Siqueira Campos',
				id_cidade: 3151,
			},
			{
				cidade: 'Sulina',
				id_cidade: 3152,
			},
			{
				cidade: 'Tamarana',
				id_cidade: 3153,
			},
			{
				cidade: 'Tamboara',
				id_cidade: 3154,
			},
			{
				cidade: 'Tapejara',
				id_cidade: 3155,
			},
			{
				cidade: 'Tapira',
				id_cidade: 3156,
			},
			{
				cidade: 'Arapongas',
				id_cidade: 2802,
			},
			{
				cidade: 'Arapoti',
				id_cidade: 2803,
			},
			{
				cidade: 'Arapuã',
				id_cidade: 2804,
			},
			{
				cidade: 'Araruna',
				id_cidade: 2805,
			},
			{
				cidade: 'Loanda',
				id_cidade: 2974,
			},
			{
				cidade: 'Teixeira Soares',
				id_cidade: 3157,
			},
			{
				cidade: 'Telêmaco Borba',
				id_cidade: 3158,
			},
			{
				cidade: 'Terra Boa',
				id_cidade: 3159,
			},
			{
				cidade: 'Terra Rica',
				id_cidade: 3160,
			},
			{
				cidade: 'Terra Roxa',
				id_cidade: 3161,
			},
			{
				cidade: 'Tibagi',
				id_cidade: 3162,
			},
			{
				cidade: 'Tijucas do Sul',
				id_cidade: 3163,
			},
			{
				cidade: 'Toledo',
				id_cidade: 3164,
			},
			{
				cidade: 'Tomazina',
				id_cidade: 3165,
			},
			{
				cidade: 'Três Barras do Paraná',
				id_cidade: 3166,
			},
			{
				cidade: 'Tunas do Paraná',
				id_cidade: 3167,
			},
			{
				cidade: 'Tuneiras do Oeste',
				id_cidade: 3168,
			},
			{
				cidade: 'Tupãssi',
				id_cidade: 3169,
			},
			{
				cidade: 'Turvo',
				id_cidade: 3170,
			},
			{
				cidade: 'Ubiratã',
				id_cidade: 3171,
			},
			{
				cidade: 'Umuarama',
				id_cidade: 3172,
			},
			{
				cidade: 'União da Vitória',
				id_cidade: 3173,
			},
			{
				cidade: 'Uniflor',
				id_cidade: 3174,
			},
			{
				cidade: 'Uraí',
				id_cidade: 3175,
			},
			{
				cidade: 'Ventania',
				id_cidade: 3176,
			},
			{
				cidade: 'Vera Cruz do Oeste',
				id_cidade: 3177,
			},
			{
				cidade: 'Verê',
				id_cidade: 3178,
			},
			{
				cidade: 'Virmond',
				id_cidade: 3179,
			},
			{
				cidade: 'Vitorino',
				id_cidade: 3180,
			},
			{
				cidade: 'Wenceslau Braz',
				id_cidade: 3181,
			},
			{
				cidade: 'Xambrê',
				id_cidade: 3182,
			},
		],
	},
	{
		estado: 'Rio de Janeiro',
		sigla: 'RJ',
		id_estado: 19,
		cidades: [
			{
				cidade: 'Areal',
				id_cidade: 3594,
			},
			{
				cidade: 'Armação dos Búzios',
				id_cidade: 3595,
			},
			{
				cidade: 'Arraial do Cabo',
				id_cidade: 3596,
			},
			{
				cidade: 'Barra do Piraí',
				id_cidade: 3597,
			},
			{
				cidade: 'Barra Mansa',
				id_cidade: 3598,
			},
			{
				cidade: 'Belford Roxo',
				id_cidade: 3599,
			},
			{
				cidade: 'Bom Jardim',
				id_cidade: 3600,
			},
			{
				cidade: 'Bom Jesus do Itabapoana',
				id_cidade: 3601,
			},
			{
				cidade: 'Cabo Frio',
				id_cidade: 3602,
			},
			{
				cidade: 'Cachoeiras de Macacu',
				id_cidade: 3603,
			},
			{
				cidade: 'Cambuci',
				id_cidade: 3604,
			},
			{
				cidade: 'Campos dos Goytacazes',
				id_cidade: 3605,
			},
			{
				cidade: 'Cantagalo',
				id_cidade: 3606,
			},
			{
				cidade: 'Carapebus',
				id_cidade: 3607,
			},
			{
				cidade: 'Cardoso Moreira',
				id_cidade: 3608,
			},
			{
				cidade: 'Carmo',
				id_cidade: 3609,
			},
			{
				cidade: 'Casimiro de Abreu',
				id_cidade: 3610,
			},
			{
				cidade: 'Comendador Levy Gasparian',
				id_cidade: 3611,
			},
			{
				cidade: 'Conceição de Macabu',
				id_cidade: 3612,
			},
			{
				cidade: 'Cordeiro',
				id_cidade: 3613,
			},
			{
				cidade: 'Duas Barras',
				id_cidade: 3614,
			},
			{
				cidade: 'Duque de Caxias',
				id_cidade: 3615,
			},
			{
				cidade: 'Engenheiro Paulo de Frontin',
				id_cidade: 3616,
			},
			{
				cidade: 'Guapimirim',
				id_cidade: 3617,
			},
			{
				cidade: 'Iguaba Grande',
				id_cidade: 3618,
			},
			{
				cidade: 'Itaboraí',
				id_cidade: 3619,
			},
			{
				cidade: 'Itaguaí',
				id_cidade: 3620,
			},
			{
				cidade: 'Italva',
				id_cidade: 3621,
			},
			{
				cidade: 'Itaocara',
				id_cidade: 3622,
			},
			{
				cidade: 'Itaperuna',
				id_cidade: 3623,
			},
			{
				cidade: 'Itatiaia',
				id_cidade: 3624,
			},
			{
				cidade: 'Japeri',
				id_cidade: 3625,
			},
			{
				cidade: 'Laje do Muriaé',
				id_cidade: 3626,
			},
			{
				cidade: 'Macaé',
				id_cidade: 3627,
			},
			{
				cidade: 'Macuco',
				id_cidade: 3628,
			},
			{
				cidade: 'Magé',
				id_cidade: 3629,
			},
			{
				cidade: 'Mangaratiba',
				id_cidade: 3630,
			},
			{
				cidade: 'Maricá',
				id_cidade: 3631,
			},
			{
				cidade: 'Mendes',
				id_cidade: 3632,
			},
			{
				cidade: 'Mesquita',
				id_cidade: 3633,
			},
			{
				cidade: 'Miguel Pereira',
				id_cidade: 3634,
			},
			{
				cidade: 'Miracema',
				id_cidade: 3635,
			},
			{
				cidade: 'Natividade',
				id_cidade: 3636,
			},
			{
				cidade: 'Nilópolis',
				id_cidade: 3637,
			},
			{
				cidade: 'Niterói',
				id_cidade: 3638,
			},
			{
				cidade: 'Nova Friburgo',
				id_cidade: 3639,
			},
			{
				cidade: 'Nova Iguaçu',
				id_cidade: 3640,
			},
			{
				cidade: 'Parati',
				id_cidade: 3643,
			},
			{
				cidade: 'São José do Vale do Rio Pret',
				id_cidade: 3667,
			},
			{
				cidade: 'Trajano de Morais',
				id_cidade: 3677,
			},
			{
				cidade: 'Paracambi',
				id_cidade: 3641,
			},
			{
				cidade: 'Paraíba do Sul',
				id_cidade: 3642,
			},
			{
				cidade: 'Paty do Alferes',
				id_cidade: 3644,
			},
			{
				cidade: 'Petrópolis',
				id_cidade: 3645,
			},
			{
				cidade: 'Pinheiral',
				id_cidade: 3646,
			},
			{
				cidade: 'Piraí',
				id_cidade: 3647,
			},
			{
				cidade: 'Porciúncula',
				id_cidade: 3648,
			},
			{
				cidade: 'Porto Real',
				id_cidade: 3649,
			},
			{
				cidade: 'Quatis',
				id_cidade: 3650,
			},
			{
				cidade: 'Queimados',
				id_cidade: 3651,
			},
			{
				cidade: 'Quissamã',
				id_cidade: 3652,
			},
			{
				cidade: 'Resende',
				id_cidade: 3653,
			},
			{
				cidade: 'Rio Bonito',
				id_cidade: 3654,
			},
			{
				cidade: 'Rio Claro',
				id_cidade: 3655,
			},
			{
				cidade: 'Rio das Flores',
				id_cidade: 3656,
			},
			{
				cidade: 'Rio das Ostras',
				id_cidade: 3657,
			},
			{
				cidade: 'Rio de Janeiro',
				id_cidade: 3658,
			},
			{
				cidade: 'Santa Maria Madalena',
				id_cidade: 3659,
			},
			{
				cidade: 'Santo Antônio de Pádua',
				id_cidade: 3660,
			},
			{
				cidade: 'São Fidélis',
				id_cidade: 3661,
			},
			{
				cidade: 'São Francisco de Itabapoana',
				id_cidade: 3662,
			},
			{
				cidade: 'São Gonçalo',
				id_cidade: 3663,
			},
			{
				cidade: 'São João da Barra',
				id_cidade: 3664,
			},
			{
				cidade: 'São João de Meriti',
				id_cidade: 3665,
			},
			{
				cidade: 'São José de Ubá',
				id_cidade: 3666,
			},
			{
				cidade: 'São Pedro da Aldeia',
				id_cidade: 3668,
			},
			{
				cidade: 'São Sebastião do Alto',
				id_cidade: 3669,
			},
			{
				cidade: 'Angra dos Reis',
				id_cidade: 3591,
			},
			{
				cidade: 'Sapucaia',
				id_cidade: 3670,
			},
			{
				cidade: 'Saquarema',
				id_cidade: 3671,
			},
			{
				cidade: 'Seropédica',
				id_cidade: 3672,
			},
			{
				cidade: 'Aperibé',
				id_cidade: 3592,
			},
			{
				cidade: 'Silva Jardim',
				id_cidade: 3673,
			},
			{
				cidade: 'Sumidouro',
				id_cidade: 3674,
			},
			{
				cidade: 'Tanguá',
				id_cidade: 3675,
			},
			{
				cidade: 'Araruama',
				id_cidade: 3593,
			},
			{
				cidade: 'Teresópolis',
				id_cidade: 3676,
			},
			{
				cidade: 'Três Rios',
				id_cidade: 3678,
			},
			{
				cidade: 'Valença',
				id_cidade: 3679,
			},
			{
				cidade: 'Varre-Sai',
				id_cidade: 3680,
			},
			{
				cidade: 'Vassouras',
				id_cidade: 3681,
			},
			{
				cidade: 'Volta Redonda',
				id_cidade: 3682,
			},
		],
	},
	{
		estado: 'Rio Grande do Norte',
		sigla: 'RN',
		id_estado: 20,
		cidades: [
			{
				cidade: 'Alexandria',
				id_cidade: 3687,
			},
			{
				cidade: 'Almino Afonso',
				id_cidade: 3688,
			},
			{
				cidade: 'Areia Branca',
				id_cidade: 3693,
			},
			{
				cidade: 'Arês',
				id_cidade: 3694,
			},
			{
				cidade: 'Baía Formosa',
				id_cidade: 3696,
			},
			{
				cidade: 'Baraúna',
				id_cidade: 3697,
			},
			{
				cidade: 'Barcelona',
				id_cidade: 3698,
			},
			{
				cidade: 'Bento Fernandes',
				id_cidade: 3699,
			},
			{
				cidade: 'Bodó',
				id_cidade: 3700,
			},
			{
				cidade: 'Bom Jesus',
				id_cidade: 3701,
			},
			{
				cidade: 'Brejinho',
				id_cidade: 3702,
			},
			{
				cidade: 'Caiçara do Norte',
				id_cidade: 3703,
			},
			{
				cidade: 'Caiçara do Rio do Vento',
				id_cidade: 3704,
			},
			{
				cidade: 'Caicó',
				id_cidade: 3705,
			},
			{
				cidade: 'Campo Redondo',
				id_cidade: 3706,
			},
			{
				cidade: 'Canguaretama',
				id_cidade: 3707,
			},
			{
				cidade: 'Caraúbas',
				id_cidade: 3708,
			},
			{
				cidade: 'Carnaúba dos Dantas',
				id_cidade: 3709,
			},
			{
				cidade: 'Carnaubais',
				id_cidade: 3710,
			},
			{
				cidade: 'Ceará-Mirim',
				id_cidade: 3711,
			},
			{
				cidade: 'Cerro Corá',
				id_cidade: 3712,
			},
			{
				cidade: 'Coronel Ezequiel',
				id_cidade: 3713,
			},
			{
				cidade: 'Coronel João Pessoa',
				id_cidade: 3714,
			},
			{
				cidade: 'Cruzeta',
				id_cidade: 3715,
			},
			{
				cidade: 'Currais Novos',
				id_cidade: 3716,
			},
			{
				cidade: 'Doutor Severiano',
				id_cidade: 3717,
			},
			{
				cidade: 'Encanto',
				id_cidade: 3718,
			},
			{
				cidade: 'Equador',
				id_cidade: 3719,
			},
			{
				cidade: 'Espírito Santo',
				id_cidade: 3720,
			},
			{
				cidade: 'Extremoz',
				id_cidade: 3721,
			},
			{
				cidade: 'Felipe Guerra',
				id_cidade: 3722,
			},
			{
				cidade: 'Fernando Pedroza',
				id_cidade: 3723,
			},
			{
				cidade: 'Florânia',
				id_cidade: 3724,
			},
			{
				cidade: 'Francisco Dantas',
				id_cidade: 3725,
			},
			{
				cidade: 'Frutuoso Gomes',
				id_cidade: 3726,
			},
			{
				cidade: 'Galinhos',
				id_cidade: 3727,
			},
			{
				cidade: 'Goianinha',
				id_cidade: 3728,
			},
			{
				cidade: 'Governador Dix-Sept Rosado',
				id_cidade: 3729,
			},
			{
				cidade: 'Grossos',
				id_cidade: 3730,
			},
			{
				cidade: 'Guamaré',
				id_cidade: 3731,
			},
			{
				cidade: 'Ielmo Marinho',
				id_cidade: 3732,
			},
			{
				cidade: 'Ipanguaçu',
				id_cidade: 3733,
			},
			{
				cidade: 'Ipueira',
				id_cidade: 3734,
			},
			{
				cidade: 'Itajá',
				id_cidade: 3735,
			},
			{
				cidade: 'Itaú',
				id_cidade: 3736,
			},
			{
				cidade: 'Jaçanã',
				id_cidade: 3737,
			},
			{
				cidade: 'Jandaíra',
				id_cidade: 3738,
			},
			{
				cidade: 'Janduís',
				id_cidade: 3739,
			},
			{
				cidade: 'Japi',
				id_cidade: 3741,
			},
			{
				cidade: 'Jardim de Angicos',
				id_cidade: 3742,
			},
			{
				cidade: 'Jardim de Piranhas',
				id_cidade: 3743,
			},
			{
				cidade: 'Jardim do Seridó',
				id_cidade: 3744,
			},
			{
				cidade: 'João Câmara',
				id_cidade: 3745,
			},
			{
				cidade: 'João Dias',
				id_cidade: 3746,
			},
			{
				cidade: 'José da Penha',
				id_cidade: 3747,
			},
			{
				cidade: 'Jucurutu',
				id_cidade: 3748,
			},
			{
				cidade: 'Jundiá',
				id_cidade: 3749,
			},
			{
				cidade: "Lagoa d'Anta",
				id_cidade: 3750,
			},
			{
				cidade: 'Lagoa de Pedras',
				id_cidade: 3751,
			},
			{
				cidade: 'Lagoa de Velhos',
				id_cidade: 3752,
			},
			{
				cidade: 'Lagoa Nova',
				id_cidade: 3753,
			},
			{
				cidade: 'Lagoa Salgada',
				id_cidade: 3754,
			},
			{
				cidade: 'Lajes',
				id_cidade: 3755,
			},
			{
				cidade: 'Lajes Pintadas',
				id_cidade: 3756,
			},
			{
				cidade: 'Lucrécia',
				id_cidade: 3757,
			},
			{
				cidade: 'Luís Gomes',
				id_cidade: 3758,
			},
			{
				cidade: 'Macaíba',
				id_cidade: 3759,
			},
			{
				cidade: 'Macau',
				id_cidade: 3760,
			},
			{
				cidade: 'Major Sales',
				id_cidade: 3761,
			},
			{
				cidade: 'Marcelino Vieira',
				id_cidade: 3762,
			},
			{
				cidade: 'Martins',
				id_cidade: 3763,
			},
			{
				cidade: 'Maxaranguape',
				id_cidade: 3764,
			},
			{
				cidade: 'Messias Targino',
				id_cidade: 3765,
			},
			{
				cidade: 'Acari',
				id_cidade: 3683,
			},
			{
				cidade: 'Montanhas',
				id_cidade: 3766,
			},
			{
				cidade: 'Monte Alegre',
				id_cidade: 3767,
			},
			{
				cidade: 'Monte das Gameleiras',
				id_cidade: 3768,
			},
			{
				cidade: 'Mossoró',
				id_cidade: 3769,
			},
			{
				cidade: 'Natal',
				id_cidade: 3770,
			},
			{
				cidade: 'Nísia Floresta',
				id_cidade: 3771,
			},
			{
				cidade: 'Nova Cruz',
				id_cidade: 3772,
			},
			{
				cidade: "Olho-d'Água do Borges",
				id_cidade: 3773,
			},
			{
				cidade: 'Ouro Branco',
				id_cidade: 3774,
			},
			{
				cidade: 'Augusto Severo',
				id_cidade: 3695,
			},
			{
				cidade: 'Januário Cicco',
				id_cidade: 3740,
			},
			{
				cidade: 'Paraú',
				id_cidade: 3776,
			},
			{
				cidade: 'Parazinho',
				id_cidade: 3777,
			},
			{
				cidade: 'Parelhas',
				id_cidade: 3778,
			},
			{
				cidade: 'Parnamirim',
				id_cidade: 3779,
			},
			{
				cidade: 'Passa e Fica',
				id_cidade: 3780,
			},
			{
				cidade: 'Passagem',
				id_cidade: 3781,
			},
			{
				cidade: 'Patu',
				id_cidade: 3782,
			},
			{
				cidade: 'Pau dos Ferros',
				id_cidade: 3783,
			},
			{
				cidade: 'Pedra Grande',
				id_cidade: 3784,
			},
			{
				cidade: 'Pedra Preta',
				id_cidade: 3785,
			},
			{
				cidade: 'Pedro Avelino',
				id_cidade: 3786,
			},
			{
				cidade: 'Pedro Velho',
				id_cidade: 3787,
			},
			{
				cidade: 'Pendências',
				id_cidade: 3788,
			},
			{
				cidade: 'Pilões',
				id_cidade: 3789,
			},
			{
				cidade: 'Poço Branco',
				id_cidade: 3790,
			},
			{
				cidade: 'Portalegre',
				id_cidade: 3791,
			},
			{
				cidade: 'Porto do Mangue',
				id_cidade: 3792,
			},
			{
				cidade: 'Presidente Juscelino',
				id_cidade: 3793,
			},
			{
				cidade: 'Pureza',
				id_cidade: 3794,
			},
			{
				cidade: 'Rafael Fernandes',
				id_cidade: 3795,
			},
			{
				cidade: 'Rafael Godeiro',
				id_cidade: 3796,
			},
			{
				cidade: 'Riacho da Cruz',
				id_cidade: 3797,
			},
			{
				cidade: 'Riacho de Santana',
				id_cidade: 3798,
			},
			{
				cidade: 'Riachuelo',
				id_cidade: 3799,
			},
			{
				cidade: 'Rio do Fogo',
				id_cidade: 3800,
			},
			{
				cidade: 'Rodolfo Fernandes',
				id_cidade: 3801,
			},
			{
				cidade: 'Ruy Barbosa',
				id_cidade: 3802,
			},
			{
				cidade: 'Santa Cruz',
				id_cidade: 3803,
			},
			{
				cidade: 'Santa Maria',
				id_cidade: 3804,
			},
			{
				cidade: 'Santana do Matos',
				id_cidade: 3805,
			},
			{
				cidade: 'Açu',
				id_cidade: 3684,
			},
			{
				cidade: 'Afonso Bezerra',
				id_cidade: 3685,
			},
			{
				cidade: 'Água Nova',
				id_cidade: 3686,
			},
			{
				cidade: 'Alto do Rodrigues',
				id_cidade: 3689,
			},
			{
				cidade: 'Santana do Seridó',
				id_cidade: 3806,
			},
			{
				cidade: 'Santo Antônio',
				id_cidade: 3807,
			},
			{
				cidade: 'Paraná',
				id_cidade: 3775,
			},
			{
				cidade: 'São Bento do Norte',
				id_cidade: 3808,
			},
			{
				cidade: 'São Bento do Trairí',
				id_cidade: 3809,
			},
			{
				cidade: 'São Fernando',
				id_cidade: 3810,
			},
			{
				cidade: 'São Francisco do Oeste',
				id_cidade: 3811,
			},
			{
				cidade: 'São Gonçalo do Amarante',
				id_cidade: 3812,
			},
			{
				cidade: 'São João do Sabugi',
				id_cidade: 3813,
			},
			{
				cidade: 'São José de Mipibu',
				id_cidade: 3814,
			},
			{
				cidade: 'São José do Campestre',
				id_cidade: 3815,
			},
			{
				cidade: 'São José do Seridó',
				id_cidade: 3816,
			},
			{
				cidade: 'São Miguel',
				id_cidade: 3817,
			},
			{
				cidade: 'São Miguel do Gostoso',
				id_cidade: 3818,
			},
			{
				cidade: 'São Paulo do Potengi',
				id_cidade: 3819,
			},
			{
				cidade: 'São Pedro',
				id_cidade: 3820,
			},
			{
				cidade: 'São Rafael',
				id_cidade: 3821,
			},
			{
				cidade: 'Angicos',
				id_cidade: 3690,
			},
			{
				cidade: 'São Tomé',
				id_cidade: 3822,
			},
			{
				cidade: 'São Vicente',
				id_cidade: 3823,
			},
			{
				cidade: 'Senador Elói de Souza',
				id_cidade: 3824,
			},
			{
				cidade: 'Senador Georgino Avelino',
				id_cidade: 3825,
			},
			{
				cidade: 'Serra de São Bento',
				id_cidade: 3826,
			},
			{
				cidade: 'Serra do Mel',
				id_cidade: 3827,
			},
			{
				cidade: 'Serra Negra do Norte',
				id_cidade: 3828,
			},
			{
				cidade: 'Serrinha',
				id_cidade: 3829,
			},
			{
				cidade: 'Antônio Martins',
				id_cidade: 3691,
			},
			{
				cidade: 'Apodi',
				id_cidade: 3692,
			},
			{
				cidade: 'Serrinha dos Pintos',
				id_cidade: 3830,
			},
			{
				cidade: 'Severiano Melo',
				id_cidade: 3831,
			},
			{
				cidade: 'Sítio Novo',
				id_cidade: 3832,
			},
			{
				cidade: 'Taboleiro Grande',
				id_cidade: 3833,
			},
			{
				cidade: 'Taipu',
				id_cidade: 3834,
			},
			{
				cidade: 'Tangará',
				id_cidade: 3835,
			},
			{
				cidade: 'Tenente Ananias',
				id_cidade: 3836,
			},
			{
				cidade: 'Tenente Laurentino Cruz',
				id_cidade: 3837,
			},
			{
				cidade: 'Tibau',
				id_cidade: 3838,
			},
			{
				cidade: 'Tibau do Sul',
				id_cidade: 3839,
			},
			{
				cidade: 'Timbaúba dos Batistas',
				id_cidade: 3840,
			},
			{
				cidade: 'Touros',
				id_cidade: 3841,
			},
			{
				cidade: 'Triunfo Potiguar',
				id_cidade: 3842,
			},
			{
				cidade: 'Umarizal',
				id_cidade: 3843,
			},
			{
				cidade: 'Upanema',
				id_cidade: 3844,
			},
			{
				cidade: 'Várzea',
				id_cidade: 3845,
			},
			{
				cidade: 'Venha-Ver',
				id_cidade: 3846,
			},
			{
				cidade: 'Vera Cruz',
				id_cidade: 3847,
			},
			{
				cidade: 'Viçosa',
				id_cidade: 3848,
			},
			{
				cidade: 'Vila Flor',
				id_cidade: 3849,
			},
		],
	},
	{
		estado: 'Rondônia',
		sigla: 'RO',
		id_estado: 21,
		cidades: [
			{
				cidade: 'Ariquemes',
				id_cidade: 4350,
			},
			{
				cidade: 'Buritis',
				id_cidade: 4351,
			},
			{
				cidade: 'Cabixi',
				id_cidade: 4352,
			},
			{
				cidade: 'Cacaulândia',
				id_cidade: 4353,
			},
			{
				cidade: 'Cacoal',
				id_cidade: 4354,
			},
			{
				cidade: 'Campo Novo de Rondônia',
				id_cidade: 4355,
			},
			{
				cidade: 'Candeias do Jamari',
				id_cidade: 4356,
			},
			{
				cidade: 'Castanheiras',
				id_cidade: 4357,
			},
			{
				cidade: 'Cerejeiras',
				id_cidade: 4358,
			},
			{
				cidade: 'Chupinguaia',
				id_cidade: 4359,
			},
			{
				cidade: 'Colorado do Oeste',
				id_cidade: 4360,
			},
			{
				cidade: 'Corumbiara',
				id_cidade: 4361,
			},
			{
				cidade: 'Costa Marques',
				id_cidade: 4362,
			},
			{
				cidade: 'Cujubim',
				id_cidade: 4363,
			},
			{
				cidade: 'Governador Jorge Teixeira',
				id_cidade: 4365,
			},
			{
				cidade: 'Guajará-Mirim',
				id_cidade: 4366,
			},
			{
				cidade: 'Itapuã do Oeste',
				id_cidade: 4367,
			},
			{
				cidade: 'Jaru',
				id_cidade: 4368,
			},
			{
				cidade: 'Ji-Paraná',
				id_cidade: 4369,
			},
			{
				cidade: 'Ministro Andreazza',
				id_cidade: 4371,
			},
			{
				cidade: 'Mirante da Serra',
				id_cidade: 4372,
			},
			{
				cidade: 'Monte Negro',
				id_cidade: 4373,
			},
			{
				cidade: 'Nova Mamoré',
				id_cidade: 4375,
			},
			{
				cidade: 'Nova União',
				id_cidade: 4376,
			},
			{
				cidade: 'Novo Horizonte do Oeste',
				id_cidade: 4377,
			},
			{
				cidade: 'Ouro Preto do Oeste',
				id_cidade: 4378,
			},
			{
				cidade: 'Parecis',
				id_cidade: 4379,
			},
			{
				cidade: 'Pimenta Bueno',
				id_cidade: 4380,
			},
			{
				cidade: 'Pimenteiras do Oeste',
				id_cidade: 4381,
			},
			{
				cidade: 'Porto Velho',
				id_cidade: 4382,
			},
			{
				cidade: 'Presidente Médici',
				id_cidade: 4383,
			},
			{
				cidade: 'Primavera de Rondônia',
				id_cidade: 4384,
			},
			{
				cidade: "Alta Floresta d'Oeste",
				id_cidade: 4346,
			},
			{
				cidade: "Alvorada d'Oeste",
				id_cidade: 4349,
			},
			{
				cidade: "Espigão d'Oeste",
				id_cidade: 4364,
			},
			{
				cidade: "Machadinho d'Oeste",
				id_cidade: 4370,
			},
			{
				cidade: "Nova Brasilândia d'Oeste",
				id_cidade: 4374,
			},
			{
				cidade: "Santa Luzia d'Oeste",
				id_cidade: 4387,
			},
			{
				cidade: "São Felipe d'Oeste",
				id_cidade: 4388,
			},
			{
				cidade: 'Rio Crespo',
				id_cidade: 4385,
			},
			{
				cidade: 'Rolim de Moura',
				id_cidade: 4386,
			},
			{
				cidade: 'Alto Alegre dos Parecis',
				id_cidade: 4347,
			},
			{
				cidade: 'Alto Paraíso',
				id_cidade: 4348,
			},
			{
				cidade: 'São Francisco do Guaporé',
				id_cidade: 4389,
			},
			{
				cidade: 'São Miguel do Guaporé',
				id_cidade: 4390,
			},
			{
				cidade: 'Seringueiras',
				id_cidade: 4391,
			},
			{
				cidade: 'Teixeirópolis',
				id_cidade: 4392,
			},
			{
				cidade: 'Theobroma',
				id_cidade: 4393,
			},
			{
				cidade: 'Urupá',
				id_cidade: 4394,
			},
			{
				cidade: 'Vale do Anari',
				id_cidade: 4395,
			},
			{
				cidade: 'Vale do Paraíso',
				id_cidade: 4396,
			},
			{
				cidade: 'Vilhena',
				id_cidade: 4397,
			},
		],
	},
	{
		estado: 'Roraima',
		sigla: 'RR',
		id_estado: 22,
		cidades: [
			{
				cidade: 'Boa Vista',
				id_cidade: 4400,
			},
			{
				cidade: 'Bonfim',
				id_cidade: 4401,
			},
			{
				cidade: 'Cantá',
				id_cidade: 4402,
			},
			{
				cidade: 'Caracaraí',
				id_cidade: 4403,
			},
			{
				cidade: 'Caroebe',
				id_cidade: 4404,
			},
			{
				cidade: 'Iracema',
				id_cidade: 4405,
			},
			{
				cidade: 'Mucajaí',
				id_cidade: 4406,
			},
			{
				cidade: 'Normandia',
				id_cidade: 4407,
			},
			{
				cidade: 'Pacaraima',
				id_cidade: 4408,
			},
			{
				cidade: 'Rorainópolis',
				id_cidade: 4409,
			},
			{
				cidade: 'Alto Alegre',
				id_cidade: 4398,
			},
			{
				cidade: 'Amajari',
				id_cidade: 4399,
			},
			{
				cidade: 'São João da Baliza',
				id_cidade: 4410,
			},
			{
				cidade: 'São Luiz',
				id_cidade: 4411,
			},
			{
				cidade: 'Uiramutã',
				id_cidade: 4412,
			},
		],
	},
	{
		estado: 'Rio Grande do Sul',
		sigla: 'RS',
		id_estado: 23,
		cidades: [
			{
				cidade: 'Agudo',
				id_cidade: 3852,
			},
			{
				cidade: 'Ajuricaba',
				id_cidade: 3853,
			},
			{
				cidade: 'Alecrim',
				id_cidade: 3854,
			},
			{
				cidade: 'Alegrete',
				id_cidade: 3855,
			},
			{
				cidade: 'Alegria',
				id_cidade: 3856,
			},
			{
				cidade: 'Almirante Tamandaré do Sul',
				id_cidade: 3857,
			},
			{
				cidade: 'Alpestre',
				id_cidade: 3858,
			},
			{
				cidade: 'Arroio do Meio',
				id_cidade: 3870,
			},
			{
				cidade: 'Arroio do Padre',
				id_cidade: 3871,
			},
			{
				cidade: 'Arroio do Sal',
				id_cidade: 3872,
			},
			{
				cidade: 'Arroio do Tigre',
				id_cidade: 3873,
			},
			{
				cidade: 'Augusto Pestana',
				id_cidade: 3877,
			},
			{
				cidade: 'Áurea',
				id_cidade: 3878,
			},
			{
				cidade: 'Bagé',
				id_cidade: 3879,
			},
			{
				cidade: 'Balneário Pinhal',
				id_cidade: 3880,
			},
			{
				cidade: 'Barão',
				id_cidade: 3881,
			},
			{
				cidade: 'Barão de Cotegipe',
				id_cidade: 3882,
			},
			{
				cidade: 'Barão do Triunfo',
				id_cidade: 3883,
			},
			{
				cidade: 'Barracão',
				id_cidade: 3889,
			},
			{
				cidade: 'Barra do Guarita',
				id_cidade: 3884,
			},
			{
				cidade: 'Barra do Quaraí',
				id_cidade: 3885,
			},
			{
				cidade: 'Barra do Ribeiro',
				id_cidade: 3886,
			},
			{
				cidade: 'Barra do Rio Azul',
				id_cidade: 3887,
			},
			{
				cidade: 'Barra Funda',
				id_cidade: 3888,
			},
			{
				cidade: 'Barros Cassal',
				id_cidade: 3890,
			},
			{
				cidade: 'Benjamin Constant do Sul',
				id_cidade: 3891,
			},
			{
				cidade: 'Bento Gonçalves',
				id_cidade: 3892,
			},
			{
				cidade: 'Boa Vista das Missões',
				id_cidade: 3893,
			},
			{
				cidade: 'Boa Vista do Buricá',
				id_cidade: 3894,
			},
			{
				cidade: 'Boa Vista do Cadeado',
				id_cidade: 3895,
			},
			{
				cidade: 'Boa Vista do Incra',
				id_cidade: 3896,
			},
			{
				cidade: 'Boa Vista do Sul',
				id_cidade: 3897,
			},
			{
				cidade: 'Bom Jesus',
				id_cidade: 3898,
			},
			{
				cidade: 'Bom Princípio',
				id_cidade: 3899,
			},
			{
				cidade: 'Bom Progresso',
				id_cidade: 3900,
			},
			{
				cidade: 'Bom Retiro do Sul',
				id_cidade: 3901,
			},
			{
				cidade: 'Boqueirão do Leão',
				id_cidade: 3902,
			},
			{
				cidade: 'Bossoroca',
				id_cidade: 3903,
			},
			{
				cidade: 'Bozano',
				id_cidade: 3904,
			},
			{
				cidade: 'Braga',
				id_cidade: 3905,
			},
			{
				cidade: 'Brochier',
				id_cidade: 3906,
			},
			{
				cidade: 'Butiá',
				id_cidade: 3907,
			},
			{
				cidade: 'Caçapava do Sul',
				id_cidade: 3908,
			},
			{
				cidade: 'Cacequi',
				id_cidade: 3909,
			},
			{
				cidade: 'Cachoeira do Sul',
				id_cidade: 3910,
			},
			{
				cidade: 'Cachoeirinha',
				id_cidade: 3911,
			},
			{
				cidade: 'Cacique Doble',
				id_cidade: 3912,
			},
			{
				cidade: 'Caibaté',
				id_cidade: 3913,
			},
			{
				cidade: 'Caiçara',
				id_cidade: 3914,
			},
			{
				cidade: 'Camaquã',
				id_cidade: 3915,
			},
			{
				cidade: 'Camargo',
				id_cidade: 3916,
			},
			{
				cidade: 'Cambará do Sul',
				id_cidade: 3917,
			},
			{
				cidade: 'Campestre da Serra',
				id_cidade: 3918,
			},
			{
				cidade: 'Campina das Missões',
				id_cidade: 3919,
			},
			{
				cidade: 'Campinas do Sul',
				id_cidade: 3920,
			},
			{
				cidade: 'Campo Bom',
				id_cidade: 3921,
			},
			{
				cidade: 'Campo Novo',
				id_cidade: 3922,
			},
			{
				cidade: 'Campos Borges',
				id_cidade: 3923,
			},
			{
				cidade: 'Candelária',
				id_cidade: 3924,
			},
			{
				cidade: 'Cândido Godói',
				id_cidade: 3925,
			},
			{
				cidade: 'Candiota',
				id_cidade: 3926,
			},
			{
				cidade: 'Canela',
				id_cidade: 3927,
			},
			{
				cidade: 'Canguçu',
				id_cidade: 3928,
			},
			{
				cidade: 'Canoas',
				id_cidade: 3929,
			},
			{
				cidade: 'Canudos do Vale',
				id_cidade: 3930,
			},
			{
				cidade: 'Capão Bonito do Sul',
				id_cidade: 3931,
			},
			{
				cidade: 'Capão da Canoa',
				id_cidade: 3932,
			},
			{
				cidade: 'Capão do Cipó',
				id_cidade: 3933,
			},
			{
				cidade: 'Capão do Leão',
				id_cidade: 3934,
			},
			{
				cidade: 'Capela de Santana',
				id_cidade: 3935,
			},
			{
				cidade: 'Capitão',
				id_cidade: 3936,
			},
			{
				cidade: 'Capivari do Sul',
				id_cidade: 3937,
			},
			{
				cidade: 'Caraá',
				id_cidade: 3938,
			},
			{
				cidade: 'Carazinho',
				id_cidade: 3939,
			},
			{
				cidade: 'Carlos Barbosa',
				id_cidade: 3940,
			},
			{
				cidade: 'Carlos Gomes',
				id_cidade: 3941,
			},
			{
				cidade: 'Casca',
				id_cidade: 3942,
			},
			{
				cidade: 'Caseiros',
				id_cidade: 3943,
			},
			{
				cidade: 'Catuípe',
				id_cidade: 3944,
			},
			{
				cidade: 'Caxias do Sul',
				id_cidade: 3945,
			},
			{
				cidade: 'Centenário',
				id_cidade: 3946,
			},
			{
				cidade: 'Cerrito',
				id_cidade: 3947,
			},
			{
				cidade: 'Cerro Branco',
				id_cidade: 3948,
			},
			{
				cidade: 'Cerro Grande',
				id_cidade: 3949,
			},
			{
				cidade: 'Cerro Grande do Sul',
				id_cidade: 3950,
			},
			{
				cidade: 'Cerro Largo',
				id_cidade: 3951,
			},
			{
				cidade: 'Chapada',
				id_cidade: 3952,
			},
			{
				cidade: 'Charqueadas',
				id_cidade: 3953,
			},
			{
				cidade: 'Charrua',
				id_cidade: 3954,
			},
			{
				cidade: 'Chuí',
				id_cidade: 3956,
			},
			{
				cidade: 'Chuvisca',
				id_cidade: 3957,
			},
			{
				cidade: 'Cidreira',
				id_cidade: 3958,
			},
			{
				cidade: 'Ciríaco',
				id_cidade: 3959,
			},
			{
				cidade: 'Colinas',
				id_cidade: 3960,
			},
			{
				cidade: 'Colorado',
				id_cidade: 3961,
			},
			{
				cidade: 'Condor',
				id_cidade: 3962,
			},
			{
				cidade: 'Constantina',
				id_cidade: 3963,
			},
			{
				cidade: 'Coqueiro Baixo',
				id_cidade: 3964,
			},
			{
				cidade: 'Coqueiros do Sul',
				id_cidade: 3965,
			},
			{
				cidade: 'Coronel Barros',
				id_cidade: 3966,
			},
			{
				cidade: 'Coronel Bicaco',
				id_cidade: 3967,
			},
			{
				cidade: 'Coronel Pilar',
				id_cidade: 3968,
			},
			{
				cidade: 'Cotiporã',
				id_cidade: 3969,
			},
			{
				cidade: 'Coxilha',
				id_cidade: 3970,
			},
			{
				cidade: 'Crissiumal',
				id_cidade: 3971,
			},
			{
				cidade: 'Cristal',
				id_cidade: 3972,
			},
			{
				cidade: 'Cristal do Sul',
				id_cidade: 3973,
			},
			{
				cidade: 'Cruz Alta',
				id_cidade: 3974,
			},
			{
				cidade: 'Cruzaltense',
				id_cidade: 3975,
			},
			{
				cidade: 'Cruzeiro do Sul',
				id_cidade: 3976,
			},
			{
				cidade: 'David Canabarro',
				id_cidade: 3977,
			},
			{
				cidade: 'Derrubadas',
				id_cidade: 3978,
			},
			{
				cidade: 'Dezesseis de Novembro',
				id_cidade: 3979,
			},
			{
				cidade: 'Dilermando de Aguiar',
				id_cidade: 3980,
			},
			{
				cidade: 'Dois Irmãos',
				id_cidade: 3981,
			},
			{
				cidade: 'Dois Irmãos das Missões',
				id_cidade: 3982,
			},
			{
				cidade: 'Dois Lajeados',
				id_cidade: 3983,
			},
			{
				cidade: 'Dom Feliciano',
				id_cidade: 3984,
			},
			{
				cidade: 'Dom Pedrito',
				id_cidade: 3985,
			},
			{
				cidade: 'Dom Pedro de Alcântara',
				id_cidade: 3986,
			},
			{
				cidade: 'Dona Francisca',
				id_cidade: 3987,
			},
			{
				cidade: 'Doutor Maurício Cardoso',
				id_cidade: 3988,
			},
			{
				cidade: 'Doutor Ricardo',
				id_cidade: 3989,
			},
			{
				cidade: 'Aratiba',
				id_cidade: 3869,
			},
			{
				cidade: 'Eldorado do Sul',
				id_cidade: 3990,
			},
			{
				cidade: 'Encantado',
				id_cidade: 3991,
			},
			{
				cidade: 'Encruzilhada do Sul',
				id_cidade: 3992,
			},
			{
				cidade: 'Entre-Ijuís',
				id_cidade: 3995,
			},
			{
				cidade: 'Entre Rios do Sul',
				id_cidade: 3994,
			},
			{
				cidade: 'Erebango',
				id_cidade: 3996,
			},
			{
				cidade: 'Erechim',
				id_cidade: 3997,
			},
			{
				cidade: 'Ernestina',
				id_cidade: 3998,
			},
			{
				cidade: 'Erval Grande',
				id_cidade: 3999,
			},
			{
				cidade: 'Erval Seco',
				id_cidade: 4000,
			},
			{
				cidade: 'Esmeralda',
				id_cidade: 4001,
			},
			{
				cidade: 'Esperança do Sul',
				id_cidade: 4002,
			},
			{
				cidade: 'Espumoso',
				id_cidade: 4003,
			},
			{
				cidade: 'Estação',
				id_cidade: 4004,
			},
			{
				cidade: 'Estância Velha',
				id_cidade: 4005,
			},
			{
				cidade: 'Esteio',
				id_cidade: 4006,
			},
			{
				cidade: 'Estrela',
				id_cidade: 4007,
			},
			{
				cidade: 'Estrela Velha',
				id_cidade: 4008,
			},
			{
				cidade: 'Eugênio de Castro',
				id_cidade: 4009,
			},
			{
				cidade: 'Fagundes Varela',
				id_cidade: 4010,
			},
			{
				cidade: 'Farroupilha',
				id_cidade: 4011,
			},
			{
				cidade: 'Faxinal do Soturno',
				id_cidade: 4012,
			},
			{
				cidade: 'Faxinalzinho',
				id_cidade: 4013,
			},
			{
				cidade: 'Fazenda Vilanova',
				id_cidade: 4014,
			},
			{
				cidade: 'Feliz',
				id_cidade: 4015,
			},
			{
				cidade: 'Flores da Cunha',
				id_cidade: 4016,
			},
			{
				cidade: 'Floriano Peixoto',
				id_cidade: 4017,
			},
			{
				cidade: 'Fontoura Xavier',
				id_cidade: 4018,
			},
			{
				cidade: 'Formigueiro',
				id_cidade: 4019,
			},
			{
				cidade: 'Forquetinha',
				id_cidade: 4020,
			},
			{
				cidade: 'Fortaleza dos Valos',
				id_cidade: 4021,
			},
			{
				cidade: 'Frederico Westphalen',
				id_cidade: 4022,
			},
			{
				cidade: 'Garibaldi',
				id_cidade: 4023,
			},
			{
				cidade: 'Garruchos',
				id_cidade: 4024,
			},
			{
				cidade: 'Gaurama',
				id_cidade: 4025,
			},
			{
				cidade: 'General Câmara',
				id_cidade: 4026,
			},
			{
				cidade: 'Gentil',
				id_cidade: 4027,
			},
			{
				cidade: 'Getúlio Vargas',
				id_cidade: 4028,
			},
			{
				cidade: 'Giruá',
				id_cidade: 4029,
			},
			{
				cidade: 'Glorinha',
				id_cidade: 4030,
			},
			{
				cidade: 'Gramado',
				id_cidade: 4031,
			},
			{
				cidade: 'Gramado dos Loureiros',
				id_cidade: 4032,
			},
			{
				cidade: 'Gramado Xavier',
				id_cidade: 4033,
			},
			{
				cidade: 'Gravataí',
				id_cidade: 4034,
			},
			{
				cidade: 'Guabiju',
				id_cidade: 4035,
			},
			{
				cidade: 'Guaíba',
				id_cidade: 4036,
			},
			{
				cidade: 'Guaporé',
				id_cidade: 4037,
			},
			{
				cidade: 'Guarani das Missões',
				id_cidade: 4038,
			},
			{
				cidade: 'Harmonia',
				id_cidade: 4039,
			},
			{
				cidade: 'Herval',
				id_cidade: 4040,
			},
			{
				cidade: 'Herveiras',
				id_cidade: 4041,
			},
			{
				cidade: 'Horizontina',
				id_cidade: 4042,
			},
			{
				cidade: 'Hulha Negra',
				id_cidade: 4043,
			},
			{
				cidade: 'Humaitá',
				id_cidade: 4044,
			},
			{
				cidade: 'Ibarama',
				id_cidade: 4045,
			},
			{
				cidade: 'Ibiaçá',
				id_cidade: 4046,
			},
			{
				cidade: 'Ibiraiaras',
				id_cidade: 4047,
			},
			{
				cidade: 'Ibirapuitã',
				id_cidade: 4048,
			},
			{
				cidade: 'Ibirubá',
				id_cidade: 4049,
			},
			{
				cidade: 'Igrejinha',
				id_cidade: 4050,
			},
			{
				cidade: 'Ijuí',
				id_cidade: 4051,
			},
			{
				cidade: 'Ilópolis',
				id_cidade: 4052,
			},
			{
				cidade: 'Imbé',
				id_cidade: 4053,
			},
			{
				cidade: 'Imigrante',
				id_cidade: 4054,
			},
			{
				cidade: 'Independência',
				id_cidade: 4055,
			},
			{
				cidade: 'Inhacorá',
				id_cidade: 4056,
			},
			{
				cidade: 'Ipê',
				id_cidade: 4057,
			},
			{
				cidade: 'Ipiranga do Sul',
				id_cidade: 4058,
			},
			{
				cidade: 'Iraí',
				id_cidade: 4059,
			},
			{
				cidade: 'Itaara',
				id_cidade: 4060,
			},
			{
				cidade: 'Itacurubi',
				id_cidade: 4061,
			},
			{
				cidade: 'Itapuca',
				id_cidade: 4062,
			},
			{
				cidade: 'Itaqui',
				id_cidade: 4063,
			},
			{
				cidade: 'Itati',
				id_cidade: 4064,
			},
			{
				cidade: 'Itatiba do Sul',
				id_cidade: 4065,
			},
			{
				cidade: 'Ivorá',
				id_cidade: 4066,
			},
			{
				cidade: 'Ivoti',
				id_cidade: 4067,
			},
			{
				cidade: 'Jaboticaba',
				id_cidade: 4068,
			},
			{
				cidade: 'Jacuizinho',
				id_cidade: 4069,
			},
			{
				cidade: 'Jacutinga',
				id_cidade: 4070,
			},
			{
				cidade: 'Jaguarão',
				id_cidade: 4071,
			},
			{
				cidade: 'Jaguari',
				id_cidade: 4072,
			},
			{
				cidade: 'Jaquirana',
				id_cidade: 4073,
			},
			{
				cidade: 'Jari',
				id_cidade: 4074,
			},
			{
				cidade: 'Jóia',
				id_cidade: 4075,
			},
			{
				cidade: 'Júlio de Castilhos',
				id_cidade: 4076,
			},
			{
				cidade: 'Lagoa Bonita do Sul',
				id_cidade: 4077,
			},
			{
				cidade: 'Lagoa dos Três Cantos',
				id_cidade: 4078,
			},
			{
				cidade: 'Lagoão',
				id_cidade: 4080,
			},
			{
				cidade: 'Lagoa Vermelha',
				id_cidade: 4079,
			},
			{
				cidade: 'Lajeado',
				id_cidade: 4081,
			},
			{
				cidade: 'Lajeado do Bugre',
				id_cidade: 4082,
			},
			{
				cidade: 'Lavras do Sul',
				id_cidade: 4083,
			},
			{
				cidade: 'Liberato Salzano',
				id_cidade: 4084,
			},
			{
				cidade: 'Lindolfo Collor',
				id_cidade: 4085,
			},
			{
				cidade: 'Linha Nova',
				id_cidade: 4086,
			},
			{
				cidade: 'Machadinho',
				id_cidade: 4088,
			},
			{
				cidade: 'Mampituba',
				id_cidade: 4089,
			},
			{
				cidade: 'Manoel Viana',
				id_cidade: 4090,
			},
			{
				cidade: 'Maquiné',
				id_cidade: 4091,
			},
			{
				cidade: 'Maratá',
				id_cidade: 4092,
			},
			{
				cidade: 'Marau',
				id_cidade: 4093,
			},
			{
				cidade: 'Marcelino Ramos',
				id_cidade: 4094,
			},
			{
				cidade: 'Mariana Pimentel',
				id_cidade: 4095,
			},
			{
				cidade: 'Mariano Moro',
				id_cidade: 4096,
			},
			{
				cidade: 'Marques de Souza',
				id_cidade: 4097,
			},
			{
				cidade: 'Mata',
				id_cidade: 4098,
			},
			{
				cidade: 'Mato Castelhano',
				id_cidade: 4099,
			},
			{
				cidade: 'Mato Leitão',
				id_cidade: 4100,
			},
			{
				cidade: 'Mato Queimado',
				id_cidade: 4101,
			},
			{
				cidade: 'Maximiliano de Almeida',
				id_cidade: 4102,
			},
			{
				cidade: 'Minas do Leão',
				id_cidade: 4103,
			},
			{
				cidade: 'Miraguaí',
				id_cidade: 4104,
			},
			{
				cidade: 'Montauri',
				id_cidade: 4105,
			},
			{
				cidade: 'Monte Alegre dos Campos',
				id_cidade: 4106,
			},
			{
				cidade: 'Monte Belo do Sul',
				id_cidade: 4107,
			},
			{
				cidade: 'Montenegro',
				id_cidade: 4108,
			},
			{
				cidade: 'Mormaço',
				id_cidade: 4109,
			},
			{
				cidade: 'Morrinhos do Sul',
				id_cidade: 4110,
			},
			{
				cidade: 'Morro Redondo',
				id_cidade: 4111,
			},
			{
				cidade: 'Morro Reuter',
				id_cidade: 4112,
			},
			{
				cidade: 'Mostardas',
				id_cidade: 4113,
			},
			{
				cidade: 'Muçum',
				id_cidade: 4114,
			},
			{
				cidade: 'Muitos Capões',
				id_cidade: 4115,
			},
			{
				cidade: 'Muliterno',
				id_cidade: 4116,
			},
			{
				cidade: 'Não-Me-Toque',
				id_cidade: 4117,
			},
			{
				cidade: 'Nicolau Vergueiro',
				id_cidade: 4118,
			},
			{
				cidade: 'Nonoai',
				id_cidade: 4119,
			},
			{
				cidade: 'Nova Alvorada',
				id_cidade: 4120,
			},
			{
				cidade: 'Nova Araçá',
				id_cidade: 4121,
			},
			{
				cidade: 'Nova Bassano',
				id_cidade: 4122,
			},
			{
				cidade: 'Nova Boa Vista',
				id_cidade: 4123,
			},
			{
				cidade: 'Nova Bréscia',
				id_cidade: 4124,
			},
			{
				cidade: 'Nova Candelária',
				id_cidade: 4125,
			},
			{
				cidade: 'Nova Esperança do Sul',
				id_cidade: 4126,
			},
			{
				cidade: 'Nova Hartz',
				id_cidade: 4127,
			},
			{
				cidade: 'Nova Pádua',
				id_cidade: 4128,
			},
			{
				cidade: 'Nova Palma',
				id_cidade: 4129,
			},
			{
				cidade: 'Nova Petrópolis',
				id_cidade: 4130,
			},
			{
				cidade: 'Nova Prata',
				id_cidade: 4131,
			},
			{
				cidade: 'Nova Ramada',
				id_cidade: 4132,
			},
			{
				cidade: 'Nova Roma do Sul',
				id_cidade: 4133,
			},
			{
				cidade: 'Nova Santa Rita',
				id_cidade: 4134,
			},
			{
				cidade: 'Novo Barreiro',
				id_cidade: 4135,
			},
			{
				cidade: 'Novo Cabrais',
				id_cidade: 4136,
			},
			{
				cidade: 'Novo Hamburgo',
				id_cidade: 4137,
			},
			{
				cidade: 'Novo Machado',
				id_cidade: 4138,
			},
			{
				cidade: 'Novo Tiradentes',
				id_cidade: 4139,
			},
			{
				cidade: 'Novo Xingu',
				id_cidade: 4140,
			},
			{
				cidade: 'Osório',
				id_cidade: 4141,
			},
			{
				cidade: 'Paim Filho',
				id_cidade: 4142,
			},
			{
				cidade: 'Palmares do Sul',
				id_cidade: 4143,
			},
			{
				cidade: 'Palmeira das Missões',
				id_cidade: 4144,
			},
			{
				cidade: 'Palmitinho',
				id_cidade: 4145,
			},
			{
				cidade: 'Panambi',
				id_cidade: 4146,
			},
			{
				cidade: 'Pantano Grande',
				id_cidade: 4147,
			},
			{
				cidade: 'Paraí',
				id_cidade: 4148,
			},
			{
				cidade: 'Paraíso do Sul',
				id_cidade: 4149,
			},
			{
				cidade: 'Pareci Novo',
				id_cidade: 4150,
			},
			{
				cidade: 'Parobé',
				id_cidade: 4151,
			},
			{
				cidade: 'Passa Sete',
				id_cidade: 4152,
			},
			{
				cidade: 'Passo do Sobrado',
				id_cidade: 4153,
			},
			{
				cidade: 'Passo Fundo',
				id_cidade: 4154,
			},
			{
				cidade: 'Amaral Ferrador',
				id_cidade: 3862,
			},
			{
				cidade: 'Paulo Bento',
				id_cidade: 4155,
			},
			{
				cidade: 'Paverama',
				id_cidade: 4156,
			},
			{
				cidade: 'Pedras Altas',
				id_cidade: 4157,
			},
			{
				cidade: 'Pedro Osório',
				id_cidade: 4158,
			},
			{
				cidade: 'Pejuçara',
				id_cidade: 4159,
			},
			{
				cidade: 'Pelotas',
				id_cidade: 4160,
			},
			{
				cidade: 'Chiapeta',
				id_cidade: 3955,
			},
			{
				cidade: 'Picada Café',
				id_cidade: 4161,
			},
			{
				cidade: 'Maçambara',
				id_cidade: 4087,
			},
			{
				cidade: 'Pinhal',
				id_cidade: 4162,
			},
			{
				cidade: 'Pinhal da Serra',
				id_cidade: 4163,
			},
			{
				cidade: 'Pinhal Grande',
				id_cidade: 4164,
			},
			{
				cidade: 'Pinheirinho do Vale',
				id_cidade: 4165,
			},
			{
				cidade: 'Pinheiro Machado',
				id_cidade: 4166,
			},
			{
				cidade: 'Pirapó',
				id_cidade: 4167,
			},
			{
				cidade: 'Piratini',
				id_cidade: 4168,
			},
			{
				cidade: 'Planalto',
				id_cidade: 4169,
			},
			{
				cidade: 'Poço das Antas',
				id_cidade: 4170,
			},
			{
				cidade: 'Pontão',
				id_cidade: 4171,
			},
			{
				cidade: 'Ponte Preta',
				id_cidade: 4172,
			},
			{
				cidade: 'Portão',
				id_cidade: 4173,
			},
			{
				cidade: 'Porto Alegre',
				id_cidade: 4174,
			},
			{
				cidade: 'Santana do Livramento',
				id_cidade: 4220,
			},
			{
				cidade: 'Porto Lucena',
				id_cidade: 4175,
			},
			{
				cidade: 'Porto Mauá',
				id_cidade: 4176,
			},
			{
				cidade: 'Porto Vera Cruz',
				id_cidade: 4177,
			},
			{
				cidade: 'Porto Xavier',
				id_cidade: 4178,
			},
			{
				cidade: 'Pouso Novo',
				id_cidade: 4179,
			},
			{
				cidade: 'Presidente Lucena',
				id_cidade: 4180,
			},
			{
				cidade: 'Progresso',
				id_cidade: 4181,
			},
			{
				cidade: 'Protásio Alves',
				id_cidade: 4182,
			},
			{
				cidade: 'Putinga',
				id_cidade: 4183,
			},
			{
				cidade: 'Quaraí',
				id_cidade: 4184,
			},
			{
				cidade: 'Quatro Irmãos',
				id_cidade: 4185,
			},
			{
				cidade: 'Quevedos',
				id_cidade: 4186,
			},
			{
				cidade: 'Quinze de Novembro',
				id_cidade: 4187,
			},
			{
				cidade: 'Redentora',
				id_cidade: 4188,
			},
			{
				cidade: 'Relvado',
				id_cidade: 4189,
			},
			{
				cidade: 'Restinga Seca',
				id_cidade: 4190,
			},
			{
				cidade: 'Rio dos Índios',
				id_cidade: 4191,
			},
			{
				cidade: 'Rio Grande',
				id_cidade: 4192,
			},
			{
				cidade: 'Rio Pardo',
				id_cidade: 4193,
			},
			{
				cidade: 'Riozinho',
				id_cidade: 4194,
			},
			{
				cidade: 'Roca Sales',
				id_cidade: 4195,
			},
			{
				cidade: 'Rodeio Bonito',
				id_cidade: 4196,
			},
			{
				cidade: 'Rolador',
				id_cidade: 4197,
			},
			{
				cidade: 'Rolante',
				id_cidade: 4198,
			},
			{
				cidade: 'Ronda Alta',
				id_cidade: 4199,
			},
			{
				cidade: 'Rondinha',
				id_cidade: 4200,
			},
			{
				cidade: 'Roque Gonzales',
				id_cidade: 4201,
			},
			{
				cidade: 'Rosário do Sul',
				id_cidade: 4202,
			},
			{
				cidade: 'Sagrada Família',
				id_cidade: 4203,
			},
			{
				cidade: 'Saldanha Marinho',
				id_cidade: 4204,
			},
			{
				cidade: 'Salto do Jacuí',
				id_cidade: 4205,
			},
			{
				cidade: 'Salvador das Missões',
				id_cidade: 4206,
			},
			{
				cidade: 'Salvador do Sul',
				id_cidade: 4207,
			},
			{
				cidade: 'Sananduva',
				id_cidade: 4208,
			},
			{
				cidade: 'Santa Bárbara do Sul',
				id_cidade: 4209,
			},
			{
				cidade: 'Santa Cecília do Sul',
				id_cidade: 4210,
			},
			{
				cidade: 'Santa Clara do Sul',
				id_cidade: 4211,
			},
			{
				cidade: 'Santa Cruz do Sul',
				id_cidade: 4212,
			},
			{
				cidade: 'Arroio dos Ratos',
				id_cidade: 3874,
			},
			{
				cidade: 'Arroio Grande',
				id_cidade: 3875,
			},
			{
				cidade: 'Arvorezinha',
				id_cidade: 3876,
			},
			{
				cidade: 'Santa Margarida do Sul',
				id_cidade: 4213,
			},
			{
				cidade: 'Santa Maria',
				id_cidade: 4214,
			},
			{
				cidade: 'Santa Maria do Herval',
				id_cidade: 4215,
			},
			{
				cidade: 'Santana da Boa Vista',
				id_cidade: 4219,
			},
			{
				cidade: 'Aceguá',
				id_cidade: 3850,
			},
			{
				cidade: 'Água Santa',
				id_cidade: 3851,
			},
			{
				cidade: 'Alto Alegre',
				id_cidade: 3859,
			},
			{
				cidade: 'Alto Feliz',
				id_cidade: 3860,
			},
			{
				cidade: 'Alvorada',
				id_cidade: 3861,
			},
			{
				cidade: 'Ametista do Sul',
				id_cidade: 3863,
			},
			{
				cidade: 'Santa Rosa',
				id_cidade: 4216,
			},
			{
				cidade: 'Santa Tereza',
				id_cidade: 4217,
			},
			{
				cidade: 'Santa Vitória do Palmar',
				id_cidade: 4218,
			},
			{
				cidade: 'Santiago',
				id_cidade: 4221,
			},
			{
				cidade: 'Santo Ângelo',
				id_cidade: 4222,
			},
			{
				cidade: 'Santo Antônio da Patrulha',
				id_cidade: 4223,
			},
			{
				cidade: 'Santo Antônio das Missões',
				id_cidade: 4224,
			},
			{
				cidade: 'Santo Antônio do Palma',
				id_cidade: 4225,
			},
			{
				cidade: 'Santo Antônio do Planalto',
				id_cidade: 4226,
			},
			{
				cidade: 'Santo Augusto',
				id_cidade: 4227,
			},
			{
				cidade: 'Santo Cristo',
				id_cidade: 4228,
			},
			{
				cidade: 'Santo Expedito do Sul',
				id_cidade: 4229,
			},
			{
				cidade: 'São Borja',
				id_cidade: 4230,
			},
			{
				cidade: 'São Domingos do Sul',
				id_cidade: 4231,
			},
			{
				cidade: 'São Francisco de Assis',
				id_cidade: 4232,
			},
			{
				cidade: 'São Francisco de Paula',
				id_cidade: 4233,
			},
			{
				cidade: 'São Gabriel',
				id_cidade: 4234,
			},
			{
				cidade: 'André da Rocha',
				id_cidade: 3864,
			},
			{
				cidade: 'São Jerônimo',
				id_cidade: 4235,
			},
			{
				cidade: 'São João da Urtiga',
				id_cidade: 4236,
			},
			{
				cidade: 'São João do Polêsine',
				id_cidade: 4237,
			},
			{
				cidade: 'São Jorge',
				id_cidade: 4238,
			},
			{
				cidade: 'São José das Missões',
				id_cidade: 4239,
			},
			{
				cidade: 'São José do Herval',
				id_cidade: 4240,
			},
			{
				cidade: 'São José do Hortêncio',
				id_cidade: 4241,
			},
			{
				cidade: 'São José do Inhacorá',
				id_cidade: 4242,
			},
			{
				cidade: 'São José do Norte',
				id_cidade: 4243,
			},
			{
				cidade: 'São José do Ouro',
				id_cidade: 4244,
			},
			{
				cidade: 'São José dos Ausentes',
				id_cidade: 4246,
			},
			{
				cidade: 'São José do Sul',
				id_cidade: 4245,
			},
			{
				cidade: 'São Leopoldo',
				id_cidade: 4247,
			},
			{
				cidade: 'São Lourenço do Sul',
				id_cidade: 4248,
			},
			{
				cidade: 'São Luiz Gonzaga',
				id_cidade: 4249,
			},
			{
				cidade: 'São Marcos',
				id_cidade: 4250,
			},
			{
				cidade: 'São Martinho',
				id_cidade: 4251,
			},
			{
				cidade: 'São Martinho da Serra',
				id_cidade: 4252,
			},
			{
				cidade: 'São Miguel das Missões',
				id_cidade: 4253,
			},
			{
				cidade: 'São Nicolau',
				id_cidade: 4254,
			},
			{
				cidade: 'São Paulo das Missões',
				id_cidade: 4255,
			},
			{
				cidade: 'São Pedro da Serra',
				id_cidade: 4256,
			},
			{
				cidade: 'São Pedro das Missões',
				id_cidade: 4257,
			},
			{
				cidade: 'São Pedro do Butiá',
				id_cidade: 4258,
			},
			{
				cidade: 'São Pedro do Sul',
				id_cidade: 4259,
			},
			{
				cidade: 'São Sebastião do Caí',
				id_cidade: 4260,
			},
			{
				cidade: 'São Sepé',
				id_cidade: 4261,
			},
			{
				cidade: 'São Valentim',
				id_cidade: 4262,
			},
			{
				cidade: 'São Valentim do Sul',
				id_cidade: 4263,
			},
			{
				cidade: 'São Valério do Sul',
				id_cidade: 4264,
			},
			{
				cidade: 'São Vendelino',
				id_cidade: 4265,
			},
			{
				cidade: 'São Vicente do Sul',
				id_cidade: 4266,
			},
			{
				cidade: 'Sapiranga',
				id_cidade: 4267,
			},
			{
				cidade: 'Sapucaia do Sul',
				id_cidade: 4268,
			},
			{
				cidade: 'Sarandi',
				id_cidade: 4269,
			},
			{
				cidade: 'Seberi',
				id_cidade: 4270,
			},
			{
				cidade: 'Sede Nova',
				id_cidade: 4271,
			},
			{
				cidade: 'Segredo',
				id_cidade: 4272,
			},
			{
				cidade: 'Selbach',
				id_cidade: 4273,
			},
			{
				cidade: 'Senador Salgado Filho',
				id_cidade: 4274,
			},
			{
				cidade: 'Sentinela do Sul',
				id_cidade: 4275,
			},
			{
				cidade: 'Serafina Corrêa',
				id_cidade: 4276,
			},
			{
				cidade: 'Sério',
				id_cidade: 4277,
			},
			{
				cidade: 'Anta Gorda',
				id_cidade: 3865,
			},
			{
				cidade: 'Antônio Prado',
				id_cidade: 3866,
			},
			{
				cidade: 'Sertão',
				id_cidade: 4278,
			},
			{
				cidade: 'Sertão Santana',
				id_cidade: 4279,
			},
			{
				cidade: 'Sete de Setembro',
				id_cidade: 4280,
			},
			{
				cidade: 'Severiano de Almeida',
				id_cidade: 4281,
			},
			{
				cidade: 'Silveira Martins',
				id_cidade: 4282,
			},
			{
				cidade: 'Sinimbu',
				id_cidade: 4283,
			},
			{
				cidade: 'Sobradinho',
				id_cidade: 4284,
			},
			{
				cidade: 'Soledade',
				id_cidade: 4285,
			},
			{
				cidade: 'Tabaí',
				id_cidade: 4286,
			},
			{
				cidade: 'Tapejara',
				id_cidade: 4287,
			},
			{
				cidade: 'Tapera',
				id_cidade: 4288,
			},
			{
				cidade: 'Tapes',
				id_cidade: 4289,
			},
			{
				cidade: 'Taquara',
				id_cidade: 4290,
			},
			{
				cidade: 'Taquari',
				id_cidade: 4291,
			},
			{
				cidade: 'Taquaruçu do Sul',
				id_cidade: 4292,
			},
			{
				cidade: 'Arambaré',
				id_cidade: 3867,
			},
			{
				cidade: 'Araricá',
				id_cidade: 3868,
			},
			{
				cidade: 'Engenho Velho',
				id_cidade: 3993,
			},
			{
				cidade: 'Tavares',
				id_cidade: 4293,
			},
			{
				cidade: 'Tenente Portela',
				id_cidade: 4294,
			},
			{
				cidade: 'Terra de Areia',
				id_cidade: 4295,
			},
			{
				cidade: 'Teutônia',
				id_cidade: 4296,
			},
			{
				cidade: 'Tio Hugo',
				id_cidade: 4297,
			},
			{
				cidade: 'Tiradentes do Sul',
				id_cidade: 4298,
			},
			{
				cidade: 'Toropi',
				id_cidade: 4299,
			},
			{
				cidade: 'Torres',
				id_cidade: 4300,
			},
			{
				cidade: 'Tramandaí',
				id_cidade: 4301,
			},
			{
				cidade: 'Travesseiro',
				id_cidade: 4302,
			},
			{
				cidade: 'Três Arroios',
				id_cidade: 4303,
			},
			{
				cidade: 'Três Cachoeiras',
				id_cidade: 4304,
			},
			{
				cidade: 'Três Coroas',
				id_cidade: 4305,
			},
			{
				cidade: 'Três de Maio',
				id_cidade: 4306,
			},
			{
				cidade: 'Três Forquilhas',
				id_cidade: 4307,
			},
			{
				cidade: 'Três Palmeiras',
				id_cidade: 4308,
			},
			{
				cidade: 'Três Passos',
				id_cidade: 4309,
			},
			{
				cidade: 'Trindade do Sul',
				id_cidade: 4310,
			},
			{
				cidade: 'Triunfo',
				id_cidade: 4311,
			},
			{
				cidade: 'Tucunduva',
				id_cidade: 4312,
			},
			{
				cidade: 'Tunas',
				id_cidade: 4313,
			},
			{
				cidade: 'Tupanci do Sul',
				id_cidade: 4314,
			},
			{
				cidade: 'Tupanciretã',
				id_cidade: 4315,
			},
			{
				cidade: 'Tupandi',
				id_cidade: 4316,
			},
			{
				cidade: 'Tuparendi',
				id_cidade: 4317,
			},
			{
				cidade: 'Turuçu',
				id_cidade: 4318,
			},
			{
				cidade: 'Ubiretama',
				id_cidade: 4319,
			},
			{
				cidade: 'União da Serra',
				id_cidade: 4320,
			},
			{
				cidade: 'Unistalda',
				id_cidade: 4321,
			},
			{
				cidade: 'Uruguaiana',
				id_cidade: 4322,
			},
			{
				cidade: 'Vacaria',
				id_cidade: 4323,
			},
			{
				cidade: 'Vale do Sol',
				id_cidade: 4324,
			},
			{
				cidade: 'Vale Real',
				id_cidade: 4325,
			},
			{
				cidade: 'Vale Verde',
				id_cidade: 4326,
			},
			{
				cidade: 'Vanini',
				id_cidade: 4327,
			},
			{
				cidade: 'Venâncio Aires',
				id_cidade: 4328,
			},
			{
				cidade: 'Vera Cruz',
				id_cidade: 4329,
			},
			{
				cidade: 'Veranópolis',
				id_cidade: 4330,
			},
			{
				cidade: 'Vespasiano Correa',
				id_cidade: 4331,
			},
			{
				cidade: 'Viadutos',
				id_cidade: 4332,
			},
			{
				cidade: 'Viamão',
				id_cidade: 4333,
			},
			{
				cidade: 'Vicente Dutra',
				id_cidade: 4334,
			},
			{
				cidade: 'Victor Graeff',
				id_cidade: 4335,
			},
			{
				cidade: 'Vila Flores',
				id_cidade: 4336,
			},
			{
				cidade: 'Vila Lângaro',
				id_cidade: 4337,
			},
			{
				cidade: 'Vila Maria',
				id_cidade: 4338,
			},
			{
				cidade: 'Vila Nova do Sul',
				id_cidade: 4339,
			},
			{
				cidade: 'Vista Alegre',
				id_cidade: 4340,
			},
			{
				cidade: 'Vista Alegre do Prata',
				id_cidade: 4341,
			},
			{
				cidade: 'Vista Gaúcha',
				id_cidade: 4342,
			},
			{
				cidade: 'Vitória das Missões',
				id_cidade: 4343,
			},
			{
				cidade: 'Westfália',
				id_cidade: 4344,
			},
			{
				cidade: 'Xangri-lá',
				id_cidade: 4345,
			},
		],
	},
	{
		estado: 'Santa Catarina',
		sigla: 'SC',
		id_estado: 24,
		cidades: [
			{
				cidade: 'Águas Mornas',
				id_cidade: 4420,
			},
			{
				cidade: 'Alfredo Wagner',
				id_cidade: 4421,
			},
			{
				cidade: 'Armazém',
				id_cidade: 4432,
			},
			{
				cidade: 'Atalanta',
				id_cidade: 4436,
			},
			{
				cidade: 'Aurora',
				id_cidade: 4437,
			},
			{
				cidade: 'Balneário Arroio do Silva',
				id_cidade: 4438,
			},
			{
				cidade: 'Balneário Barra do Sul',
				id_cidade: 4439,
			},
			{
				cidade: 'Balneário Camboriú',
				id_cidade: 4440,
			},
			{
				cidade: 'Balneário Gaivota',
				id_cidade: 4441,
			},
			{
				cidade: 'Bandeirante',
				id_cidade: 4442,
			},
			{
				cidade: 'Barra Bonita',
				id_cidade: 4443,
			},
			{
				cidade: 'Barra Velha',
				id_cidade: 4444,
			},
			{
				cidade: 'Bela Vista do Toldo',
				id_cidade: 4445,
			},
			{
				cidade: 'Belmonte',
				id_cidade: 4446,
			},
			{
				cidade: 'Benedito Novo',
				id_cidade: 4447,
			},
			{
				cidade: 'Biguaçu',
				id_cidade: 4448,
			},
			{
				cidade: 'Blumenau',
				id_cidade: 4449,
			},
			{
				cidade: 'Bocaina do Sul',
				id_cidade: 4450,
			},
			{
				cidade: 'Bombinhas',
				id_cidade: 4455,
			},
			{
				cidade: 'Bom Jardim da Serra',
				id_cidade: 4451,
			},
			{
				cidade: 'Bom Jesus',
				id_cidade: 4452,
			},
			{
				cidade: 'Bom Jesus do Oeste',
				id_cidade: 4453,
			},
			{
				cidade: 'Bom Retiro',
				id_cidade: 4454,
			},
			{
				cidade: 'Botuverá',
				id_cidade: 4456,
			},
			{
				cidade: 'Braço do Norte',
				id_cidade: 4457,
			},
			{
				cidade: 'Braço do Trombudo',
				id_cidade: 4458,
			},
			{
				cidade: 'Brunópolis',
				id_cidade: 4459,
			},
			{
				cidade: 'Brusque',
				id_cidade: 4460,
			},
			{
				cidade: 'Caçador',
				id_cidade: 4461,
			},
			{
				cidade: 'Caibi',
				id_cidade: 4462,
			},
			{
				cidade: 'Calmon',
				id_cidade: 4463,
			},
			{
				cidade: 'Camboriú',
				id_cidade: 4464,
			},
			{
				cidade: 'Campo Alegre',
				id_cidade: 4465,
			},
			{
				cidade: 'Campo Belo do Sul',
				id_cidade: 4466,
			},
			{
				cidade: 'Campo Erê',
				id_cidade: 4467,
			},
			{
				cidade: 'Campos Novos',
				id_cidade: 4468,
			},
			{
				cidade: 'Canelinha',
				id_cidade: 4469,
			},
			{
				cidade: 'Canoinhas',
				id_cidade: 4470,
			},
			{
				cidade: 'Capão Alto',
				id_cidade: 4471,
			},
			{
				cidade: 'Capinzal',
				id_cidade: 4472,
			},
			{
				cidade: 'Capivari de Baixo',
				id_cidade: 4473,
			},
			{
				cidade: 'Catanduvas',
				id_cidade: 4474,
			},
			{
				cidade: 'Caxambu do Sul',
				id_cidade: 4475,
			},
			{
				cidade: 'Celso Ramos',
				id_cidade: 4476,
			},
			{
				cidade: 'Cerro Negro',
				id_cidade: 4477,
			},
			{
				cidade: 'Chapadão do Lageado',
				id_cidade: 4478,
			},
			{
				cidade: 'Chapecó',
				id_cidade: 4479,
			},
			{
				cidade: 'Cocal do Sul',
				id_cidade: 4480,
			},
			{
				cidade: 'Concórdia',
				id_cidade: 4481,
			},
			{
				cidade: 'Cordilheira Alta',
				id_cidade: 4482,
			},
			{
				cidade: 'Coronel Freitas',
				id_cidade: 4483,
			},
			{
				cidade: 'Coronel Martins',
				id_cidade: 4484,
			},
			{
				cidade: 'Correia Pinto',
				id_cidade: 4485,
			},
			{
				cidade: 'Corupá',
				id_cidade: 4486,
			},
			{
				cidade: 'Criciúma',
				id_cidade: 4487,
			},
			{
				cidade: 'Cunha Porã',
				id_cidade: 4488,
			},
			{
				cidade: 'Cunhataí',
				id_cidade: 4489,
			},
			{
				cidade: 'Curitibanos',
				id_cidade: 4490,
			},
			{
				cidade: 'Descanso',
				id_cidade: 4491,
			},
			{
				cidade: 'Dionísio Cerqueira',
				id_cidade: 4492,
			},
			{
				cidade: 'Dona Emma',
				id_cidade: 4493,
			},
			{
				cidade: 'Doutor Pedrinho',
				id_cidade: 4494,
			},
			{
				cidade: 'Entre Rios',
				id_cidade: 4495,
			},
			{
				cidade: 'Ermo',
				id_cidade: 4496,
			},
			{
				cidade: 'Erval Velho',
				id_cidade: 4497,
			},
			{
				cidade: 'Faxinal dos Guedes',
				id_cidade: 4498,
			},
			{
				cidade: 'Flor do Sertão',
				id_cidade: 4499,
			},
			{
				cidade: 'Florianópolis',
				id_cidade: 4500,
			},
			{
				cidade: 'Formosa do Sul',
				id_cidade: 4501,
			},
			{
				cidade: 'Forquilhinha',
				id_cidade: 4502,
			},
			{
				cidade: 'Fraiburgo',
				id_cidade: 4503,
			},
			{
				cidade: 'Frei Rogério',
				id_cidade: 4504,
			},
			{
				cidade: 'Galvão',
				id_cidade: 4505,
			},
			{
				cidade: 'Garopaba',
				id_cidade: 4506,
			},
			{
				cidade: 'Garuva',
				id_cidade: 4507,
			},
			{
				cidade: 'Gaspar',
				id_cidade: 4508,
			},
			{
				cidade: 'Governador Celso Ramos',
				id_cidade: 4509,
			},
			{
				cidade: 'Grão Pará',
				id_cidade: 4510,
			},
			{
				cidade: 'Gravatal',
				id_cidade: 4511,
			},
			{
				cidade: 'Guabiruba',
				id_cidade: 4512,
			},
			{
				cidade: 'Guaraciaba',
				id_cidade: 4513,
			},
			{
				cidade: 'Guaramirim',
				id_cidade: 4514,
			},
			{
				cidade: 'Guarujá do Sul',
				id_cidade: 4515,
			},
			{
				cidade: 'Guatambú',
				id_cidade: 4516,
			},
			{
				cidade: "Herval d'Oeste",
				id_cidade: 4517,
			},
			{
				cidade: 'Ibiam',
				id_cidade: 4518,
			},
			{
				cidade: 'Ibicaré',
				id_cidade: 4519,
			},
			{
				cidade: 'Ibirama',
				id_cidade: 4520,
			},
			{
				cidade: 'Içara',
				id_cidade: 4521,
			},
			{
				cidade: 'Ilhota',
				id_cidade: 4522,
			},
			{
				cidade: 'Imaruí',
				id_cidade: 4523,
			},
			{
				cidade: 'Imbituba',
				id_cidade: 4524,
			},
			{
				cidade: 'Imbuia',
				id_cidade: 4525,
			},
			{
				cidade: 'Indaial',
				id_cidade: 4526,
			},
			{
				cidade: 'Iomerê',
				id_cidade: 4527,
			},
			{
				cidade: 'Ipira',
				id_cidade: 4528,
			},
			{
				cidade: 'Iporã do Oeste',
				id_cidade: 4529,
			},
			{
				cidade: 'Ipuaçu',
				id_cidade: 4530,
			},
			{
				cidade: 'Ipumirim',
				id_cidade: 4531,
			},
			{
				cidade: 'Iraceminha',
				id_cidade: 4532,
			},
			{
				cidade: 'Irani',
				id_cidade: 4533,
			},
			{
				cidade: 'Irati',
				id_cidade: 4534,
			},
			{
				cidade: 'Irineópolis',
				id_cidade: 4535,
			},
			{
				cidade: 'Itá',
				id_cidade: 4536,
			},
			{
				cidade: 'Itaiópolis',
				id_cidade: 4537,
			},
			{
				cidade: 'Itajaí',
				id_cidade: 4538,
			},
			{
				cidade: 'Itapema',
				id_cidade: 4539,
			},
			{
				cidade: 'Itapiranga',
				id_cidade: 4540,
			},
			{
				cidade: 'Itapoá',
				id_cidade: 4541,
			},
			{
				cidade: 'Ituporanga',
				id_cidade: 4542,
			},
			{
				cidade: 'Jaborá',
				id_cidade: 4543,
			},
			{
				cidade: 'Jacinto Machado',
				id_cidade: 4544,
			},
			{
				cidade: 'Jaguaruna',
				id_cidade: 4545,
			},
			{
				cidade: 'Jaraguá do Sul',
				id_cidade: 4546,
			},
			{
				cidade: 'Jardinópolis',
				id_cidade: 4547,
			},
			{
				cidade: 'Joaçaba',
				id_cidade: 4548,
			},
			{
				cidade: 'Joinville',
				id_cidade: 4549,
			},
			{
				cidade: 'José Boiteux',
				id_cidade: 4550,
			},
			{
				cidade: 'Jupiá',
				id_cidade: 4551,
			},
			{
				cidade: 'Lacerdópolis',
				id_cidade: 4552,
			},
			{
				cidade: 'Lages',
				id_cidade: 4553,
			},
			{
				cidade: 'Laguna',
				id_cidade: 4554,
			},
			{
				cidade: 'Lajeado Grande',
				id_cidade: 4555,
			},
			{
				cidade: 'Laurentino',
				id_cidade: 4556,
			},
			{
				cidade: 'Lauro Muller',
				id_cidade: 4557,
			},
			{
				cidade: 'Lebon Régis',
				id_cidade: 4558,
			},
			{
				cidade: 'Leoberto Leal',
				id_cidade: 4559,
			},
			{
				cidade: 'Lindóia do Sul',
				id_cidade: 4560,
			},
			{
				cidade: 'Lontras',
				id_cidade: 4561,
			},
			{
				cidade: 'Luiz Alves',
				id_cidade: 4562,
			},
			{
				cidade: 'Luzerna',
				id_cidade: 4563,
			},
			{
				cidade: 'Macieira',
				id_cidade: 4564,
			},
			{
				cidade: 'Mafra',
				id_cidade: 4565,
			},
			{
				cidade: 'Major Gercino',
				id_cidade: 4566,
			},
			{
				cidade: 'Major Vieira',
				id_cidade: 4567,
			},
			{
				cidade: 'Maracajá',
				id_cidade: 4568,
			},
			{
				cidade: 'Maravilha',
				id_cidade: 4569,
			},
			{
				cidade: 'Marema',
				id_cidade: 4570,
			},
			{
				cidade: 'Massaranduba',
				id_cidade: 4571,
			},
			{
				cidade: 'Matos Costa',
				id_cidade: 4572,
			},
			{
				cidade: 'Meleiro',
				id_cidade: 4573,
			},
			{
				cidade: 'Mirim Doce',
				id_cidade: 4574,
			},
			{
				cidade: 'Modelo',
				id_cidade: 4575,
			},
			{
				cidade: 'Mondaí',
				id_cidade: 4576,
			},
			{
				cidade: 'Monte Carlo',
				id_cidade: 4577,
			},
			{
				cidade: 'Monte Castelo',
				id_cidade: 4578,
			},
			{
				cidade: 'Morro da Fumaça',
				id_cidade: 4579,
			},
			{
				cidade: 'Morro Grande',
				id_cidade: 4580,
			},
			{
				cidade: 'Navegantes',
				id_cidade: 4581,
			},
			{
				cidade: 'Nova Erechim',
				id_cidade: 4582,
			},
			{
				cidade: 'Nova Itaberaba',
				id_cidade: 4583,
			},
			{
				cidade: 'Nova Trento',
				id_cidade: 4584,
			},
			{
				cidade: 'Nova Veneza',
				id_cidade: 4585,
			},
			{
				cidade: 'Novo Horizonte',
				id_cidade: 4586,
			},
			{
				cidade: 'Orleans',
				id_cidade: 4587,
			},
			{
				cidade: 'Otacílio Costa',
				id_cidade: 4588,
			},
			{
				cidade: 'Ouro',
				id_cidade: 4589,
			},
			{
				cidade: 'Ouro Verde',
				id_cidade: 4590,
			},
			{
				cidade: 'Paial',
				id_cidade: 4591,
			},
			{
				cidade: 'Painel',
				id_cidade: 4592,
			},
			{
				cidade: 'Palhoça',
				id_cidade: 4593,
			},
			{
				cidade: 'Palma Sola',
				id_cidade: 4594,
			},
			{
				cidade: 'Palmeira',
				id_cidade: 4595,
			},
			{
				cidade: 'Palmitos',
				id_cidade: 4596,
			},
			{
				cidade: 'Papanduva',
				id_cidade: 4597,
			},
			{
				cidade: 'Paraíso',
				id_cidade: 4598,
			},
			{
				cidade: 'Passo de Torres',
				id_cidade: 4599,
			},
			{
				cidade: 'Passos Maia',
				id_cidade: 4600,
			},
			{
				cidade: 'Paulo Lopes',
				id_cidade: 4601,
			},
			{
				cidade: 'Pedras Grandes',
				id_cidade: 4602,
			},
			{
				cidade: 'Penha',
				id_cidade: 4603,
			},
			{
				cidade: 'Peritiba',
				id_cidade: 4604,
			},
			{
				cidade: 'Petrolândia',
				id_cidade: 4605,
			},
			{
				cidade: 'Pinhalzinho',
				id_cidade: 4607,
			},
			{
				cidade: 'Pinheiro Preto',
				id_cidade: 4608,
			},
			{
				cidade: 'Piratuba',
				id_cidade: 4609,
			},
			{
				cidade: 'Planalto Alegre',
				id_cidade: 4610,
			},
			{
				cidade: 'Pomerode',
				id_cidade: 4611,
			},
			{
				cidade: 'Ponte Alta',
				id_cidade: 4612,
			},
			{
				cidade: 'Ponte Alta do Norte',
				id_cidade: 4613,
			},
			{
				cidade: 'Ponte Serrada',
				id_cidade: 4614,
			},
			{
				cidade: 'Porto Belo',
				id_cidade: 4615,
			},
			{
				cidade: 'Porto União',
				id_cidade: 4616,
			},
			{
				cidade: 'Pouso Redondo',
				id_cidade: 4617,
			},
			{
				cidade: 'Praia Grande',
				id_cidade: 4618,
			},
			{
				cidade: 'Presidente Castelo Branco',
				id_cidade: 4619,
			},
			{
				cidade: 'Presidente Getúlio',
				id_cidade: 4620,
			},
			{
				cidade: 'Presidente Nereu',
				id_cidade: 4621,
			},
			{
				cidade: 'Princesa',
				id_cidade: 4622,
			},
			{
				cidade: 'Quilombo',
				id_cidade: 4623,
			},
			{
				cidade: 'Rancho Queimado',
				id_cidade: 4624,
			},
			{
				cidade: 'Rio das Antas',
				id_cidade: 4625,
			},
			{
				cidade: 'Rio do Campo',
				id_cidade: 4626,
			},
			{
				cidade: 'Rio do Oeste',
				id_cidade: 4627,
			},
			{
				cidade: 'Rio dos Cedros',
				id_cidade: 4629,
			},
			{
				cidade: 'Rio do Sul',
				id_cidade: 4628,
			},
			{
				cidade: 'Rio Fortuna',
				id_cidade: 4630,
			},
			{
				cidade: 'Rio Negrinho',
				id_cidade: 4631,
			},
			{
				cidade: 'Rio Rufino',
				id_cidade: 4632,
			},
			{
				cidade: 'Riqueza',
				id_cidade: 4633,
			},
			{
				cidade: 'Rodeio',
				id_cidade: 4634,
			},
			{
				cidade: 'Romelândia',
				id_cidade: 4635,
			},
			{
				cidade: 'Piçarras',
				id_cidade: 4606,
			},
			{
				cidade: 'Salete',
				id_cidade: 4636,
			},
			{
				cidade: 'Saltinho',
				id_cidade: 4637,
			},
			{
				cidade: 'Salto Veloso',
				id_cidade: 4638,
			},
			{
				cidade: 'Sangão',
				id_cidade: 4639,
			},
			{
				cidade: 'Santa Cecília',
				id_cidade: 4640,
			},
			{
				cidade: 'Arroio Trinta',
				id_cidade: 4433,
			},
			{
				cidade: 'Arvoredo',
				id_cidade: 4434,
			},
			{
				cidade: 'Ascurra',
				id_cidade: 4435,
			},
			{
				cidade: 'Santa Helena',
				id_cidade: 4641,
			},
			{
				cidade: 'Abdon Batista',
				id_cidade: 4413,
			},
			{
				cidade: 'Abelardo Luz',
				id_cidade: 4414,
			},
			{
				cidade: 'Agrolândia',
				id_cidade: 4415,
			},
			{
				cidade: 'Agronômica',
				id_cidade: 4416,
			},
			{
				cidade: 'Água Doce',
				id_cidade: 4417,
			},
			{
				cidade: 'Águas de Chapecó',
				id_cidade: 4418,
			},
			{
				cidade: 'Águas Frias',
				id_cidade: 4419,
			},
			{
				cidade: 'Alto Bela Vista',
				id_cidade: 4422,
			},
			{
				cidade: 'Anchieta',
				id_cidade: 4423,
			},
			{
				cidade: 'Santa Rosa de Lima',
				id_cidade: 4642,
			},
			{
				cidade: 'Santa Rosa do Sul',
				id_cidade: 4643,
			},
			{
				cidade: 'Santa Terezinha',
				id_cidade: 4644,
			},
			{
				cidade: 'Santa Terezinha do Progresso',
				id_cidade: 4645,
			},
			{
				cidade: 'Santiago do Sul',
				id_cidade: 4646,
			},
			{
				cidade: 'Santo Amaro da Imperatriz',
				id_cidade: 4647,
			},
			{
				cidade: 'São Bento do Sul',
				id_cidade: 4648,
			},
			{
				cidade: 'São Bernardino',
				id_cidade: 4649,
			},
			{
				cidade: 'São Bonifácio',
				id_cidade: 4650,
			},
			{
				cidade: 'São Carlos',
				id_cidade: 4651,
			},
			{
				cidade: 'São Cristovão do Sul',
				id_cidade: 4652,
			},
			{
				cidade: 'São Domingos',
				id_cidade: 4653,
			},
			{
				cidade: 'São Francisco do Sul',
				id_cidade: 4654,
			},
			{
				cidade: 'São João Batista',
				id_cidade: 4655,
			},
			{
				cidade: 'São João do Itaperiú',
				id_cidade: 4656,
			},
			{
				cidade: 'São João do Oeste',
				id_cidade: 4657,
			},
			{
				cidade: 'São João do Sul',
				id_cidade: 4658,
			},
			{
				cidade: 'São Joaquim',
				id_cidade: 4659,
			},
			{
				cidade: 'São José',
				id_cidade: 4660,
			},
			{
				cidade: 'São José do Cedro',
				id_cidade: 4661,
			},
			{
				cidade: 'São José do Cerrito',
				id_cidade: 4662,
			},
			{
				cidade: 'Angelina',
				id_cidade: 4424,
			},
			{
				cidade: 'São Lourenço do Oeste',
				id_cidade: 4663,
			},
			{
				cidade: 'São Ludgero',
				id_cidade: 4664,
			},
			{
				cidade: 'São Martinho',
				id_cidade: 4665,
			},
			{
				cidade: 'São Miguel da Boa Vista',
				id_cidade: 4666,
			},
			{
				cidade: 'São Miguel do Oeste',
				id_cidade: 4667,
			},
			{
				cidade: 'São Pedro de Alcântara',
				id_cidade: 4668,
			},
			{
				cidade: 'Saudades',
				id_cidade: 4669,
			},
			{
				cidade: 'Schroeder',
				id_cidade: 4670,
			},
			{
				cidade: 'Seara',
				id_cidade: 4671,
			},
			{
				cidade: 'Serra Alta',
				id_cidade: 4672,
			},
			{
				cidade: 'Anita Garibaldi',
				id_cidade: 4425,
			},
			{
				cidade: 'Anitápolis',
				id_cidade: 4426,
			},
			{
				cidade: 'Antônio Carlos',
				id_cidade: 4427,
			},
			{
				cidade: 'Apiúna',
				id_cidade: 4428,
			},
			{
				cidade: 'Arabutã',
				id_cidade: 4429,
			},
			{
				cidade: 'Siderópolis',
				id_cidade: 4673,
			},
			{
				cidade: 'Sombrio',
				id_cidade: 4674,
			},
			{
				cidade: 'Sul Brasil',
				id_cidade: 4675,
			},
			{
				cidade: 'Taió',
				id_cidade: 4676,
			},
			{
				cidade: 'Tangará',
				id_cidade: 4677,
			},
			{
				cidade: 'Araquari',
				id_cidade: 4430,
			},
			{
				cidade: 'Araranguá',
				id_cidade: 4431,
			},
			{
				cidade: 'Tigrinhos',
				id_cidade: 4678,
			},
			{
				cidade: 'Tijucas',
				id_cidade: 4679,
			},
			{
				cidade: 'Timbé do Sul',
				id_cidade: 4680,
			},
			{
				cidade: 'Timbó',
				id_cidade: 4681,
			},
			{
				cidade: 'Timbó Grande',
				id_cidade: 4682,
			},
			{
				cidade: 'Três Barras',
				id_cidade: 4683,
			},
			{
				cidade: 'Treviso',
				id_cidade: 4684,
			},
			{
				cidade: 'Treze de Maio',
				id_cidade: 4685,
			},
			{
				cidade: 'Treze Tílias',
				id_cidade: 4686,
			},
			{
				cidade: 'Trombudo Central',
				id_cidade: 4687,
			},
			{
				cidade: 'Tubarão',
				id_cidade: 4688,
			},
			{
				cidade: 'Tunápolis',
				id_cidade: 4689,
			},
			{
				cidade: 'Turvo',
				id_cidade: 4690,
			},
			{
				cidade: 'União do Oeste',
				id_cidade: 4691,
			},
			{
				cidade: 'Urubici',
				id_cidade: 4692,
			},
			{
				cidade: 'Urupema',
				id_cidade: 4693,
			},
			{
				cidade: 'Urussanga',
				id_cidade: 4694,
			},
			{
				cidade: 'Vargeão',
				id_cidade: 4695,
			},
			{
				cidade: 'Vargem',
				id_cidade: 4696,
			},
			{
				cidade: 'Vargem Bonita',
				id_cidade: 4697,
			},
			{
				cidade: 'Vidal Ramos',
				id_cidade: 4698,
			},
			{
				cidade: 'Videira',
				id_cidade: 4699,
			},
			{
				cidade: 'Vitor Meireles',
				id_cidade: 4700,
			},
			{
				cidade: 'Witmarsum',
				id_cidade: 4701,
			},
			{
				cidade: 'Xanxerê',
				id_cidade: 4702,
			},
			{
				cidade: 'Xavantina',
				id_cidade: 4703,
			},
			{
				cidade: 'Xaxim',
				id_cidade: 4704,
			},
			{
				cidade: 'Zortéa',
				id_cidade: 4705,
			},
		],
	},
	{
		estado: 'Sergipe',
		sigla: 'SE',
		id_estado: 25,
		cidades: [
			{
				cidade: 'Arauá',
				id_cidade: 5354,
			},
			{
				cidade: 'Areia Branca',
				id_cidade: 5355,
			},
			{
				cidade: 'Barra dos Coqueiros',
				id_cidade: 5356,
			},
			{
				cidade: 'Boquim',
				id_cidade: 5357,
			},
			{
				cidade: 'Brejo Grande',
				id_cidade: 5358,
			},
			{
				cidade: 'Campo do Brito',
				id_cidade: 5359,
			},
			{
				cidade: 'Canhoba',
				id_cidade: 5360,
			},
			{
				cidade: 'Canindé de São Francisco',
				id_cidade: 5361,
			},
			{
				cidade: 'Capela',
				id_cidade: 5362,
			},
			{
				cidade: 'Carira',
				id_cidade: 5363,
			},
			{
				cidade: 'Carmópolis',
				id_cidade: 5364,
			},
			{
				cidade: 'Cedro de São João',
				id_cidade: 5365,
			},
			{
				cidade: 'Cristinápolis',
				id_cidade: 5366,
			},
			{
				cidade: 'Cumbe',
				id_cidade: 5367,
			},
			{
				cidade: 'Divina Pastora',
				id_cidade: 5368,
			},
			{
				cidade: 'Estância',
				id_cidade: 5369,
			},
			{
				cidade: 'Feira Nova',
				id_cidade: 5370,
			},
			{
				cidade: 'Frei Paulo',
				id_cidade: 5371,
			},
			{
				cidade: 'Gararu',
				id_cidade: 5372,
			},
			{
				cidade: 'General Maynard',
				id_cidade: 5373,
			},
			{
				cidade: 'Gracho Cardoso',
				id_cidade: 5374,
			},
			{
				cidade: 'Ilha das Flores',
				id_cidade: 5375,
			},
			{
				cidade: 'Indiaroba',
				id_cidade: 5376,
			},
			{
				cidade: 'Itabaiana',
				id_cidade: 5377,
			},
			{
				cidade: 'Itabaianinha',
				id_cidade: 5378,
			},
			{
				cidade: 'Itabi',
				id_cidade: 5379,
			},
			{
				cidade: "Itaporanga d'Ajuda",
				id_cidade: 5380,
			},
			{
				cidade: 'Japaratuba',
				id_cidade: 5381,
			},
			{
				cidade: 'Japoatã',
				id_cidade: 5382,
			},
			{
				cidade: 'Lagarto',
				id_cidade: 5383,
			},
			{
				cidade: 'Laranjeiras',
				id_cidade: 5384,
			},
			{
				cidade: 'Macambira',
				id_cidade: 5385,
			},
			{
				cidade: 'Malhada dos Bois',
				id_cidade: 5386,
			},
			{
				cidade: 'Malhador',
				id_cidade: 5387,
			},
			{
				cidade: 'Maruim',
				id_cidade: 5388,
			},
			{
				cidade: 'Moita Bonita',
				id_cidade: 5389,
			},
			{
				cidade: 'Monte Alegre de Sergipe',
				id_cidade: 5390,
			},
			{
				cidade: 'Muribeca',
				id_cidade: 5391,
			},
			{
				cidade: 'Neópolis',
				id_cidade: 5392,
			},
			{
				cidade: 'Nossa Senhora Aparecida',
				id_cidade: 5393,
			},
			{
				cidade: 'Nossa Senhora da Glória',
				id_cidade: 5394,
			},
			{
				cidade: 'Nossa Senhora das Dores',
				id_cidade: 5395,
			},
			{
				cidade: 'Nossa Senhora de Lourdes',
				id_cidade: 5396,
			},
			{
				cidade: 'Nossa Senhora do Socorro',
				id_cidade: 5397,
			},
			{
				cidade: 'Pacatuba',
				id_cidade: 5398,
			},
			{
				cidade: 'Pedra Mole',
				id_cidade: 5399,
			},
			{
				cidade: 'Pedrinhas',
				id_cidade: 5400,
			},
			{
				cidade: 'Pinhão',
				id_cidade: 5401,
			},
			{
				cidade: 'Pirambu',
				id_cidade: 5402,
			},
			{
				cidade: 'Poço Redondo',
				id_cidade: 5403,
			},
			{
				cidade: 'Poço Verde',
				id_cidade: 5404,
			},
			{
				cidade: 'Porto da Folha',
				id_cidade: 5405,
			},
			{
				cidade: 'Propriá',
				id_cidade: 5406,
			},
			{
				cidade: 'Riachão do Dantas',
				id_cidade: 5407,
			},
			{
				cidade: 'Riachuelo',
				id_cidade: 5408,
			},
			{
				cidade: 'Ribeirópolis',
				id_cidade: 5409,
			},
			{
				cidade: 'Rosário do Catete',
				id_cidade: 5410,
			},
			{
				cidade: 'Salgado',
				id_cidade: 5411,
			},
			{
				cidade: 'Santa Luzia do Itanhy',
				id_cidade: 5412,
			},
			{
				cidade: 'Santana do São Francisco',
				id_cidade: 5414,
			},
			{
				cidade: 'Amparo de São Francisco',
				id_cidade: 5351,
			},
			{
				cidade: 'Santa Rosa de Lima',
				id_cidade: 5413,
			},
			{
				cidade: 'Santo Amaro das Brotas',
				id_cidade: 5415,
			},
			{
				cidade: 'São Cristóvão',
				id_cidade: 5416,
			},
			{
				cidade: 'São Domingos',
				id_cidade: 5417,
			},
			{
				cidade: 'São Francisco',
				id_cidade: 5418,
			},
			{
				cidade: 'São Miguel do Aleixo',
				id_cidade: 5419,
			},
			{
				cidade: 'Aquidabã',
				id_cidade: 5352,
			},
			{
				cidade: 'Aracaju',
				id_cidade: 5353,
			},
			{
				cidade: 'Simão Dias',
				id_cidade: 5420,
			},
			{
				cidade: 'Siriri',
				id_cidade: 5421,
			},
			{
				cidade: 'Telha',
				id_cidade: 5422,
			},
			{
				cidade: 'Tobias Barreto',
				id_cidade: 5423,
			},
			{
				cidade: 'Tomar do Geru',
				id_cidade: 5424,
			},
			{
				cidade: 'Umbaúba',
				id_cidade: 5425,
			},
		],
	},
	{
		estado: 'São Paulo',
		sigla: 'SP',
		id_estado: 26,
		cidades: [
			{
				cidade: 'Arco-Íris',
				id_cidade: 4744,
			},
			{
				cidade: 'Arealva',
				id_cidade: 4745,
			},
			{
				cidade: 'Areias',
				id_cidade: 4746,
			},
			{
				cidade: 'Areiópolis',
				id_cidade: 4747,
			},
			{
				cidade: 'Ariranha',
				id_cidade: 4748,
			},
			{
				cidade: 'Atibaia',
				id_cidade: 4753,
			},
			{
				cidade: 'Auriflama',
				id_cidade: 4754,
			},
			{
				cidade: 'Avaí',
				id_cidade: 4755,
			},
			{
				cidade: 'Avanhandava',
				id_cidade: 4756,
			},
			{
				cidade: 'Avaré',
				id_cidade: 4757,
			},
			{
				cidade: 'Bady Bassitt',
				id_cidade: 4758,
			},
			{
				cidade: 'Balbinos',
				id_cidade: 4759,
			},
			{
				cidade: 'Bálsamo',
				id_cidade: 4760,
			},
			{
				cidade: 'Bananal',
				id_cidade: 4761,
			},
			{
				cidade: 'Barão de Antonina',
				id_cidade: 4762,
			},
			{
				cidade: 'Barbosa',
				id_cidade: 4763,
			},
			{
				cidade: 'Bariri',
				id_cidade: 4764,
			},
			{
				cidade: 'Barra Bonita',
				id_cidade: 4765,
			},
			{
				cidade: 'Barra do Chapéu',
				id_cidade: 4766,
			},
			{
				cidade: 'Barra do Turvo',
				id_cidade: 4767,
			},
			{
				cidade: 'Barretos',
				id_cidade: 4768,
			},
			{
				cidade: 'Barrinha',
				id_cidade: 4769,
			},
			{
				cidade: 'Barueri',
				id_cidade: 4770,
			},
			{
				cidade: 'Bastos',
				id_cidade: 4771,
			},
			{
				cidade: 'Batatais',
				id_cidade: 4772,
			},
			{
				cidade: 'Bauru',
				id_cidade: 4773,
			},
			{
				cidade: 'Bebedouro',
				id_cidade: 4774,
			},
			{
				cidade: 'Bento de Abreu',
				id_cidade: 4775,
			},
			{
				cidade: 'Bernardino de Campos',
				id_cidade: 4776,
			},
			{
				cidade: 'Bertioga',
				id_cidade: 4777,
			},
			{
				cidade: 'Bilac',
				id_cidade: 4778,
			},
			{
				cidade: 'Birigui',
				id_cidade: 4779,
			},
			{
				cidade: 'Biritiba-Mirim',
				id_cidade: 4780,
			},
			{
				cidade: 'Boa Esperança do Sul',
				id_cidade: 4781,
			},
			{
				cidade: 'Bocaina',
				id_cidade: 4782,
			},
			{
				cidade: 'Bofete',
				id_cidade: 4783,
			},
			{
				cidade: 'Boituva',
				id_cidade: 4784,
			},
			{
				cidade: 'Bom Jesus dos Perdões',
				id_cidade: 4785,
			},
			{
				cidade: 'Bom Sucesso de Itararé',
				id_cidade: 4786,
			},
			{
				cidade: 'Borá',
				id_cidade: 4787,
			},
			{
				cidade: 'Boracéia',
				id_cidade: 4788,
			},
			{
				cidade: 'Borborema',
				id_cidade: 4789,
			},
			{
				cidade: 'Borebi',
				id_cidade: 4790,
			},
			{
				cidade: 'Botucatu',
				id_cidade: 4791,
			},
			{
				cidade: 'Bragança Paulista',
				id_cidade: 4792,
			},
			{
				cidade: 'Braúna',
				id_cidade: 4793,
			},
			{
				cidade: 'Brejo Alegre',
				id_cidade: 4794,
			},
			{
				cidade: 'Brodowski',
				id_cidade: 4795,
			},
			{
				cidade: 'Brotas',
				id_cidade: 4796,
			},
			{
				cidade: 'Buri',
				id_cidade: 4797,
			},
			{
				cidade: 'Buritama',
				id_cidade: 4798,
			},
			{
				cidade: 'Buritizal',
				id_cidade: 4799,
			},
			{
				cidade: 'Cabrália Paulista',
				id_cidade: 4800,
			},
			{
				cidade: 'Cabreúva',
				id_cidade: 4801,
			},
			{
				cidade: 'Caçapava',
				id_cidade: 4802,
			},
			{
				cidade: 'Cachoeira Paulista',
				id_cidade: 4803,
			},
			{
				cidade: 'Caconde',
				id_cidade: 4804,
			},
			{
				cidade: 'Cafelândia',
				id_cidade: 4805,
			},
			{
				cidade: 'Caiabu',
				id_cidade: 4806,
			},
			{
				cidade: 'Caieiras',
				id_cidade: 4807,
			},
			{
				cidade: 'Caiuá',
				id_cidade: 4808,
			},
			{
				cidade: 'Cajamar',
				id_cidade: 4809,
			},
			{
				cidade: 'Cajati',
				id_cidade: 4810,
			},
			{
				cidade: 'Cajobi',
				id_cidade: 4811,
			},
			{
				cidade: 'Cajuru',
				id_cidade: 4812,
			},
			{
				cidade: 'Campina do Monte Alegre',
				id_cidade: 4813,
			},
			{
				cidade: 'Campinas',
				id_cidade: 4814,
			},
			{
				cidade: 'Campo Limpo Paulista',
				id_cidade: 4815,
			},
			{
				cidade: 'Campos do Jordão',
				id_cidade: 4816,
			},
			{
				cidade: 'Campos Novos Paulista',
				id_cidade: 4817,
			},
			{
				cidade: 'Cananéia',
				id_cidade: 4818,
			},
			{
				cidade: 'Canas',
				id_cidade: 4819,
			},
			{
				cidade: 'Cândido Mota',
				id_cidade: 4820,
			},
			{
				cidade: 'Cândido Rodrigues',
				id_cidade: 4821,
			},
			{
				cidade: 'Canitar',
				id_cidade: 4822,
			},
			{
				cidade: 'Capão Bonito',
				id_cidade: 4823,
			},
			{
				cidade: 'Capela do Alto',
				id_cidade: 4824,
			},
			{
				cidade: 'Capivari',
				id_cidade: 4825,
			},
			{
				cidade: 'Caraguatatuba',
				id_cidade: 4826,
			},
			{
				cidade: 'Carapicuíba',
				id_cidade: 4827,
			},
			{
				cidade: 'Casa Branca',
				id_cidade: 4829,
			},
			{
				cidade: 'Cássia dos Coqueiros',
				id_cidade: 4830,
			},
			{
				cidade: 'Castilho',
				id_cidade: 4831,
			},
			{
				cidade: 'Catanduva',
				id_cidade: 4832,
			},
			{
				cidade: 'Catiguá',
				id_cidade: 4833,
			},
			{
				cidade: 'Cedral',
				id_cidade: 4834,
			},
			{
				cidade: 'Cerqueira César',
				id_cidade: 4835,
			},
			{
				cidade: 'Cerquilho',
				id_cidade: 4836,
			},
			{
				cidade: 'Cesário Lange',
				id_cidade: 4837,
			},
			{
				cidade: 'Charqueada',
				id_cidade: 4838,
			},
			{
				cidade: 'Chavantes',
				id_cidade: 4839,
			},
			{
				cidade: 'Clementina',
				id_cidade: 4840,
			},
			{
				cidade: 'Colina',
				id_cidade: 4841,
			},
			{
				cidade: 'Colômbia',
				id_cidade: 4842,
			},
			{
				cidade: 'Conchal',
				id_cidade: 4843,
			},
			{
				cidade: 'Conchas',
				id_cidade: 4844,
			},
			{
				cidade: 'Cordeirópolis',
				id_cidade: 4845,
			},
			{
				cidade: 'Coroados',
				id_cidade: 4846,
			},
			{
				cidade: 'Coronel Macedo',
				id_cidade: 4847,
			},
			{
				cidade: 'Corumbataí',
				id_cidade: 4848,
			},
			{
				cidade: 'Cosmópolis',
				id_cidade: 4849,
			},
			{
				cidade: 'Cosmorama',
				id_cidade: 4850,
			},
			{
				cidade: 'Cotia',
				id_cidade: 4851,
			},
			{
				cidade: 'Cravinhos',
				id_cidade: 4852,
			},
			{
				cidade: 'Cristais Paulista',
				id_cidade: 4853,
			},
			{
				cidade: 'Cruzália',
				id_cidade: 4854,
			},
			{
				cidade: 'Cruzeiro',
				id_cidade: 4855,
			},
			{
				cidade: 'Cubatão',
				id_cidade: 4856,
			},
			{
				cidade: 'Cunha',
				id_cidade: 4857,
			},
			{
				cidade: 'Descalvado',
				id_cidade: 4858,
			},
			{
				cidade: 'Diadema',
				id_cidade: 4859,
			},
			{
				cidade: 'Dirce Reis',
				id_cidade: 4860,
			},
			{
				cidade: 'Divinolândia',
				id_cidade: 4861,
			},
			{
				cidade: 'Dobrada',
				id_cidade: 4862,
			},
			{
				cidade: 'Dois Córregos',
				id_cidade: 4863,
			},
			{
				cidade: 'Dolcinópolis',
				id_cidade: 4864,
			},
			{
				cidade: 'Dourado',
				id_cidade: 4865,
			},
			{
				cidade: 'Dracena',
				id_cidade: 4866,
			},
			{
				cidade: 'Duartina',
				id_cidade: 4867,
			},
			{
				cidade: 'Dumont',
				id_cidade: 4868,
			},
			{
				cidade: 'Echaporã',
				id_cidade: 4869,
			},
			{
				cidade: 'Eldorado',
				id_cidade: 4870,
			},
			{
				cidade: 'Elias Fausto',
				id_cidade: 4871,
			},
			{
				cidade: 'Elisiário',
				id_cidade: 4872,
			},
			{
				cidade: 'Embaúba',
				id_cidade: 4873,
			},
			{
				cidade: 'Embu-Guaçu',
				id_cidade: 4875,
			},
			{
				cidade: 'Emilianópolis',
				id_cidade: 4876,
			},
			{
				cidade: 'Engenheiro Coelho',
				id_cidade: 4877,
			},
			{
				cidade: 'Espírito Santo do Pinhal',
				id_cidade: 4878,
			},
			{
				cidade: 'Espírito Santo do Turvo',
				id_cidade: 4879,
			},
			{
				cidade: 'Estiva Gerbi',
				id_cidade: 4880,
			},
			{
				cidade: "Estrela d'Oeste",
				id_cidade: 4881,
			},
			{
				cidade: 'Estrela do Norte',
				id_cidade: 4882,
			},
			{
				cidade: 'Euclides da Cunha Paulista',
				id_cidade: 4883,
			},
			{
				cidade: 'Fartura',
				id_cidade: 4884,
			},
			{
				cidade: 'Fernandópolis',
				id_cidade: 4886,
			},
			{
				cidade: 'Fernando Prestes',
				id_cidade: 4885,
			},
			{
				cidade: 'Fernão',
				id_cidade: 4887,
			},
			{
				cidade: 'Ferraz de Vasconcelos',
				id_cidade: 4888,
			},
			{
				cidade: 'Flora Rica',
				id_cidade: 4889,
			},
			{
				cidade: 'Floreal',
				id_cidade: 4890,
			},
			{
				cidade: 'Flórida Paulista',
				id_cidade: 4891,
			},
			{
				cidade: 'Florínia',
				id_cidade: 4892,
			},
			{
				cidade: 'Franca',
				id_cidade: 4893,
			},
			{
				cidade: 'Francisco Morato',
				id_cidade: 4894,
			},
			{
				cidade: 'Franco da Rocha',
				id_cidade: 4895,
			},
			{
				cidade: 'Gabriel Monteiro',
				id_cidade: 4896,
			},
			{
				cidade: 'Gália',
				id_cidade: 4897,
			},
			{
				cidade: 'Garça',
				id_cidade: 4898,
			},
			{
				cidade: 'Gastão Vidigal',
				id_cidade: 4899,
			},
			{
				cidade: 'Gavião Peixoto',
				id_cidade: 4900,
			},
			{
				cidade: 'General Salgado',
				id_cidade: 4901,
			},
			{
				cidade: 'Getulina',
				id_cidade: 4902,
			},
			{
				cidade: 'Glicério',
				id_cidade: 4903,
			},
			{
				cidade: 'Guaiçara',
				id_cidade: 4904,
			},
			{
				cidade: 'Guaimbê',
				id_cidade: 4905,
			},
			{
				cidade: 'Guaíra',
				id_cidade: 4906,
			},
			{
				cidade: 'Guapiaçu',
				id_cidade: 4907,
			},
			{
				cidade: 'Guapiara',
				id_cidade: 4908,
			},
			{
				cidade: 'Guará',
				id_cidade: 4909,
			},
			{
				cidade: 'Guaraçaí',
				id_cidade: 4910,
			},
			{
				cidade: 'Guaraci',
				id_cidade: 4911,
			},
			{
				cidade: "Guarani d'Oeste",
				id_cidade: 4912,
			},
			{
				cidade: 'Guarantã',
				id_cidade: 4913,
			},
			{
				cidade: 'Guararapes',
				id_cidade: 4914,
			},
			{
				cidade: 'Guararema',
				id_cidade: 4915,
			},
			{
				cidade: 'Guaratinguetá',
				id_cidade: 4916,
			},
			{
				cidade: 'Guareí',
				id_cidade: 4917,
			},
			{
				cidade: 'Guariba',
				id_cidade: 4918,
			},
			{
				cidade: 'Guarujá',
				id_cidade: 4919,
			},
			{
				cidade: 'Guarulhos',
				id_cidade: 4920,
			},
			{
				cidade: 'Guatapará',
				id_cidade: 4921,
			},
			{
				cidade: 'Guzolândia',
				id_cidade: 4922,
			},
			{
				cidade: 'Herculândia',
				id_cidade: 4923,
			},
			{
				cidade: 'Holambra',
				id_cidade: 4924,
			},
			{
				cidade: 'Hortolândia',
				id_cidade: 4925,
			},
			{
				cidade: 'Iacanga',
				id_cidade: 4926,
			},
			{
				cidade: 'Iacri',
				id_cidade: 4927,
			},
			{
				cidade: 'Iaras',
				id_cidade: 4928,
			},
			{
				cidade: 'Ibaté',
				id_cidade: 4929,
			},
			{
				cidade: 'Ibirá',
				id_cidade: 4930,
			},
			{
				cidade: 'Ibirarema',
				id_cidade: 4931,
			},
			{
				cidade: 'Ibitinga',
				id_cidade: 4932,
			},
			{
				cidade: 'Ibiúna',
				id_cidade: 4933,
			},
			{
				cidade: 'Icém',
				id_cidade: 4934,
			},
			{
				cidade: 'Iepê',
				id_cidade: 4935,
			},
			{
				cidade: 'Igaraçu do Tietê',
				id_cidade: 4936,
			},
			{
				cidade: 'Igarapava',
				id_cidade: 4937,
			},
			{
				cidade: 'Igaratá',
				id_cidade: 4938,
			},
			{
				cidade: 'Iguape',
				id_cidade: 4939,
			},
			{
				cidade: 'Ilhabela',
				id_cidade: 4942,
			},
			{
				cidade: 'Ilha Comprida',
				id_cidade: 4940,
			},
			{
				cidade: 'Ilha Solteira',
				id_cidade: 4941,
			},
			{
				cidade: 'Indaiatuba',
				id_cidade: 4943,
			},
			{
				cidade: 'Indiana',
				id_cidade: 4944,
			},
			{
				cidade: 'Indiaporã',
				id_cidade: 4945,
			},
			{
				cidade: 'Inúbia Paulista',
				id_cidade: 4946,
			},
			{
				cidade: 'Ipaussu',
				id_cidade: 4947,
			},
			{
				cidade: 'Iperó',
				id_cidade: 4948,
			},
			{
				cidade: 'Ipeúna',
				id_cidade: 4949,
			},
			{
				cidade: 'Ipiguá',
				id_cidade: 4950,
			},
			{
				cidade: 'Iporanga',
				id_cidade: 4951,
			},
			{
				cidade: 'Ipuã',
				id_cidade: 4952,
			},
			{
				cidade: 'Iracemápolis',
				id_cidade: 4953,
			},
			{
				cidade: 'Irapuã',
				id_cidade: 4954,
			},
			{
				cidade: 'Irapuru',
				id_cidade: 4955,
			},
			{
				cidade: 'Itaberá',
				id_cidade: 4956,
			},
			{
				cidade: 'Itaí',
				id_cidade: 4957,
			},
			{
				cidade: 'Itajobi',
				id_cidade: 4958,
			},
			{
				cidade: 'Itaju',
				id_cidade: 4959,
			},
			{
				cidade: 'Itanhaém',
				id_cidade: 4960,
			},
			{
				cidade: 'Itaóca',
				id_cidade: 4961,
			},
			{
				cidade: 'Itapecerica da Serra',
				id_cidade: 4962,
			},
			{
				cidade: 'Itapetininga',
				id_cidade: 4963,
			},
			{
				cidade: 'Itapeva',
				id_cidade: 4964,
			},
			{
				cidade: 'Itapevi',
				id_cidade: 4965,
			},
			{
				cidade: 'Itapira',
				id_cidade: 4966,
			},
			{
				cidade: 'Itapirapuã Paulista',
				id_cidade: 4967,
			},
			{
				cidade: 'Itápolis',
				id_cidade: 4968,
			},
			{
				cidade: 'Itaporanga',
				id_cidade: 4969,
			},
			{
				cidade: 'Itapuí',
				id_cidade: 4970,
			},
			{
				cidade: 'Itapura',
				id_cidade: 4971,
			},
			{
				cidade: 'Itaquaquecetuba',
				id_cidade: 4972,
			},
			{
				cidade: 'Itararé',
				id_cidade: 4973,
			},
			{
				cidade: 'Itariri',
				id_cidade: 4974,
			},
			{
				cidade: 'Itatiba',
				id_cidade: 4975,
			},
			{
				cidade: 'Itatinga',
				id_cidade: 4976,
			},
			{
				cidade: 'Itirapina',
				id_cidade: 4977,
			},
			{
				cidade: 'Itirapuã',
				id_cidade: 4978,
			},
			{
				cidade: 'Itobi',
				id_cidade: 4979,
			},
			{
				cidade: 'Itu',
				id_cidade: 4980,
			},
			{
				cidade: 'Itupeva',
				id_cidade: 4981,
			},
			{
				cidade: 'Ituverava',
				id_cidade: 4982,
			},
			{
				cidade: 'Jaborandi',
				id_cidade: 4983,
			},
			{
				cidade: 'Jaboticabal',
				id_cidade: 4984,
			},
			{
				cidade: 'Jacareí',
				id_cidade: 4985,
			},
			{
				cidade: 'Jaci',
				id_cidade: 4986,
			},
			{
				cidade: 'Jacupiranga',
				id_cidade: 4987,
			},
			{
				cidade: 'Jaguariúna',
				id_cidade: 4988,
			},
			{
				cidade: 'Jales',
				id_cidade: 4989,
			},
			{
				cidade: 'Jambeiro',
				id_cidade: 4990,
			},
			{
				cidade: 'Jandira',
				id_cidade: 4991,
			},
			{
				cidade: 'Jardinópolis',
				id_cidade: 4992,
			},
			{
				cidade: 'Jarinu',
				id_cidade: 4993,
			},
			{
				cidade: 'Jaú',
				id_cidade: 4994,
			},
			{
				cidade: 'Jeriquara',
				id_cidade: 4995,
			},
			{
				cidade: 'Joanópolis',
				id_cidade: 4996,
			},
			{
				cidade: 'João Ramalho',
				id_cidade: 4997,
			},
			{
				cidade: 'José Bonifácio',
				id_cidade: 4998,
			},
			{
				cidade: 'Júlio Mesquita',
				id_cidade: 4999,
			},
			{
				cidade: 'Jumirim',
				id_cidade: 5000,
			},
			{
				cidade: 'Jundiaí',
				id_cidade: 5001,
			},
			{
				cidade: 'Junqueirópolis',
				id_cidade: 5002,
			},
			{
				cidade: 'Juquiá',
				id_cidade: 5003,
			},
			{
				cidade: 'Juquitiba',
				id_cidade: 5004,
			},
			{
				cidade: 'Lagoinha',
				id_cidade: 5005,
			},
			{
				cidade: 'Laranjal Paulista',
				id_cidade: 5006,
			},
			{
				cidade: 'Lavínia',
				id_cidade: 5007,
			},
			{
				cidade: 'Lavrinhas',
				id_cidade: 5008,
			},
			{
				cidade: 'Leme',
				id_cidade: 5009,
			},
			{
				cidade: 'Lençóis Paulista',
				id_cidade: 5010,
			},
			{
				cidade: 'Limeira',
				id_cidade: 5011,
			},
			{
				cidade: 'Lindóia',
				id_cidade: 5012,
			},
			{
				cidade: 'Lins',
				id_cidade: 5013,
			},
			{
				cidade: 'Lorena',
				id_cidade: 5014,
			},
			{
				cidade: 'Lourdes',
				id_cidade: 5015,
			},
			{
				cidade: 'Louveira',
				id_cidade: 5016,
			},
			{
				cidade: 'Lucélia',
				id_cidade: 5017,
			},
			{
				cidade: 'Lucianópolis',
				id_cidade: 5018,
			},
			{
				cidade: 'Luís Antônio',
				id_cidade: 5019,
			},
			{
				cidade: 'Luiziânia',
				id_cidade: 5020,
			},
			{
				cidade: 'Lupércio',
				id_cidade: 5021,
			},
			{
				cidade: 'Lutécia',
				id_cidade: 5022,
			},
			{
				cidade: 'Macatuba',
				id_cidade: 5023,
			},
			{
				cidade: 'Macaubal',
				id_cidade: 5024,
			},
			{
				cidade: 'Macedônia',
				id_cidade: 5025,
			},
			{
				cidade: 'Magda',
				id_cidade: 5026,
			},
			{
				cidade: 'Mairinque',
				id_cidade: 5027,
			},
			{
				cidade: 'Mairiporã',
				id_cidade: 5028,
			},
			{
				cidade: 'Manduri',
				id_cidade: 5029,
			},
			{
				cidade: 'Marabá Paulista',
				id_cidade: 5030,
			},
			{
				cidade: 'Maracaí',
				id_cidade: 5031,
			},
			{
				cidade: 'Marapoama',
				id_cidade: 5032,
			},
			{
				cidade: 'Mariápolis',
				id_cidade: 5033,
			},
			{
				cidade: 'Marília',
				id_cidade: 5034,
			},
			{
				cidade: 'Marinópolis',
				id_cidade: 5035,
			},
			{
				cidade: 'Martinópolis',
				id_cidade: 5036,
			},
			{
				cidade: 'Matão',
				id_cidade: 5037,
			},
			{
				cidade: 'Mauá',
				id_cidade: 5038,
			},
			{
				cidade: 'Mendonça',
				id_cidade: 5039,
			},
			{
				cidade: 'Meridiano',
				id_cidade: 5040,
			},
			{
				cidade: 'Mesópolis',
				id_cidade: 5041,
			},
			{
				cidade: 'Miguelópolis',
				id_cidade: 5042,
			},
			{
				cidade: 'Mineiros do Tietê',
				id_cidade: 5043,
			},
			{
				cidade: 'Miracatu',
				id_cidade: 5045,
			},
			{
				cidade: 'Mira Estrela',
				id_cidade: 5044,
			},
			{
				cidade: 'Mirandópolis',
				id_cidade: 5046,
			},
			{
				cidade: 'Mirante do Paranapanema',
				id_cidade: 5047,
			},
			{
				cidade: 'Mirassol',
				id_cidade: 5048,
			},
			{
				cidade: 'Mirassolândia',
				id_cidade: 5049,
			},
			{
				cidade: 'Mococa',
				id_cidade: 5050,
			},
			{
				cidade: 'Mogi das Cruzes',
				id_cidade: 5051,
			},
			{
				cidade: 'Mogi Guaçu',
				id_cidade: 5052,
			},
			{
				cidade: 'Mombuca',
				id_cidade: 5054,
			},
			{
				cidade: 'Monções',
				id_cidade: 5055,
			},
			{
				cidade: 'Mongaguá',
				id_cidade: 5056,
			},
			{
				cidade: 'Monte Alegre do Sul',
				id_cidade: 5057,
			},
			{
				cidade: 'Monte Alto',
				id_cidade: 5058,
			},
			{
				cidade: 'Monte Aprazível',
				id_cidade: 5059,
			},
			{
				cidade: 'Monte Azul Paulista',
				id_cidade: 5060,
			},
			{
				cidade: 'Monte Castelo',
				id_cidade: 5061,
			},
			{
				cidade: 'Monteiro Lobato',
				id_cidade: 5063,
			},
			{
				cidade: 'Monte Mor',
				id_cidade: 5062,
			},
			{
				cidade: 'Morro Agudo',
				id_cidade: 5064,
			},
			{
				cidade: 'Morungaba',
				id_cidade: 5065,
			},
			{
				cidade: 'Motuca',
				id_cidade: 5066,
			},
			{
				cidade: 'Murutinga do Sul',
				id_cidade: 5067,
			},
			{
				cidade: 'Nantes',
				id_cidade: 5068,
			},
			{
				cidade: 'Narandiba',
				id_cidade: 5069,
			},
			{
				cidade: 'Cardoso',
				id_cidade: 4828,
			},
			{
				cidade: 'Natividade da Serra',
				id_cidade: 5070,
			},
			{
				cidade: 'Nazaré Paulista',
				id_cidade: 5071,
			},
			{
				cidade: 'Neves Paulista',
				id_cidade: 5072,
			},
			{
				cidade: 'Nhandeara',
				id_cidade: 5073,
			},
			{
				cidade: 'Nipoã',
				id_cidade: 5074,
			},
			{
				cidade: 'Nova Aliança',
				id_cidade: 5075,
			},
			{
				cidade: 'Nova Campina',
				id_cidade: 5076,
			},
			{
				cidade: 'Nova Canaã Paulista',
				id_cidade: 5077,
			},
			{
				cidade: 'Nova Castilho',
				id_cidade: 5078,
			},
			{
				cidade: 'Nova Europa',
				id_cidade: 5079,
			},
			{
				cidade: 'Nova Granada',
				id_cidade: 5080,
			},
			{
				cidade: 'Nova Guataporanga',
				id_cidade: 5081,
			},
			{
				cidade: 'Nova Independência',
				id_cidade: 5082,
			},
			{
				cidade: 'Novais',
				id_cidade: 5085,
			},
			{
				cidade: 'Nova Luzitânia',
				id_cidade: 5083,
			},
			{
				cidade: 'Nova Odessa',
				id_cidade: 5084,
			},
			{
				cidade: 'Novo Horizonte',
				id_cidade: 5086,
			},
			{
				cidade: 'Nuporanga',
				id_cidade: 5087,
			},
			{
				cidade: 'Ocauçu',
				id_cidade: 5088,
			},
			{
				cidade: 'Óleo',
				id_cidade: 5089,
			},
			{
				cidade: 'Olímpia',
				id_cidade: 5090,
			},
			{
				cidade: 'Onda Verde',
				id_cidade: 5091,
			},
			{
				cidade: 'Oriente',
				id_cidade: 5092,
			},
			{
				cidade: 'Orindiúva',
				id_cidade: 5093,
			},
			{
				cidade: 'Orlândia',
				id_cidade: 5094,
			},
			{
				cidade: 'Osasco',
				id_cidade: 5095,
			},
			{
				cidade: 'Oscar Bressane',
				id_cidade: 5096,
			},
			{
				cidade: 'Osvaldo Cruz',
				id_cidade: 5097,
			},
			{
				cidade: 'Ourinhos',
				id_cidade: 5098,
			},
			{
				cidade: 'Ouroeste',
				id_cidade: 5100,
			},
			{
				cidade: 'Ouro Verde',
				id_cidade: 5099,
			},
			{
				cidade: 'Pacaembu',
				id_cidade: 5101,
			},
			{
				cidade: 'Palestina',
				id_cidade: 5102,
			},
			{
				cidade: 'Palmares Paulista',
				id_cidade: 5103,
			},
			{
				cidade: "Palmeira d'Oeste",
				id_cidade: 5104,
			},
			{
				cidade: 'Palmital',
				id_cidade: 5105,
			},
			{
				cidade: 'Panorama',
				id_cidade: 5106,
			},
			{
				cidade: 'Paraguaçu Paulista',
				id_cidade: 5107,
			},
			{
				cidade: 'Paraibuna',
				id_cidade: 5108,
			},
			{
				cidade: 'Paraíso',
				id_cidade: 5109,
			},
			{
				cidade: 'Paranapanema',
				id_cidade: 5110,
			},
			{
				cidade: 'Paranapuã',
				id_cidade: 5111,
			},
			{
				cidade: 'Parapuã',
				id_cidade: 5112,
			},
			{
				cidade: 'Pardinho',
				id_cidade: 5113,
			},
			{
				cidade: 'Pariquera-Açu',
				id_cidade: 5114,
			},
			{
				cidade: 'Parisi',
				id_cidade: 5115,
			},
			{
				cidade: 'Patrocínio Paulista',
				id_cidade: 5116,
			},
			{
				cidade: 'Paulicéia',
				id_cidade: 5117,
			},
			{
				cidade: 'Paulínia',
				id_cidade: 5118,
			},
			{
				cidade: 'Paulistânia',
				id_cidade: 5119,
			},
			{
				cidade: 'Paulo de Faria',
				id_cidade: 5120,
			},
			{
				cidade: 'Pederneiras',
				id_cidade: 5121,
			},
			{
				cidade: 'Pedra Bela',
				id_cidade: 5122,
			},
			{
				cidade: 'Pedranópolis',
				id_cidade: 5123,
			},
			{
				cidade: 'Pedregulho',
				id_cidade: 5124,
			},
			{
				cidade: 'Pedreira',
				id_cidade: 5125,
			},
			{
				cidade: 'Pedrinhas Paulista',
				id_cidade: 5126,
			},
			{
				cidade: 'Pedro de Toledo',
				id_cidade: 5127,
			},
			{
				cidade: 'Penápolis',
				id_cidade: 5128,
			},
			{
				cidade: 'Pereira Barreto',
				id_cidade: 5129,
			},
			{
				cidade: 'Pereiras',
				id_cidade: 5130,
			},
			{
				cidade: 'Peruíbe',
				id_cidade: 5131,
			},
			{
				cidade: 'Piacatu',
				id_cidade: 5132,
			},
			{
				cidade: 'Piedade',
				id_cidade: 5133,
			},
			{
				cidade: 'Pilar do Sul',
				id_cidade: 5134,
			},
			{
				cidade: 'Pindamonhangaba',
				id_cidade: 5135,
			},
			{
				cidade: 'Pindorama',
				id_cidade: 5136,
			},
			{
				cidade: 'Pinhalzinho',
				id_cidade: 5137,
			},
			{
				cidade: 'Piquerobi',
				id_cidade: 5138,
			},
			{
				cidade: 'Piquete',
				id_cidade: 5139,
			},
			{
				cidade: 'Piracaia',
				id_cidade: 5140,
			},
			{
				cidade: 'Piracicaba',
				id_cidade: 5141,
			},
			{
				cidade: 'Piraju',
				id_cidade: 5142,
			},
			{
				cidade: 'Pirajuí',
				id_cidade: 5143,
			},
			{
				cidade: 'Pirangi',
				id_cidade: 5144,
			},
			{
				cidade: 'Pirapora do Bom Jesus',
				id_cidade: 5145,
			},
			{
				cidade: 'Pirapozinho',
				id_cidade: 5146,
			},
			{
				cidade: 'Pirassununga',
				id_cidade: 5147,
			},
			{
				cidade: 'Piratininga',
				id_cidade: 5148,
			},
			{
				cidade: 'Pitangueiras',
				id_cidade: 5149,
			},
			{
				cidade: 'Planalto',
				id_cidade: 5150,
			},
			{
				cidade: 'Platina',
				id_cidade: 5151,
			},
			{
				cidade: 'Poá',
				id_cidade: 5152,
			},
			{
				cidade: 'Poloni',
				id_cidade: 5153,
			},
			{
				cidade: 'Pompéia',
				id_cidade: 5154,
			},
			{
				cidade: 'Pongaí',
				id_cidade: 5155,
			},
			{
				cidade: 'Pontal',
				id_cidade: 5156,
			},
			{
				cidade: 'Pontalinda',
				id_cidade: 5157,
			},
			{
				cidade: 'Pontes Gestal',
				id_cidade: 5158,
			},
			{
				cidade: 'Populina',
				id_cidade: 5159,
			},
			{
				cidade: 'Porangaba',
				id_cidade: 5160,
			},
			{
				cidade: 'Porto Feliz',
				id_cidade: 5161,
			},
			{
				cidade: 'Porto Ferreira',
				id_cidade: 5162,
			},
			{
				cidade: 'Potim',
				id_cidade: 5163,
			},
			{
				cidade: 'Potirendaba',
				id_cidade: 5164,
			},
			{
				cidade: 'Pracinha',
				id_cidade: 5165,
			},
			{
				cidade: 'Pradópolis',
				id_cidade: 5166,
			},
			{
				cidade: 'Praia Grande',
				id_cidade: 5167,
			},
			{
				cidade: 'Pratânia',
				id_cidade: 5168,
			},
			{
				cidade: 'Presidente Alves',
				id_cidade: 5169,
			},
			{
				cidade: 'Presidente Bernardes',
				id_cidade: 5170,
			},
			{
				cidade: 'Presidente Epitácio',
				id_cidade: 5171,
			},
			{
				cidade: 'Presidente Prudente',
				id_cidade: 5172,
			},
			{
				cidade: 'Presidente Venceslau',
				id_cidade: 5173,
			},
			{
				cidade: 'Promissão',
				id_cidade: 5174,
			},
			{
				cidade: 'Quadra',
				id_cidade: 5175,
			},
			{
				cidade: 'Quatá',
				id_cidade: 5176,
			},
			{
				cidade: 'Queiroz',
				id_cidade: 5177,
			},
			{
				cidade: 'Queluz',
				id_cidade: 5178,
			},
			{
				cidade: 'Quintana',
				id_cidade: 5179,
			},
			{
				cidade: 'Rafard',
				id_cidade: 5180,
			},
			{
				cidade: 'Rancharia',
				id_cidade: 5181,
			},
			{
				cidade: 'Redenção da Serra',
				id_cidade: 5182,
			},
			{
				cidade: 'Regente Feijó',
				id_cidade: 5183,
			},
			{
				cidade: 'Reginópolis',
				id_cidade: 5184,
			},
			{
				cidade: 'Registro',
				id_cidade: 5185,
			},
			{
				cidade: 'Restinga',
				id_cidade: 5186,
			},
			{
				cidade: 'Ribeira',
				id_cidade: 5187,
			},
			{
				cidade: 'Ribeirão Bonito',
				id_cidade: 5188,
			},
			{
				cidade: 'Ribeirão Branco',
				id_cidade: 5189,
			},
			{
				cidade: 'Ribeirão Corrente',
				id_cidade: 5190,
			},
			{
				cidade: 'Ribeirão dos Índios',
				id_cidade: 5192,
			},
			{
				cidade: 'Ribeirão do Sul',
				id_cidade: 5191,
			},
			{
				cidade: 'Ribeirão Grande',
				id_cidade: 5193,
			},
			{
				cidade: 'Ribeirão Pires',
				id_cidade: 5194,
			},
			{
				cidade: 'Ribeirão Preto',
				id_cidade: 5195,
			},
			{
				cidade: 'Rifaina',
				id_cidade: 5196,
			},
			{
				cidade: 'Rincão',
				id_cidade: 5197,
			},
			{
				cidade: 'Rinópolis',
				id_cidade: 5198,
			},
			{
				cidade: 'Rio Claro',
				id_cidade: 5199,
			},
			{
				cidade: 'Rio das Pedras',
				id_cidade: 5200,
			},
			{
				cidade: 'Rio Grande da Serra',
				id_cidade: 5201,
			},
			{
				cidade: 'Riolândia',
				id_cidade: 5202,
			},
			{
				cidade: 'Riversul',
				id_cidade: 5203,
			},
			{
				cidade: 'Rosana',
				id_cidade: 5204,
			},
			{
				cidade: 'Roseira',
				id_cidade: 5205,
			},
			{
				cidade: 'Adamantina',
				id_cidade: 4706,
			},
			{
				cidade: 'Adolfo',
				id_cidade: 4707,
			},
			{
				cidade: 'Aguaí',
				id_cidade: 4708,
			},
			{
				cidade: 'Águas da Prata',
				id_cidade: 4709,
			},
			{
				cidade: 'Águas de Lindóia',
				id_cidade: 4710,
			},
			{
				cidade: 'Águas de Santa Bárbara',
				id_cidade: 4711,
			},
			{
				cidade: 'Águas de São Pedro',
				id_cidade: 4712,
			},
			{
				cidade: 'Agudos',
				id_cidade: 4713,
			},
			{
				cidade: 'Alambari',
				id_cidade: 4714,
			},
			{
				cidade: 'Alfredo Marcondes',
				id_cidade: 4715,
			},
			{
				cidade: 'Altair',
				id_cidade: 4716,
			},
			{
				cidade: 'Rubiácea',
				id_cidade: 5206,
			},
			{
				cidade: 'Rubinéia',
				id_cidade: 5207,
			},
			{
				cidade: 'Sabino',
				id_cidade: 5208,
			},
			{
				cidade: 'Sagres',
				id_cidade: 5209,
			},
			{
				cidade: 'Sales',
				id_cidade: 5210,
			},
			{
				cidade: 'Sales Oliveira',
				id_cidade: 5211,
			},
			{
				cidade: 'Salesópolis',
				id_cidade: 5212,
			},
			{
				cidade: 'Salmourão',
				id_cidade: 5213,
			},
			{
				cidade: 'Saltinho',
				id_cidade: 5214,
			},
			{
				cidade: 'Salto',
				id_cidade: 5215,
			},
			{
				cidade: 'Salto de Pirapora',
				id_cidade: 5216,
			},
			{
				cidade: 'Salto Grande',
				id_cidade: 5217,
			},
			{
				cidade: 'Sandovalina',
				id_cidade: 5218,
			},
			{
				cidade: 'Santa Adélia',
				id_cidade: 5219,
			},
			{
				cidade: 'Santa Albertina',
				id_cidade: 5220,
			},
			{
				cidade: "Santa Bárbara d'Oeste",
				id_cidade: 5221,
			},
			{
				cidade: 'Santa Branca',
				id_cidade: 5222,
			},
			{
				cidade: "Santa Clara d'Oeste",
				id_cidade: 5223,
			},
			{
				cidade: 'Santa Cruz da Conceição',
				id_cidade: 5224,
			},
			{
				cidade: 'Santa Cruz da Esperança',
				id_cidade: 5225,
			},
			{
				cidade: 'Santa Cruz das Palmeiras',
				id_cidade: 5226,
			},
			{
				cidade: 'Santa Cruz do Rio Pardo',
				id_cidade: 5227,
			},
			{
				cidade: 'Santa Ernestina',
				id_cidade: 5228,
			},
			{
				cidade: 'Artur Nogueira',
				id_cidade: 4749,
			},
			{
				cidade: 'Arujá',
				id_cidade: 4750,
			},
			{
				cidade: 'Aspásia',
				id_cidade: 4751,
			},
			{
				cidade: 'Assis',
				id_cidade: 4752,
			},
			{
				cidade: 'Santa Fé do Sul',
				id_cidade: 5229,
			},
			{
				cidade: 'Santa Gertrudes',
				id_cidade: 5230,
			},
			{
				cidade: 'Santa Isabel',
				id_cidade: 5231,
			},
			{
				cidade: 'Santa Lúcia',
				id_cidade: 5232,
			},
			{
				cidade: 'Santa Maria da Serra',
				id_cidade: 5233,
			},
			{
				cidade: 'Embu',
				id_cidade: 4874,
			},
			{
				cidade: 'Santa Mercedes',
				id_cidade: 5234,
			},
			{
				cidade: 'Santana da Ponte Pensa',
				id_cidade: 5239,
			},
			{
				cidade: 'Santana de Parnaíba',
				id_cidade: 5240,
			},
			{
				cidade: 'Moji Mirim',
				id_cidade: 5053,
			},
			{
				cidade: 'Altinópolis',
				id_cidade: 4717,
			},
			{
				cidade: 'Alto Alegre',
				id_cidade: 4718,
			},
			{
				cidade: 'Alumínio',
				id_cidade: 4719,
			},
			{
				cidade: 'Álvares Florence',
				id_cidade: 4720,
			},
			{
				cidade: 'Álvares Machado',
				id_cidade: 4721,
			},
			{
				cidade: 'Álvaro de Carvalho',
				id_cidade: 4722,
			},
			{
				cidade: 'Alvinlândia',
				id_cidade: 4723,
			},
			{
				cidade: "Santa Rita d'Oeste",
				id_cidade: 5235,
			},
			{
				cidade: 'São Luís do Paraitinga',
				id_cidade: 5267,
			},
			{
				cidade: 'Americana',
				id_cidade: 4724,
			},
			{
				cidade: 'Américo Brasiliense',
				id_cidade: 4725,
			},
			{
				cidade: 'Américo de Campos',
				id_cidade: 4726,
			},
			{
				cidade: 'Amparo',
				id_cidade: 4727,
			},
			{
				cidade: 'Analândia',
				id_cidade: 4728,
			},
			{
				cidade: 'Andradina',
				id_cidade: 4729,
			},
			{
				cidade: 'Santa Rita do Passa Quatro',
				id_cidade: 5236,
			},
			{
				cidade: 'Santa Rosa de Viterbo',
				id_cidade: 5237,
			},
			{
				cidade: 'Santa Salete',
				id_cidade: 5238,
			},
			{
				cidade: 'Santo Anastácio',
				id_cidade: 5241,
			},
			{
				cidade: 'Santo André',
				id_cidade: 5242,
			},
			{
				cidade: 'Santo Antônio da Alegria',
				id_cidade: 5243,
			},
			{
				cidade: 'Santo Antônio de Posse',
				id_cidade: 5244,
			},
			{
				cidade: 'Santo Antônio do Aracanguá',
				id_cidade: 5245,
			},
			{
				cidade: 'Santo Antônio do Jardim',
				id_cidade: 5246,
			},
			{
				cidade: 'Santo Antônio do Pinhal',
				id_cidade: 5247,
			},
			{
				cidade: 'Santo Expedito',
				id_cidade: 5248,
			},
			{
				cidade: 'Santópolis do Aguapeí',
				id_cidade: 5249,
			},
			{
				cidade: 'Santos',
				id_cidade: 5250,
			},
			{
				cidade: 'São Bento do Sapucaí',
				id_cidade: 5251,
			},
			{
				cidade: 'São Bernardo do Campo',
				id_cidade: 5252,
			},
			{
				cidade: 'São Caetano do Sul',
				id_cidade: 5253,
			},
			{
				cidade: 'São Carlos',
				id_cidade: 5254,
			},
			{
				cidade: 'São Francisco',
				id_cidade: 5255,
			},
			{
				cidade: 'Angatuba',
				id_cidade: 4730,
			},
			{
				cidade: 'São João da Boa Vista',
				id_cidade: 5256,
			},
			{
				cidade: 'São João das Duas Pontes',
				id_cidade: 5257,
			},
			{
				cidade: 'São João de Iracema',
				id_cidade: 5258,
			},
			{
				cidade: "São João do Pau d'Alho",
				id_cidade: 5259,
			},
			{
				cidade: 'São Joaquim da Barra',
				id_cidade: 5260,
			},
			{
				cidade: 'São José da Bela Vista',
				id_cidade: 5261,
			},
			{
				cidade: 'São José do Barreiro',
				id_cidade: 5262,
			},
			{
				cidade: 'São José do Rio Pardo',
				id_cidade: 5263,
			},
			{
				cidade: 'São José do Rio Preto',
				id_cidade: 5264,
			},
			{
				cidade: 'São José dos Campos',
				id_cidade: 5265,
			},
			{
				cidade: 'São Lourenço da Serra',
				id_cidade: 5266,
			},
			{
				cidade: 'São Manuel',
				id_cidade: 5268,
			},
			{
				cidade: 'São Miguel Arcanjo',
				id_cidade: 5269,
			},
			{
				cidade: 'São Paulo',
				id_cidade: 5270,
			},
			{
				cidade: 'São Pedro',
				id_cidade: 5271,
			},
			{
				cidade: 'São Pedro do Turvo',
				id_cidade: 5272,
			},
			{
				cidade: 'São Roque',
				id_cidade: 5273,
			},
			{
				cidade: 'São Sebastião',
				id_cidade: 5274,
			},
			{
				cidade: 'São Sebastião da Grama',
				id_cidade: 5275,
			},
			{
				cidade: 'São Simão',
				id_cidade: 5276,
			},
			{
				cidade: 'São Vicente',
				id_cidade: 5277,
			},
			{
				cidade: 'Sarapuí',
				id_cidade: 5278,
			},
			{
				cidade: 'Sarutaiá',
				id_cidade: 5279,
			},
			{
				cidade: 'Sebastianópolis do Sul',
				id_cidade: 5280,
			},
			{
				cidade: 'Serra Azul',
				id_cidade: 5281,
			},
			{
				cidade: 'Serrana',
				id_cidade: 5283,
			},
			{
				cidade: 'Serra Negra',
				id_cidade: 5282,
			},
			{
				cidade: 'Anhembi',
				id_cidade: 4731,
			},
			{
				cidade: 'Anhumas',
				id_cidade: 4732,
			},
			{
				cidade: 'Aparecida',
				id_cidade: 4733,
			},
			{
				cidade: "Aparecida d'Oeste",
				id_cidade: 4734,
			},
			{
				cidade: 'Apiaí',
				id_cidade: 4735,
			},
			{
				cidade: 'Araçariguama',
				id_cidade: 4736,
			},
			{
				cidade: 'Araçatuba',
				id_cidade: 4737,
			},
			{
				cidade: 'Sertãozinho',
				id_cidade: 5284,
			},
			{
				cidade: 'Sete Barras',
				id_cidade: 5285,
			},
			{
				cidade: 'Severínia',
				id_cidade: 5286,
			},
			{
				cidade: 'Silveiras',
				id_cidade: 5287,
			},
			{
				cidade: 'Socorro',
				id_cidade: 5288,
			},
			{
				cidade: 'Sorocaba',
				id_cidade: 5289,
			},
			{
				cidade: 'Sud Mennucci',
				id_cidade: 5290,
			},
			{
				cidade: 'Sumaré',
				id_cidade: 5291,
			},
			{
				cidade: 'Suzanápolis',
				id_cidade: 5292,
			},
			{
				cidade: 'Suzano',
				id_cidade: 5293,
			},
			{
				cidade: 'Tabapuã',
				id_cidade: 5294,
			},
			{
				cidade: 'Tabatinga',
				id_cidade: 5295,
			},
			{
				cidade: 'Taboão da Serra',
				id_cidade: 5296,
			},
			{
				cidade: 'Taciba',
				id_cidade: 5297,
			},
			{
				cidade: 'Taguaí',
				id_cidade: 5298,
			},
			{
				cidade: 'Taiaçu',
				id_cidade: 5299,
			},
			{
				cidade: 'Taiúva',
				id_cidade: 5300,
			},
			{
				cidade: 'Tambaú',
				id_cidade: 5301,
			},
			{
				cidade: 'Tanabi',
				id_cidade: 5302,
			},
			{
				cidade: 'Tapiraí',
				id_cidade: 5303,
			},
			{
				cidade: 'Tapiratiba',
				id_cidade: 5304,
			},
			{
				cidade: 'Taquaral',
				id_cidade: 5305,
			},
			{
				cidade: 'Taquaritinga',
				id_cidade: 5306,
			},
			{
				cidade: 'Taquarituba',
				id_cidade: 5307,
			},
			{
				cidade: 'Taquarivaí',
				id_cidade: 5308,
			},
			{
				cidade: 'Tarabai',
				id_cidade: 5309,
			},
			{
				cidade: 'Araçoiaba da Serra',
				id_cidade: 4738,
			},
			{
				cidade: 'Aramina',
				id_cidade: 4739,
			},
			{
				cidade: 'Arandu',
				id_cidade: 4740,
			},
			{
				cidade: 'Arapeí',
				id_cidade: 4741,
			},
			{
				cidade: 'Araraquara',
				id_cidade: 4742,
			},
			{
				cidade: 'Araras',
				id_cidade: 4743,
			},
			{
				cidade: 'Tarumã',
				id_cidade: 5310,
			},
			{
				cidade: 'Tatuí',
				id_cidade: 5311,
			},
			{
				cidade: 'Taubaté',
				id_cidade: 5312,
			},
			{
				cidade: 'Tejupá',
				id_cidade: 5313,
			},
			{
				cidade: 'Teodoro Sampaio',
				id_cidade: 5314,
			},
			{
				cidade: 'Terra Roxa',
				id_cidade: 5315,
			},
			{
				cidade: 'Tietê',
				id_cidade: 5316,
			},
			{
				cidade: 'Timburi',
				id_cidade: 5317,
			},
			{
				cidade: 'Torre de Pedra',
				id_cidade: 5318,
			},
			{
				cidade: 'Torrinha',
				id_cidade: 5319,
			},
			{
				cidade: 'Trabiju',
				id_cidade: 5320,
			},
			{
				cidade: 'Tremembé',
				id_cidade: 5321,
			},
			{
				cidade: 'Três Fronteiras',
				id_cidade: 5322,
			},
			{
				cidade: 'Tuiuti',
				id_cidade: 5323,
			},
			{
				cidade: 'Tupã',
				id_cidade: 5324,
			},
			{
				cidade: 'Tupi Paulista',
				id_cidade: 5325,
			},
			{
				cidade: 'Turiúba',
				id_cidade: 5326,
			},
			{
				cidade: 'Turmalina',
				id_cidade: 5327,
			},
			{
				cidade: 'Ubarana',
				id_cidade: 5328,
			},
			{
				cidade: 'Ubatuba',
				id_cidade: 5329,
			},
			{
				cidade: 'Ubirajara',
				id_cidade: 5330,
			},
			{
				cidade: 'Uchoa',
				id_cidade: 5331,
			},
			{
				cidade: 'União Paulista',
				id_cidade: 5332,
			},
			{
				cidade: 'Urânia',
				id_cidade: 5333,
			},
			{
				cidade: 'Uru',
				id_cidade: 5334,
			},
			{
				cidade: 'Urupês',
				id_cidade: 5335,
			},
			{
				cidade: 'Valentim Gentil',
				id_cidade: 5336,
			},
			{
				cidade: 'Valinhos',
				id_cidade: 5337,
			},
			{
				cidade: 'Valparaíso',
				id_cidade: 5338,
			},
			{
				cidade: 'Vargem',
				id_cidade: 5339,
			},
			{
				cidade: 'Vargem Grande do Sul',
				id_cidade: 5340,
			},
			{
				cidade: 'Vargem Grande Paulista',
				id_cidade: 5341,
			},
			{
				cidade: 'Várzea Paulista',
				id_cidade: 5342,
			},
			{
				cidade: 'Vera Cruz',
				id_cidade: 5343,
			},
			{
				cidade: 'Vinhedo',
				id_cidade: 5344,
			},
			{
				cidade: 'Viradouro',
				id_cidade: 5345,
			},
			{
				cidade: 'Vista Alegre do Alto',
				id_cidade: 5346,
			},
			{
				cidade: 'Vitória Brasil',
				id_cidade: 5347,
			},
			{
				cidade: 'Votorantim',
				id_cidade: 5348,
			},
			{
				cidade: 'Votuporanga',
				id_cidade: 5349,
			},
			{
				cidade: 'Zacarias',
				id_cidade: 5350,
			},
		],
	},
	{
		estado: 'Tocantins',
		sigla: 'TO',
		id_estado: 27,
		cidades: [
			{
				cidade: 'Arraias',
				id_cidade: 5441,
			},
			{
				cidade: 'Augustinópolis',
				id_cidade: 5442,
			},
			{
				cidade: 'Babaçulândia',
				id_cidade: 5445,
			},
			{
				cidade: 'Barrolândia',
				id_cidade: 5448,
			},
			{
				cidade: 'Bom Jesus do Tocantins',
				id_cidade: 5450,
			},
			{
				cidade: 'Cachoeirinha',
				id_cidade: 5454,
			},
			{
				cidade: 'Carmolândia',
				id_cidade: 5457,
			},
			{
				cidade: 'Caseara',
				id_cidade: 5459,
			},
			{
				cidade: 'Centenário',
				id_cidade: 5460,
			},
			{
				cidade: 'Colméia',
				id_cidade: 5464,
			},
			{
				cidade: 'Combinado',
				id_cidade: 5465,
			},
			{
				cidade: 'Cristalândia',
				id_cidade: 5468,
			},
			{
				cidade: 'Darcinópolis',
				id_cidade: 5470,
			},
			{
				cidade: 'Dianópolis',
				id_cidade: 5471,
			},
			{
				cidade: 'Dueré',
				id_cidade: 5474,
			},
			{
				cidade: 'Esperantina',
				id_cidade: 5475,
			},
			{
				cidade: 'Fátima',
				id_cidade: 5476,
			},
			{
				cidade: 'Figueirópolis',
				id_cidade: 5477,
			},
			{
				cidade: 'Filadélfia',
				id_cidade: 5478,
			},
			{
				cidade: 'Goianorte',
				id_cidade: 5481,
			},
			{
				cidade: 'Goiatins',
				id_cidade: 5482,
			},
			{
				cidade: 'Guaraí',
				id_cidade: 5483,
			},
			{
				cidade: 'Gurupi',
				id_cidade: 5484,
			},
			{
				cidade: 'Ipueiras',
				id_cidade: 5485,
			},
			{
				cidade: 'Itacajá',
				id_cidade: 5486,
			},
			{
				cidade: 'Itaguatins',
				id_cidade: 5487,
			},
			{
				cidade: 'Itapiratins',
				id_cidade: 5488,
			},
			{
				cidade: 'Juarina',
				id_cidade: 5491,
			},
			{
				cidade: 'Lajeado',
				id_cidade: 5494,
			},
			{
				cidade: 'Lavandeira',
				id_cidade: 5495,
			},
			{
				cidade: 'Lizarda',
				id_cidade: 5496,
			},
			{
				cidade: 'Luzinópolis',
				id_cidade: 5497,
			},
			{
				cidade: 'Mateiros',
				id_cidade: 5499,
			},
			{
				cidade: 'Miranorte',
				id_cidade: 5502,
			},
			{
				cidade: 'Muricilândia',
				id_cidade: 5505,
			},
			{
				cidade: 'Natividade',
				id_cidade: 5506,
			},
			{
				cidade: 'Nazaré',
				id_cidade: 5507,
			},
			{
				cidade: 'Nova Olinda',
				id_cidade: 5508,
			},
			{
				cidade: 'Palmas',
				id_cidade: 5514,
			},
			{
				cidade: 'Palmeirante',
				id_cidade: 5515,
			},
			{
				cidade: 'Palmeirópolis',
				id_cidade: 5517,
			},
			{
				cidade: 'Paraíso do Tocantins',
				id_cidade: 5518,
			},
			{
				cidade: 'Paranã',
				id_cidade: 5519,
			},
			{
				cidade: "Pau d'Arco",
				id_cidade: 5520,
			},
			{
				cidade: 'Pedro Afonso',
				id_cidade: 5521,
			},
			{
				cidade: 'Peixe',
				id_cidade: 5522,
			},
			{
				cidade: 'Pequizeiro',
				id_cidade: 5523,
			},
			{
				cidade: 'Pindorama do Tocantins',
				id_cidade: 5524,
			},
			{
				cidade: 'Piraquê',
				id_cidade: 5525,
			},
			{
				cidade: 'Pium',
				id_cidade: 5526,
			},
			{
				cidade: 'Ponte Alta do Bom Jesus',
				id_cidade: 5527,
			},
			{
				cidade: 'Ponte Alta do Tocantins',
				id_cidade: 5528,
			},
			{
				cidade: 'Porto Alegre do Tocantins',
				id_cidade: 5529,
			},
			{
				cidade: 'Porto Nacional',
				id_cidade: 5530,
			},
			{
				cidade: 'Praia Norte',
				id_cidade: 5531,
			},
			{
				cidade: 'Presidente Kennedy',
				id_cidade: 5532,
			},
			{
				cidade: 'Pugmil',
				id_cidade: 5533,
			},
			{
				cidade: 'Recursolândia',
				id_cidade: 5534,
			},
			{
				cidade: 'Riachinho',
				id_cidade: 5535,
			},
			{
				cidade: 'Rio da Conceição',
				id_cidade: 5536,
			},
			{
				cidade: 'Rio dos Bois',
				id_cidade: 5537,
			},
			{
				cidade: 'Rio Sono',
				id_cidade: 5538,
			},
			{
				cidade: 'Sampaio',
				id_cidade: 5539,
			},
			{
				cidade: 'Sandolândia',
				id_cidade: 5540,
			},
			{
				cidade: 'Araguanã',
				id_cidade: 5438,
			},
			{
				cidade: 'Santa Fé do Araguaia',
				id_cidade: 5541,
			},
			{
				cidade: 'Santa Maria do Tocantins',
				id_cidade: 5542,
			},
			{
				cidade: 'Alvorada',
				id_cidade: 5430,
			},
			{
				cidade: 'Abreulândia',
				id_cidade: 5426,
			},
			{
				cidade: 'Ananás',
				id_cidade: 5431,
			},
			{
				cidade: 'Santa Rita do Tocantins',
				id_cidade: 5543,
			},
			{
				cidade: 'Santa Rosa do Tocantins',
				id_cidade: 5544,
			},
			{
				cidade: 'Santa Tereza do Tocantins',
				id_cidade: 5545,
			},
			{
				cidade: 'Santa Terezinha do Tocantins',
				id_cidade: 5546,
			},
			{
				cidade: 'Aguiarnópolis',
				id_cidade: 5427,
			},
			{
				cidade: 'Aliança do Tocantins',
				id_cidade: 5428,
			},
			{
				cidade: 'Almas',
				id_cidade: 5429,
			},
			{
				cidade: 'Aparecida do Rio Negro',
				id_cidade: 5433,
			},
			{
				cidade: 'Aurora do Tocantins',
				id_cidade: 5443,
			},
			{
				cidade: 'Axixá do Tocantins',
				id_cidade: 5444,
			},
			{
				cidade: 'Bandeirantes do Tocantins',
				id_cidade: 5446,
			},
			{
				cidade: 'Barra do Ouro',
				id_cidade: 5447,
			},
			{
				cidade: 'Bernardo Sayão',
				id_cidade: 5449,
			},
			{
				cidade: 'Brasilândia do Tocantins',
				id_cidade: 5451,
			},
			{
				cidade: 'Brejinho de Nazaré',
				id_cidade: 5452,
			},
			{
				cidade: 'Buriti do Tocantins',
				id_cidade: 5453,
			},
			{
				cidade: 'Couto de Magalhães',
				id_cidade: 5467,
			},
			{
				cidade: 'Campos Lindos',
				id_cidade: 5455,
			},
			{
				cidade: 'Cariri do Tocantins',
				id_cidade: 5456,
			},
			{
				cidade: 'Carrasco Bonito',
				id_cidade: 5458,
			},
			{
				cidade: 'Chapada da Natividade',
				id_cidade: 5461,
			},
			{
				cidade: 'Chapada de Areia',
				id_cidade: 5462,
			},
			{
				cidade: 'Colinas do Tocantins',
				id_cidade: 5463,
			},
			{
				cidade: 'Conceição do Tocantins',
				id_cidade: 5466,
			},
			{
				cidade: 'Crixás do Tocantins',
				id_cidade: 5469,
			},
			{
				cidade: 'Divinópolis do Tocantins',
				id_cidade: 5472,
			},
			{
				cidade: 'Dois Irmãos do Tocantins',
				id_cidade: 5473,
			},
			{
				cidade: 'Formoso do Araguaia',
				id_cidade: 5479,
			},
			{
				cidade: 'Fortaleza do Tabocão',
				id_cidade: 5480,
			},
			{
				cidade: 'Itaporã do Tocantins',
				id_cidade: 5489,
			},
			{
				cidade: 'Jaú do Tocantins',
				id_cidade: 5490,
			},
			{
				cidade: 'Lagoa da Confusão',
				id_cidade: 5492,
			},
			{
				cidade: 'Lagoa do Tocantins',
				id_cidade: 5493,
			},
			{
				cidade: 'Marianópolis do Tocantins',
				id_cidade: 5498,
			},
			{
				cidade: 'Maurilândia do Tocantins',
				id_cidade: 5500,
			},
			{
				cidade: 'Miracema do Tocantins',
				id_cidade: 5501,
			},
			{
				cidade: 'Monte do Carmo',
				id_cidade: 5503,
			},
			{
				cidade: 'Monte Santo do Tocantins',
				id_cidade: 5504,
			},
			{
				cidade: 'Nova Rosalândia',
				id_cidade: 5509,
			},
			{
				cidade: 'Novo Acordo',
				id_cidade: 5510,
			},
			{
				cidade: 'Novo Alegre',
				id_cidade: 5511,
			},
			{
				cidade: 'Novo Jardim',
				id_cidade: 5512,
			},
			{
				cidade: 'Oliveira de Fátima',
				id_cidade: 5513,
			},
			{
				cidade: 'Palmeiras do Tocantins',
				id_cidade: 5516,
			},
			{
				cidade: 'São Valério da Natividade',
				id_cidade: 5552,
			},
			{
				cidade: 'São Bento do Tocantins',
				id_cidade: 5547,
			},
			{
				cidade: 'São Félix do Tocantins',
				id_cidade: 5548,
			},
			{
				cidade: 'Angico',
				id_cidade: 5432,
			},
			{
				cidade: 'São Miguel do Tocantins',
				id_cidade: 5549,
			},
			{
				cidade: 'São Salvador do Tocantins',
				id_cidade: 5550,
			},
			{
				cidade: 'São Sebastião do Tocantins',
				id_cidade: 5551,
			},
			{
				cidade: 'Silvanópolis',
				id_cidade: 5553,
			},
			{
				cidade: 'Sítio Novo do Tocantins',
				id_cidade: 5554,
			},
			{
				cidade: 'Sucupira',
				id_cidade: 5555,
			},
			{
				cidade: 'Taguatinga',
				id_cidade: 5556,
			},
			{
				cidade: 'Taipas do Tocantins',
				id_cidade: 5557,
			},
			{
				cidade: 'Talismã',
				id_cidade: 5558,
			},
			{
				cidade: 'Aragominas',
				id_cidade: 5434,
			},
			{
				cidade: 'Araguacema',
				id_cidade: 5435,
			},
			{
				cidade: 'Araguaçu',
				id_cidade: 5436,
			},
			{
				cidade: 'Araguaína',
				id_cidade: 5437,
			},
			{
				cidade: 'Araguatins',
				id_cidade: 5439,
			},
			{
				cidade: 'Arapoema',
				id_cidade: 5440,
			},
			{
				cidade: 'Tocantínia',
				id_cidade: 5559,
			},
			{
				cidade: 'Tocantinópolis',
				id_cidade: 5560,
			},
			{
				cidade: 'Tupirama',
				id_cidade: 5561,
			},
			{
				cidade: 'Tupiratins',
				id_cidade: 5562,
			},
			{
				cidade: 'Wanderlândia',
				id_cidade: 5563,
			},
			{
				cidade: 'Xambioá',
				id_cidade: 5564,
			},
		],
	},
];
