import fetch from './api-service';

const diaSemanaService = {};

diaSemanaService.getPaginate = async function () {
	return fetch({
		url: '/v1/rest/dia-semana',
		method: 'get',
		headers: {is_app: true},
	});
};

diaSemanaService.getAll = async function () {
	return fetch({
		url: '/v1/rest/dia-semana',
		method: 'get',
	});
};

diaSemanaService.get = async id => {
	return fetch({
		url: `/v1/rest/dia-semana/${id}`,
		method: 'get',
	});
};

export default diaSemanaService;
