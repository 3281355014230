import axios from 'axios';
import {AUTH_TOKEN} from '../redux/constants/Auth';
import {env} from 'config/env-config';

let baseUrl = env.API_ENDPOINT_URL;

const api = axios.create({
	baseURL: baseUrl,
	headers: {
		'Content-Type': 'application/json',
	},
	// timeout: 5000,
});

api.interceptors.request.use(async config => {
	const jwtToken = config?.token ?? localStorage.getItem(AUTH_TOKEN);
	if (jwtToken) {
		config.headers.Authorization = `Bearer ${jwtToken}`;
		// config.headers.Authorization = `Bearer ${token}`;
	}

	if (config.connectionTimeout) {
		const source = axios.CancelToken.source();
		setTimeout(() => {
			source.cancel();
		}, config.connectionTimeout);
		config.cancelToken = source.token;
	}
	// config.headers.Authorization = `Bearer ${token}`;
	return config;
});

export default api;
