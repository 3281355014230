import fetch from './api-service';

const alunoService = {};

alunoService.getAll = async function () {
	return fetch({
		url: '/v1/rest/aluno',
		method: 'get',
		headers: {is_app: true},
	});
};
alunoService.get = async id => {
	return fetch({
		url: `/v1/rest/aluno/${id}`,
		method: 'get',
		headers: {is_app: true},
	});
};

alunoService.getDisciplinasAluno = async id => {
	return fetch({
		url: `/v1/rest/aluno-disciplina/${id}`,
		method: 'get',
		headers: {is_app: true},
	});
};

alunoService.post = async function (values) {
	return fetch({
		url: '/v1/rest/aluno',
		method: 'post',
		data: values,
	});
};

alunoService.update = async function (values, id) {
	return fetch({
		url: `/v1/rest/aluno/${id}`,
		method: 'put',
		data: values,
	});
};

alunoService.updateSenha = async function (values) {
	return fetch({
		url: `/v1/rpc/alterar-senha`,
		method: 'post',
		data: values,
	});
};

export default alunoService;
