const dev = {
	API_ENDPOINT_URL: 'http://localhost:8099/gradu-api/gradu/api/',
	PUBLIC_PREFIX_PATH: '',
};

const prod = {
	API_ENDPOINT_URL: 'https://app.gradu.io/gradu-api/gradu/api',
	PUBLIC_PREFIX_PATH: '',
};

const test = {
	API_ENDPOINT_URL: 'https://homolog.dimo.com.br/gradu-api/gradu/api',
	PUBLIC_PREFIX_PATH: '/gradu',
};

const getEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case 'development':
			return dev;
		case 'production':
			return prod;
		case 'test':
			return test;
		default:
			break;
	}
};

export const env = getEnv();
