import {Button, PageContent, Text} from '_atoms';
import {Row, Col} from 'antd';
import {Colors, Typography} from '_styles';
import {Icon} from '_atoms';
import {useLocation, useNavigate} from 'react-router-dom';
import React from 'react';

const PageFooter = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const getDestination = () => {
		const currentPath = location.pathname;
		if (currentPath === '/landing-page-estudante') {
			return '/duvidas-estudante';
		} else if (currentPath === '/landing-page-instituicao') {
			return '/duvidas-instituicao';
		} else {
			return '/duvidas';
		}
	};

	return (
		<div style={{width: '100%'}}>
			<PageContent>
				<Row
					gutter={[15, 15]}
					style={{
						backgroundColor: Colors.white,
						paddingTop: 80,
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
					}}>
					<Col lg={12} style={{display: 'flex', justifyContent: 'start'}}>
						<Button
							style={{display: 'flex', justifyContent: 'center'}}
							onClick={() => navigate(getDestination())}>
							Dúvidas
						</Button>
					</Col>
					<Col
						lg={12}
						style={{
							display: 'flex',
							justifyContent: 'end',
						}}>
						{/*<Button*/}
						{/*	type={'custom'}*/}
						{/*	onClick={() => {*/}
						{/*		window.open('https://wa.me/554999509951');*/}
						{/*	}}>*/}
						{/*	<Icon name={'akar-icons:whatsapp-fill'} color={Colors.orange} size={32} />*/}
						{/*</Button>*/}
						<Button type={'custom'}>
							<div
								style={{
									width: 32,
									height: 32,
									borderRadius: 32,
									backgroundColor: Colors.orange,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									marginTop: -4,
								}}>
								<Icon name={'akar-icons:tiktok-fill'} color={Colors.white} size={22} />
							</div>
						</Button>

						<Button type={'custom'}>
							<Icon name={'akar-icons:linkedin-box-fill'} color={Colors.orange} size={32} />
						</Button>
						<Button
							type={'custom'}
							onClick={() => window.open('https://instagram.com/gradu_disciplinasisoladas')}>
							<Icon name={'akar-icons:instagram-fill'} color={Colors.orange} size={32} />
						</Button>
						<Button type={'custom'}>
							<Icon name={'akar-icons:facebook-fill'} color={Colors.orange} size={32} />
						</Button>
					</Col>
				</Row>
				<Row style={{backgroundColor: Colors.white}}>
					<div style={{marginTop: 22, justifyContent: 'center', display: 'flex', flex: 1}}>
						<Text style={{...Typography.medium20, color: Colors.orange}}>Gradu © 2023</Text>
					</div>
				</Row>
			</PageContent>
		</div>
	);
};

export default PageFooter;
