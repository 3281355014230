import {SET_USER, UPDATE_USER} from '../constants/User';

const user = (state = {}, action) => {
	switch (action.type) {
		case SET_USER:
			return {...state, ...action.pessoa};
		case UPDATE_USER:
			return {
				...state,
			};
		default:
			return state;
	}
};

export default user;
