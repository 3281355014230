import React from 'react';
import './App.less';
import Routes from './routes';
import {Provider} from 'react-redux';
import store from './redux/store';
import {ThemeSwitcherProvider} from 'react-css-theme-switcher';
import {Colors} from '_styles';
import {PageFooter} from '_molecules';
const themes = {
	// dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
	// light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};
const App = () => {
	return (
		<div className="App">
			<Provider store={store}>
				<ThemeSwitcherProvider
					themeMap={themes}
					defaultTheme={'light'} /*insertionPoint="styles-insertion-point"*/
				>
					{/*<div style={{height: '100vh', display: 'flex', alignContent: 'center', justifyContent: 'center'}}>*/}
						<Routes />
					{/*</div>*/}
				</ThemeSwitcherProvider>
			</Provider>
		</div>
	);
};

export default App;
