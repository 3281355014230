import fetch from './api-service';

const tipoPessoaService = {};

export const ALUNO = 1;
export const FACULDADE = 2;
export const REPRESENTANTE = 3;

tipoPessoaService.getAll = async function () {
	return fetch({
		url: '/v1/rest/tipo-pessoa',
		method: 'get',
	});
};

tipoPessoaService.getPaginate = async function () {
	return fetch({
		url: '/v1/rest/tipo-pessoa',
		method: 'get',
		headers: {is_app: true},
	});
};

tipoPessoaService.get = async id => {
	return fetch({
		url: `/v1/rest/tipo-pessoa/${id}`,
		method: 'get',
	});
};

export default tipoPessoaService;
