import fetch from './api-service';

const cadastroEventosService = {};

cadastroEventosService.getAll = async function () {
	return fetch({
		url: '/v1/rest/cadastro-eventos',
		method: 'get',
	});
};

cadastroEventosService.getPaginate = async function () {
	return fetch({
		url: '/v1/rest/cadastro-eventos',
		method: 'get',
		headers: {is_app: true},
	});
};

cadastroEventosService.post = async function (values) {
	return fetch({
		url: '/v1/rest/cadastro-eventos',
		method: 'post',
		data: values,
	});
};

export default cadastroEventosService;
