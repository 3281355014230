import Nunito from '../fonts/Nunito-Regular.ttf';
export const fontFamily = Nunito;

export const regular = {
	fontWeight: '400',
	fontFamily: fontFamily,
};

export const medium = {
	fontWeight: '500',
	fontFamily: fontFamily,
};

export const strong = {
	fontWeight: '500',
	fontFamily: fontFamily,
};

export const semiBold = {
	fontWeight: '600',
	fontFamily: fontFamily,
};

export const bold = {
	fontWeight: '700',
	fontFamily: fontFamily,
};

export const extraBold = {
	fontWeight: '800',
	fontFamily: fontFamily,
};

export const black = {
	fontWeight: '900',
	fontFamily: fontFamily,
};

export const size11 = {
	fontSize: 11,
};
export const size12 = {
	fontSize: 12,
};

export const size13 = {
	fontSize: 13,
};

export const size14 = {
	fontSize: 14,
};

export const size15 = {
	fontSize: 15,
};

export const size16 = {
	fontSize: 16,
};

export const size18 = {
	fontSize: 18,
};

export const size20 = {
	fontSize: 20,
};

export const size22 = {
	fontSize: 22,
};

export const size24 = {
	fontSize: 24,
};
export const size25 = {
	fontSize: 25,
};
export const size26 = {
	fontSize: 26,
};
export const size28 = {
	fontSize: 28,
};

export const size36 = {
	fontSize: 36,
};

export const size40 = {
	fontSize: 40,
};

export const size30 = {
	fontSize: 30,
};

export const size32 = {
	fontSize: 32,
};

export const bold14 = {
	...bold,
	...size14,
};
export const bold20 = {
	...bold,
	...size20,
};
export const bold22 = {
	...bold,
	...size22,
};
export const bold24 = {
	...bold,
	...size24,
};
export const bold25 = {
	...bold,
	...size25,
};
export const bold28 = {
	...bold,
	...size24,
};

export const bold16 = {
	...bold,
	...size16,
};

export const bold18 = {
	...bold,
	...size18,
};

export const bold12 = {
	...bold,
	...size12,
};

export const bold13 = {
	...bold,
	...size13,
};

export const bold36 = {
	...bold,
	...size36,
};

export const bold40 = {
	...bold,
	...size40,
};
export const bold30 = {
	...bold,
	...size30,
};

export const bold32 = {
	...bold,
	...size32,
};

export const regular16 = {
	...regular,
	...size16,
};

export const regular12 = {
	...regular,
	...size12,
};
export const regular11 = {
	...regular,
	...size11,
};

export const regular13 = {
	...regular,
	...size13,
};

export const regular14 = {
	...regular,
	...size14,
};

export const regular15 = {
	...regular,
	...size15,
};

export const regular18 = {
	...regular,
	...size18,
};

export const regular20 = {
	...regular,
	...size20,
};

export const regular22 = {
	...regular,
	...size22,
};

export const regular24 = {
	...regular,
	...size24,
};

export const regular25 = {
	...regular,
	...size25,
};

export const regular26 = {
	...regular,
	...size26,
};

export const strong16 = {
	...strong,
	...size16,
};

export const strong22 = {
	...strong,
	...size22,
};
export const strong26 = {
	...strong,
	...size26,
};

export const medium12 = {
	...medium,
	...size12,
};

export const medium13 = {
	...medium,
	...size13,
};

export const medium14 = {
	...medium,
	...size14,
};

export const medium15 = {
	...medium,
	...size15,
};

export const medium16 = {
	...medium,
	...size16,
};

export const medium20 = {
	...medium,
	...size20,
};
export const medium22 = {
	...medium,
	...size22,
};

export const medium24 = {
	...medium,
	...size24,
};

export const medium18 = {
	...medium,
	...size18,
};

export const semiBold16 = {
	...semiBold,
	...size16,
};

export const semiBold18 = {
	...semiBold,
	...size18,
};

export const semiBold30 = {
	...semiBold,
	...size30,
};

export const extraBold16 = {
	...extraBold,
	...size16,
};
export const extraBold30 = {
	...extraBold,
	...size30,
};

export const black18 = {
	...black,
	...size18,
};

export const regular30 = {
	...regular,
	...size30,
};

export const semiBold20 = {
	...semiBold,
	...size20,
};
export const strong20 = {
	...strong,
	...size20,
};
