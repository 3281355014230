import {FormSubTitle, PageContent, Password, Row, Text, TextInput} from '_atoms';
import {Colors, Typography} from '_styles';
import {Form} from '_organisms';
import {useEffect, useState} from 'react';
import {DatePicker, Select} from '_molecules';
import {api, CidadesService, EstadosService} from '_services';
import AlunoService from '_services/alunoService';
import {getUsuario} from '_services/usuarioService';
import moment from 'moment/moment';
import dayjs from 'dayjs';
import {AUTH_TOKEN} from '../../../redux/constants/Auth';
import {useNavigate} from 'react-router-dom';
import authService from '_services/auth-service';
import {SET_USER, USER} from '../../../redux/constants/User';
import store from '../../../redux/store';
import {ALUNO} from '_services/tipoPessoaService';
import {getBySigla} from '_services/estados-service';

export default ({backOptionClick, setOpen = false, google = null, facebook = null, ...props}) => {
	const navigate = useNavigate();
	const user = getUsuario();
	const autenticado = localStorage.getItem(AUTH_TOKEN);
	const [values, setValues] = useState({});
	const [erroSenha, setErroSenha] = useState(false);
	const [waiting, setWaiting] = useState(false);

	useEffect(() => {
		if (google) {
			setValues({...values, email: google.email, nome: google.name});
		}
		if (facebook) {
			setValues({...values, email: google.email, nome: google.name});
		}
	}, [google, facebook]);

	const updateValues = response => {
		setValues({
			email: response?.pessoa?.usuario?.email,
			nome: response?.pessoa?.nome,
			data_nascimento: moment(response?.data_nascimento),
			cpf: response?.cpf,
			cep: response?.pessoa?.endereco?.cep,
			estado: response?.pessoa?.endereco?.estado,
			cidade: response?.pessoa?.endereco?.cidade,
			logradouro: response?.pessoa?.endereco?.logradouro,
			numero: response?.pessoa?.endereco?.numero,
			bairro: response?.pessoa?.endereco?.bairro,
			telefone: response?.pessoa?.telefone,
			interesse: response?.interesse,
		});
	};

	useEffect(() => {
		(async () => {
			AlunoService.get(user.pessoa_fisica.id).then(resp => {
				updateValues(resp.data);
			});
		})();
	}, []);

	const getCep = async e => {
		const cep = e.target.value?.replace(/\D/g, '');
		if (cep.length < 8) return;
		try {
			const response = await api.get(`/ws/${cep}/json`, {baseURL: 'https://viacep.com.br'});
			const data = response.data;
			const estado = EstadosService.getBySigla(data.uf)?.estado;
			// const cidade = CidadesService.findByDesc(estado, data.localidade)?.id;
			setValues({
				...values,
				cep,
				estado,
				cidade: data.localidade,
				bairro: data.bairro,
				logradouro: data.logradouro,
			});
		} catch (e) {
			setValues({...values, cep});
		}
	};

	const disabledDate = current => {
		return current && current > dayjs().endOf('day');
	};

	const postData = values => {
		const retorno = {
			pessoa: {
				nome: values.nome,
				telefone: values.telefone,
				endereco: {
					cep: values.cep,
					estado: values.estado,
					cidade: values.cidade,
					logradouro: values.logradouro,
					bairro: values.bairro,
					numero: values.numero,
				},
			},
			responsavel: {
				cpf: values.cpf,
				data_nascimento: values.data_nascimento,
				usuario: {
					email: values.email,
					senha: values.senha,
				},
			},
		};
		return retorno;
	};

	const onLogin = async (email, senha) => {
		setWaiting(true);
		let redirect = 1;
		const response = await authService.login(email.toLowerCase(), senha);
		try {
			const data = response.data;
			localStorage.setItem(AUTH_TOKEN, data.token);
			localStorage.setItem(USER, JSON.stringify(data.pessoa));
			redirect = data.pessoa.id_tipo_pessoa;
			store.dispatch({
				type: SET_USER,
				pessoa: data.pessoa,
			});
			setWaiting(false);
			navigate('/home');
		} catch (e) {
			setWaiting(false);
		}
	};

	const onFinish = async values => {
		setWaiting(true);
		comparaSenhas(values.senha, values.confirmacao_senha);
		try {
			if (!autenticado) {
				const response = await AlunoService.post(postData(values));
				if (!!setOpen) {
					setOpen(false);
				}
				if (response?.data) {
					setWaiting(false);
					await onLogin(values.email, values.senha);
				}
			} else {
				const update = await AlunoService.update(postData(values), user?.id);
				if (update?.data) {
					setWaiting(false);
					window.location.reload();
				}
			}
		} catch (e) {
			setWaiting(false);
			setErroSenha(
				!!e?.response?.data?.error?.errors
					? e?.response?.data?.error?.errors[0]?.message
					: !!e?.response?.data?.error?.name
					? e?.response?.data?.error?.name
					: e?.response?.data?.error,
			);
		}
	};

	const comparaSenhas = (senha1, senha2) => {
		let erro = senha1 !== senha2;
		if (erro) {
			setErroSenha('As senhas devem ser iguais');
		} else setErroSenha(false);
	};

	const validateCPF = (_, value) => {
		const cpf = value.replace(/[^\d]+/g, ''); // Remove non-digits from the input
		if (cpf.length !== 11) {
			return Promise.reject(new Error('CPF must have 11 digits'));
		}

		let sum = 0;
		let remainder;

		for (let i = 1; i <= 9; i++) {
			sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
		}

		remainder = (sum * 10) % 11;

		if (remainder === 10 || remainder === 11) {
			remainder = 0;
		}

		if (remainder !== parseInt(cpf.substring(9, 10))) {
			return Promise.reject(new Error('Invalid CPF'));
		}

		sum = 0;

		for (let i = 1; i <= 10; i++) {
			sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
		}

		remainder = (sum * 10) % 11;

		if (remainder === 10 || remainder === 11) {
			remainder = 0;
		}

		if (remainder !== parseInt(cpf.substring(10, 11))) {
			return Promise.reject(new Error('Invalid CPF'));
		}

		return Promise.resolve();
	};

	const validatePassword = (_, value) => {
		if (value && value.length >= 8 && /\d/.test(value) && /[a-zA-Z]/.test(value)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('A senha deve ter pelo menos 8 caracteres e conter letras e números.'));
	};

	const fields = [
		{type: TextInput, name: 'nome', label: 'Nome completo', rules: {required: true}},
		[
			{
				type: TextInput,
				name: 'cpf',
				label: 'CPF',
				mask: '999.999.999-99',
				maskChar: '',
				rules: {required: true, min: 14, validator: validateCPF, message: 'CPF inválido'},
			},
			{
				type: DatePicker,
				mask: '99/99/9999',
				name: 'data_nascimento',
				label: 'Data de nascimento',
				rules: {required: true},
				disabledDate,
				setValue: value => {
					setValues({...values, data_nascimento: value});
				},
			},
		],
		{type: FormSubTitle, title: 'Endereço'},
		[
			{
				type: TextInput,
				name: 'cep',
				label: 'Cep',
				mask: '99999-999',
				maskChar: '',
				rules: {required: true, min: 8},
				onChange: getCep,
			},
			{
				type: Select,
				showSearch: true,
				options: EstadosService.asOption(EstadosService.getAll()),
				name: 'estado',
				label: 'Estado',
				onChange: value => {
					if (value != values.estado) {
						setValues({...values, estado: value, cidade: undefined});
					}
				},
				rules: {required: true},
			},
			{
				type: Select,
				showSearch: true,
				options: CidadesService.asOption(CidadesService.findByEstado(values.estado)),
				emptyText: 'Selecione o estado',
				name: 'cidade',
				label: 'Cidade',
				rules: {required: true},
			},
		],
		[
			{type: TextInput, name: 'logradouro', label: 'Logradouro', rules: {required: true}},
			{type: TextInput, name: 'numero', label: 'Número', rules: {required: true}},
			{type: TextInput, name: 'bairro', label: 'Bairro', rules: {required: true}},
		],
		{type: FormSubTitle, title: 'Contato'},
		[
			{
				type: TextInput,
				name: 'telefone',
				mask: '(99) 99999-9999',
				maskChar: '',
				label: 'Telefone',
				rules: {required: true, min: 15},
			},
		],
		{type: FormSubTitle, title: 'Credenciais'},
		{
			type: TextInput,
			name: 'email',
			label: 'E-mail',
			rules: {
				required: true,
				message: 'Por favor insira seu e-mail!',
				type: 'email',
			},
		},
		[
			{
				type: Password,
				name: 'senha',
				label: 'Senha',
				maskChar: '',
				rules: {
					required: true,
					validator: validatePassword,
					min: 8,
					minMessage: 'A senha deve ter pelo menos 8 caracteres e conter letras e números.',
				},
			},
			{
				type: Password,
				name: 'confirmacao_senha',
				label: 'Confirmação da senha',
				rules: {
					required: true,
					erroSenha,
					validator: validatePassword,
					min: 8,
					minMessage: 'A senha deve ter pelo menos 8 caracteres e conter letras e números.',
				},
			},
		],
	];

	const fieldsAutenticado = [
		{type: TextInput, name: 'nome', label: 'Nome completo', rules: {required: true}},
		[
			{
				type: TextInput,
				name: 'cpf',
				label: 'CPF',
				mask: '999.999.999-99',
				maskChar: '',
				rules: {required: true, validator: validateCPF, message: 'CPF inválido'},
			},
			{
				type: DatePicker,
				name: 'data_nascimento',
				label: 'Data de nascimento',
				rules: {required: true},
				disabledDate,
				setValue: value => {
					setValues({...values, data_nascimento: value});
				},
			},
		],
		{type: FormSubTitle, title: 'Endereço'},
		[
			{
				type: TextInput,
				name: 'cep',
				label: 'Cep',
				mask: '99999-999',
				maskChar: '',
				rules: {required: true, min: 8},
				onChange: getCep,
			},
			{
				type: Select,
				showSearch: true,
				options: EstadosService.asOption(EstadosService.getAll()),
				name: 'estado',
				label: 'Estado',
				onChange: value => {
					if (value != values.estado) {
						setValues({...values, estado: value, cidade: undefined});
					}
				},
				rules: {required: true},
			},
			{
				type: Select,
				showSearch: true,
				options: CidadesService.asOption(CidadesService.findByEstado(values.estado)),
				emptyText: 'Selecione o estado',
				name: 'cidade',
				label: 'Cidade',
				rules: {required: true},
			},
		],
		[
			{type: TextInput, name: 'logradouro', label: 'Logradouro', rules: {required: true}},
			{type: TextInput, name: 'numero', label: 'Número', rules: {required: true}},
			{type: TextInput, name: 'bairro', label: 'Bairro', rules: {required: true}},
		],
		{type: FormSubTitle, title: 'Contato'},
		[
			{
				type: TextInput,
				name: 'telefone',
				mask: '(99) 99999-9999',
				maskChar: '',
				label: 'Telefone',
				rules: {required: true, min: 15},
			},
		],
		{type: FormSubTitle, title: 'Credenciais'},
		{
			type: TextInput,
			name: 'email',
			label: 'E-mail',
			rules: {
				required: true,
				message: 'Por favor insira seu e-mail!',
				type: 'email',
			},
		},
	];

	return (
		<PageContent>
			<Row style={{display: 'flex', justifyContent: 'center'}}>
				<Text style={{...Typography.extraBold30, color: Colors.primary}}>
					{!autenticado ? 'Cadastro do aluno' : 'Atualizar dados'}
				</Text>
			</Row>
			<Form
				waiting={waiting}
				fields={!!autenticado ? fieldsAutenticado : fields}
				values={values}
				onValueChange={setValues}
				confirmLabel={!autenticado ? 'Cadastrar' : 'Atualizar'}
				backOption={!!autenticado}
				backOptionClick={backOptionClick}
				backOptionLabel={'Voltar'}
				onFinish={() => onFinish(values)}
				error={erroSenha}
			/>
		</PageContent>
	);
};
