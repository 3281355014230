import React, {lazy, Suspense, useEffect, useState} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {Loading} from '_atoms';
import {BotaoWhats, Error, PageFooter, PageHeader} from '_molecules';
import {AUTH_TOKEN} from '../../redux/constants/Auth';
import {getUsuario} from '_services/usuarioService';
import {ALUNO, FACULDADE, REPRESENTANTE} from '_services/tipoPessoaService';
import {useSelector} from 'react-redux';
import {Colors} from '_styles';
import {Grid} from 'antd';
import utils from '../../utils';
const {useBreakpoint} = Grid;

const App = () => {
	const screens = utils.getBreakPoint(useBreakpoint());
	const isMobile = !screens.includes('md');

	const [user, setUser] = useState(getUsuario());
	const reducer = useSelector(state => state.userReducer);

	useEffect(() => {
		setUser(getUsuario());
	}, [reducer]);

	const getLazyView = view => {
		let temPermissao = true;
		if (!!view?.permission) {
			const busca = view.permission.find(v => v === user?.id_tipo_pessoa);
			temPermissao = busca;
		}

		if (view.token && !localStorage.getItem(AUTH_TOKEN)) {
			return <Navigate to={'/login'} />;
		}

		if (!view.token) temPermissao = true;

		const View = lazy(() => import(`${view.elementPath}`));

		return (
			<>
				{!!temPermissao && (
					<Suspense
						fallback={
							<div style={{padding: 40}}>
								<Loading cover="content" />
							</div>
						}>
						<div style={{backgroundColor: Colors.white}}>
							<PageHeader {...view.headerOptions} />
							<View />
							<div
								style={{
									flex: 1,
									display: 'flex',
									alignItems: 'flex-end',
									paddingTop: 150,
									paddingBottom: 50,
									backgroundColor: Colors.white,
									justifyContent: 'center',
								}}>
								{!isMobile && <BotaoWhats />}

								<PageFooter />
							</div>
						</div>
					</Suspense>
				)}
				{!temPermissao && <Error />}
			</>
		);
	};

	const views = [
		{
			path: 'login',
			elementPath: './login',
			headerOptions: {
				showLeftButton: false,
				showRightButton: false,
			},
			token: false,
		},
		{
			path: 'cadastro-instituicao',
			elementPath: './cadastro-instituicao',
			permission: [FACULDADE, REPRESENTANTE],
			token: false,
		},
		{
			path: 'cadastro-aluno',
			elementPath: './cadastro-aluno',
			permission: [ALUNO],
			token: false,
		},
		//HOME DO ALUNO
		{
			path: 'home',
			elementPath: './home',
			headerOptions: {
				// showLeftButton: false,
				// showRightButton: false,
			},
			// permission: [ALUNO],
			// token: true,
		},
		{
			path: 'cadastro-disciplina',
			elementPath: './cadastro-disciplina',
			headerOptions: {},
			permission: [FACULDADE, REPRESENTANTE],
			token: true,
		},
		{
			path: 'home-instituicao',
			elementPath: './home-instituicao',
			headerOptions: {},
			permission: [FACULDADE, REPRESENTANTE],
			token: true,
		},
		{
			path: 'detalhes-venda-disciplina/:id',
			elementPath: './detalhes-venda-disciplina',
			headerOptions: {},
			permission: [FACULDADE, REPRESENTANTE],
			token: true,
		},
		{
			path: 'pagamento',
			elementPath: './pagamento',
			headerOptions: {},
			permission: [ALUNO],
			token: true,
		},
		{
			path: 'matricula-disciplina',
			elementPath: './matricula-disciplina',
			headerOptions: {},
			permission: [ALUNO],
			token: true,
		},
		//ACESSA QUANDO É ALUNO E CLICA NO SEU NOME NO HEADER
		{
			path: 'perfil-aluno',
			elementPath: './perfil-aluno',
			headerOptions: {},
			permission: [ALUNO],
			token: true,
		},
		{
			path: 'duvidas',
			elementPath: './duvidas',
			headerOptions: {},
			permission: [ALUNO, FACULDADE, REPRESENTANTE],
			token: false,
		},
		{
			path: 'disciplina',
			elementPath: './disciplina',
			headerOptions: {},
			permission: [ALUNO, FACULDADE, REPRESENTANTE],
			token: false,
		},
		{
			path: 'politica-de-privacidade',
			elementPath: './politica-de-privacidade',
			headerOptions: {},
			token: false,
		},
		// LANDING PAGES ESTUDANTE E INSTITUIÇÃO
		{
			path: 'landing-page-estudante',
			elementPath: './landing-page-estudante',
			headerOptions: {},
			token: false,
		},
		{
			path: 'landing-page-instituicao',
			elementPath: './landing-page-instituicao',
			headerOptions: {},
			token: false,
		},
		// DÚVIDAS ESTUDANTE E INSTITUIÇÃO
		{
			path: 'duvidas-instituicao',
			elementPath: './duvidas-instituicao',
			headerOptions: {},
			token: false,
		},
		{
			path: 'duvidas-estudante',
			elementPath: './duvidas-estudante',
			headerOptions: {},
			token: false,
		},
	];

	return (
		<Routes>
			{views.map((view, k) => (
				<Route path={view.path} element={getLazyView(view)} key={k} />
			))}

			<Route
				path=""
				element={
					!!localStorage.getItem(AUTH_TOKEN) ? (
						user?.id_tipo_pessoa === 1 ? (
							<Navigate to={'home'} />
						) : (
							<Navigate to={'home-instituicao'} />
						)
					) : (
						<Navigate to={'home'} />
					)
				}
			/>
		</Routes>
	);
};

export default App;
