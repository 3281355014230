import React from 'react';
import {BrowserRouter, HashRouter} from 'react-router-dom';
import {Loading} from '_atoms';
import {UtilsService} from '_services';
import {Layout, Grid} from 'antd';
import {useThemeSwitcher} from 'react-css-theme-switcher';
import AppViews from '_pages/app';
import {Colors} from '_styles';
export default ({...props}) => {
	const {Content} = Layout;
	const {useBreakpoint} = Grid;
	const screens = UtilsService.getBreakPoint(useBreakpoint());
	const isMobile = !screens.includes('lg');

	const {status} = useThemeSwitcher();

	if (status === 'loading') {
		return <Loading cover="page" />;
	}

	return (
		<Layout className="app-layout">
			<Content>
				<HashRouter>
					<AppViews />
				</HashRouter>
			</Content>
		</Layout>
	);
};
